import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Colors } from 'modules/theme';

const FormError = styled.p`
  color: ${Colors.red};
  font-size: 14px;
  line-height: 17px;
  margin-top: 5px;
`;

const FormErrorComponent = props => {
  const { text } = props;
  if (!text) {
    return null;
  }
  return <FormError>{text}</FormError>;
};

FormErrorComponent.propTypes = {
  text: PropTypes.string,
};

export default FormErrorComponent;
