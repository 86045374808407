import React, { Component } from 'react';
import styled from 'styled-components';
import ButtonText from 'components/ButtonText';
import PropTypes from 'prop-types';

const Container = styled.div`
  margin: 30px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export default class LogoutView extends Component {
  render() {
    const { logout } = this.props;

    return (
      <Container>
        <ButtonText text="Logout" icon="sign-out-alt" theme="warning" onClick={logout} />
      </Container>
    );
  }
}

LogoutView.propTypes = {
  logout: PropTypes.func.isRequired,
};
