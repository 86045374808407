import React, { Component } from 'react';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Colors } from 'modules/theme';
import InputPassword from 'components/InputPassword';
import ButtonText from 'components/ButtonText';
import BG from 'components/BG';
import H3 from 'components/H3';
import Logo from 'components/Logo';
import FormError from 'components/FormError';
import PropTypes from 'prop-types';
import { PASS_REG_EXP } from '../../constants';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px auto 50px auto;
  padding 20px;
  width: 40%;
  text-align: center;
  @media (max-width: 970px) {
    width: 100%;
  }
`;

const LogoContainer = styled.div`
  margin-top: 80px;
  margin-bottom: 80px;
  text-align: center;
`;

const InputContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`;

const ErrorContainer = styled.div`
  text-align: left;
`;

export default class NewPasswordView extends Component {
  submit = values => {
    const { newPassword } = this.props;
    const { password } = values;

    newPassword(password);
  };

  render() {
    const initialValues = {
      password: '',
      passwordConfirm: '',
    };
    const { token_expired, resendInvitation } = this.props;

    return (
      <>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={this.submit}
          validationSchema={Yup.object().shape({
            password: Yup.string()
              .required('Required')
              .matches(
                PASS_REG_EXP,
                'Make sure your password has numbers one capital letter and one symbol',
              )
              .min(8, 'The password must have more than eight characters'),
            passwordConfirm: Yup.string().oneOf(
              [Yup.ref('password'), null],
              'Passwords must match',
            ),
          })}
        >
          {({ values, errors, touched, handleChange, handleBlur }) => (
            <Form>
              <Container>
                <LogoContainer>
                  <Logo />
                </LogoContainer>
                <BG>
                  {!token_expired && (
                    <>
                      <H3 color={Colors.lightBlue}>NEW PASSWORD</H3>
                      <InputContainer>
                        <InputPassword
                          placeholder="Password"
                          name="password"
                          type="password"
                          value={values.password}
                          onChange={handleChange}
                          secureTextEntry
                          onBlur={handleBlur}
                        />
                        <ErrorContainer>
                          <FormError
                            text={errors.password && touched.password && errors.password}
                          />
                        </ErrorContainer>
                      </InputContainer>
                      <InputContainer>
                        <InputPassword
                          placeholder="Confirm Password"
                          name="passwordConfirm"
                          type="password"
                          value={values.passwordConfirm}
                          onChange={handleChange}
                          secureTextEntry
                          onBlur={handleBlur}
                        />
                        <ErrorContainer>
                          <FormError
                            text={
                              errors.passwordConfirm &&
                              touched.passwordConfirm &&
                              errors.passwordConfirm
                            }
                          />
                        </ErrorContainer>
                      </InputContainer>

                      <ButtonText text="Save Password" theme="primary" full type="submit" />
                    </>
                  )}
                  {token_expired && (
                    <>
                      <H3 color={Colors.lightBlue}>
                        Token expired, try resending another invitation email.
                      </H3>
                      <ButtonText
                        text="Resend invitation"
                        theme="primary"
                        full
                        onClick={resendInvitation}
                        type="button"
                      />
                    </>
                  )}
                </BG>
              </Container>
            </Form>
          )}
        </Formik>
      </>
    );
  }
}

NewPasswordView.propTypes = {
  newPassword: PropTypes.func.isRequired,
  resendInvitation: PropTypes.func.isRequired,
  token_expired: PropTypes.bool,
};
