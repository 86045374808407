import React, { Component } from 'react';
import styled from 'styled-components';
import BG from 'components/BG';
import { Colors } from 'modules/theme';
import { Link } from 'react-router-dom';
import { Heading } from 'styled-minimal';
import Logo from 'components/Logo';
import H5 from 'components/H5';
import E400 from 'assets/media/images/E400.png';
import Col from 'components/Col';
import Row from 'components/Row';

import { connect } from 'react-redux';
import { logOut } from 'actions/index';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px auto 50px auto;
  padding 20px;
  width: 40%;
  text-align: center;
  @media (max-width: 970px) {
    width: 100%;
  }
`;

const LogoContainer = styled.div`
  margin-top: 80px;
  margin-bottom: 80px;
  text-align: center;
`;

class SettingsContainer extends Component {
  render() {
    const { logout } = this.props;

    return (
      <Container>
        <LogoContainer>
          <Logo />
        </LogoContainer>
        <BG>
          <Row>
            <Col>
              <img src={E400} width={120} style={{ opacity: 0.6 }} />
            </Col>
            <Col>
              <Heading fontSize={80} style={{ color: Colors.gray6, paddingTop: 0 }}>
                401
                <H5 fontSize={20} style={{ color: Colors.gray6, marginBottom: 20 }}>
                  Incorrect token is provided, try re-login{' '}
                </H5>
              </Heading>

              <Link to="/login" onClick={logout}>
                <Heading as="h2">Go login</Heading>
              </Link>
            </Col>
          </Row>
        </BG>
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    requireLogout: state.auth.requireLogout,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    logout: () => dispatch(logOut()),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(SettingsContainer);
