import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import './style.css';

export default class DatePickerComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      date: props.date || new Date(),
    };
  }

  render() {
    const { onChange, minDate } = this.props;
    const { date } = this.state;
    const today = new Date();

    return (
      <DatePicker
        dateFormat="MM/dd/yyyy h:mm aa"
        showTimeSelect
        selected={date}
        onChange={value => {
          this.setState({ date: value });
          onChange(value);
        }}
        minDate={minDate}
        maxDate={today}
      />
    );
  }
}

DatePickerComponent.propTypes = {
  date: PropTypes.object,
  minDate: PropTypes.object,
  onChange: PropTypes.func,
};
