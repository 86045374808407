import React, { Component } from 'react';
import styled from 'styled-components';
import { Colors } from 'modules/theme';
import InputSearch from 'components/InputSearch';
import Row from 'components/Row';
import H4 from 'components/H4';
import Loader from 'components/Loader';

import Table, { THeader, TableTitle, TRow, TableItem, TableSubItem, TBody } from 'components/Table';
import { FilterContainer } from 'components/Filter';
import ListEmpty from 'components/ListEmpty';
import PropTypes from 'prop-types';

const Container = styled.div``;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 20px;
`;

const SearchContainer = styled.div`
  padding: 10px;
  flex-direction: column;
`;

const TableContainer = styled.div`
  padding-bottom: 50px;
  margin-bottom: 50px;
`;

export default class OtaActiveDevicesListView extends Component {
  state = {
    search: '',
  };

  searchOnChange = search => {
    const { fetchUpdateActiveDevices, update_id } = this.props;
    this.setState({ search }, () => {
      fetchUpdateActiveDevices({ id: update_id, search });
    });
  };

  renderDevice = (deviceConfig, lastUpdate, type, draw_line) => (
    <Row style={draw_line && { borderBottom: `0.5px solid ${Colors.gray2}` }}>
      <TableSubItem color={Colors.gray} width="19%">
        {type}
      </TableSubItem>
      <TableSubItem color={Colors.gray} width="24%">
        {deviceConfig.name}
      </TableSubItem>
      <TableSubItem color={Colors.gray} width="18%">
        {lastUpdate ? lastUpdate.OtaUpdateVersion.version : 'Not found'}
      </TableSubItem>
    </Row>
  );

  renderItem = (item, key) => (
    <TRow key={key}>
      <TableSubItem width="16.66%">
        <H4 textAlign="center">{item.location.identificator}</H4>
      </TableSubItem>
      <TableSubItem width="16.66%">
        <H4 textAlign="center">{item.location.brand_name}</H4>
      </TableSubItem>
      <TableSubItem width="16.66%">
        <H4 textAlign="center">{item.location.account_name}</H4>
      </TableSubItem>
      <TableItem width="50.2%">
        {item.WaterFilterHead &&
          this.renderDevice(
            item.WaterFilterHead.WaterFilterConfiguration,
            item.wfLastUpdate,
            'Water Filter',
            item.SoftenerFilterHead || item.ReverseOsmosisFilterHead, // draw line
          )}
        {item.SoftenerFilterHead &&
          this.renderDevice(
            item.SoftenerFilterHead.SoftenerConfiguration,
            item.softenerLastUpdate,
            'Softener',
            item.ReverseOsmosisFilterHead, // draw line
          )}
        {item.ReverseOsmosisFilterHead &&
          this.renderDevice(
            item.ReverseOsmosisFilterHead.ReverseOsmosisFilterConfiguration,
            item.roLastUpdate,
            'Reverse Osmosis',
          )}
      </TableItem>
    </TRow>
  );

  render() {
    const { search } = this.state;
    const { loadingActiveDevices, listActiveDevices } = this.props;

    return (
      <Container>
        <Header style={{ paddingBottom: '0px' }}>
          <SearchContainer>
            <InputSearch onChange={this.searchOnChange} value={search} name="search" />
          </SearchContainer>
        </Header>
        <Row justifyContent="start" style={{ padding: '10px' }}>
          <FilterContainer>
            <Container>
              <H4 color={Colors.blue}>Active devices</H4>
            </Container>
          </FilterContainer>
        </Row>
        <TableContainer>
          <Table>
            <THeader>
              <TableTitle width="16.66%">Name</TableTitle>
              <TableTitle width="16.66%">Brand</TableTitle>
              <TableTitle width="16.66%">Account</TableTitle>
              <TableTitle width="16.66%">Device type</TableTitle>
              <TableTitle width="16.66%">Device name</TableTitle>
              <TableTitle width="16.66%">Last update</TableTitle>
            </THeader>
            <TBody>
              {loadingActiveDevices ? (
                <Loader size={200} block />
              ) : (
                listActiveDevices.map((item, key) => this.renderItem(item, key))
              )}
            </TBody>
          </Table>
          {(!listActiveDevices || !listActiveDevices.length) && !loadingActiveDevices && (
            <ListEmpty message="There are currently no OTA" />
          )}
        </TableContainer>
        {/* <Footer>
          <PaginationButtons
            currentPage={currentPage}
            changePage={page => fetchAccounts({ page, brand_id, state, search })}
            totalPages={pages}
          />
        </Footer> */}
      </Container>
    );
  }
}

OtaActiveDevicesListView.propTypes = {
  fetchUpdateActiveDevices: PropTypes.func,
  listActiveDevices: PropTypes.array,
  loadingActiveDevices: PropTypes.bool,
  update_id: PropTypes.string,
};
