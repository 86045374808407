import React, { Component } from 'react';
import styled from 'styled-components';
import { Colors } from 'modules/theme';
import { push } from 'modules/history';
import { DeviceTypes, AlertTypes } from 'constants/index';
import InputSearch from 'components/InputSearch';
import Dropdown from 'components/Dropdown';
import H4 from 'components/H4';
import H5 from 'components/H5';
import Icon from 'components/Icon';
import PaginationButtons from 'components/PaginationButtons';
import Filter, { FilterGroup, FilterContainer } from 'components/Filter';
import Table, { THeader, TableTitle, TRow, TableItem, TBody } from 'components/Table';
import AlertConfirm from 'components/AlertConfirm';
import ListEmpty from 'components/ListEmpty';
import Loader from 'components/Loader';
import PropTypes from 'prop-types';

const Container = styled.div`
  padding-top: 20px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 20px;
`;

const SearchContainer = styled.div`
  padding: 10px;
  flex-direction: column;
`;

const TableContainer = styled.div`
  padding-bottom: 50px;
  margin-bottom: 50px;
`;

const Footer = styled.div`
  position: fixed;
  bottom: 0;
  padding-left: 25px;
  padding-bottom: 25px;
  background-color: ${Colors.white};
  width: 100%;
`;

export default class AlertTypeListView extends Component {
  state = {
    dropdownOpenId: null,
    alertTypeSelectedId: null,
    isOpenAlert: false,
    deviceType: '',
    type: '',
  };

  filterOnChange = event => {
    const { name, value } = event.target;

    this.setState({ [name]: value }, () => {
      const { fetchAlertsTypes } = this.props;
      const { deviceType, type, search } = this.state;

      fetchAlertsTypes({ search, device_type: deviceType, type });
    });
  };

  searchOnChange = search => {
    this.setState({ search }, () => {
      const { fetchAlertsTypes } = this.props;
      const { deviceType, type, search } = this.state;

      fetchAlertsTypes({ search, device_type: deviceType, type });
    });
  };

  switchDropdown = key => {
    const { dropdownOpenId } = this.state;
    const id = dropdownOpenId !== key ? key : null;

    this.setState({ dropdownOpenId: id });
  };

  switchAlert = (alertTypeSelectedId = null) => {
    const { isOpenAlert } = this.state;

    this.setState({ isOpenAlert: !isOpenAlert, alertTypeSelectedId });
  };

  editAlertType = id => {
    push(`/alert-types/edit/${id}`);
  };

  remove = () => {
    const { removeAlertType } = this.props;
    const { alertTypeSelectedId } = this.state;

    removeAlertType(alertTypeSelectedId);
    this.switchAlert();
  };

  renderAlertConfirm = () => {
    const { isOpenAlert } = this.state;

    return (
      <AlertConfirm
        title="Delete Alert"
        onCancel={() => this.switchAlert()}
        onSuccess={this.remove}
        successText="Remove"
        isVisible={isOpenAlert}
      >
        <H4 style={{ textAlign: 'center' }}>
          Are you sure you want to delete this alert? It could be linked with active locations and
          this will cause the alert to disappear from all linked KineticoPro locations.
        </H4>
      </AlertConfirm>
    );
  };

  renderAlertType = (
    {
      id,
      device_type,
      type,
      trigger,
      repeat,
      allow_email_notifications,
      allow_sms_notifications,
      title,
    },
    key,
  ) => {
    const iconColor =
      type === 'warning' ? Colors.red : type === 'alert' ? Colors.orange : Colors.green;
    const iconName = type === 'success' ? 'check-circle' : 'exclamation-circle';
    return (
      <TRow key={key}>
        <TableItem width="30%" textAlign="left" style={{ padding: '12px' }} isComponent>
          <H5 style={{ lineHeight: '0px' }}>
            <Icon name={iconName} color={iconColor} customStyle={{ marginRight: '10px' }} />
            {title}
          </H5>
        </TableItem>
        <TableItem width="15%">
          <H5>{device_type}</H5>
        </TableItem>
        <TableItem width="30%" style={{ padding: '20px 12px' }}>
          <H5 color={Colors.black3} weight={325}>
            {trigger}
          </H5>
        </TableItem>
        <TableItem width="10%" style={{ padding: '20px 12px' }}>
          <H5 color={Colors.black3} weight={325}>
            {repeat}
          </H5>
        </TableItem>
        <TableItem width="10%" style={{ padding: '9px' }}>
          <H5
            color={allow_email_notifications ? Colors.green : Colors.red}
            weight={325}
            style={{ display: 'inline-block' }}
          >
            {`${allow_email_notifications ? 'ON' : 'OFF'}/`}
          </H5>
          <H5
            color={allow_sms_notifications ? Colors.green : Colors.red}
            weight={325}
            style={{ display: 'inline-block' }}
          >
            {allow_sms_notifications ? 'ON' : 'OFF'}
          </H5>
        </TableItem>
        <TableItem width="5%" style={{ padding: '5px' }} isComponent>
          <Dropdown
            icon="ellipsis-v"
            options={[
              {
                title: 'Edit',
                icon: 'pencil-alt',
                color: Colors.green,
                action: () => this.editAlertType(id),
              },
              {
                title: 'Remove',
                icon: 'trash',
                color: Colors.red,
                action: () => this.switchAlert(id),
              },
            ]}
            isDropdownOpen={this.state.dropdownOpenId === key}
            onClickDropdownButton={() => this.switchDropdown(key)}
          />
        </TableItem>
      </TRow>
    );
  };

  render() {
    const { fetchAlertsTypes, list, currentPage, pages, loading } = this.props;
    const { deviceType, type, search } = this.state;

    return (
      <Container>
        <Header>
          <SearchContainer>
            <InputSearch onChange={this.searchOnChange} value={search} name="search" />
          </SearchContainer>
        </Header>
        <FilterGroup>
          <FilterContainer>
            <Filter
              title="Device Type:"
              selectedValue={deviceType}
              name="deviceType"
              handleChange={this.filterOnChange}
              options={[{ label: 'All', value: '' }, ...DeviceTypes]}
            />
          </FilterContainer>
          <FilterContainer>
            <Filter
              title="Status:"
              selectedValue={type}
              name="type"
              handleChange={this.filterOnChange}
              options={[{ label: 'All', value: '' }, ...AlertTypes]}
            />
          </FilterContainer>
        </FilterGroup>
        <TableContainer>
          <Table>
            <THeader>
              <TableTitle width="30%">Status</TableTitle>
              <TableTitle width="15%">Device Type</TableTitle>
              <TableTitle width="30%">Alert Trigger</TableTitle>
              <TableTitle width="10%">Repeat</TableTitle>
              <TableTitle width="10%">Email/SMS</TableTitle>
              <TableTitle width="5%" />
            </THeader>
            <TBody>
              {loading ? (
                <Loader size={100} block />
              ) : (
                list.map((alertType, key) => this.renderAlertType(alertType, key))
              )}
            </TBody>
          </Table>
          {(!list || !list.length) && !loading && (
            <ListEmpty message="There are currently no alert settings" />
          )}
        </TableContainer>
        <Footer>
          <PaginationButtons
            currentPage={currentPage}
            changePage={page => fetchAlertsTypes({ page, search, device_type: deviceType, type })}
            totalPages={pages}
          />
        </Footer>
        {this.renderAlertConfirm()}
      </Container>
    );
  }
}

AlertTypeListView.propTypes = {
  currentPage: PropTypes.number,
  fetchAlertsTypes: PropTypes.func,
  list: PropTypes.array,
  loading: PropTypes.bool,
  pages: PropTypes.number,
  removeAlertType: PropTypes.func,
};
