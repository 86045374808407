import React, { Component } from 'react';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Colors } from 'modules/theme';
import Input from 'components/InputText';
import ButtonText from 'components/ButtonText';
import H2 from 'components/H2';
import FormError from 'components/FormError';
import PropTypes from 'prop-types';

const Container = styled.div`
  margin: 30px;
`;

const InputContainer = styled.div`
  margin: 20px 0px;
`;

const ButtonContainer = styled.div`
  margin-top: 25px;
  margin-bottom: 25px;
  float: left;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const Column = styled.div`
  flex-direction: column;
  width: 50%;
`;

export default class EditProfileView extends Component {
  submit = values => {
    const { editProfile } = this.props;
    editProfile(values);
  };

  render() {
    const {
      profile: { first_name, last_name, email, phone_number },
    } = this.props;
    const initialValues = {
      first_name,
      last_name,
      email,
      phone_number,
    };

    return (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={this.submit}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .required('Required')
            .email('Invalid Format'),
          first_name: Yup.string().required('Required'),
          last_name: Yup.string().required('Required'),
          phone_number: Yup.string().required('Required'),
        })}
      >
        {({ values, errors, touched, handleChange, handleBlur, isValid }) => (
          <Container>
            <Form style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
              <H2 color={Colors.black}>Account</H2>
              <Row>
                <Column style={{ marginRigth: '30px' }}>
                  <InputContainer>
                    <Input
                      placeholder="First Name"
                      name="first_name"
                      value={values.first_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      icon="user"
                    />
                    <FormError
                      text={errors.first_name && touched.first_name && errors.first_name}
                    />
                  </InputContainer>
                </Column>
                <Column style={{ marginLeft: '30px' }}>
                  <InputContainer>
                    <Input
                      placeholder="Last Name"
                      name="last_name"
                      value={values.last_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      icon="user"
                    />
                    <FormError text={errors.last_name && touched.last_name && errors.last_name} />
                  </InputContainer>
                </Column>
              </Row>
              <Row>
                <Column style={{ marginRigth: '30px' }}>
                  <InputContainer>
                    <Input
                      placeholder="Email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      icon="envelope"
                    />
                    <FormError text={errors.email && touched.email && errors.email} />
                  </InputContainer>
                </Column>
                <Column style={{ marginLeft: '30px' }}>
                  <InputContainer>
                    <Input
                      placeholder="Phone Number"
                      name="phone_number"
                      value={values.phone_number}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      icon="phone-alt"
                    />
                    <FormError
                      text={errors.phone_number && touched.phone_number && errors.phone_number}
                    />
                  </InputContainer>
                </Column>
              </Row>
              <Row>
                <ButtonContainer>
                  <ButtonText text="Save" theme="primary" type="submit" disabled={!isValid} />
                </ButtonContainer>
              </Row>
            </Form>
          </Container>
        )}
      </Formik>
    );
  }
}

EditProfileView.propTypes = {
  editProfile: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
};
