import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import {
  getLocation,
  editLocation,
  getStates,
  getCitiesByState,
  getBrandsForFilter,
  getAccountsForFilter,
  getInstallersByAccount,
  getWaterFilterConfigurationsForFilter,
  getReverseOsmosisConfigurationsForFilter,
  getSoftenerConfigurationsForFilter,
} from 'actions/index';
import { Colors } from 'modules/theme';
import { push } from 'modules/history';
import Icon from 'components/Icon';
import H2 from 'components/H2';
import Tabs from 'components/Tabs';
import Loader from 'components/Loader';
import PropTypes from 'prop-types';
import DetailsForm from './DetailsForm';
import ConfigurationForm from './ConfigurationForm';
import InstallerForm from './InstallerForm';

const Container = styled.div`
  margin: 30px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const Column = styled.div`
  flex-direction: column;
  align-self: center;
`;

class EditLocation extends Component {
  state = {
    activeTab: 'Details',
  };

  componentDidMount() {
    const {
      match: { params },
      fetchLocation,
      fetchStates,
      fetchBrands,
      fetchWaterFilterConfigurations,
      fetchReverseOsmosisConfigurations,
      fetchSoftenerConfigurations,
      fetchAccounts,
    } = this.props;
    const { id } = params;
    fetchLocation(id);
    fetchStates();
    fetchBrands();
    fetchWaterFilterConfigurations();
    fetchReverseOsmosisConfigurations();
    fetchSoftenerConfigurations();
    fetchAccounts();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.location && this.props.location) {
      const {
        fetchCities,
        fetchAccounts,
        location: {
          state_id,
          Account: { brand_id },
        },
      } = this.props;
      fetchAccounts({ brands: [brand_id] });
      fetchCities(state_id);

      let { location } = this.props;

      const waterFilter = {
        water_filter_config_id: location.WaterFilterHead
          ? location.WaterFilterHead.water_filter_config_id
          : '',
        chlorine_or_chloramine: location.WaterFilterHead
          ? location.WaterFilterHead.chlorine_or_chloramine
          : 'Chlorine',
        flowMeterOfWaterFilters: location.WaterFilterHead
          ? location.WaterFilterHead.FlowMeterOfWaterFilters || []
          : [],
      };

      const reverseOsmosis = {
        ro_filter_config_id: location.ReverseOsmosisFilterHead
          ? location.ReverseOsmosisFilterHead.ro_filter_config_id
          : '',
        chlorine_or_chloramine: location.ReverseOsmosisFilterHead
          ? location.ReverseOsmosisFilterHead.chlorine_or_chloramine
          : 'Chlorine',
        last_membrane_change_date: location.ReverseOsmosisFilterHead
          ? location.ReverseOsmosisFilterHead.last_membrane_change_date
          : null,
        flowMeterOfROFilters: location.ReverseOsmosisFilterHead
          ? location.ReverseOsmosisFilterHead.FlowMeterOfROFilters || []
          : [],
      };

      const waterSoftener = {
        softener_configuration_id: location.SoftenerFilterHead
          ? location.SoftenerFilterHead.softener_configuration_id
          : '',
        brine_drum_id: location.SoftenerFilterHead
          ? location.SoftenerFilterHead.FlowMeterOfSofteners[0].brine_drum_id
          : '',
        description: location.SoftenerFilterHead ? location.SoftenerFilterHead.description : '',
      };

      location = {
        ...location,
        waterFilter,
        reverseOsmosis,
        waterSoftener,
        brand_id: location.Account.brand_id,
        admins: location.LocationsPerUsers.reduce(
          (acc, { User, id }) => [
            ...acc,
            {
              label: `${User.first_name} ${User.last_name} (${User.email})`,
              value: id,
              deleted: false,
              new: false,
            },
          ],
          [],
        ),
      };

      this.setState({ location });
    }
  }

  saveData = (data, nextTab, persistLocation = false) => {
    const { location /* activeTab */ } = this.state;
    const locationUpdated = {
      ...location,
      ...data,
    };

    if (nextTab === 'Installer') {
      const { fetchInstallers } = this.props;
      const { account_id } = locationUpdated;

      fetchInstallers(account_id);
    }

    if (persistLocation) {
      const {
        match: { params },
        submitLocation,
      } = this.props;
      const { id } = params;

      submitLocation(id, locationUpdated);
    } else {
      this.setState({
        activeTab: nextTab,
        location: locationUpdated,
      });
    }
  };

  back = backTab => {
    this.setState({
      activeTab: backTab,
    });
  };

  switchTab = activeTab => {
    this.setState({ activeTab });
  };

  render() {
    const {
      brands,
      accounts,
      states,
      cities,
      installers,
      waterFilterConfigurationsForFilter,
      reverseOsmosisConfigurationsForFilter,
      softenerConfigurationsForFilter,
      fetchCities,
      fetchAccounts,
      loading,
    } = this.props;
    const { activeTab, location } = this.state;

    if (loading) {
      return <Loader size={200} block />;
    }

    if (!location) {
      return null;
    }

    const tabs = [
      {
        title: 'Details',
      },
      {
        title: 'Configuration',
      },
      {
        title: 'Installer',
      },
    ];

    const waterFilterDisabled = location.WaterFilterHead && location.WaterFilterHead.device_id;
    const reverseOsmosisDisabled =
      location.ReverseOsmosisFilterHead && location.ReverseOsmosisFilterHead.device_id;
    const softenerDisabled = location.SoftenerFilterHead && location.SoftenerFilterHead.device_id;

    return (
      <Container>
        <Icon onClick={() => push('/locations')} name="chevron-left" color={Colors.blue} />
        <Row>
          <Column>
            <H2 color={Colors.black} style={{ paddingRight: 30 }}>
              Edit location
            </H2>
          </Column>
          <Column>
            <Tabs tabs={tabs} active={activeTab} />
          </Column>
        </Row>
        {activeTab === 'Details' && (
          <DetailsForm
            initialValues={location}
            isInitialValid
            brands={brands}
            accounts={accounts}
            cities={cities}
            states={states}
            fetchCities={fetchCities}
            fetchAccounts={fetchAccounts}
            onSubmit={this.saveData}
            isEditing
          />
        )}
        {activeTab === 'Configuration' && (
          <ConfigurationForm
            initialValues={location}
            isInitialValid
            waterFilterConfigurationsForFilter={waterFilterConfigurationsForFilter}
            reverseOsmosisConfigurationsForFilter={reverseOsmosisConfigurationsForFilter}
            softenerConfigurationsForFilter={softenerConfigurationsForFilter}
            back={this.back}
            onSubmit={this.saveData}
            isEdit
            waterFilterDisabled={waterFilterDisabled}
            reverseOsmosisDisabled={reverseOsmosisDisabled}
            softenerDisabled={softenerDisabled}
          />
        )}
        {activeTab === 'Installer' && (
          <InstallerForm
            initialValues={location}
            isInitialValid
            installers={installers}
            back={this.back}
            onSubmit={this.saveData}
          />
        )}
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    location: state.locations.item,
    loading: state.locations.loading,
    brands: state.brands.listForFilter,
    accounts: state.accounts.listForFilter,
    states: state.utils.states,
    cities: state.utils.cities,
    installers: state.users.installers,
    waterFilterConfigurationsForFilter: state.libraries.waterFilterConfigurationsForFilter,
    reverseOsmosisConfigurationsForFilter: state.libraries.reverseOsmosisConfigurationsForFilter,
    softenerConfigurationsForFilter: state.libraries.softenerConfigurationsForFilter,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    submitLocation: (id, data) => dispatch(editLocation(id, data)),
    fetchLocation: id => dispatch(getLocation(id)),
    fetchStates: () => dispatch(getStates()),
    fetchCities: stateId => dispatch(getCitiesByState(stateId)),
    fetchBrands: () => dispatch(getBrandsForFilter()),
    fetchAccounts: params => dispatch(getAccountsForFilter(params)),
    fetchInstallers: accountId => dispatch(getInstallersByAccount(accountId)),
    fetchWaterFilterConfigurations: type => dispatch(getWaterFilterConfigurationsForFilter(type)),
    fetchReverseOsmosisConfigurations: type =>
      dispatch(getReverseOsmosisConfigurationsForFilter(type)),
    fetchSoftenerConfigurations: type => dispatch(getSoftenerConfigurationsForFilter(type)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(EditLocation);

EditLocation.propTypes = {
  accounts: PropTypes.array,
  brands: PropTypes.array,
  cities: PropTypes.array,
  fetchAccounts: PropTypes.func,
  fetchBrands: PropTypes.func,
  fetchCities: PropTypes.func,
  fetchInstallers: PropTypes.func,
  fetchLocation: PropTypes.func,
  fetchReverseOsmosisConfigurations: PropTypes.func,
  fetchSoftenerConfigurations: PropTypes.func,
  fetchStates: PropTypes.func,
  fetchWaterFilterConfigurations: PropTypes.func,
  installers: PropTypes.array,
  loading: PropTypes.bool,
  location: PropTypes.object,
  match: PropTypes.object,
  reverseOsmosisConfigurationsForFilter: PropTypes.array,
  softenerConfigurationsForFilter: PropTypes.array,
  states: PropTypes.array,
  submitLocation: PropTypes.func,
  waterFilterConfigurationsForFilter: PropTypes.array,
};
