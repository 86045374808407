import { REHYDRATE } from 'redux-persist/lib/constants';
import { handleActions } from 'modules/helpers';

import { ActionTypes } from 'constants/index';

export const appState = {
  list: [],
  pages: 0,
  currentPage: 0,
  loading: false,
  installers: [],
  item: null,
};

export default {
  users: handleActions(
    {
      [REHYDRATE]: draft => {
        draft.list = [];
      },
      [ActionTypes.GET_USERS]: draft => {
        draft.loading = true;
      },
      [ActionTypes.GET_USERS_SUCCESS]: (draft, { payload: { rows, pages, currentPage } }) => {
        draft.list = rows;
        draft.pages = pages;
        draft.currentPage = currentPage;
        draft.loading = false;
      },
      [ActionTypes.GET_USER]: draft => {
        draft.item = null;
        draft.loading = true;
      },
      [ActionTypes.GET_USER_SUCCESS]: (draft, { payload }) => {
        draft.item = payload;
        draft.loading = false;
      },
      [ActionTypes.GET_USER_FAILURE]: draft => {
        draft.loading = false;
      },
      [ActionTypes.RESEND_EMAIL]: draft => {
        draft.loading = true;
      },
      [ActionTypes.RESEND_EMAIL_SUCCESS]: draft => {
        draft.loading = false;
      },
      [ActionTypes.RESEND_EMAIL_FAILURE]: draft => {
        draft.loading = false;
      },
      [ActionTypes.GET_INSTALLERS_BY_ACCOUNT_SUCCESS]: (draft, { payload: { installers } }) => {
        draft.installers = installers;
      },
      [ActionTypes.GET_INSTALLERS_BY_ACCOUNT_FAILURE]: draft => {
        draft.installers = [];
      },
      [ActionTypes.GET_USERS_FAILURE]: draft => {
        draft.loading = false;
      },
      [ActionTypes.CREATE_USER]: draft => {
        draft.loading = true;
      },
      [ActionTypes.CREATE_USER_RESOLVED]: draft => {
        draft.loading = false;
      },
      [ActionTypes.EDIT_USER]: draft => {
        draft.loading = true;
      },
      [ActionTypes.EDIT_USER_RESOLVED]: draft => {
        draft.loading = false;
      },
      [ActionTypes.DELETE_USER]: draft => {
        draft.loading = true;
      },
      [ActionTypes.DELETE_USER_SUCCESS]: (draft, { payload: { id } }) => {
        draft.list = draft.list.filter(item => item.id !== id);
        draft.loading = false;
      },
      [ActionTypes.DELETE_USER_FAILURE]: (draft, { payload: { id } }) => {
        draft.loading = false;
      },
    },
    appState,
  ),
};
