import React, { Component } from 'react';
import styled from 'styled-components';
import checked from 'assets/media/images/checked.png';
import unchecked from 'assets/media/images/unchecked.png';
import PropTypes from 'prop-types';

export const Image = styled.img`
  width: 20px;
  heigth: 20px;
`;

const Container = styled.div``;

export default class Checbox extends Component {
  render() {
    const { isChecked, onChange, onClick } = this.props;

    return (
      <Container onClick={onChange}>
        <Image onClick={onClick} src={isChecked ? checked : unchecked} />
      </Container>
    );
  }
}

Checbox.propTypes = {
  isChecked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func,
};
