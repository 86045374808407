import React from 'react';
import styled from 'styled-components';
import BG from 'components/BG';
import { Colors } from 'modules/theme';
import { Link } from 'react-router-dom';
import { Heading } from 'styled-minimal';
import Logo from 'components/Logo';
import H5 from 'components/H5';
import E400 from 'assets/media/images/E400.png';
import Col from 'components/Col';
import Row from 'components/Row';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px auto 50px auto;
  padding 20px;
  width: 40%;
  text-align: center;
  @media (max-width: 970px) {
    width: 100%;
  }
`;

const LogoContainer = styled.div`
  margin-top: 80px;
  margin-bottom: 80px;
  text-align: center;
`;

const NotFound = () => (
  <Container>
    <LogoContainer>
      <Logo />
    </LogoContainer>
    <BG>
      <Row>
        <Col>
          <img src={E400} width={120} style={{ opacity: 0.6 }} />
        </Col>
        <Col>
          <Heading fontSize={80} style={{ color: Colors.gray6, paddingTop: 0 }}>
            404
            <H5 style={{ fontSize: '20px', color: Colors.gray6 }}>Page not found</H5>
            <H5 fontSize={20} style={{ color: Colors.gray6, marginBottom: 20 }}>
              There was an error. Please try again later. That's all we know
            </H5>
          </Heading>

          <Link to="/">
            <Heading as="h2">Go home</Heading>
          </Link>
        </Col>
      </Row>
    </BG>
  </Container>
);

export default NotFound;
