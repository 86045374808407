import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Colors } from 'modules/theme';
import Icon from './Icon';

const Container = styled.button`
  position: relative;
  font-style: normal;
  line-height: 22px;
  cursor: pointer;
  outline: none;
  padding: 5px;
  font-size: 12px;
  &: hover {
    opacity: 0.8;
  }
  ${props =>
    props.disabled &&
    `
    opacity: 0.5;   
  `}
`;

// https://docs.aws.amazon.com/iot/latest/apireference/API_OTAUpdateInfo.html (possible job status)
// CREATE_COMPLETE
// CREATE_FAILED
// CREATE_IN_PROGRESS
// CREATE_PENDING
// QUEUED (State created by us, since the creation is executed at a later date by a cron. The status will be SCHEDULED until the cron is executed.)

// ------------------

// https://docs.aws.amazon.com/iot/latest/apireference/API_JobExecutionSummary.html  (possible job EXECUTION status, A job can have many job executions)
// QUEUED
// IN_PROGRESS
// SUCCEEDED
// FAILED
// TIMED_OUT
// REJECTED
// REMOVED
// CANCELED

const matchStatus = {
  CREATE_IN_PROGRESS: 'Create In Progress',
  CREATE_FAILED: 'Create Failed',
  CREATE_COMPLETE: 'Create Completed',
  CREATE_PENDING: 'Create Pending',
  QUEUED: 'Queued',
  IN_PROGRESS: 'In Progress',
  SUCCEEDED: 'Succeeded',
  FAILED: 'Failed',
  TIMED_OUT: 'Timed Out',
  REJECTED: 'Rejected',
  REMOVED: 'Removed',
  CANCELED: 'Canceled',
};

export default class ButtonStatus extends Component {
  getColor = () => {
    const { text } = this.props;
    switch (text) {
      // possible job status
      case 'CREATE_IN_PROGRESS':
        return `${Colors.lightBlue}`;
      case 'CREATE_FAILED':
        return `${Colors.red}`;
      case 'CREATE_COMPLETE':
        return `${Colors.green2}`;
      case 'CREATE_PENDING':
        return `${Colors.yellow2}`;
      // possible job EXECUTION status, A job can have many job executions
      case 'QUEUED':
        return `${Colors.gray6}`;
      case 'IN_PROGRESS':
        return `${Colors.lightBlue}`;
      case 'SUCCEEDED':
        return `${Colors.green2}`;
      case 'FAILED':
        return `${Colors.red}`;
      case 'TIMED_OUT':
        return `${Colors.yellow2}`;
      case 'REJECTED':
        return `${Colors.red}`;
      case 'REMOVED':
        return `${Colors.orange}`;
      case 'CANCELED':
        return `${Colors.gray6}`;
      default:
        return Colors.transparent;
    }
  };

  getBorderRadius = () => {
    const { theme } = this.props;

    switch (theme) {
      case 'primary':
        return '30px';
      case 'round':
        return '7px';
      default:
        return '0px';
    }
  };

  render() {
    const { icon, text, customStyles, iconAction, renderTimes } = this.props;

    return (
      <Container
        {...this.props}
        style={{
          color: this.getColor(),
          borderRadius: this.getBorderRadius(),
          border: `solid ${this.getColor()} 1px`,
          ...(customStyles || {}),
        }}
      >
        {icon && <Icon name={icon} color={this.getColor()} />}

        <p style={{ margin: '0px' }}>
          {matchStatus[text]}
          {renderTimes && (
            <span style={{ marginLeft: '10px' }}>
              <Icon onClick={iconAction} name="times" color={Colors.red} />
            </span>
          )}
        </p>
      </Container>
    );
  }
}

ButtonStatus.propTypes = {
  color: PropTypes.string,
  customStyles: PropTypes.object,
  icon: PropTypes.string,
  iconAction: PropTypes.func,
  renderTimes: PropTypes.bool,
  small: PropTypes.bool,
  text: PropTypes.string,
  theme: PropTypes.string,
};
