export const headerHeight = 30;

export const appColor = '#00b4d5';

export const easing = 'cubic-bezier(0.35, 0.01, 0.77, 0.34);';

export default {
  breakpoints: {
    xs: 0,
    ix: 400,
    md: 768,
    lg: 1024,
    xl: 1280,
    xxl: 1920,
  },
  palette: {
    primary: appColor,
  },
  button: {
    borderRadius: {
      xs: 4,
      lg: 28,
      xl: 32,
    },
    padding: {
      lg: [12, 28],
      xl: [14, 32],
    },
  },
};

export const Colors = {
  blue: '#314683',
  darkBlue: '#314683',
  ligthDarkBlue: '#ECE9FF',
  lightBlue: '#1498CB',
  lightBlueAlternative: '#1788C5',
  black: '#000000',
  black2: '#231F20',
  black3: '#141414',
  gray: '#808285',
  gray2: '#E3E4E5',
  gray3: '#969696',
  gray4: '#C0C2C4',
  gray5: '#88898C',
  gray6: '#747474',
  gray7: '#C8C8C8',
  gray8: '#717171',
  gray9: '#dadada',
  lightGray: '#FBFBFB',
  grayChart: '#DEDFE0',
  yellow: '#FFA800',
  yellow2: '#EDD818',
  lightYellow: '#FFFEF2',
  white: '#ffffff',
  green: '#1E9331',
  green2: '#17C548',
  lightGreen: '#F3FFF2',
  red: '#C72A2A',
  lightRed: '#FFF8F8',
  orange: '#FFA800',
  lightOrange: '#FFFAF2',
  transparent: 'rgba(0,0,0,0)',
};
