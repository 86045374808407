import React from 'react';
import { createGlobalStyle } from 'styled-components';
import { appColor } from 'modules/theme';

const GlobalStyle = createGlobalStyle`
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  html {
    font-size: 62.5%;
    -webkit-font-smoothing: antialiased;
    height: 100%;
    background-color: #fbfdff;
  }

  body {
    font-family: Gotham, sans-serif;
    font-size: 16px; /* stylelint-disable unit-blacklist */
    margin: 0;
    min-height: 100vh;
    padding: 0;
  }

  img {
    height: auto;
    max-width: 100%;
  }

  a {
    color: ${appColor};
    text-decoration: none;

    &.disabled {
      pointer-events: none;
    }
  }

  button {
    appearance: none;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    display: inline-block;
    font-family: inherit;
    line-height: 1;
    padding: 0;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  .item-renderer > input[type="checkbox"] {
    height: auto !important;
    padding: 10px !important;
    width: auto !important;  
  }

  .dropdown-heading { 
    height: 44px !important;
    border: 1px solid #e1edf3 !important;
  }

  .dropdown-content { 
    border: 1px solid #e1edf3 !important;
  }

  .dropdown-heading-value {
    line-height: 40px !important;
  }

  .dropdown-heading-value > span {
    font-size: 18px !important;
    color: #808285 !important;
  }
  g > text {
    font-family: sans-serif !important;
  }
`;

export default () => <GlobalStyle />;
