import React, { Component } from 'react';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Colors } from 'modules/theme';
import Input from 'components/InputText';
import Select from 'components/Select';
import ButtonText from 'components/ButtonText';
import H4 from 'components/H4';
import FormError from 'components/FormError';
import Line from 'components/Line';
import Switch from 'components/Switch';
import PropTypes from 'prop-types';

const InputContainer = styled.div`
  width: 100%;
`;

const ButtonContainer = styled.div`
  margin-top: 25px;
  margin-bottom: 25px;
  float: left;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Column = styled.div`
  flex-direction: column;
  width: 50%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export default class InstallerForm extends Component {
  state = {
    existInstaller: false,
    installer: {},
  };

  submit = values => {
    const { onSubmit } = this.props;
    const activeTab = 'Configuration';

    onSubmit(values, activeTab, true);
  };

  setInstallerInfo = installerId => {
    const { installers } = this.props;
    const installer =
      installers.find(installer => installer.value === parseInt(installerId, 10)) || {};

    this.setState({ installer });
  };

  backStep = values => {
    const { onSubmit } = this.props;
    const nextTab = 'Configuration';
    onSubmit({ ...values }, nextTab);
  };

  render() {
    const { initialValues, installers, /* back, */ loading } = this.props;
    const { existInstaller, installer } = this.state;
    return (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={this.submit}
        validationSchema={Yup.object().shape(
          existInstaller
            ? {
                installer_id: Yup.string().required('Required'),
                estimated_install_date_from: Yup.string().required('Required'),
                estimated_install_date_to: Yup.string().required('Required'),
              }
            : {
                installer_first_name: Yup.string().required('Required'),
                installer_last_name: Yup.string().required('Required'),
                installer_email: Yup.string()
                  .required('Required')
                  .email('Invalid Format'),
                installer_phone_number: Yup.string().required('Required'),
                installer_company_name: Yup.string().required('Required'),
                estimated_install_date_from: Yup.string().required('Required'),
                estimated_install_date_to: Yup.string().required('Required'),
              },
        )}
      >
        {({ values, errors, touched, handleChange, handleBlur }) => (
          <Form>
            <Row>
              <Column style={{ paddingRight: '15px' }}>
                <Switch
                  name="existInstaller"
                  isChecked={existInstaller}
                  titleChecked="Existing Installer ?"
                  titleUnchecked="Existing Installer ?"
                  onChange={event => this.setState({ [event.target.name]: event.target.checked })}
                />
                {existInstaller && (
                  <InputContainer>
                    <H4 inputLabel color={Colors.gray8}>
                      Select Installer
                    </H4>
                    <Select
                      placeholder="Select Installer"
                      name="installer_id"
                      options={installers}
                      value={values.installer_id}
                      onChange={evt => {
                        this.setInstallerInfo(evt.target.value);
                        handleChange(evt);
                      }}
                      onBlur={handleBlur}
                    />
                    <FormError
                      text={errors.installer_id && touched.installer_id && errors.installer_id}
                    />
                  </InputContainer>
                )}
              </Column>
            </Row>
            <Row>
              <Column style={{ marginRight: '15px' }}>
                <H4 inputLabel color={Colors.gray8}>
                  First name
                </H4>
                {existInstaller ? (
                  <H4 style={{ paddingLeft: '4px' }}>{installer.first_name || '-'}</H4>
                ) : (
                  <InputContainer>
                    <Input
                      placeholder="First name"
                      name="installer_first_name"
                      value={values.installer_first_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      icon="user"
                    />
                    <FormError
                      text={
                        errors.installer_first_name &&
                        touched.installer_first_name &&
                        errors.installer_first_name
                      }
                    />
                  </InputContainer>
                )}

                <H4 inputLabel color={Colors.gray8}>
                  Email
                </H4>
                {existInstaller ? (
                  <H4 style={{ paddingLeft: '4px' }}>{installer.email || '-'}</H4>
                ) : (
                  <InputContainer>
                    <Input
                      placeholder="Email"
                      name="installer_email"
                      value={values.installer_email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      icon="envelope"
                    />
                    <FormError
                      text={
                        errors.installer_email && touched.installer_email && errors.installer_email
                      }
                    />
                  </InputContainer>
                )}
                {/*
                  <H4 inputLabel color={Colors.gray8}>
                    Estimated Install Date
                  </H4>
                  <Input
                    placeholder="Address line 2"
                    name="estimated_install_date_from"
                    value={values.estimated_install_date_from}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    icon="map-marker-alt"
                  />
                  <FormError
                    text={
                      errors.estimated_install_date_from &&
                      touched.estimated_install_date_from &&
                      errors.estimated_install_date_from
                    }
                  />
                  */}
              </Column>
              <Column style={{ marginLeft: '15px' }}>
                <H4 inputLabel color={Colors.gray8}>
                  Last name
                </H4>
                {existInstaller ? (
                  <H4 style={{ paddingLeft: '4px' }}>{installer.last_name || '-'}</H4>
                ) : (
                  <InputContainer>
                    <Input
                      placeholder="Last name"
                      name="installer_last_name"
                      value={values.installer_last_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      icon="user"
                    />
                    <FormError
                      text={
                        errors.installer_last_name &&
                        touched.installer_last_name &&
                        errors.installer_last_name
                      }
                    />
                  </InputContainer>
                )}

                <H4 inputLabel color={Colors.gray8}>
                  Phone number
                </H4>
                {existInstaller ? (
                  <H4 style={{ paddingLeft: '4px' }}>{installer.phone_number || '-'}</H4>
                ) : (
                  <InputContainer>
                    <Input
                      placeholder="Phone number"
                      name="installer_phone_number"
                      value={values.installer_phone_number}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      icon="phone"
                    />
                    <FormError
                      text={
                        errors.installer_phone_number &&
                        touched.installer_phone_number &&
                        errors.installer_phone_number
                      }
                    />
                  </InputContainer>
                )}

                <H4 inputLabel color={Colors.gray8}>
                  Installer Company Name
                </H4>
                {existInstaller ? (
                  <H4 style={{ paddingLeft: '4px' }}>We Install Inc.</H4>
                ) : (
                  <InputContainer>
                    <Input
                      placeholder="Installer Company Name "
                      name="installer_company_name"
                      value={values.installer_company_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      icon="stream"
                    />
                    <FormError
                      text={
                        errors.installer_company_name &&
                        touched.installer_company_name &&
                        errors.installer_company_name
                      }
                    />
                  </InputContainer>
                )}
              </Column>
            </Row>
            <Row style={{ marginTop: '30px' }}>
              <Line />
            </Row>
            <Row>
              <ButtonContainer>
                <ButtonText
                  text="Back"
                  type="button"
                  theme="default"
                  customStyles={{ marginTop: '20px', marginRight: '20px' }}
                  onClick={() => this.backStep(values)}
                />
                <ButtonText text="Save" theme="primary" type="submit" disabled={loading} />
              </ButtonContainer>
            </Row>
          </Form>
        )}
      </Formik>
    );
  }
}

InstallerForm.propTypes = {
  initialValues: PropTypes.object,
  installers: PropTypes.array,
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
};
