import React, { Component } from 'react';
import styled from 'styled-components';
import { Colors } from 'modules/theme';

const InputText = styled.input`
  border: 1px solid ${Colors.gray2};
  box-sizing: border-box;
  border-radius: 20px;
  font-style: normal;
  font-weight: 325;
  font-size: 14px;
  line-height: 17px;
  color: ${Colors.gray};
  padding: 5px 5px 5px 50px;
  outline-style: none;
  ::placeholder {
    font-size: ${props => props.fontSize || 14}px;
    color: ${props => props.color || Colors.gray};
  }
`;

const Container = styled.div`
  position: relative;
`;

const Icon = styled.i`
  position: absolute;
  margin-left: 20px;
  margin-top: 15px;
  color: #1498cb;
  transform: matrix(-1, 0, 0, 1, 0, 0);
  font-size: 16px;
`;

export default class InputSearch extends Component {
  state = {
    delayTyping: null,
    search: '',
  };

  handleChange = event => {
    const search = event.target.value;
    const { onChange } = this.props;

    clearTimeout(this.state.delayTyping);
    const delayTyping = setTimeout(() => {
      onChange(search);
    }, 1000);

    this.setState({
      search,
      delayTyping,
    });
  };

  render() {
    return (
      <Container>
        <Icon className="fas fa-search" />
        <InputText placeholder="Search" onChange={this.handleChange} />
      </Container>
    );
  }
}
