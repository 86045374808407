import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import {
  createAccount,
  getStates,
  getCitiesByState,
  getBrandsForFilter,
  getAdminsAccounts,
} from 'actions/index';
import { Colors } from 'modules/theme';
import { push } from 'modules/history';
import Icon from 'components/Icon';
import H2 from 'components/H2';
import Tabs from 'components/Tabs';
import PropTypes from 'prop-types';
import DetailsForm from './DetailsForm';
import LocationsForm from './LocationsForm';

const Container = styled.div`
  margin: 30px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const Column = styled.div`
  flex-direction: column;
  align-self: center;
  margin-right: 10px;
  margin-bottom: 10px;
`;

class CreateAccount extends Component {
  state = {
    activeTab: 'Details',
    account: {},
  };

  componentDidMount() {
    const { fetchStates, fetchBrandsForFilter } = this.props;

    fetchStates();
    fetchBrandsForFilter();
  }

  getCities = stateId => {
    const { fetchCitiesByState } = this.props;

    fetchCitiesByState(stateId);
  };

  getAdminsBrandsAccounts = brandId => {
    const { fetchAdminsAccounts } = this.props;
    fetchAdminsAccounts(brandId);
  };

  saveData = (data, nextTab) => {
    const { account, activeTab } = this.state;
    const accountUpdated = {
      ...account,
      ...data,
    };

    if (activeTab === 'Locations') {
      const { submitAccount } = this.props;
      submitAccount(accountUpdated);
    } else {
      this.setState({
        activeTab: nextTab,
        account: accountUpdated,
      });
    }
  };

  back = backTab => {
    this.setState({
      activeTab: backTab,
    });
  };

  render() {
    const { states, cities, token, brands, adminsAccounts } = this.props;
    const {
      activeTab,
      account: { locations },
    } = this.state;
    const detailsInitialValues = {
      name: '',
      address_line: '',
      address_line_1: '',
      city_id: '',
      state_id: '',
      zip_code: '',
      phone_number: '',
      contact_first_name: '',
      contact_last_name: '',
      contact_phone_number: '',
      contact_email: '',
      brand_id: '',
      createNewUser: true,
      account_admin_id: null,
    };

    const tabs = [
      {
        title: 'Details',
      },
      {
        title: 'Locations',
      },
    ];

    const additionalOptions =
      adminsAccounts.length > 0
        ? adminsAccounts.map(admin => ({
            label: `${admin.first_name} ${admin.last_name}`,
            value: admin.id,
          }))
        : [];

    console.log('additionalOptions', additionalOptions);

    return (
      <Container>
        <Row>
          <Column>
            <Icon onClick={() => push('/accounts')} name="chevron-left" color={Colors.blue} />
          </Column>
          <Column>
            <H2 color={Colors.black} style={{ paddingRight: 30 }}>
              Add new account
            </H2>
          </Column>
        </Row>
        <Tabs tabs={tabs} active={activeTab} />

        {activeTab === 'Details' && (
          <DetailsForm
            initialValues={detailsInitialValues}
            additionalOptions={additionalOptions}
            isInitialValid={false}
            cities={cities}
            states={states}
            brands={brands}
            getCities={this.getCities}
            onSubmit={this.saveData}
            getAdminsBrandsAccounts={this.getAdminsBrandsAccounts}
          />
        )}
        {activeTab === 'Locations' && (
          <LocationsForm
            token={token}
            locations={locations}
            back={() => this.back('Details')}
            onSubmit={this.saveData}
          />
        )}
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    states: state.utils.states,
    cities: state.utils.cities,
    token: state.auth.token,
    brands: state.brands.listForFilter,
    adminsAccounts: state.brands.adminsAccounts,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    submitAccount: data => dispatch(createAccount(data)),
    fetchStates: () => dispatch(getStates()),
    fetchCitiesByState: stateId => dispatch(getCitiesByState(stateId)),
    fetchBrandsForFilter: () => dispatch(getBrandsForFilter()),
    fetchAdminsAccounts: brandId => dispatch(getAdminsAccounts(brandId)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(CreateAccount);
CreateAccount.propTypes = {
  brands: PropTypes.array,
  cities: PropTypes.array,
  fetchBrandsForFilter: PropTypes.func,
  fetchCitiesByState: PropTypes.func,
  fetchStates: PropTypes.func,
  states: PropTypes.array,
  submitAccount: PropTypes.func,
  token: PropTypes.string,
};
