/**
 * Configuration
 * @module config
 */

const config = {
  name: 'KineticoPRO-STAGING',
  description: 'Kinetico Incorporated',
  BASE_URL: 'https://staging-api.aquarianhub.com/api/v2',
  BUCKET_NAME: 'staging-ota-versions',
  ACCOUNTS_CSV_EXAMPLE_URL: 'https://www.aquasdadarianhub.com/account_csv_example.csv',
  LOCATIONS_CSV_EXAMPLE_URL: 'https://www.aquariaasdasdaanhub.com/location_csv_example.csv',
 };

export default config;
