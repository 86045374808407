import React, { Component } from 'react';
import { connect } from 'react-redux';
import { editWaterFilter, getWaterFilter, getCatridgesForFilter } from 'actions/index';
import Loader from 'components/Loader';
import PropTypes from 'prop-types';
import WaterFilterForm from './WaterFilterForm';

class EditWaterFilter extends Component {
  componentDidMount() {
    const {
      match: { params },
      fetchWaterFilter,
      fetchCatridges,
    } = this.props;
    const { id } = params;

    fetchWaterFilter(id);
    fetchCatridges();
  }

  onSubmit = data => {
    const {
      submitWaterFilter,
      match: { params },
    } = this.props;
    const { id } = params;

    submitWaterFilter(id, data);
  };

  render() {
    const { library, catridges, loading, token } = this.props;

    if (loading) {
      return <Loader size={200} block />;
    }

    if (!library) {
      return null;
    }
    const initialValues = {
      name: library.name,
      series_or_parallel: library.series_or_parallel,
      filter_head_name: library.filter_head_name,
      taste_odor: library.taste_odor,
      contaminant: library.contaminant,
      scale: library.scale,
      photo: library.photo,
      spots: library.SpotsWaterFilters || [],
      bypass_flow: library.bypass_flow,
      bypass_key: library.bypass_key,
    };

    return (
      <WaterFilterForm
        title="Edit water filter"
        onSubmit={this.onSubmit}
        initialValues={initialValues}
        loading={loading}
        catridges={catridges}
        token={token}
        editMode
        editable={library.editable}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    loading: state.libraries.loading,
    token: state.auth.token,
    catridges: state.libraries.catridgesForFilter,
    library: state.libraries.item,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    submitWaterFilter: (id, library) => dispatch(editWaterFilter(id, library)),
    fetchWaterFilter: id => dispatch(getWaterFilter(id)),
    fetchCatridges: () => dispatch(getCatridgesForFilter()),
  };
}

EditWaterFilter.propTypes = {
  catridges: PropTypes.array,
  fetchCatridges: PropTypes.func,
  fetchWaterFilter: PropTypes.func,
  library: PropTypes.object,
  loading: PropTypes.bool,
  match: PropTypes.object,
  submitWaterFilter: PropTypes.func,
  token: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditWaterFilter);
