import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createBrineDrum } from 'actions/index';
import PropTypes from 'prop-types';
import BrineDrumForm from './BrineDrumForm';

class CreateBrineDrum extends Component {
  render() {
    const { submitBrineDrum, loading, token } = this.props;

    const initialValues = {
      name: '',
      good_salt_level: '',
      fair_salt_level: '',
      poor_salt_level: '',
      description: '',
      photo: '',
    };

    return (
      <BrineDrumForm
        title="Add New Brine Drum"
        onSubmit={submitBrineDrum}
        initialValues={initialValues}
        loading={loading}
        token={token}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    loading: state.libraries.loading,
    token: state.auth.token,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    submitBrineDrum: library => dispatch(createBrineDrum(library)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(CreateBrineDrum);

CreateBrineDrum.propTypes = {
  loading: PropTypes.bool,
  submitBrineDrum: PropTypes.func,
  token: PropTypes.string,
};
