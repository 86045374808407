import React, { Component } from 'react';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Colors } from 'modules/theme';
import Input from 'components/InputText';
import ButtonText from 'components/ButtonText';
import H4 from 'components/H4';
import Icon from 'components/Icon';
import FormError from 'components/FormError';
import Line from 'components/Line';
import PropTypes from 'prop-types';

const InputContainer = styled.div`
  width: 100%;
`;

const ButtonContainer = styled.div`
  margin-top: 25px;
  margin-bottom: 25px;
  float: left;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Column = styled.div`
  flex-direction: column;
  width: 35%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Box = styled.div`
  border: 1px solid #e4e4e4;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 10px;
`;

export default class DataForm extends Component {
  submit = values => {
    const { onSubmit } = this.props;
    onSubmit(values);
  };

  render() {
    const { initialValues, isInitialValid, back } = this.props;

    return (
      <Formik
        enableReinitialize
        isInitialValid={isInitialValid}
        initialValues={initialValues}
        onSubmit={this.submit}
        validationSchema={Yup.object().shape({
          fair_end: Yup.number()
            .integer()
            .min(0)
            .max(100)
            .nullable(),
          good_end: Yup.number()
            .integer()
            .min(0)
            .max(100)
            .nullable(),
          in_compliance_end: Yup.number()
            .integer()
            .min(0)
            .nullable(),
          near_compliance_1_end: Yup.number()
            .integer()
            .min(0)
            .nullable(),
          near_compliance_2_end: Yup.number()
            .integer()
            .min(0)
            .nullable(),
          out_of_compliance: Yup.number()
            .integer()
            .min(0)
            .nullable(),
          out_of_compliance_2_end: Yup.number()
            .integer()
            .min(0)
            .nullable(),
          poor_end: Yup.number()
            .integer()
            .min(0)
            .max(100)
            .nullable(),
        })}
      >
        {({ values, errors, touched, handleChange, handleBlur, isValid }) => (
          <Form>
            <Row>
              <Column style={{ marginRight: '15px' }}>
                <H4 inputLabel color={Colors.lightBlue}>
                  TDS Compliance
                </H4>
                <Row>
                  <InputContainer>
                    <H4 inputLabel color={Colors.gray8}>
                      Out of Compliance (ppm- starts at 0)
                    </H4>
                    <Input
                      name="out_of_compliance"
                      type="number"
                      value={values.out_of_compliance}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      icon="hashtag"
                    />
                    <FormError
                      text={
                        errors.out_of_compliance &&
                        touched.out_of_compliance &&
                        errors.out_of_compliance
                      }
                    />
                  </InputContainer>
                </Row>
                <Row>
                  <InputContainer>
                    <H4 inputLabel color={Colors.gray8}>
                      Near Compliance 1 End (ppm)
                    </H4>
                    <Input
                      name="near_compliance_1_end"
                      type="number"
                      value={values.near_compliance_1_end}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      icon="hashtag"
                    />
                    <FormError
                      text={
                        errors.near_compliance_1_end &&
                        touched.near_compliance_1_end &&
                        errors.near_compliance_1_end
                      }
                    />
                  </InputContainer>
                </Row>
                <Row>
                  <InputContainer>
                    <H4 inputLabel color={Colors.gray8}>
                      In Compliance End (ppm)
                    </H4>
                    <Input
                      name="in_compliance_end"
                      type="number"
                      value={values.in_compliance_end}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      icon="hashtag"
                    />
                    <FormError
                      text={
                        errors.in_compliance_end &&
                        touched.in_compliance_end &&
                        errors.in_compliance_end
                      }
                    />
                  </InputContainer>
                </Row>
                <Row>
                  <InputContainer>
                    <H4 inputLabel color={Colors.gray8}>
                      Near Compliance 2 End (ppm)
                    </H4>
                    <Input
                      name="near_compliance_2_end"
                      type="number"
                      value={values.near_compliance_2_end}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      icon="hashtag"
                    />
                    <FormError
                      text={
                        errors.near_compliance_2_end &&
                        touched.near_compliance_2_end &&
                        errors.near_compliance_2_end
                      }
                    />
                  </InputContainer>
                </Row>
                <Row>
                  <InputContainer>
                    <H4 inputLabel color={Colors.gray8}>
                      Out of Compliance 2 End (ppm)
                    </H4>
                    <Input
                      name="out_of_compliance_2_end"
                      type="number"
                      value={values.out_of_compliance_2_end}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      icon="hashtag"
                    />
                    <FormError
                      text={
                        errors.out_of_compliance_2_end &&
                        touched.out_of_compliance_2_end &&
                        errors.out_of_compliance_2_end
                      }
                    />
                  </InputContainer>
                </Row>
              </Column>
              <Column style={{ marginLeft: '15px' }}>
                <H4 inputLabel color={Colors.lightBlue}>
                  TDS Compliance Guide
                </H4>
                <Row>
                  <InputContainer>
                    <Box>
                      <H4 inputLabel color={Colors.gray8}>
                        <Icon
                          name="circle"
                          color={Colors.green}
                          customStyle={{ marginRight: 10 }}
                        />
                        In Compliance
                      </H4>
                      <H4 inputLabel color={Colors.gray8}>
                        <Icon
                          name="circle"
                          color={Colors.yellow}
                          customStyle={{ marginRight: 10 }}
                        />
                        Near Compliance
                      </H4>
                      <H4 inputLabel color={Colors.gray8}>
                        <Icon name="circle" color={Colors.red} customStyle={{ marginRight: 10 }} />
                        Out of Compliance
                      </H4>
                    </Box>
                  </InputContainer>
                </Row>
              </Column>
            </Row>
            <Row style={{ marginTop: '15px' }}>
              <Column style={{ marginRight: '15px' }}>
                <H4 inputLabel color={Colors.lightBlue}>
                  Waste Water Percentage
                </H4>
                <Row>
                  <InputContainer>
                    <H4 inputLabel color={Colors.gray8}>
                      Good End (% - 0 implied)
                    </H4>
                    <Input
                      name="good_end"
                      type="number"
                      value={values.good_end}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      icon="percent"
                    />
                    <FormError text={errors.good_end && touched.good_end && errors.good_end} />
                  </InputContainer>
                </Row>
                <Row>
                  <InputContainer>
                    <H4 inputLabel color={Colors.gray8}>
                      Fair End (%)
                    </H4>
                    <Input
                      name="fair_end"
                      type="number"
                      value={values.fair_end}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      icon="percent"
                    />
                    <FormError text={errors.fair_end && touched.fair_end && errors.fair_end} />
                  </InputContainer>
                </Row>
                <Row>
                  <InputContainer>
                    <H4 inputLabel color={Colors.gray8}>
                      Poor End (%)
                    </H4>
                    <Input
                      name="poor_end"
                      type="number"
                      value={values.poor_end}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      icon="percent"
                    />
                    <FormError text={errors.poor_end && touched.poor_end && errors.poor_end} />
                  </InputContainer>
                </Row>
              </Column>
              <Column style={{ marginLeft: '15px' }}>
                <H4 inputLabel color={Colors.lightBlue}>
                  Waste Water % Guide
                </H4>
                <Row>
                  <InputContainer>
                    <Box>
                      <H4 inputLabel color={Colors.gray8}>
                        <Icon
                          name="circle"
                          color={Colors.green}
                          customStyle={{ marginRight: 10 }}
                        />
                        In Compliance
                      </H4>
                      <H4 inputLabel color={Colors.gray8}>
                        <Icon
                          name="circle"
                          color={Colors.yellow}
                          customStyle={{ marginRight: 10 }}
                        />
                        Near Compliance
                      </H4>
                      <H4 inputLabel color={Colors.gray8}>
                        <Icon name="circle" color={Colors.red} customStyle={{ marginRight: 10 }} />
                        Out of Compliance
                      </H4>
                    </Box>
                  </InputContainer>
                </Row>
              </Column>
            </Row>
            <Row style={{ marginTop: '30px' }}>
              <Line />
            </Row>
            <Row>
              <ButtonContainer>
                <ButtonText
                  text="Back"
                  type="button"
                  theme="default"
                  customStyles={{ marginRight: '20px' }}
                  onClick={back}
                />
                <ButtonText text="Save" theme="primary" type="submit" disabled={!isValid} />
              </ButtonContainer>
            </Row>
          </Form>
        )}
      </Formik>
    );
  }
}

DataForm.propTypes = {
  back: PropTypes.func,
  initialValues: PropTypes.object,
  isInitialValid: PropTypes.bool,
  onSubmit: PropTypes.func,
};
