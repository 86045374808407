import React from 'react';
import { Colors } from 'modules/theme';
import moment from 'moment';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import Logo from 'assets/media/images/logo.png';
import Check from 'assets/media/images/check.png';
import ErrorIco from 'assets/media/images/error.png';
import PropTypes from 'prop-types';

const styles = StyleSheet.create({
  table: {
    marginTop: 15,
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderColor: Colors.grayChart,
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
  },
  tableCol: {
    borderStyle: 'solid',
    borderColor: Colors.grayChart,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: {
    margin: 'auto',
    marginTop: 5,
    marginBottom: 5,
    fontSize: 10,
    color: Colors.gray,
  },
  line: {
    width: '100%',
    height: 1,
    backgroundColor: Colors.grayChart,
    marginTop: 5,
    marginBottom: 5,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 5,
    marginBottom: 5,
  },
  col: {
    display: 'flex',
    flexDirection: 'column',
  },
});

export const ReportWS = ({
  saltChart,

  saltLevelRemaining,
  allAlerts,
  softenerCurrentState: { status_value, flow_2, distance },
  location: {
    brand_name,
    account_name,
    identificator,
    contact_first_name,
    contact_last_name,
    addres_line,
    addres_line_1,
    zip_code,
    City,
    State,
    SoftenerFilterHead,
  },
}) => {
  const { device_id, installed_date } = SoftenerFilterHead;

  const flow_2_gallons = (flow_2 / 1218.902029).toFixed(2);

  return (
    <Document>
      <Page wrap size="A4" style={{ padding: 25 }}>
        <View>
          <View style={{ flexDirection: 'row' }}>
            <View style={{ width: '70%' }}>
              <Image src={Logo} style={{ width: 170, marginBottom: 15 }} />
              <Text style={{ fontSize: 10, color: Colors.gray }}>
                Installed date:
                {installed_date
                  ? moment(installed_date).format('MM/DD/YYYY')
                  : 'Installation not Complete'}
              </Text>
              <Text style={{ fontSize: 10, color: Colors.gray }}>
                Operation Status: {status_value || 'N/A'}
              </Text>
            </View>

            <View style={{ width: '30%' }}>
              <Text style={{ fontSize: 10, color: Colors.gray }}>Brand / Account</Text>
              <Text style={{ fontSize: 10 }}>
                {brand_name} /{account_name}
              </Text>

              <Text style={{ fontSize: 10, color: Colors.gray }}>Location ID</Text>
              <Text style={{ fontSize: 10 }}>{identificator}</Text>

              <Text style={{ fontSize: 10, color: Colors.gray }}>Store Contact</Text>
              <Text style={{ fontSize: 10 }}>
                {contact_first_name} {contact_last_name}
              </Text>

              <Text style={{ fontSize: 10, color: Colors.gray }}>Location Address</Text>
              <Text style={{ fontSize: 10 }}>{addres_line}</Text>
              <Text style={{ fontSize: 10 }}>{addres_line_1}</Text>
              <Text style={{ fontSize: 10 }}>
                {City.name},{State.name},{zip_code}
              </Text>
            </View>
          </View>

          <View style={{ marginBottom: 15 }}>
            <Text style={{ fontSize: 15 }}>
              Device ID:
              {device_id}
            </Text>
            <Text style={{ fontSize: 17 }}>{SoftenerFilterHead.SoftenerConfiguration.name}</Text>
          </View>

          {/* <View style={styles.line} />
          <View style={styles.row}>
            <Text style={{ textAlign: 'center' }}>Water Flow</Text>
          </View> */}

          {/* {!device_id ? (
            <Text style={{ textAlign: 'center', color: Colors.gray, fontSize: 10 }}>
              Installation not complete
            </Text>
          ) : (
            <View style={styles.row}>
              <View>
                <Text style={{ fontSize: 10, color: Colors.gray, marginBottom: 10 }}>
                  Time period: {moment(datePickerFromConsumption).format('MM/DD/YYYY')} -
                  {moment(datePickerToConsumption).format('MM/DD/YYYY')}
                </Text>

                <Image src={totalWaterChart} style={{ width: 260, heigth: 'auto' }} />
                {last_updated && (
                  <Text style={{ fontSize: 10, color: Colors.gray, textAlign: 'center' }}>
                    Last Updated: {moment.unix(last_updated).format('h:mma MM/DD/YYYY')}
                  </Text>
                )}
              </View>
              <View style={{ ...styles.row, justifyContent: 'center', alignItems: 'center' }}>
                <View>
                  <Text style={{ fontSize: 22 }}>Total Flow </Text>
                </View>
                <View>
                  <Text style={{ fontSize: 22, color: Colors.blue }}>
                    {total_flow === 0 ? ' N/A' : ` ${total_flow}`}
                  </Text>
                </View>
                <View>
                  <Text> gal</Text>
                </View>
              </View>
            </View>
          )} */}

          {/* <View style={styles.line} /> */}

          {/* <View
            style={{
              ...styles.col,
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Text>Waste Water Flow</Text>
            {!device_id ? (
              <Text style={{ color: Colors.gray, fontSize: 10 }}>Installation not complete</Text>
            ) : (
              <View style={styles.row}>
                <View style={{ width: '100%', justifyContent: 'center' }}>
                  <View style={{ ...styles.row, marginBottom: 5, alignItems: 'center' }}>
                    <Text style={{ fontSize: 10, color: Colors.gray }}>
                      Time period: {moment(datePickerFromWaste).format('MM/DD/YYYY')} -
                      {moment(datePickerToWaste).format('MM/DD/YYYY')}
                    </Text>
                  </View>
                  <Image src={wasteWaterChart} style={{ width: '100%', height: 'auto' }} />
                </View>
              </View>
            )}
          </View> */}

          <View style={styles.line} />

          <View
            style={{
              ...styles.col,
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Text>Salt Level</Text>
            {!device_id ? (
              <Text style={{ color: Colors.gray, fontSize: 10 }}>Installation not complete</Text>
            ) : (
              <View style={styles.row}>
                <View style={{ ...styles.row }}>
                  <View style={{ ...styles.row, alignItems: 'center' }}>
                    <Image src={saltChart} style={{ width: 50, height: 100 }} />
                    <Text style={{ color: Colors.blue, marginRight: 10, fontSize: 32 }}>
                      {distance}
                      <Text style={{ fontSize: 32, color: Colors.black }}> inches </Text>
                    </Text>
                  </View>
                </View>

                <View style={{ ...styles.row, alignItems: 'center' }}>
                  <Text style={{ color: Colors.green, marginRight: 15, fontSize: 32 }}>
                    {saltLevelRemaining}%
                  </Text>
                  <Text style={{ color: Colors.gray, fontSize: 20 }}>remaining</Text>
                </View>
              </View>
            )}
          </View>

          <View style={styles.line} />

          <View style={styles.row} wrap={false}>
            <View
              style={{
                ...styles.col,
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <Text style={{ margin: 10 }}>Bypass Status</Text>
              {!device_id ? (
                <Text style={{ color: Colors.gray, fontSize: 10 }}>Installation not complete</Text>
              ) : (
                <>
                  {!flow_2_gallons || flow_2_gallons === 0 ? (
                    <Text style={{ color: Colors.green }}>Off</Text>
                  ) : (
                    <Text style={{ color: Colors.red }}>On</Text>
                  )}
                </>
              )}
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.row} wrap={false}>
              {/*
              <View
                style={{
                  ...styles.col,
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '33%',
                }}
              >
                <Text style={{ margin: 10, fontSize: 15 }}>Inlet Pressure</Text>

                {!device_id ? (
                  <Text style={{ color: Colors.gray, fontSize: 10 }}>
                    Installation not complete
                  </Text>
                ) : (
                  <View style={{ ...styles.col }}>
                    <Text style={{ fontSize: 13 }}>
                      <Text style={{ color: Colors.blue }}>{`    ${Math.round(
                        pressure.inletPressure,
                      )}   `}</Text>
                      PSI
                    </Text>
                  </View>
                )}
              </View>

              <View
                style={{
                  ...styles.col,
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '33%',
                }}
              >
                <Text style={{ margin: 10, fontSize: 15 }}>Outlet Pressure</Text>

                {!device_id ? (
                  <Text style={{ color: Colors.gray, fontSize: 10 }}>
                    Installation not complete
                  </Text>
                ) : (
                  <View style={{ ...styles.col }}>
                    <Text style={{ fontSize: 13 }}>
                      <Text
                        style={{ color: Colors.blue }}
                      >{`    ${Math.round(pressure.outletPressure)}   `}</Text>
                      PSI
                    </Text>
                  </View>
                )}
              </View>

              <View
                style={{
                  ...styles.col,
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '33%',
                }}
              >
                <Text style={{ margin: 10, fontSize: 15 }}>Differential Pressure</Text>

                {!device_id ? (
                  <Text style={{ color: Colors.gray, fontSize: 10 }}>
                    Installation not complete
                  </Text>
                ) : (
                  <View style={{ ...styles.col }}>
                    <Text style={{ fontSize: 13 }}>
                      <Text style={{ color: Colors.blue }}>
                        {`    ${pressure.inletPressure - pressure.outletPressure >= 0 ? Math.round((pressure.inletPressure - pressure.outletPressure).toFixed(2)) : 0}   `}
                      </Text>
                      PSI
                    </Text>
                  </View>
                )}
              </View>

              */}
            </View>
          </View>

          {allAlerts && allAlerts.length > 0 && (
            <View style={styles.table}>
              {/* TableHeader */}

              <View style={styles.tableRow}>
                <View style={[styles.tableCol, { width: '20%' }]}>
                  <Text style={styles.tableCell}>Status</Text>
                </View>
                <View style={[styles.tableCol, { width: '20%' }]}>
                  <Text style={styles.tableCell}>Title</Text>
                </View>
                <View style={[styles.tableCol, { width: '20%' }]}>
                  <Text style={styles.tableCell}>Type</Text>
                </View>
                <View style={[styles.tableCol, { width: '20%' }]}>
                  <Text style={styles.tableCell}>Email Notification</Text>
                </View>
                <View style={[styles.tableCol, { width: '20%' }]}>
                  <Text style={styles.tableCell}>Sms Notification</Text>
                </View>
              </View>
              {/* TableContent */}

              {(allAlerts || []).map(alert => (
                <View style={styles.tableRow} wrap={false}>
                  <View style={[styles.tableCol, { width: '20%' }]}>
                    <Text style={[styles.tableCell, { color: Colors.yellow }]}>{alert.status}</Text>
                  </View>
                  <View style={[styles.tableCol, { width: '20%' }]}>
                    <Text style={styles.tableCell}>{alert.title} </Text>
                  </View>
                  <View style={[styles.tableCol, { width: '20%' }]}>
                    <Text style={styles.tableCell}>{alert.type}</Text>
                  </View>
                  <View style={[styles.tableCol, { width: '20%' }]}>
                    <Text style={styles.tableCell}>
                      <Image
                        src={alert.allow_email_notifications ? Check : ErrorIco}
                        style={{ width: 15, height: 15, marginRight: 5 }}
                      />
                    </Text>
                  </View>
                  <View style={[styles.tableCol, { width: '20%' }]}>
                    <Text style={styles.tableCell}>
                      <Image
                        src={alert.allow_sms_notifications ? Check : ErrorIco}
                        style={{ width: 15, height: 15, marginRight: 5 }}
                      />
                    </Text>
                  </View>
                </View>
              ))}
            </View>
          )}
        </View>
      </Page>
    </Document>
  );
};

ReportWS.propTypes = {
  allAlerts: PropTypes.array,
  location: PropTypes.object,
  saltChart: PropTypes.string,
  saltLevelRemaining: PropTypes.number,
  softenerCurrentState: PropTypes.object,
};
