import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  getLocations,
  getBrandsForFilter,
  getAccountsForFilter,
  deleteLocation,
  getStates,
} from 'actions/index';
import PropTypes from 'prop-types';
import LocationListView from './LocationListView';

class LocationListContainer extends Component {
  componentDidMount() {
    const { fetchLocations, fetchBrands, fetchStates } = this.props;

    fetchLocations();
    fetchBrands();
    fetchStates();
  }

  render() {
    const {
      list,
      pages,
      currentPage,
      loading,
      brands,
      accounts,
      states,
      fetchLocations,
      fetchAccounts,
      removeLocation,
      role_code,
    } = this.props;

    return (
      <LocationListView
        role_code={role_code}
        list={list}
        pages={pages}
        currentPage={currentPage}
        loading={loading}
        states={states}
        brands={brands}
        accounts={accounts}
        fetchLocations={fetchLocations}
        fetchAccounts={fetchAccounts}
        removeLocation={removeLocation}
      />
    );
  }
}

function mapStateToProps(state) {
  const sortedBrands = [{ label: 'All', value: '' }, ...state.brands.listForFilter].sort((a, b) =>
    a.label.localeCompare(b.label),
  );
  const sortedAccounts = [
    { label: 'All', value: '' },
    ...state.accounts.listForFilter,
  ].sort((a, b) => a.label.localeCompare(b.label));
  const sortedStates = [{ label: 'All', value: '' }, ...state.utils.states].sort((a, b) =>
    a.label.localeCompare(b.label),
  );

  return {
    role_code: state.auth.profile.role_code,
    list: state.locations.list,
    count: state.locations.count,
    loading: state.locations.loading,
    pages: state.locations.pages,
    currentPage: state.locations.currentPage,
    brands: sortedBrands,
    accounts: sortedAccounts,
    states: sortedStates,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchLocations: params => dispatch(getLocations(params)),
    fetchBrands: () => dispatch(getBrandsForFilter()),
    fetchAccounts: params => dispatch(getAccountsForFilter(params)),
    fetchStates: () => dispatch(getStates()),
    removeLocation: id => dispatch(deleteLocation(id)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationListContainer);

LocationListContainer.propTypes = {
  accounts: PropTypes.array,
  brands: PropTypes.array,
  currentPage: PropTypes.number,
  fetchAccounts: PropTypes.func,
  fetchBrands: PropTypes.func,
  fetchLocations: PropTypes.func,
  fetchStates: PropTypes.func,
  list: PropTypes.array,
  loading: PropTypes.bool,
  pages: PropTypes.number,
  removeLocation: PropTypes.func,
  role_code: PropTypes.string,
  states: PropTypes.array,
};
