import React, { Component } from 'react';
import { BrandsRolesCode } from 'constants/index';
import { connect } from 'react-redux';
import {
  getUsers,
  getBrandsForFilter,
  getAccountsForFilter,
  getLocationsForFilter,
  deleteUser,
  resendEmail,
} from 'actions/index';
import PropTypes from 'prop-types';
import UserListView from './UserListView';

class UserListContainer extends Component {
  componentDidMount() {
    const { fetchUsers, fetchBrands } = this.props;

    fetchUsers({
      roles: BrandsRolesCode,
    });

    fetchBrands();
  }

  render() {
    const {
      list,
      count,
      pages,
      currentPage,
      loading,
      brands,
      accounts,
      locations,
      fetchAccounts,
      fetchLocations,
      fetchUsers,
      removeUser,
      resendInvitation,
    } = this.props;

    return (
      <UserListView
        fetchUsers={fetchUsers}
        list={list}
        count={count}
        pages={pages}
        currentPage={currentPage}
        loading={loading}
        brands={brands}
        accounts={accounts}
        locations={locations}
        fetchAccounts={fetchAccounts}
        fetchLocations={fetchLocations}
        removeUser={removeUser}
        resendInvitation={resendInvitation}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    list: state.users.list,
    count: state.users.count,
    pages: state.users.pages,
    currentPage: state.users.currentPage,
    loading: state.users.loading,
    brands: [{ label: 'All', value: '' }, ...state.brands.listForFilter],
    accounts: [{ label: 'All', value: '' }, ...state.accounts.listForFilter],
    locations: [{ label: 'All', value: '' }, ...state.locations.listForFilter],
  };
}
function mapDispatchToProps(dispatch) {
  return {
    fetchUsers: params => dispatch(getUsers(params)),
    fetchBrands: () => dispatch(getBrandsForFilter()),
    fetchAccounts: params => dispatch(getAccountsForFilter(params)),
    fetchLocations: params => dispatch(getLocationsForFilter(params)),
    removeUser: id => dispatch(deleteUser(id)),
    resendInvitation: id => dispatch(resendEmail(id)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(UserListContainer);

UserListContainer.propTypes = {
  accounts: PropTypes.array,
  brands: PropTypes.array,
  count: PropTypes.number,
  currentPage: PropTypes.number,
  fetchAccounts: PropTypes.func,
  fetchBrands: PropTypes.func,
  fetchLocations: PropTypes.func,
  fetchUsers: PropTypes.func,
  list: PropTypes.array,
  loading: PropTypes.bool,
  locations: PropTypes.array,
  pages: PropTypes.number,
  removeUser: PropTypes.func,
  resendInvitation: PropTypes.func,
};
