import React from 'react';
import moment from 'moment';
import { Colors } from 'modules/theme';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import Logo from 'assets/media/images/logo.png';
import Check from 'assets/media/images/check.png';
import ErrorIco from 'assets/media/images/error.png';
import PropTypes from 'prop-types';

const styles = StyleSheet.create({
  table: {
    marginTop: 15,
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderColor: Colors.grayChart,
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
  },
  tableCol: {
    borderStyle: 'solid',
    borderColor: Colors.grayChart,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: {
    margin: 'auto',
    marginTop: 5,
    marginBottom: 5,
    fontSize: 10,
    color: Colors.gray,
  },
  line: {
    width: '100%',
    height: 1,
    backgroundColor: Colors.grayChart,
    marginTop: 5,
    marginBottom: 5,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 5,
    marginBottom: 5,
  },
  col: {
    display: 'flex',
    flexDirection: 'column',
  },
});

export const ReportWF = ({
  datePickerFrom,
  datePickerTo,
  totalGaloonsChart,
  maxFlowAndPressureChart,
  exhaustionChart,
  allAlerts,
  waterFilterConsumption: { last_updated, total_flow },
  waterFilterTotalConsumption,
  catridgeSelectedObjet,
  pressures,
  cartridgeTotalConsumptionWf,
  cartridgeAllTotalConsumptionWf,
  totalCatridgeCapacity,
  waterFilterCurrentBypass,
  location: {
    brand_name,
    account_name,
    identificator,
    contact_first_name,
    contact_last_name,
    addres_line,
    addres_line_1,
    zip_code,
    City,
    State,
    WaterFilterHead,
  },
}) => {
  let filterWeeksDuration;
  let replacedWeeks;
  if (catridgeSelectedObjet && catridgeSelectedObjet.last_replaced) {
    const {
      SpotsWaterFilter: {
        Catridge: { warranty: warrantyYears },
      },
    } = catridgeSelectedObjet;
    filterWeeksDuration = 52 * warrantyYears;

    const lastReplacedMoment = moment(catridgeSelectedObjet.last_replaced);
    replacedWeeks = moment().diff(lastReplacedMoment, 'weeks');
  }
  const { device_id, installed_date } = WaterFilterHead;

  return (
    <Document>
      <Page wrap size="A4" style={{ padding: 25 }}>
        <View>
          <View style={{ flexDirection: 'row' }}>
            <View style={{ width: '70%' }}>
              <Image src={Logo} style={{ width: 170, marginBottom: 15 }} />
              <Text style={{ fontSize: 10, color: Colors.gray }}>
                Installed date:
                {installed_date
                  ? moment(installed_date).format('MM/DD/YYYY')
                  : 'Installation not Complete'}
              </Text>
            </View>

            <View style={{ width: '30%' }}>
              <Text style={{ fontSize: 10, color: Colors.gray }}>Brand / Account</Text>
              <Text style={{ fontSize: 10 }}>
                {brand_name} /{account_name}
              </Text>

              <Text style={{ fontSize: 10, color: Colors.gray }}>Location ID</Text>
              <Text style={{ fontSize: 10 }}>{identificator}</Text>

              <Text style={{ fontSize: 10, color: Colors.gray }}>Store Contact</Text>
              <Text style={{ fontSize: 10 }}>
                {contact_first_name} {contact_last_name}
              </Text>

              <Text style={{ fontSize: 10, color: Colors.gray }}>Location Address</Text>
              <Text style={{ fontSize: 10 }}>{addres_line}</Text>
              <Text style={{ fontSize: 10 }}>{addres_line_1}</Text>
              <Text style={{ fontSize: 10 }}>
                {City.name},{State.name},{zip_code}
              </Text>
            </View>
          </View>

          <View style={{ marginBottom: 15 }}>
            <Text style={{ fontSize: 15 }}>
              Device ID: {WaterFilterHead.device_id || 'Installation not complete'}
            </Text>
            <Text style={{ fontSize: 17 }}>
              {WaterFilterHead.WaterFilterConfiguration.name}
              <Text style={{ color: Colors.blue }}>
                {`(${WaterFilterHead.chlorine_or_chloramine})`}
              </Text>
            </Text>
          </View>

          <View>
            {(WaterFilterHead.FlowMeterOfWaterFilters || []).map(
              ({ SpotsWaterFilter: { Catridge } }) => (
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                  <Text style={{ fontSize: 13 }}>
                    Model:
                    {Catridge.name}
                  </Text>
                  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <Image
                      src={Catridge.scale ? Check : ErrorIco}
                      style={{ width: 15, height: 15, marginRight: 5 }}
                    />
                    <Text style={{ fontSize: 13 }}>Scale</Text>
                  </View>
                  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <Image
                      src={Catridge.contaminant ? Check : ErrorIco}
                      style={{ width: 15, height: 15, marginRight: 5 }}
                    />
                    <Text style={{ fontSize: 13 }}>Contaminants</Text>
                  </View>
                  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <Image
                      src={Catridge.taste_odor ? Check : ErrorIco}
                      style={{ width: 15, height: 15, marginRight: 5 }}
                    />
                    <Text style={{ fontSize: 13 }}>Taste/Odor</Text>
                  </View>
                </View>
              ),
            )}
          </View>

          <View style={styles.line} />
          <View style={styles.row}>
            <Text style={{ textAlign: 'center' }}>Water Flow</Text>
          </View>

          {!device_id ? (
            <Text style={{ textAlign: 'center', color: Colors.gray, fontSize: 10 }}>
              Installation not complete
            </Text>
          ) : (
            <View style={styles.row}>
              <View>
                <Text style={{ fontSize: 10, color: Colors.gray, marginBottom: 10 }}>
                  Time period: {moment(datePickerFrom).format('MM/DD/YYYY')} -
                  {moment(datePickerTo).format('MM/DD/YYYY')}
                </Text>

                <Image src={totalGaloonsChart} style={{ width: 260, heigth: 'auto' }} />
                {last_updated && (
                  <Text style={{ fontSize: 10, color: Colors.gray, textAlign: 'center' }}>
                    Last Updated: {moment.unix(last_updated).format('h:mma MM/DD/YYYY')}
                  </Text>
                )}
              </View>

              <View
                style={{
                  flexDirection: 'col',
                  width: '50%',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <View>
                  <Text style={{ fontSize: 15 }}>Flow Since Installation: </Text>
                </View>
                <View>
                  <Text style={{ fontSize: 15, color: Colors.blue }}>
                    {waterFilterTotalConsumption === 0
                      ? ' N/A'
                      : ` ${Math.round(waterFilterTotalConsumption)} gal`}
                  </Text>
                </View>

                <View>
                  <Text style={{ fontSize: 15 }}>Flow Period selected: </Text>
                </View>
                <View>
                  <Text style={{ fontSize: 15, color: Colors.blue }}>
                    {total_flow === 0 ? ' N/A' : ` ${Math.round(total_flow)} gal`}
                    {}
                  </Text>
                </View>
              </View>
            </View>
          )}

          <View style={styles.line} />
          <View style={{ alignItems: 'center', marginTop: 5 }}>
            <Text>Filter Catridges</Text>
            {cartridgeTotalConsumptionWf ? (
              <>
                <View style={styles.row}>
                  <View style={{ ...styles.row, width: '50%' }}>
                    <View
                      style={{
                        ...styles.col,
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '50%',
                      }}
                    >
                      <Text style={{ color: Colors.gray, fontSize: 15 }}>Exhaustion</Text>
                      <Text style={{ fontSize: 15 }} />
                      <Text style={{ fontSize: 10, color: Colors.gray }}>Last Replaced:</Text>
                      <Text style={{ fontSize: 10, color: Colors.gray }}>
                        {moment(catridgeSelectedObjet.last_replaced).format('MMMM Do yyyy')}
                      </Text>
                    </View>
                    <View
                      style={{
                        ...styles.col,
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '50%',
                      }}
                    >
                      <Image src={exhaustionChart} style={{ width: 95, heigth: 'auto' }} />
                    </View>
                  </View>

                  <View style={{ ...styles.row, width: '50%' }}>
                    <View
                      style={{
                        ...styles.col,
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                      }}
                    >
                      <Text style={{ color: Colors.gray, fontSize: 15 }}>Expiration:</Text>
                      <Text style={{ fontSize: 15 }}>Installed For:{replacedWeeks} weeks.</Text>
                      <Text style={{ fontSize: 15, color: Colors.yellow }}>
                        {filterWeeksDuration - replacedWeeks} weeks remaining
                      </Text>
                    </View>
                  </View>
                </View>

                <View
                  style={{
                    ...styles.row,
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '50%',
                  }}
                >
                  <Text style={{ fontSize: 10, color: Colors.gray }}>
                    Summary cartridges: {Math.round(cartridgeAllTotalConsumptionWf)} /{' '}
                    {totalCatridgeCapacity} gal.
                  </Text>
                </View>
              </>
            ) : (
              <Text style={{ color: Colors.gray, fontSize: 10 }}>Installation not complete</Text>
            )}
          </View>

          <View style={styles.line} />

          <View style={styles.row} wrap={false}>
            <View
              style={{
                ...styles.col,
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <Text style={{ margin: 10 }}>Bypass Status</Text>
              {!device_id ? (
                <Text style={{ color: Colors.gray, fontSize: 10 }}>Installation not complete</Text>
              ) : (
                <>
                  {!waterFilterCurrentBypass || waterFilterCurrentBypass === 0 ? (
                    <Text style={{ color: Colors.green }}>Off</Text>
                  ) : (
                    <Text style={{ color: Colors.red }}>On</Text>
                  )}
                </>
              )}
            </View>
          </View>

          <View style={styles.line} />

          <View style={styles.row} wrap={false}>
            <View
              style={{
                ...styles.col,
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <Text style={{ margin: 10 }}>Max Pressure Differential & Flow Rate</Text>
              {!device_id ? (
                <Text style={{ color: Colors.gray, fontSize: 10 }}>Installation not complete</Text>
              ) : (
                <Image src={maxFlowAndPressureChart} style={{ width: '100%', heigth: 'auto' }} />
              )}
            </View>
          </View>

          <View style={styles.line} />

          <View style={styles.row} wrap={false}>
            <View
              style={{
                ...styles.col,
                justifyContent: 'center',
                alignItems: 'center',
                width: '33%',
              }}
            >
              <Text style={{ margin: 10, fontSize: 15 }}>Inlet Pressure</Text>

              {!device_id ? (
                <Text style={{ color: Colors.gray, fontSize: 10 }}>Installation not complete</Text>
              ) : (
                <View style={{ ...styles.col }}>
                  {pressures.map((pressure, index) => (
                    <Text style={{ fontSize: 13 }}>
                      Filter {index + 1}:
                      <Text style={{ color: Colors.blue }}>
                        {`    ${Math.round(pressure.inletPressure)}   `}{' '}
                      </Text>
                      PSI
                    </Text>
                  ))}
                </View>
              )}
            </View>

            <View
              style={{
                ...styles.col,
                justifyContent: 'center',
                alignItems: 'center',
                width: '33%',
              }}
            >
              <Text style={{ margin: 10, fontSize: 15 }}>Outlet Pressure</Text>

              {!device_id ? (
                <Text style={{ color: Colors.gray, fontSize: 10 }}>Installation not complete</Text>
              ) : (
                <View style={{ ...styles.col }}>
                  {pressures.map((pressure, index) => (
                    <Text style={{ fontSize: 13 }}>
                      Filter {index + 1}:
                      <Text style={{ color: Colors.blue }}>
                        {`    ${Math.round(pressure.outletPressure)}   `}
                      </Text>
                      PSI
                    </Text>
                  ))}
                </View>
              )}
            </View>

            <View
              style={{
                ...styles.col,
                justifyContent: 'center',
                alignItems: 'center',
                width: '33%',
              }}
            >
              <Text style={{ margin: 10, fontSize: 15 }}>Differential Pressure</Text>

              {!device_id ? (
                <Text style={{ color: Colors.gray, fontSize: 10 }}>Installation not complete</Text>
              ) : (
                <View style={{ ...styles.col }}>
                  {pressures.map((pressure, index) => (
                    <Text style={{ fontSize: 13 }}>
                      Filter {index + 1}:
                      <Text style={{ color: Colors.blue }}>
                        {`    ${
                          pressure.inletPressure - pressure.outletPressure >= 0
                            ? Math.round(
                                (pressure.inletPressure - pressure.outletPressure).toFixed(2),
                              )
                            : 0
                        }   `}
                      </Text>
                      PSI
                    </Text>
                  ))}
                </View>
              )}
            </View>
          </View>

          <View style={styles.line} />

          {allAlerts && allAlerts.length > 0 && (
            <View style={styles.table}>
              {/* TableHeader */}

              <View style={styles.tableRow}>
                <View style={[styles.tableCol, { width: '20%' }]}>
                  <Text style={styles.tableCell}>Status</Text>
                </View>
                <View style={[styles.tableCol, { width: '20%' }]}>
                  <Text style={styles.tableCell}>Title</Text>
                </View>
                <View style={[styles.tableCol, { width: '20%' }]}>
                  <Text style={styles.tableCell}>Type</Text>
                </View>
                <View style={[styles.tableCol, { width: '20%' }]}>
                  <Text style={styles.tableCell}>Email Notification</Text>
                </View>
                <View style={[styles.tableCol, { width: '20%' }]}>
                  <Text style={styles.tableCell}>Sms Notification</Text>
                </View>
              </View>
              {/* TableContent */}

              {(allAlerts || []).map(alert => (
                <View style={styles.tableRow} wrap={false}>
                  <View style={[styles.tableCol, { width: '20%' }]}>
                    <Text style={[styles.tableCell, { color: Colors.yellow }]}>{alert.status}</Text>
                  </View>
                  <View style={[styles.tableCol, { width: '20%' }]}>
                    <Text style={styles.tableCell}>{alert.title} </Text>
                  </View>
                  <View style={[styles.tableCol, { width: '20%' }]}>
                    <Text style={styles.tableCell}>{alert.type}</Text>
                  </View>
                  <View style={[styles.tableCol, { width: '20%' }]}>
                    <Text style={styles.tableCell}>
                      <Image
                        src={alert.allow_email_notifications ? Check : ErrorIco}
                        style={{ width: 15, height: 15, marginRight: 5 }}
                      />
                    </Text>
                  </View>
                  <View style={[styles.tableCol, { width: '20%' }]}>
                    <Text style={styles.tableCell}>
                      <Image
                        src={alert.allow_sms_notifications ? Check : ErrorIco}
                        style={{ width: 15, height: 15, marginRight: 5 }}
                      />
                    </Text>
                  </View>
                </View>
              ))}
            </View>
          )}
        </View>
      </Page>
    </Document>
  );
};

ReportWF.propTypes = {
  allAlerts: PropTypes.array,
  cartridgeAllTotalConsumptionWf: PropTypes.number,
  cartridgeTotalConsumptionWf: PropTypes.number,
  catridgeSelectedObjet: PropTypes.object,
  datePickerFrom: PropTypes.string,
  datePickerTo: PropTypes.string,
  exhaustionChart: PropTypes.string,
  location: PropTypes.object,
  maxFlowAndPressureChart: PropTypes.string,
  pressures: PropTypes.array,
  totalCatridgeCapacity: PropTypes.number,
  totalGaloonsChart: PropTypes.string,
  waterFilterConsumption: PropTypes.object,
  waterFilterCurrentBypass: PropTypes.number,
  waterFilterTotalConsumption: PropTypes.number,
};
