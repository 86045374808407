import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  getBrandsForFilter,
  getStates,
  getOtaVersionsWfAndSoftener,
  getOtaVersionsRo,
  deleteOtaVersion,
} from 'actions/index';
import PropTypes from 'prop-types';
import OtaManageVersionsView from './OtaManageVersionsView';

class OtaManageVersionsContainer extends Component {
  componentDidMount() {
    const {
      fetchBrandsForFilter,
      fetchStates,
      fetchOtaVersionsWfAndSoftener,
      fetchOtaVersionsRo,
    } = this.props;

    fetchOtaVersionsRo({ page: 1 });
    fetchOtaVersionsWfAndSoftener({ page: 1 });
    fetchBrandsForFilter();
    fetchStates();
  }

  render() {
    const {
      brands,
      states,
      removeOtaVersion,
      listRoVersions,
      listWfAndSoftenerVersions,
      roVersionsCurrentPage,
      wfAndSoftenerVersionsCurrentPage,
      roVersionsPages,
      wfAndSoftenerVersionsPages,
      fetchOtaVersionsWfAndSoftener,
      fetchOtaVersionsRo,
      loadingRoVersions,
      loadingWfAndSoftenerVersions,
    } = this.props;

    return (
      <OtaManageVersionsView
        brands={brands}
        states={states}
        removeOtaVersion={removeOtaVersion}
        listRoVersions={listRoVersions}
        listWfAndSoftenerVersions={listWfAndSoftenerVersions}
        roVersionsCurrentPage={roVersionsCurrentPage}
        wfAndSoftenerVersionsCurrentPage={wfAndSoftenerVersionsCurrentPage}
        roVersionsPages={roVersionsPages}
        wfAndSoftenerVersionsPages={wfAndSoftenerVersionsPages}
        fetchOtaVersionsWfAndSoftener={fetchOtaVersionsWfAndSoftener}
        fetchOtaVersionsRo={fetchOtaVersionsRo}
        loadingRoVersions={loadingRoVersions}
        loadingWfAndSoftenerVersions={loadingWfAndSoftenerVersions}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    brands: [{ label: 'All', value: '' }, ...state.brands.listForFilter],
    states: [{ label: 'All', value: '' }, ...state.utils.states],
    listRoVersions: state.ota.listRoVersions,
    roVersionsPages: state.ota.roVersionsPages,
    roVersionsCurrentPage: state.ota.roVersionsCurrentPage,
    loadingRoVersions: state.ota.loadingRoVersions,
    listWfAndSoftenerVersions: state.ota.listWfAndSoftenerVersions,
    wfAndSoftenerVersionsPages: state.ota.wfAndSoftenerVersionsPages,
    wfAndSoftenerVersionsCurrentPage: state.ota.wfAndSoftenerVersionsCurrentPage,
    loadingWfAndSoftenerVersions: state.ota.loadingWfAndSoftenerVersions,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchOtaVersionsWfAndSoftener: params => dispatch(getOtaVersionsWfAndSoftener(params)),
    fetchOtaVersionsRo: params => dispatch(getOtaVersionsRo(params)),

    fetchBrandsForFilter: () => dispatch(getBrandsForFilter()),
    fetchStates: () => dispatch(getStates()),
    removeOtaVersion: id => dispatch(deleteOtaVersion(id)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(OtaManageVersionsContainer);

OtaManageVersionsContainer.propTypes = {
  brands: PropTypes.array,
  fetchBrandsForFilter: PropTypes.func,
  fetchOtaVersionsRo: PropTypes.func,
  fetchOtaVersionsWfAndSoftener: PropTypes.func,
  fetchStates: PropTypes.func,
  listRoVersions: PropTypes.array,
  listWfAndSoftenerVersions: PropTypes.array,
  loadingRoVersions: PropTypes.bool,
  loadingWfAndSoftenerVersions: PropTypes.bool,
  removeOtaVersion: PropTypes.func,
  roVersionsCurrentPage: PropTypes.number,
  roVersionsPages: PropTypes.number,
  states: PropTypes.array,
  wfAndSoftenerVersionsCurrentPage: PropTypes.number,
  wfAndSoftenerVersionsPages: PropTypes.number,
};
