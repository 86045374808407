import app from './app';
import auth from './auth';
import library from './library';
import location from './location';
import alert from './alert';
import alertType from './alertType';
import user from './user';
import account from './account';
import brand from './brand';
import utils from './utils';
import support from './support';
import ota from './ota';

export default {
  ...app,
  ...auth,
  ...library,
  ...location,
  ...alert,
  ...alertType,
  ...user,
  ...account,
  ...brand,
  ...utils,
  ...support,
  ...ota,
};
