import React, { Component } from 'react';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Colors } from 'modules/theme';
import InputPassword from 'components/InputPassword';
import ButtonText from 'components/ButtonText';
import H2 from 'components/H2';
import FormError from 'components/FormError';
import PropTypes from 'prop-types';
import { PASS_REG_EXP } from '../../constants';

const Container = styled.div`
  margin: 30px;
`;

const InputContainer = styled.div`
  margin: 20px 0px;
`;

const ButtonContainer = styled.div`
  margin-top: 25px;
  margin-bottom: 25px;
  float: left;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const Column = styled.div`
  flex-direction: column;
  width: 50%;
  margin-rigth: 30px;
`;

export default class LoginView extends Component {
  submit = values => {
    const { editPassword } = this.props;
    const { currentPassword, password } = values;

    editPassword({ currentPassword, password });
  };

  render() {
    const initialValues = {
      currentPassword: '',
      password: '',
      passwordConfirm: '',
    };

    return (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={this.submit}
        validationSchema={Yup.object().shape({
          currentPassword: Yup.string()
            .required('Required')
            .min(8, 'The password must have more than eight characters'),
          password: Yup.string()
            .required('Required')
            .matches(
              PASS_REG_EXP,
              'Make sure your password has numbers one capital letter and one symbol',
            )
            .min(8, 'The password must have more than eight characters'),
          passwordConfirm: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match'),
        })}
      >
        {({ values, errors, touched, handleChange, handleBlur, isValid }) => (
          <Container>
            <Form style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
              <H2 color={Colors.black}>Password</H2>
              <Row>
                <Column>
                  <InputContainer>
                    <InputPassword
                      placeholder="Current Password"
                      name="currentPassword"
                      type="password"
                      value={values.currentPassword}
                      onChange={handleChange}
                      secureTextEntry
                      onBlur={handleBlur}
                    />
                    <FormError
                      text={
                        errors.currentPassword && touched.currentPassword && errors.currentPassword
                      }
                    />
                  </InputContainer>
                </Column>
              </Row>
              <Row>
                <Column>
                  <InputContainer>
                    <InputPassword
                      placeholder="Password"
                      name="password"
                      type="password"
                      value={values.password}
                      onChange={handleChange}
                      secureTextEntry
                      onBlur={handleBlur}
                    />
                    <FormError text={errors.password && touched.password && errors.password} />
                  </InputContainer>
                </Column>
              </Row>
              <Row>
                <Column>
                  <InputContainer>
                    <InputPassword
                      placeholder="Confirm Password"
                      name="passwordConfirm"
                      type="password"
                      value={values.passwordConfirm}
                      onChange={handleChange}
                      secureTextEntry
                      onBlur={handleBlur}
                    />
                    <FormError
                      text={
                        errors.passwordConfirm && touched.passwordConfirm && errors.passwordConfirm
                      }
                    />
                  </InputContainer>
                </Column>
              </Row>
              <Row>
                <ButtonContainer>
                  <ButtonText text="Save" theme="primary" disabled={!isValid} type="submit" />
                </ButtonContainer>
              </Row>
            </Form>
          </Container>
        )}
      </Formik>
    );
  }
}

LoginView.propTypes = {
  editPassword: PropTypes.func,
};
