import { REHYDRATE } from 'redux-persist/lib/constants';
import { handleActions } from 'modules/helpers';

import { ActionTypes } from 'constants/index';

export const appState = {
  list: [],
  pages: 0,
  currentPage: 0,
  loading: false,
  allAlerts: [],
  count: 0,
};

export default {
  alerts: handleActions(
    {
      [REHYDRATE]: draft => {
        draft.list = [];
      },
      [ActionTypes.GET_ALERTS]: draft => {
        draft.loading = true;
      },
      [ActionTypes.GET_ALERTS_SUCCESS]: (
        draft,
        { payload: { rows, pages, currentPage, count } },
      ) => {
        draft.list = rows;
        draft.pages = pages;
        draft.currentPage = currentPage;
        draft.count = count;
        draft.loading = false;
      },
      [ActionTypes.GET_ALERTS_FAILURE]: draft => {
        draft.loading = false;
      },
      [ActionTypes.GET_ALL_ALERTS_SUCCESS]: (draft, { payload: { rows } }) => {
        draft.allAlerts = rows;
      },
    },
    appState,
  ),
};
