import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createWaterCatridge } from 'actions/index';
import PropTypes from 'prop-types';
import CatridgeForm from './CatridgeForm';

class CreateCatridge extends Component {
  render() {
    const { submitCatridge, loading, token } = this.props;

    const initialValues = {
      name: '',
      part: '',
      price: '',
      scale_control_capacity: '',
      chlorine_capacity: '',
      chloramine_capacity: '',
      sediment: false,
      taste_odor: false,
      contaminant: false,
      scale: false,
      photo: '',
      warranty: '',
    };

    return (
      <CatridgeForm
        onSubmit={submitCatridge}
        title="Create New Water Cartridge"
        initialValues={initialValues}
        loading={loading}
        token={token}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    loading: state.libraries.loading,
    token: state.auth.token,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    submitCatridge: library => dispatch(createWaterCatridge(library)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(CreateCatridge);

CreateCatridge.propTypes = {
  loading: PropTypes.bool,
  submitCatridge: PropTypes.func,
  token: PropTypes.string,
};
