import React, { Component } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { Colors } from 'modules/theme';
import { push } from 'modules/history';
import InputSearch from 'components/InputSearch';
import Row from 'components/Row';
import H4 from 'components/H4';
import ButtonText from 'components/ButtonText';
import ButtonStatus from 'components/ButtonStatus';
import PaginationButtons from 'components/PaginationButtons';
import Table, { THeader, TableTitle, TRow, TableItem, TableSubItem, TBody } from 'components/Table';
import { FilterContainer } from 'components/Filter';
import ListEmpty from 'components/ListEmpty';
import { ModalUpgrade } from 'components/ModalUpgrade';
import Icon from 'components/Icon';
import Loader from 'components/Loader';
import PropTypes from 'prop-types';

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 20px;
`;

const SearchContainer = styled.div`
  padding: 10px;
  flex-direction: column;
`;

const ButtonContainer = styled.div`
  flex-direction: column;
  float: right;
`;

const ButtonGroup = styled.div`
  padding: 10px;
  flex-direction: column;
  float: right;
`;

const TableContainer = styled.div`
  padding-bottom: 50px;
  margin-bottom: 50px;
`;

const Footer = styled.div`
  position: fixed;
  bottom: 0;
  padding-left: 25px;
  padding-bottom: 25px;
  background-color: ${Colors.white};
  width: 100%;
`;

const Container = styled.div`
  margin-top: 10px;
`;

export default class OtaUpgradeStatusLocationsView extends Component {
  state = {
    dropdownOpenId: null,
    isOpenModal: false,
    stepActive: 1,
    upgradeOptions: {},
    search: '',
    brand_id: '',
    state: '',
  };

  filterOnChange = event => {
    const { name, value } = event.target;

    this.setState({ [name]: value }, () => {
      console.log(name, value);
    });
  };

  searchOnChange = search => {
    this.setState({ search }, () => {
      console.log('search', search);
    });
  };

  switchModal = () => {
    const { isOpenModal } = this.state;
    this.setState({ isOpenModal: !isOpenModal, stepActive: 1, upgradeOptions: {} });
  };

  saveStep1 = values => {
    const { check_brand, check_device_family, check_location } = values;
    const { fetchPreviousInstallationInfo } = this.props;
    if (check_location) {
      const { location_id } = values;
      fetchPreviousInstallationInfo({ location_id });
    }
    if (check_device_family) {
      const { device_family } = values;
      fetchPreviousInstallationInfo({ device_family });
    }
    if (check_brand) {
      const { brand_id } = values;
      fetchPreviousInstallationInfo({ brand_id });
    }
    this.setState({ upgradeOptions: values, stepActive: 2 });
  };

  onSubmit = values => {
    const { submitUpgradeStatus } = this.props;
    this.switchModal();
    submitUpgradeStatus(values);
  };

  backStep = () => {
    this.setState({ stepActive: 1 });
  };

  renderItem = (item, key) => (
    <TRow key={key}>
      <TableItem width="16.66%">{item.location.full_address}</TableItem>
      <TableItem width="16.66%">{item.location.brand_name}</TableItem>
      <TableItem width="16.66%">{item.location.Account.name}</TableItem>
      <TableItem width="16.66%">{item.device_type}</TableItem>
      <TableItem width="16.66%">
        {item.started_at ? moment(item.started_at).format('MM/DD/YYYY hh:mm a') : '-'}
      </TableItem>
      <TableSubItem color={Colors.gray} width="16.66%">
        <ButtonStatus
          theme="round"
          text={item.status}
          onClick={() => console.log('/accounts/create')}
        />
      </TableSubItem>
    </TRow>
  );

  render() {
    const {
      upgradesListDevices,
      upgradesPagesDevices,
      upgradesCurrentPageDevices,
      fetchUpgradeStatusDevices,
      upgradesLoadingDevices,
      otaUpdateId,
      locationsForFilter,
      brandsForFilter,
      previousInstallationInfo,
      loadingGetPreviousInstallationInfo,
    } = this.props;
    const { search, isOpenModal, stepActive, upgradeOptions } = this.state;

    return (
      <Container>
        <Header style={{ paddingBottom: '0px' }}>
          <SearchContainer>
            <InputSearch onChange={this.searchOnChange} value={search} name="search" />
          </SearchContainer>
          <ButtonContainer>
            <Row>
              <ButtonGroup style={{ paddingBottom: '0px' }}>
                <ButtonText
                  theme="primary"
                  text="New Upgrade"
                  icon="plus"
                  onClick={() => this.switchModal()}
                />
              </ButtonGroup>
            </Row>
          </ButtonContainer>
        </Header>
        <Row justifyContent="start" style={{ padding: '10px' }}>
          <FilterContainer>
            <Container>
              <H4
                color={Colors.blue}
                style={{ cursor: 'pointer' }}
                onClick={() => push('/ota/upgrade-status')}
              >
                Upgrade Status
              </H4>
            </Container>
          </FilterContainer>
          <FilterContainer>
            <Container>
              <Icon name="chevron-right" color={Colors.gray} />
            </Container>
          </FilterContainer>
          <FilterContainer>
            <Container>
              <H4 color={Colors.blue}> Locations</H4>
            </Container>
          </FilterContainer>
        </Row>
        <TableContainer>
          <Table>
            <THeader>
              <TableTitle width="16.66%">Address</TableTitle>
              <TableTitle width="16.66%">Brand</TableTitle>
              <TableTitle width="16.66%">Account</TableTitle>
              <TableTitle width="16.66%">Device type</TableTitle>
              <TableTitle width="16.66%">Starting date</TableTitle>
              <TableTitle width="16.66%">Status</TableTitle>
            </THeader>
            <TBody>
              {upgradesLoadingDevices ? (
                <Loader size={100} block />
              ) : (
                upgradesListDevices.map((item, key) => this.renderItem(item, key))
              )}
            </TBody>
          </Table>
          {(!upgradesListDevices || !upgradesListDevices.length) && !upgradesLoadingDevices && (
            <ListEmpty message="There are currently no OTA" />
          )}
        </TableContainer>
        <Footer>
          <PaginationButtons
            currentPage={upgradesCurrentPageDevices}
            changePage={page => fetchUpgradeStatusDevices({ page, otaUpdateId })}
            totalPages={upgradesPagesDevices}
          />
        </Footer>
        <ModalUpgrade
          isOpenModal={isOpenModal}
          switchModal={() => this.switchModal()}
          stepActive={stepActive}
          saveStep1={this.saveStep1}
          backStep={this.backStep}
          upgradeOptions={upgradeOptions}
          saveData={this.onSubmit}
          locationsForFilter={locationsForFilter}
          brandsForFilter={brandsForFilter}
          previousInstallationInfo={previousInstallationInfo}
          loadingGetPreviousInstallationInfo={loadingGetPreviousInstallationInfo}
        />
      </Container>
    );
  }
}

OtaUpgradeStatusLocationsView.propTypes = {
  brandsForFilter: PropTypes.array,
  fetchPreviousInstallationInfo: PropTypes.func,
  fetchUpgradeStatusDevices: PropTypes.func,
  loadingGetPreviousInstallationInfo: PropTypes.bool,
  locationsForFilter: PropTypes.array,
  otaUpdateId: PropTypes.string,
  previousInstallationInfo: PropTypes.object,
  submitUpgradeStatus: PropTypes.func,
  upgradesCurrentPageDevices: PropTypes.number,
  upgradesListDevices: PropTypes.array,
  upgradesLoadingDevices: PropTypes.bool,
  upgradesPagesDevices: PropTypes.number,
};
