import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSensor } from 'actions/index';
import PropTypes from 'prop-types';
import SensorForm from './SensorForm';

const initialValues = {
  name: '',
  sensor_type: '',
};

class CreateSensor extends Component {
  render() {
    const { loading, submitSensor } = this.props;

    return (
      <SensorForm
        title="Add New Sensor"
        initialValues={initialValues}
        onSubmit={submitSensor}
        loading={loading}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    loading: state.libraries.loading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    submitSensor: library => dispatch(createSensor(library)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(CreateSensor);

CreateSensor.propTypes = {
  loading: PropTypes.bool,
  submitSensor: PropTypes.func,
};
