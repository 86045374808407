import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Colors } from 'modules/theme';
import { push } from 'modules/history';
import Icon from 'components/Icon';
import H2 from 'components/H2';
import { createOtaVersion } from 'actions/index';
import PropTypes from 'prop-types';
import DetailsForm from './DetailsForm';

const Container = styled.div`
  margin: 30px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const Column = styled.div`
  flex-direction: column;
  align-self: center;
`;

class NewVersionContainer extends Component {
  render() {
    const matchTypes = {
      'Filter - Softener': 'Filter/Softener',
      'Reverse Osmosis': 'Reverse Osmosis',
    };
    const {
      submitVersion,
      match: {
        params: { device_type },
      },
    } = this.props;

    const detailsInitialValues = {
      device_type: matchTypes[device_type],
      update_type: '',
      version: '',
      title: '',
      description: '',
      file: '',
    };

    const { token } = this.props;
    const saveVersion = values => {
      const match = {
        'Filter - Softener': 'filter',
        'Reverse Osmosis': 'reverse-osmosis',
      };
      submitVersion({ ...values, device_type: match[device_type] });
    };

    return (
      <Container>
        <Icon
          onClick={() => push('/ota/manage-versions')}
          name="chevron-left"
          color={Colors.blue}
        />
        <Row>
          <Column>
            <H2 color={Colors.black} style={{ paddingRight: 30 }}>
              Add new version
            </H2>
          </Column>
        </Row>
        <DetailsForm
          token={token}
          initialValues={detailsInitialValues}
          isInitialValid={false}
          onSubmit={saveVersion}
        />
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    token: state.auth.token,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    submitVersion: data => dispatch(createOtaVersion(data)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(NewVersionContainer);

NewVersionContainer.propTypes = {
  match: PropTypes.object,
  submitVersion: PropTypes.func,
  token: PropTypes.string,
};
