import React, { Component } from 'react';
import styled from 'styled-components';
import { Colors } from 'modules/theme';
import InputSearch from 'components/InputSearch';
import H4 from 'components/H4';
import H5 from 'components/H5';
import PaginationButtons from 'components/PaginationButtons';
import Table, { THeader, TableTitle, TRow, TableItem, TBody } from 'components/Table';
import Filter, { FilterGroup, FilterContainer } from 'components/Filter';
import Dropdown from 'components/Dropdown';
import AlertConfirm from 'components/AlertConfirm';
import ListEmpty from 'components/ListEmpty';
import Modal from 'components/Modal';
import Loader from 'components/Loader';
import PropTypes from 'prop-types';

const Container = styled.div``;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 20px;
`;

const SearchContainer = styled.div`
  padding: 10px;
  flex-direction: column;
`;

const TableContainer = styled.div`
  padding-bottom: 50px;
  margin-bottom: 50px;
`;

const Footer = styled.div`
  position: fixed;
  bottom: 0;
  padding-left: 25px;
  padding-bottom: 25px;
  background-color: ${Colors.white};
  width: 100%;
`;

const options = [
  {
    label: 'All',
    value: '',
  },
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
];

export default class SupportListView extends Component {
  state = {
    dropdownOpenId: null,
    supportSelected: null,
    isOpenModal: false,
    support: null,
    isOpenAlert: false,
    search: '',
    read: '',
  };

  searchOnChange = search => {
    this.setState({ search }, () => {
      const { fetchSupports } = this.props;
      const { search, read } = this.state;

      fetchSupports({ search, read });
    });
  };

  switchModal = support => {
    const { readSupport } = this.props;
    const { isOpenModal } = this.state;

    this.setState({ isOpenModal: !isOpenModal, support });

    if (support && !support.read) {
      readSupport(support.id);
    }
  };

  switchDropdown = key => {
    const { dropdownOpenId } = this.state;
    const id = dropdownOpenId !== key ? key : null;

    this.setState({ dropdownOpenId: id });
  };

  switchAlert = (supportSelected = null) => {
    const { isOpenAlert } = this.state;

    this.setState({ isOpenAlert: !isOpenAlert, supportSelected });
  };

  remove = () => {
    const { removeSupport } = this.props;
    const { supportSelected } = this.state;

    removeSupport(supportSelected.id);
    this.switchAlert();
  };

  filterOnChange = event => {
    const { name, value } = event.target;

    this.setState({ [name]: value }, () => {
      const { fetchSupports } = this.props;
      const { search, read } = this.state;

      fetchSupports({ search, read });
    });
  };

  renderItem = (item, key) => (
    <TRow key={key}>
      <TableItem width="25%">{item.user_full_name}</TableItem>
      <TableItem width="25%">{item.user_email}</TableItem>
      <TableItem width="25%">{item.user_phone_number}</TableItem>
      <TableItem width="25%">{item.message}</TableItem>
      <TableItem width="20%">{item.created_at}</TableItem>
      <TableItem width="5%" isComponent>
        <Dropdown
          icon="ellipsis-v"
          options={[
            {
              title: 'View',
              icon: 'eye',
              color: Colors.blue,
              action: () => this.switchModal(item),
            },
            {
              title: 'Remove',
              icon: 'trash',
              color: Colors.red,
              action: () => this.switchAlert(item),
            },
          ]}
          isDropdownOpen={this.state.dropdownOpenId === key}
          onClickDropdownButton={() => this.switchDropdown(key)}
        />
      </TableItem>
    </TRow>
  );

  renderAlertConfirm = () => {
    const { isOpenAlert, supportSelected } = this.state;

    return (
      <AlertConfirm
        title="Remove support"
        onCancel={() => this.switchAlert()}
        onSuccess={this.remove}
        successText="Remove"
        isVisible={isOpenAlert}
      >
        <H4 style={{ textAlign: 'center' }}>
          {`Are you sure that want remove the "${
            supportSelected ? supportSelected.name : ''
          }" brand`}
        </H4>
      </AlertConfirm>
    );
  };

  renderModal = () => {
    const { isOpenModal, support } = this.state;

    if (!support) {
      return null;
    }

    return (
      <Modal title="Support" onClose={this.switchModal} isVisible={isOpenModal} size="small">
        <H5 color={Colors.gray8} weight={325} style={{ marginBottom: 5, textAlign: 'left' }}>
          Full Name
        </H5>
        <H5 color={Colors.black} weight={500} style={{ textAlign: 'left' }}>
          {support.user_full_name}
        </H5>
        <H5
          color={Colors.gray8}
          weight={325}
          style={{ marginTop: 15, marginBottom: 5, textAlign: 'left' }}
        >
          Email
        </H5>
        <H5 color={Colors.black} weight={500} style={{ textAlign: 'left' }}>
          {support.user_email}
        </H5>
        <H5
          color={Colors.gray8}
          weight={325}
          style={{ marginTop: 15, marginBottom: 5, textAlign: 'left' }}
        >
          Message
        </H5>
        <H5 color={Colors.black} weight={500} style={{ textAlign: 'left' }}>
          {support.message}
        </H5>
        <H5
          color={Colors.gray8}
          weight={325}
          style={{ marginTop: 15, marginBottom: 5, textAlign: 'right' }}
        >
          {`Created at: ${support.created_at}`}
        </H5>
      </Modal>
    );
  };

  render() {
    const { list, currentPage, pages, loading } = this.props;
    const { search, read } = this.state;
    return (
      <Container>
        <Header>
          <SearchContainer>
            <InputSearch onChange={this.searchOnChange} value={search} name="search" />
          </SearchContainer>
        </Header>
        <FilterGroup>
          <FilterContainer>
            <Filter
              title="Read:"
              selectedValue={read}
              name="read"
              handleChange={this.filterOnChange}
              options={options}
            />
          </FilterContainer>
        </FilterGroup>
        <TableContainer>
          <Table>
            <THeader>
              <TableTitle width="25%">Full name</TableTitle>
              <TableTitle width="25%">Email</TableTitle>
              <TableTitle width="25%">Phone number</TableTitle>
              <TableTitle width="25%">Message</TableTitle>
              <TableTitle width="20%">Date</TableTitle>
              <TableTitle width="5%" />
            </THeader>
            <TBody>
              {loading ? (
                <Loader size={100} block />
              ) : (
                list.map((item, key) => this.renderItem(item, key))
              )}
            </TBody>
          </Table>
          {(!list || !list.length) && !loading && (
            <ListEmpty message="There are currently no supports" />
          )}
        </TableContainer>
        <Footer>
          <PaginationButtons
            currentPage={currentPage}
            /* changePage={page => fetchSupports({ page })} */
            totalPages={pages}
          />
        </Footer>
        {this.renderModal()}
        {this.renderAlertConfirm()}
      </Container>
    );
  }
}

SupportListView.propTypes = {
  currentPage: PropTypes.number,
  fetchSupports: PropTypes.func,
  list: PropTypes.array,
  loading: PropTypes.bool,
  pages: PropTypes.number,
  readSupport: PropTypes.func,
  removeSupport: PropTypes.func,
};
