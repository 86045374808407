import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSoftener, getBrineDrumForFilter } from 'actions/index';
import PropTypes from 'prop-types';
import SoftenerForm from './SoftenerForm';

class CreateSoftener extends Component {
  componentDidMount() {
    const { fetchBrineDrums } = this.props;
    fetchBrineDrums();
  }

  render() {
    const { submitSoftener, loading, token, brineDrums } = this.props;

    const initialValues = {
      name: '',
      brine_drum_id: '',
      overdrive: '',
      description: '',
      photo: '',
    };

    return (
      <SoftenerForm
        title="Add New Softener"
        onSubmit={submitSoftener}
        initialValues={initialValues}
        loading={loading}
        token={token}
        brineDrums={brineDrums}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    loading: state.libraries.loading,
    token: state.auth.token,
    brineDrums: state.libraries.brineDrumForFilter,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    submitSoftener: library => dispatch(createSoftener(library)),
    fetchBrineDrums: () => dispatch(getBrineDrumForFilter()),
  };
}

CreateSoftener.propTypes = {
  brineDrums: PropTypes.array,
  fetchBrineDrums: PropTypes.func,
  loading: PropTypes.bool,
  submitSoftener: PropTypes.func,
  token: PropTypes.string,
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateSoftener);
