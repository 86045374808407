// @flow
/**
 * @module Actions/Utils
 * @desc Utils Actions
 */
import { createActions } from 'redux-actions';

import { ActionTypes } from 'constants/index';

export const { getRoles, getStates, getCitiesByState } = createActions({
  [ActionTypes.GET_ROLES]: () => ({}),
  [ActionTypes.GET_STATES]: () => ({}),
  [ActionTypes.GET_CITIES_BY_STATE]: stateId => ({ stateId }),
});
