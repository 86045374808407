import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme, { Colors } from 'modules/theme';

import { Alert as AlertComponent, Box, utils } from 'styled-minimal';
import Icon from 'components/Icon';

const { colors, palette } = utils.getTheme(theme);
const variants = { ...colors, ...palette };

AlertComponent.displayName = 'AlertComponent';

const AlertWrapper = styled.div`
  display: flex;
  line-height: 1;
  padding: 0;
  position: relative;
  background: ${Colors.white};
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
`;

const AlertIcon = styled.div`
  align-items: flex-start;
  background-color: ${Colors.white};
  color: #fff;
  display: flex;
  padding: ${utils.spacer(3)};
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
`;

const AlertButton = styled.button`
  background-color: ${({ variant }) => variants[variant]};
  color: #ccc;
  pointer-events: all;
  position: absolute;
  right: ${utils.spacer(3)};
  top: 50%; /* Adjusted to 50% from the top */
  transform: translateY(-50%); /* Centers the button vertically */
  cursor: pointer;
`;

const Alert = ({ children, handleClickClose, id, icon, ...rest }) => {
  const output = {};
  let name;
  let color;

  switch (rest.variant) {
    case 'success': {
      name = icon || 'check';
      color = Colors.green;
      break;
    }
    case 'warning': {
      name = icon || 'exclamation';
      color = Colors.orange;
      break;
    }
    case 'danger': {
      name = icon || 'times';
      color = Colors.red;
      break;
    }
    case 'info': {
      name = icon || 'question';
      color = Colors.lightBlue;
      break;
    }
    case 'dark': {
      name = icon || 'bell';
      color = Colors.black;
      break;
    }
    default: {
      name = icon || 'circle';
      color = Colors.black;
    }
  }

  if (handleClickClose) {
    output.button = (
      <AlertButton data-id={id} onClick={handleClickClose} type="button" data-testid="AlertButton">
        <Icon name="times" fontSize={10} />
      </AlertButton>
    );
  }

  return (
    <AlertWrapper {...rest} data-testid="AlertWrapper">
      <AlertIcon {...rest}>
        <Icon name={name} color={color} fontSize={20} />
      </AlertIcon>
      <Box p={2} pr={handleClickClose ? 3 : 2} alignSelf="center" color={color}>
        {children}
      </Box>
      {output.button}
    </AlertWrapper>
  );
};

Alert.propTypes = {
  children: PropTypes.node.isRequired,
  handleClickClose: PropTypes.func,
  icon: PropTypes.string,
  id: PropTypes.string,
  outline: PropTypes.bool,
  variant: PropTypes.string,
};

Alert.defaultProps = {
  outline: true,
  variant: 'info',
};

export default Alert;
