import React, { Component } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { Colors } from 'modules/theme';
import { push } from 'modules/history';
import InputSearch from 'components/InputSearch';
import { DeviceTypes, StatusTypes } from 'constants/index';
import Row from 'components/Row';
import H5 from 'components/H5';
import ButtonText from 'components/ButtonText';
import PaginationButtons from 'components/PaginationButtons';
import Table, { THeader, TableTitle, TRow, TableItem, TableSubItem, TBody } from 'components/Table';
import Filter, { FilterGroup, FilterContainer } from 'components/Filter';
import ListEmpty from 'components/ListEmpty';
import Loader from 'components/Loader';
import { ModalUpgrade } from 'components/ModalUpgrade';
import PropTypes from 'prop-types';

const Container = styled.div``;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 20px;
`;

const Footer = styled.div`
  position: fixed;
  bottom: 0;
  padding-left: 25px;
  padding-bottom: 25px;
  background-color: ${Colors.white};
  width: 100%;
`;

const SearchContainer = styled.div`
  padding: 10px;
  flex-direction: column;
`;

const ButtonContainer = styled.div`
  flex-direction: column;
  float: right;
`;

const ButtonGroup = styled.div`
  padding: 10px;
  flex-direction: column;
  float: right;
`;

const TableContainer = styled.div`
  padding-bottom: 50px;
  margin-bottom: 50px;
`;

export default class OtaListView extends Component {
  state = {
    dropdownOpenId: null,
    accountSelected: null,
    isOpenModal: false,
    stepActive: 1,
    upgradeOptions: {},
    search: '',
    brand_id: '',
    account_id: '',
    type: '',
    version: '',
    filterStatus: '',
    state: '',
  };

  filterOnChange = event => {
    const { name, value } = event.target;
    const { fetchOtas } = this.props;

    this.setState({ [name]: value }, () => {
      const { brand_id, account_id, type, filterStatus, search } = this.state;
      fetchOtas({ brand_id, account_id, type, filterStatus, search });
    });
  };

  searchOnChange = search => {
    this.setState({ search }, () => {
      const { brand_id, account_id, type, filterStatus, search } = this.state;
      const { fetchOtas } = this.props;

      fetchOtas({ brand_id, account_id, type, filterStatus, search });
    });
  };

  switchModal = () => {
    const { isOpenModal } = this.state;
    this.setState({ isOpenModal: !isOpenModal, stepActive: 1, upgradeOptions: {} });
  };

  saveStep1 = values => {
    const { check_brand, check_device_family, check_location } = values;
    const { fetchPreviousInstallationInfo } = this.props;
    if (check_location) {
      const { location_id } = values;
      fetchPreviousInstallationInfo({ location_id });
    }
    if (check_device_family) {
      const { device_family } = values;
      fetchPreviousInstallationInfo({ device_family });
    }
    if (check_brand) {
      const { brand_id } = values;
      fetchPreviousInstallationInfo({ brand_id });
    }
    this.setState({ upgradeOptions: values, stepActive: 2 });
  };

  onSubmit = values => {
    const { submitUpgradeStatus } = this.props;
    this.switchModal();
    submitUpgradeStatus(values);
  };

  backStep = () => {
    this.setState({ stepActive: 1 });
  };

  renderDevice = (deviceConfig, lastUpdate, type, drawLine) => (
    <Row style={drawLine && { borderBottom: `0.5px solid ${Colors.gray2}` }}>
      <TableSubItem color={Colors.gray} width="19%">
        {type}
      </TableSubItem>
      <TableSubItem color={Colors.gray} width="24%">
        {deviceConfig.name}
      </TableSubItem>
      <TableSubItem color={Colors.gray} width="18%">
        {lastUpdate ? lastUpdate.OtaUpdateVersion.version : 'Not found'}
      </TableSubItem>
      <TableSubItem color={Colors.gray} width="18%">
        {lastUpdate?.started_at
          ? moment(lastUpdate.started_at).format('MM/DD/YYYY')
          : 'Not Installed'}
      </TableSubItem>
    </Row>
  );

  renderItem = (item, key) => (
    <TRow key={key}>
      <TableSubItem width="43.28%" textAlign="left">
        <Row justifyContent="left" style={{ padding: '10px' }}>
          <H5 color={Colors.gray8} textAlign="left" style={{ paddingRight: '5px' }}>
            Name:
          </H5>
          <H5 textAlign="left" color={Colors.lightBlue}>
            {item.identificator}
          </H5>
        </Row>
        <Row justifyContent="left" style={{ padding: '10px' }}>
          <H5 color={Colors.gray8} textAlign="left" style={{ paddingRight: '5px' }}>
            Brand:
          </H5>
          <H5 textAlign="left" color={Colors.lightBlue}>
            {item.brand_name}
          </H5>
        </Row>
        <Row justifyContent="left" style={{ padding: '10px' }}>
          <H5 color={Colors.gray8} textAlign="left" style={{ paddingRight: '5px' }}>
            Account:
          </H5>
          <H5 textAlign="left" color={Colors.lightBlue}>
            {item.account_name}
          </H5>
        </Row>
      </TableSubItem>
      <TableItem width="57.16%">
        {item.WaterFilterHead &&
          this.renderDevice(
            item.WaterFilterHead.WaterFilterConfiguration,
            item.wfLastUpdate,
            'Water Filter',
            item.SoftenerFilterHead || item.ReverseOsmosisFilterHead, // to draw line
          )}
        {item.SoftenerFilterHead &&
          this.renderDevice(
            item.SoftenerFilterHead.SoftenerConfiguration,
            item.softenerLastUpdate,
            'Softener',
            item.ReverseOsmosisFilterHead, // to draw line
          )}
        {item.ReverseOsmosisFilterHead &&
          this.renderDevice(
            item.ReverseOsmosisFilterHead.ReverseOsmosisFilterConfiguration,
            item.roLastUpdate,
            'Reverse Osmosis',
          )}
      </TableItem>
    </TRow>
  );

  render() {
    const {
      list,
      currentPage,
      pages,
      accounts,
      fetchOtas,
      locationsForFilter,
      brandsForFilter,
      loading,
      previousInstallationInfo,
      loadingGetPreviousInstallationInfo,
    } = this.props;
    const {
      brand_id,
      account_id,
      type,
      filterStatus,
      search,
      isOpenModal,
      stepActive,
      upgradeOptions,
    } = this.state;

    return (
      <Container>
        <Header style={{ paddingBottom: '0px' }}>
          <SearchContainer>
            <InputSearch onChange={this.searchOnChange} value={search} name="search" />
          </SearchContainer>
          <ButtonContainer>
            <Row>
              {!loading && (
                <ButtonGroup style={{ paddingBottom: '0px' }}>
                  <ButtonText
                    theme="primary"
                    text="New Upgrade"
                    icon="plus"
                    onClick={() => this.switchModal()}
                  />
                </ButtonGroup>
              )}
            </Row>
            <Row>
              <ButtonGroup style={{ paddingBottom: '0px' }}>
                <ButtonText
                  theme="round"
                  text="Manage versions"
                  onClick={() => push('/ota/manage-versions')}
                />
              </ButtonGroup>
            </Row>
            <Row>
              <ButtonGroup style={{ paddingBottom: '0px' }}>
                <ButtonText
                  theme="round"
                  text="Upgrade status"
                  onClick={() => push('/ota/upgrade-status')}
                />
              </ButtonGroup>
            </Row>
          </ButtonContainer>
        </Header>
        <FilterGroup>
          <FilterContainer>
            <Filter
              title="Brand:"
              selectedValue={brand_id}
              name="brand_id"
              handleChange={this.filterOnChange}
              options={[{ label: 'All', value: '' }, ...brandsForFilter]}
            />
          </FilterContainer>
          <FilterContainer>
            <Filter
              title="Account:"
              selectedValue={account_id}
              name="account_id"
              handleChange={this.filterOnChange}
              options={accounts}
            />
          </FilterContainer>
          <FilterContainer>
            <Filter
              title="Device Type:"
              selectedValue={type}
              name="type"
              handleChange={this.filterOnChange}
              options={[{ label: 'All', value: '' }, ...DeviceTypes]}
            />
          </FilterContainer>
          <FilterContainer>
            <Filter
              title="Status:"
              selectedValue={filterStatus}
              name="filterStatus"
              handleChange={this.filterOnChange}
              options={[{ label: 'All', value: '' }, ...StatusTypes]}
            />
          </FilterContainer>
        </FilterGroup>
        <TableContainer>
          <Table>
            <THeader>
              <TableTitle width="43.28%" />
              <TableTitle width="14.28%">Device type</TableTitle>
              <TableTitle width="14.28%">Device name</TableTitle>
              <TableTitle width="14.28%">Version</TableTitle>
              <TableTitle width="14.28%">Last update</TableTitle>
            </THeader>
            <TBody>
              {loading ? (
                <Loader size={100} block />
              ) : (
                list.map((item, key) => this.renderItem(item, key))
              )}
            </TBody>
          </Table>

          {(!list || !list.length) && !loading && (
            <ListEmpty message="There are currently no OTA" />
          )}
        </TableContainer>

        <Footer>
          <PaginationButtons
            currentPage={currentPage}
            changePage={page =>
              fetchOtas({ page, brand_id, account_id, search, type, filterStatus })
            }
            totalPages={pages}
          />
        </Footer>
        <ModalUpgrade
          isOpenModal={isOpenModal}
          switchModal={() => this.switchModal()}
          stepActive={stepActive}
          saveStep1={this.saveStep1}
          backStep={this.backStep}
          upgradeOptions={upgradeOptions}
          saveData={this.onSubmit}
          locationsForFilter={locationsForFilter}
          brandsForFilter={brandsForFilter}
          previousInstallationInfo={previousInstallationInfo}
          loadingGetPreviousInstallationInfo={loadingGetPreviousInstallationInfo}
        />
      </Container>
    );
  }
}

OtaListView.propTypes = {
  accounts: PropTypes.array,
  brandsForFilter: PropTypes.array,
  currentPage: PropTypes.number,
  fetchOtas: PropTypes.func,
  fetchPreviousInstallationInfo: PropTypes.func,
  list: PropTypes.array,
  loading: PropTypes.bool,
  loadingGetPreviousInstallationInfo: PropTypes.bool,
  locationsForFilter: PropTypes.array,
  pages: PropTypes.number,
  previousInstallationInfo: PropTypes.object,
  submitUpgradeStatus: PropTypes.func,
};
