import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  getBrandsForFilter,
  getStates,
  getUpgradeStatus,
  createUpgradeStatus,
  getLocationsForFilter,
  getPreviousInstallationInfo,
  deleteUpgradeStatus,
} from 'actions/index';
import PropTypes from 'prop-types';
import OtaUpgradeStatusView from './OtaUpgradeStatusView';

class OtaUpgradeStatusContainer extends Component {
  componentDidMount() {
    const {
      fetchBrandsForFilter,
      fetchStates,
      fetchUpgradeStatus,
      fetchLocationsForFilter,
    } = this.props;

    fetchUpgradeStatus();
    fetchBrandsForFilter();
    fetchStates();
    fetchLocationsForFilter();
  }

  render() {
    const {
      brandsForFilter,
      states,
      submitUpgradeStatus,
      upgradesList,
      upgradesPages,
      upgradesCurrentPage,
      fetchUpgradeStatus,
      upgradesLoading,
      locationsForFilter,
      loadingGetPreviousInstallationInfo,
      previousInstallationInfo,
      fetchPreviousInstallationInfo,
      deleteUpgradeStatusAction,
    } = this.props;

    return (
      <OtaUpgradeStatusView
        submitUpgradeStatus={submitUpgradeStatus}
        brandsForFilter={brandsForFilter}
        states={states}
        upgradesList={upgradesList}
        upgradesPages={upgradesPages}
        upgradesCurrentPage={upgradesCurrentPage}
        fetchUpgradeStatus={fetchUpgradeStatus}
        upgradesLoading={upgradesLoading}
        locationsForFilter={locationsForFilter}
        fetchPreviousInstallationInfo={fetchPreviousInstallationInfo}
        loadingGetPreviousInstallationInfo={loadingGetPreviousInstallationInfo}
        previousInstallationInfo={previousInstallationInfo}
        deleteUpgradeStatus={deleteUpgradeStatusAction}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    brandsForFilter: state.brands.listForFilter,
    states: [{ label: 'All', value: '' }, ...state.utils.states],
    upgradesList: state.ota.upgradesList,
    upgradesPages: state.ota.upgradesPages,
    upgradesCurrentPage: state.ota.upgradesCurrentPage,
    upgradesLoading: state.ota.upgradesLoading,
    locationsForFilter: state.locations.listForFilter,
    loadingGetPreviousInstallationInfo: state.ota.loadingGetPreviousInstallationInfo,
    previousInstallationInfo: state.ota.previousInstallationInfo,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    fetchUpgradeStatus: params => dispatch(getUpgradeStatus(params)),
    fetchBrandsForFilter: () => dispatch(getBrandsForFilter()),
    fetchStates: () => dispatch(getStates()),
    submitUpgradeStatus: data => dispatch(createUpgradeStatus(data)),
    fetchLocationsForFilter: params => dispatch(getLocationsForFilter(params)),
    fetchPreviousInstallationInfo: params => dispatch(getPreviousInstallationInfo(params)),
    deleteUpgradeStatusAction: params => dispatch(deleteUpgradeStatus(params)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(OtaUpgradeStatusContainer);

OtaUpgradeStatusContainer.propTypes = {
  brandsForFilter: PropTypes.array,
  deleteUpgradeStatusAction: PropTypes.func,
  fetchBrandsForFilter: PropTypes.func,
  fetchLocationsForFilter: PropTypes.func,
  fetchPreviousInstallationInfo: PropTypes.func,
  fetchStates: PropTypes.func,
  fetchUpgradeStatus: PropTypes.func,
  loadingGetPreviousInstallationInfo: PropTypes.bool,
  locationsForFilter: PropTypes.array,
  previousInstallationInfo: PropTypes.object,
  states: PropTypes.array,
  submitUpgradeStatus: PropTypes.func,
  upgradesCurrentPage: PropTypes.number,
  upgradesList: PropTypes.array,
  upgradesLoading: PropTypes.bool,
  upgradesPages: PropTypes.number,
};
