import React, { Component } from 'react';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Colors } from 'modules/theme';
import Input from 'components/InputText';
import ButtonText from 'components/ButtonText';
import H4 from 'components/H4';
import FormError from 'components/FormError';
import Line from 'components/Line';
import PropTypes from 'prop-types';

const ButtonContainer = styled.div`
  margin-top: 25px;
  margin-bottom: 25px;
  float: left;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Column = styled.div`
  flex-direction: column;
  width: 50%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export default class DetailsForm extends Component {
  submit = values => {
    const { onSubmit } = this.props;
    const activeTab = 'Permissions';

    onSubmit(values, activeTab);
  };

  render() {
    const { initialValues, isInitialValid } = this.props;

    return (
      <Formik
        enableReinitialize
        isInitialValid={isInitialValid}
        initialValues={initialValues}
        onSubmit={this.submit}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .required('Required')
            .email('Invalid Format'),
          first_name: Yup.string().required('Required'),
          last_name: Yup.string().required('Required'),
          phone_number: Yup.string().required('Required'),
        })}
      >
        {({ values, errors, touched, handleChange, handleBlur, isValid }) => (
          <Form>
            <Row>
              <Column style={{ marginRight: '15px' }}>
                <H4 inputLabel color={Colors.gray8}>
                  First name
                </H4>
                <Input
                  placeholder="First name"
                  name="first_name"
                  value={values.first_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  icon="bookmark"
                />
                <FormError text={errors.first_name && touched.first_name && errors.first_name} />
                <H4 inputLabel color={Colors.gray8}>
                  Email
                </H4>
                <Input
                  placeholder="Email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  icon="envelope"
                />
                <FormError text={errors.email && touched.email && errors.email} />
              </Column>
              <Column style={{ marginLeft: '15px' }}>
                <H4 inputLabel color={Colors.gray8}>
                  Last name
                </H4>
                <Input
                  placeholder="Last name"
                  name="last_name"
                  value={values.last_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  icon="bookmark"
                />
                <FormError text={errors.last_name && touched.last_name && errors.last_name} />
                <H4 inputLabel color={Colors.gray8}>
                  Phone number
                </H4>
                <Input
                  placeholder="Phone number"
                  name="phone_number"
                  value={values.phone_number}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  icon="phone"
                />
                <FormError
                  text={errors.phone_number && touched.phone_number && errors.phone_number}
                />
              </Column>
            </Row>
            <Row style={{ marginTop: '30px' }}>
              <Line />
            </Row>
            <Row>
              <ButtonContainer>
                <ButtonText text="Next" theme="primary" type="submit" disabled={!isValid} />
              </ButtonContainer>
            </Row>
          </Form>
        )}
      </Formik>
    );
  }
}

DetailsForm.propTypes = {
  initialValues: PropTypes.object,
  isInitialValid: PropTypes.bool,
  onSubmit: PropTypes.func,
};
