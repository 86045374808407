import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import {
  editUser,
  getUser,
  getRoles,
  getBrandsForFilter,
  getAccountsForFilter,
  getLocationsForFilter,
} from 'actions/index';
import { Colors } from 'modules/theme';
import { push } from 'modules/history';
import Icon from 'components/Icon';
import H2 from 'components/H2';
import Tabs from 'components/Tabs';
import Loader from 'components/Loader';
import PropTypes from 'prop-types';
import { DeviceTypes } from 'constants/index';
import AlertsForm from 'components/AlertsForm';
import DetailsForm from './DetailsForm';
import PermissionsForm from './PermissionsForm';

const Container = styled.div`
  margin: 30px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const Column = styled.div`
  flex-direction: column;
  align-self: center;
`;

class EditUser extends Component {
  state = {
    activeTab: 'Details',
    user: {
      brands: [],
      accounts: [],
      locations: [],
    },
  };

  componentDidMount() {
    const {
      match: { params },
      fetchUser,
      fetchRoles,
      fetchBrands,
    } = this.props;
    const { id } = params;

    fetchUser(id);
    fetchRoles();
    fetchBrands();
  }

  componentDidUpdate(prevProps) {
    let {brandsSelected} = this.state;
    let {accountsSelected} = this.state;
    let {locationsSelected} = this.state;

    const updateBrand =
      this.props.user?.BrandsPerUsers !== undefined &&
      prevProps.user?.BrandsPerUsers !== this.props.user?.BrandsPerUsers;

    const updateAccount =
      this.props.user?.AccountsPerUsers !== undefined &&
      prevProps.user?.AccountsPerUsers !== this.props.user?.AccountsPerUsers;

    const updateLocation =
      this.props.user?.LocationsPerUsers !== undefined &&
      prevProps.user?.LocationsPerUsers !== this.props.user?.LocationsPerUsers;

    if (updateBrand) {
      brandsSelected = (this.props.user.BrandsPerUsers || []).map(({ brand_id }) => brand_id);
    }
    if (updateAccount) {
      accountsSelected = (this.props.user.AccountsPerUsers || []).map(
        ({ account_id }) => account_id,
      );
    }
    if (updateLocation) {
      locationsSelected = (this.props.user.LocationsPerUsers || []).map(
        ({ location_id }) => location_id,
      );
    }

    if (updateLocation || updateAccount || updateBrand) {
      this.setState(prevState => ({
        user: {
          ...prevState.user,
          brands: brandsSelected,
          accounts: accountsSelected,
          locations: locationsSelected,
        },
        brandsSelected,
        accountsSelected,
        locationsSelected,
      }));
    }
  }

  saveData = (data, nextTab) => {
    const { user, activeTab } = this.state;
    const userUpdated = {
      ...user,
      ...data,
    };

    if (activeTab === 'Permissions') {
      const {
        match: { params },
        submitUser,
      } = this.props;
      const { id } = params;

      submitUser(id, userUpdated);
    } else {
      this.setState({
        activeTab: nextTab,
        user: userUpdated,
      });
    }
  };

  back = backTab => {
    this.setState({
      activeTab: backTab,
    });
  };

  updateUserState = user => {
    this.setState(prevState => ({
      user: {
        ...prevState.user,
        ...user,
      },
    }));
  };

  render() {
    const {
      loading,
      user,
      roles,
      brands,
      accounts,
      locations,
      fetchAccounts,
      fetchLocations,
    } = this.props;

    const { activeTab, brandsSelected, accountsSelected, locationsSelected } = this.state;

    if (loading) {
      return <Loader size={200} block />;
    }

    if (!user) {
      return null;
    }

    const tabs = [
      {
        title: 'Details',
      },
      {
        title: 'Permissions',
      },
    ];

    const detailsInitialValues = {
      email: user.email,
      first_name: user.first_name,
      last_name: user.last_name,
      phone_number: user.phone_number,
    };

    const permissionsInitialValues = {
      role_id: user.role_id,
      auto_add_to_new_locations: user.auto_add_to_new_locations,
    };

    const userBlackList = (user.UserBlackLists || []).map(({ alert_type_id }) => alert_type_id);

    return (
      <Container>
        <Icon onClick={() => push('/users')} name="chevron-left" color={Colors.blue} />
        <Row>
          <Column>
            <H2 color={Colors.black} style={{ paddingRight: 30 }}>
              Edit user
            </H2>
          </Column>
          <Column>
            <Tabs tabs={tabs} active={activeTab} />
          </Column>
        </Row>
        {activeTab === 'Details' && (
          <DetailsForm
            initialValues={detailsInitialValues}
            isInitialValid
            onSubmit={this.saveData}
          />
        )}
        {activeTab === 'Permissions' && (
          <>
            <PermissionsForm
              initialValues={permissionsInitialValues}
              isInitialValid={false}
              roles={roles}
              user={user}
              brands={brands}
              accounts={accounts}
              locations={locations}
              fetchAccounts={fetchAccounts}
              fetchLocations={fetchLocations}
              back={() => this.back('Details')}
              onSubmit={this.saveData}
              updateUserState={this.updateUserState}
              brandsSelected={brandsSelected}
              accountsSelected={accountsSelected}
              locationsSelected={locationsSelected}
            />
            {user.role_id && (
              <AlertsForm
                back={() => this.back('Details')}
                subtitle="Select all the alerts that would you like to assign to this user."
                onSubmit={this.saveData}
                deviceTypes={DeviceTypes}
                fromUsers={true}
                userRole={{ label: user.role_name, value: user.role_id }}
                userBlackList={userBlackList}
              />
            )}
          </>
        )}
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.users.item,
    loading: state.users.loading,
    roles: state.utils.roles,
    brands: state.brands.listForFilter,
    accounts: state.accounts.listForFilter,
    locations: state.locations.listForFilter,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    submitUser: (id, data) => dispatch(editUser(id, data)),
    fetchUser: id => dispatch(getUser(id)),
    fetchRoles: () => dispatch(getRoles()),
    fetchBrands: () => dispatch(getBrandsForFilter()),
    fetchAccounts: params => dispatch(getAccountsForFilter(params)),
    fetchLocations: params => dispatch(getLocationsForFilter(params)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(EditUser);

EditUser.propTypes = {
  accounts: PropTypes.array,
  brands: PropTypes.array,
  fetchAccounts: PropTypes.func,
  fetchBrands: PropTypes.func,
  fetchLocations: PropTypes.func,
  fetchRoles: PropTypes.func,
  fetchUser: PropTypes.func,
  loading: PropTypes.bool,
  locations: PropTypes.array,
  match: PropTypes.object,
  roles: PropTypes.array,
  submitUser: PropTypes.func,
  user: PropTypes.object,
};
