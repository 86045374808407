import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getSupports, deleteSupport, checkAsRead } from 'actions/index';
import PropTypes from 'prop-types';
import SupportListView from './SupportListView';

class SupportListContainer extends Component {
  componentDidMount() {
    const { fetchSupports } = this.props;
    fetchSupports();
  }

  render() {
    const {
      list,
      pages,
      currentPage,
      loading,
      fetchSupports,
      removeSupport,
      readSupport,
    } = this.props;

    return (
      <SupportListView
        list={list}
        pages={pages}
        currentPage={currentPage}
        loading={loading}
        fetchSupports={fetchSupports}
        removeSupport={removeSupport}
        readSupport={readSupport}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    list: state.supports.list,
    pages: state.supports.pages,
    currentPage: state.supports.currentPage,
    loading: state.supports.loading,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    fetchSupports: params => dispatch(getSupports(params)),
    readSupport: id => dispatch(checkAsRead(id)),
    removeSupport: id => dispatch(deleteSupport(id)),
  };
}

SupportListContainer.propTypes = {
  currentPage: PropTypes.number,
  fetchSupports: PropTypes.func,
  list: PropTypes.array,
  loading: PropTypes.bool,
  pages: PropTypes.number,
  readSupport: PropTypes.func,
  removeSupport: PropTypes.func,
};
export default connect(mapStateToProps, mapDispatchToProps)(SupportListContainer);
