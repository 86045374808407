import React from 'react';
import styled from 'styled-components';

const Text = styled.div`
  color: #999999;
  text-align: center;
  margin: 20px;
  font-weight: 500;
  letter-spacing: 1px;
`;

const ListEmpty = ({ message }) => <Text>{message || 'The list is empty'}</Text>;

export default ListEmpty;
