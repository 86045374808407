import { put, call } from 'redux-saga/effects';
import { showAlert } from 'actions/index';
import { ActionTypes } from 'constants/index';
import { replace } from 'modules/history';

export function* getBrands(api, action) {
  const response = yield call(api.getBrands, action.payload);

  const { status, data } = response;

  if (status === 200) {
    const { rows, pages, currentPage } = data;
    yield put({
      type: ActionTypes.GET_BRANDS_SUCCESS,
      payload: { rows, pages, currentPage },
    });
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.GET_BRANDS_FAILURE,
    });
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger', timeout: 9 }));
    }
  }
}

export function* getBrand(api, action) {
  const { id } = action.payload;
  const response = yield call(api.getBrand, id);
  const { status, data } = response;

  if (status === 200) {
    yield put({
      type: ActionTypes.GET_BRAND_SUCCESS,
      payload: data,
    });
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.GET_BRAND_FAILURE,
    });
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger', timeout: 9 }));
    }
  }
}

export function* getBrandsForFilter(api, action) {
  const response = yield call(api.getBrandsForFilter, action.payload);

  const { status, data } = response;

  if (status === 200) {
    yield put({
      type: ActionTypes.GET_BRANDS_FOR_FILTER_SUCCESS,
      payload: data,
    });
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.GET_BRANDS_FOR_FILTER_FAILURE,
    });
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger', timeout: 9 }));
    }
  }
}

export function* createBrand(api, action) {
  // If the item is null, we delete the property.
  // In the database we have default values
  const item = { ...action.payload };

  Object.keys(item).forEach(k => !item[k] && item[k] !== undefined && delete item[k]);

  const response = yield call(api.createBrand, item);

  const { status, data } = response;

  if (status === 200) {
    yield put(showAlert('Brand created', { variant: 'success' }));
    replace('/brands');
  } else {
    const { errors } = data;
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger', timeout: 9 }));
    }
  }
  yield put({
    type: ActionTypes.CREATE_BRAND_RESOLVED,
  });
}

export function* editBrand(api, action) {
  const { id, brand } = action.payload;
  const response = yield call(api.editBrand, id, brand);

  const { status, data } = response;

  if (status === 200) {
    yield put(showAlert('Brand edited', { variant: 'success' }));
    replace('/brands');
  } else {
    const { errors } = data;
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger', timeout: 9 }));
    }
  }

  yield put({
    type: ActionTypes.EDIT_BRAND_RESOLVED,
  });
}

export function* deleteBrand(api, action) {
  const { id } = action.payload;
  const response = yield call(api.deleteBrand, id);

  const { status, data } = response;

  if (status === 200) {
    yield put(showAlert('Brand deleted', { variant: 'success' }));
    yield put({
      type: ActionTypes.DELETE_BRAND_SUCCESS,
      payload: { id },
    });
  } else {
    const { errors } = data;
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger', timeout: 9 }));
    }
    yield put({
      type: ActionTypes.DELETE_BRAND_FAILURE,
      payload: { id },
    });
  }
}

export function* getAdminsAccounts(api, action) {
  const { brandId } = action.payload;
  const response = yield call(api.getAdminsAccounts, brandId);
  const { status, data } = response;
  if (status === 200) {
    yield put({
      type: ActionTypes.GET_ADMINS_ACCOUNTS_SUCCESS,
      payload: data,
    });
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.GET_ADMINS_ACCOUNTS_FAILURE,
    });
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
  }
}
export function* getAdminsLocations(api, action) {
  const { accountId } = action.payload;

  const response = yield call(api.getAdminsLocations, accountId);
  const { status, data } = response;
  if (status === 200) {
    yield put({
      type: ActionTypes.GET_ADMINS_LOCATIONS_SUCCESS,
      payload: data,
    });
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.GET_ADMINS_LOCATIONS_FAILURE,
    });
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger' }));
    }
  }
}