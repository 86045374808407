import React from 'react';
import styled from 'styled-components';
import { Colors } from 'modules/theme';

const Text = styled.h1`
  font-family: Gotham, sans-serif;
  font-style: normal;
  font-weight: 350;
  font-size: 48px;
  line-height: 58px;
  margin-top: ${props => props.marginTop || 3}px;
  margin-bottom: ${props => props.marginBottom || 3}px;
  margin-bottom: ${props => props.marginBottom || 0}px;
  margin-right: ${props => props.marginRight || 0}px;
  color: ${props => props.color || Colors.black};
  line-height: ${props => (props.lineHeight ? props.lineHeight : 'initial')};
`;

const H1 = props => <Text {...props}>{props.children}</Text>;

export default H1;
