import React, { Component } from 'react';
import styled from 'styled-components';
import { Formik, Form, FieldArray } from 'formik';
import * as Yup from 'yup';
import { Colors } from 'modules/theme';
import { push } from 'modules/history';
import { PositionLabels, Outlets, SeriesOrParallel } from 'constants/index';
import config from 'config';
import Input from 'components/InputText';
import Select from 'components/Select';
import ButtonText from 'components/ButtonText';
import H2 from 'components/H2';
import H4 from 'components/H4';
import Icon from 'components/Icon';
import DropZone from 'components/DropZone';
import ImageUploaded from 'components/ImageUploaded';
import FormError from 'components/FormError';
import Line from 'components/Line';
import PropTypes from 'prop-types';

const Container = styled.div`
  margin: 30px;
`;

const InputContainer = styled.div`
  margin: 20px 0px;
`;

const ButtonContainer = styled.div`
  margin-top: 25px;
  margin-bottom: 25px;
  float: left;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const Column = styled.div`
  flex-direction: column;
`;

const SpotsContainer = styled.div``;

const spotDefault = {
  catridge_id: '',
  position: '',
  outlet: '',
  series_or_parallel: '',
};

const url = `${config.BASE_URL}/uploads`;

export default class WaterFilterForm extends Component {
  submit = values => {
    const { onSubmit } = this.props;

    onSubmit(values);
  };

  renderSpot = (spot, key, form, push, remove, errors = {}, disabledForm) => {
    const { catridges } = this.props;
    const spotSize = form.values.spots.length;
    const changeValue = e => {
      e.stopPropagation();
      form.setFieldValue(e.target.name, e.target.value);
    };

    const updateSpotFlowKeys = e => {
      e.stopPropagation();
      const newSpots = form.values.spots.map(spot => ({ ...spot, flow_key: e.target.value }));
      form.setFieldValue('spots', newSpots);
    };

    return (
      <>
        <Row>
          <Column style={{ width: '95%' }}>
            <Row key={key} style={{ paddingBottom: '20px' }}>
              <Column style={{ marginRight: '10px', width: '25%' }}>
                <H4 inputLabel color={Colors.gray8}>
                  Choose a catridge
                </H4>
                <Select
                  placeholder="Choose a catridge"
                  name={`spots.${key}.catridge_id`}
                  value={spot.catridge_id}
                  options={catridges}
                  onChange={changeValue}
                  disabled={disabledForm}
                />
                <FormError text={errors.catridge_id && errors.catridge_id} />
              </Column>
              <Column style={{ marginRight: '10px', width: '25%' }}>
                <H4 inputLabel color={Colors.gray8}>
                  Choose Position
                </H4>
                <Select
                  placeholder="Choose Position"
                  name={`spots.${key}.position`}
                  options={PositionLabels}
                  value={spot.position}
                  onChange={changeValue}
                  disabled={disabledForm}
                />
                <FormError text={errors.position && errors.position} />
              </Column>
              <Column style={{ marginRight: '10px', width: '25%' }}>
                <H4 inputLabel color={Colors.gray8}>
                  Select outlet
                </H4>
                <Select
                  placeholder="Select outlet"
                  name={`spots.${key}.outlet`}
                  options={Outlets}
                  value={spot.outlet}
                  onChange={changeValue}
                  disabled={disabledForm}
                />
                <FormError text={errors.outlet && errors.outlet} />
              </Column>
              <Column style={{ marginRight: '10px', width: '25%' }}>
                <H4 inputLabel color={Colors.gray8}>
                  Series or Parallel
                </H4>
                <Select
                  placeholder="Series or Parallel"
                  name={`spots.${key}.series_or_parallel`}
                  options={SeriesOrParallel}
                  value={spot.series_or_parallel}
                  onChange={changeValue}
                  disabled={disabledForm}
                />
                <FormError text={errors.series_or_parallel && errors.series_or_parallel} />
              </Column>
            </Row>
            <Row key={key} style={{ paddingBottom: '20px' }}>
              <Column style={{ marginRight: '10px', width: '25%' }} />

              <Column style={{ marginRight: '10px', width: '25%' }}>
                <H4 inputLabel color={Colors.gray8}>
                  Flow key
                </H4>
                <Select
                  placeholder="Flow key"
                  name={`spots.${key}.flow_key`}
                  options={[
                    { label: 'flow_1', value: 'flow_1' },
                    { label: 'flow_2', value: 'flow_2' },
                    { label: 'flow_3', value: 'flow_3' },
                  ]}
                  value={spot.flow_key}
                  onChange={updateSpotFlowKeys}
                  disabled={disabledForm}
                />
                <FormError text={errors.flow_key && errors.flow_key} />
              </Column>
              <Column style={{ marginRight: '10px', width: '25%' }}>
                <H4 inputLabel color={Colors.gray8}>
                  Inlet Pressure Key
                </H4>
                <Select
                  placeholder="Inlet Pressure Key"
                  name={`spots.${key}.inlet_pressure_key`}
                  options={[
                    { label: 'psi_1', value: 'psi_1' },
                    { label: 'psi_2', value: 'psi_2' },
                    { label: 'psi_3', value: 'psi_3' },
                    { label: 'psi_4', value: 'psi_4' },
                  ]}
                  value={spot.inlet_pressure_key}
                  onChange={changeValue}
                  disabled={disabledForm}
                />
                <FormError text={errors.inlet_pressure_key && errors.inlet_pressure_key} />
              </Column>
              <Column style={{ marginRight: '10px', width: '25%' }}>
                <H4 inputLabel color={Colors.gray8}>
                  Outlet Pressure Key
                </H4>
                <Select
                  placeholder="Outlet Pressure Key"
                  name={`spots.${key}.outlet_pressure_key`}
                  options={[
                    { label: 'psi_1', value: 'psi_1' },
                    { label: 'psi_2', value: 'psi_2' },
                    { label: 'psi_3', value: 'psi_3' },
                    { label: 'psi_4', value: 'psi_4' },
                  ]}
                  value={spot.outlet_pressure_key}
                  onChange={changeValue}
                  disabled={disabledForm}
                />
                <FormError text={errors.outlet_pressure_key && errors.outlet_pressure_key} />
              </Column>
            </Row>
          </Column>
          {!disabledForm && (
            <Column
              style={{
                width: '5%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {spotSize - 1 === key ? (
                <Icon
                  name="plus-circle"
                  color={Colors.lightBlue}
                  fontSize={22}
                  onClick={() => push(spotDefault)}
                />
              ) : (
                <Icon
                  name="minus-circle"
                  color={Colors.red}
                  fontSize={22}
                  onClick={() => remove(key)}
                />
              )}
            </Column>
          )}
        </Row>
        <Line style={{ marginTop: 10, marginBottom: 15 }} />
      </>
    );
  };

  render() {
    const { initialValues, token, title, editMode, editable } = this.props;
    const disabledForm = editMode && !editable;
    return (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          filter_head_name: Yup.string().required('Required'),
          name: Yup.string().required('Required'),
          series_or_parallel: Yup.string().required('Required'),
          spots: Yup.array().of(
            Yup.object().shape({
              catridge_id: Yup.string().required('Required'),
              flow_key: Yup.string().required('Required'),
              inlet_pressure_key: Yup.string().required('Required'),
              outlet: Yup.string().required('Required'),
              outlet_pressure_key: Yup.string().required('Required'),
              position: Yup.string().required('Required'),
              series_or_parallel: Yup.string().required('Required'),
            }),
          ),
        })}
        onSubmit={this.submit}
      >
        {({ values, errors, touched, handleChange, handleBlur, isValid, setFieldValue }) => (
          <Container>
            <Form>
              <Icon onClick={() => push('/libraries')} name="chevron-left" color={Colors.blue} />
              <H2 color={Colors.black}>{title}</H2>
              {disabledForm && (
                <H4 inputLabel color={Colors.red}>
                  You cannot edit this water filter because it is assigned to a location.
                </H4>
              )}
              <Row>
                <Column style={{ marginRight: '30px', width: '33.33%' }}>
                  <InputContainer>
                    <H4 inputLabel color={Colors.lightBlue}>
                      Head Model
                    </H4>
                    <Input
                      placeholder="Head Model"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      icon="bookmark"
                      disabled={disabledForm}
                    />
                    <FormError text={errors.name && touched.name && errors.name} />
                  </InputContainer>
                </Column>
              </Row>
              <Row>
                <Column style={{ marginRight: '30px', width: '33.33%' }}>
                  <InputContainer>
                    <H4 inputLabel color={Colors.gray8}>
                      Series or Parallel
                    </H4>
                    <Select
                      placeholder="Series or Parallel"
                      name="series_or_parallel"
                      options={SeriesOrParallel}
                      value={values.series_or_parallel}
                      onChange={handleChange}
                      disabled={disabledForm}
                    />
                    <FormError
                      text={
                        errors.series_or_parallel &&
                        touched.series_or_parallel &&
                        errors.series_or_parallel
                      }
                    />
                  </InputContainer>
                </Column>
                <Column style={{ marginRight: '30px', width: '33.33%' }}>
                  <InputContainer>
                    <H4 inputLabel color={Colors.gray8}>
                      Filter head name
                    </H4>
                    <Input
                      placeholder="Filter head name"
                      name="filter_head_name"
                      value={values.filter_head_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      icon="bookmark"
                      disabled={disabledForm}
                    />
                    <FormError
                      text={
                        errors.filter_head_name &&
                        touched.filter_head_name &&
                        errors.filter_head_name
                      }
                    />
                  </InputContainer>
                </Column>
                <Column style={{ marginRight: '30px', width: '33.33%' }}>
                  <InputContainer>
                    <H4 inputLabel color={Colors.gray8}>
                      Bypass Key
                    </H4>
                    <Select
                      placeholder="Bypass Key"
                      name="bypass_key"
                      options={[
                        { label: 'flow_1', value: 'flow_1' },
                        { label: 'flow_2', value: 'flow_2' },
                        { label: 'flow_3', value: 'flow_3' },
                      ]}
                      value={values.bypass_key}
                      onChange={handleChange}
                      disabled={disabledForm}
                    />
                    <FormError
                      text={errors.bypass_key && touched.bypass_key && errors.bypass_key}
                    />
                  </InputContainer>
                </Column>
              </Row>
              <Row>
                <H4 inputLabel color={Colors.gray8}>
                  Select Cartridges
                </H4>
              </Row>
              <FieldArray
                name="spots"
                render={({ form, push, remove }) => (
                  <SpotsContainer>
                    {values.spots.map((spot, key) =>
                      this.renderSpot(
                        spot,
                        key,
                        form,
                        push,
                        remove,
                        errors && errors.spots ? errors.spots[key] : {},
                        disabledForm,
                      ),
                    )}
                  </SpotsContainer>
                )}
              />

              <Row>
                <Column style={{ width: '33.33%', paddingRight: '30px' }}>
                  <H4 inputLabel color={Colors.black}>
                    Photo
                  </H4>

                  {values.photo ? (
                    <ImageUploaded
                      image={values.photo}
                      onClick={disabledForm ? () => setFieldValue('photo', null) : null}
                    />
                  ) : (
                    <DropZone
                      folder="water-filter"
                      title="Choose a photo"
                      height="100px"
                      token={token}
                      handleResponse={({ url }) => setFieldValue('photo', url)}
                      url={url}
                      disabled={disabledForm}
                    />
                  )}
                </Column>
              </Row>
              <Row>
                <ButtonContainer>
                  <ButtonText text="Save" theme="primary" type="submit" disabled={!isValid} />
                </ButtonContainer>
              </Row>
            </Form>
          </Container>
        )}
      </Formik>
    );
  }
}

WaterFilterForm.propTypes = {
  catridges: PropTypes.array.isRequired,
  editable: PropTypes.bool,
  editMode: PropTypes.bool,
  initialValues: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
};
