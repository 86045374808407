import React, { Component } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Colors } from 'modules/theme';
import H1 from 'components/H1';
import H2 from 'components/H2';
import H3 from 'components/H3';
import H4 from 'components/H4';
import H5 from 'components/H5';
import VerticalLine from 'components/Line';
import Col from 'components/Col';
import Row from 'components/Row';
import Filter, { FilterGroup, FilterContainer } from 'components/Filter';
import Icon from 'components/Icon';
import ListEmpty from 'components/ListEmpty';
import BG from 'components/BG';
import Modal from 'components/Modal';
import Loader from 'components/Loader';
import Input from 'components/InputText';
import ButtonText from 'components/ButtonText';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { Tooltip as TooltipReact } from 'react-tooltip';
import {
  waterFilterSetInitialState,
  getWaterFilterCurrentState,
  getWaterFilterCurrentPressure,
  getWaterFilterConsumption,
  getWaterFilterMaxFlowRate,
  getWaterFilterPressureForFlowRate,
  getWaterFilterPressureForConsumption,
  getWaterFilterBypass,
  getTotalConsumptionWaterFilter,
  generateWaterFilterCsv,
  getCsvFiles,
  getWaterFilterCurrentBypass,
  getTotalConsumptionCartridgeWf,
  getAllTotalConsumptionCartridgeWf,
  replaceWaterFilterCartridges,
} from 'actions/index';
import domtoimage from 'dom-to-image';
import {
  PieChart,
  Pie,
  Sector,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ComposedChart,
  Line,
} from 'recharts';
import PropTypes from 'prop-types';
import { ReportWF } from './ReportWF';
import { ModalDatePicker } from './ModalDatePicker';
import ModalCsvFiles from './ModalCsvFiles';

const Container = styled.div`
  margin: 0px;
`;

const GraphContainer = styled.div`
  width: 100%;
  position: relative;
  min-height: ${props => props.height}px;
  display: ${props => props.display ?? 'inherit'};
  justify-content: ${props => props.justifyContent ?? 'center'};
  align-items: ${props => props.alignItems ?? 'center'};
  flex-direction: ${props => props.flexDirection ?? 'row'};
`;

const startDate = moment()
  .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
  .add(-7, 'days')
  .toDate();

const endDate = moment().toDate();

class WF extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showConfiguration: false,
      generatePDF: false,
      downloadPDF: false,
      modalDatePickerConsumption: false,
      datePickerFromConsumption: startDate,
      datePickerToConsumption: endDate,
      catridgeSelectedId: null,
      catridgeSelectedObjet: null,
      catridgeCapacity: 0,
      modalViewInstructions: false,
      range: 'days',
      possibleDayRangesConsumption: ['days'],
      possibleDayRangesBypass: ['days'],
      possibleDayRangesMaxFlowRate: ['days'],
      modalDatePickerCsv: false,
      datePickerFromCsv: startDate,
      datePickerToCsv: endDate,
      csvType: 1,
      csvTypeString: 'raw_data',
      modalDeviceId: false,
      new_device_id: '',
      modalBypass: false,
      datePickerFromBypass: startDate,
      datePickerToBypass: endDate,
      modalDatePickerBypass: false,
      datePickerToReport: endDate,
      datePickerFromReport: startDate,
      modalDatePickerReport: false,
      datePickerFromMaxFlowRate: startDate,
      datePickerToMaxFlowRate: endDate,
      modalDatePickerMaxFlowRate: false,
      totalCatridgeCapacity: 0,
    };
  }

  componentDidMount() {
    const {
      waterFilterSetInitialState,
      location: { WaterFilterHead },
    } = this.props;

    const { FlowMeterOfWaterFilters, installed_date, chlorine_or_chloramine } = WaterFilterHead;
    let accumulator = 0;

    FlowMeterOfWaterFilters?.map(({ SpotsWaterFilter }) => {
      const { Catridge } = SpotsWaterFilter;
      accumulator += this.calculeCatridgeCapacity(Catridge, chlorine_or_chloramine);
    });

    this.setState({
      totalCatridgeCapacity: accumulator,
    });

    waterFilterSetInitialState();
    if (moment(startDate) < moment(installed_date)) {
      this.setState(
        {
          datePickerFromConsumption: moment(installed_date).toDate(),
          datePickerFromCsv: moment(installed_date).toDate(),
          datePickerFromBypass: moment(installed_date).toDate(),
        },
        () => this.getInitialData(),
      );
    } else {
      this.getInitialData();
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.wf_for_pfd && this.props.wf_for_pfd) {
      this.putDataInPdf();
    }
  }

  putDataInPdf = async () => {
    try {
      const {
        location: { id: location_id },
        fetchAllAlerts,
      } = this.props;
      await fetchAllAlerts({ location_id });

      let totalGaloonsChart = document.getElementById('totalGaloonsChart');
      let exhaustionChart = document.getElementById('exhaustionChart');
      let maxFlowAndPressureChart = document.getElementById('maxFlowAndPressureChart');

      totalGaloonsChart = totalGaloonsChart && (await domtoimage.toPng(totalGaloonsChart));
      exhaustionChart = exhaustionChart && (await domtoimage.toPng(exhaustionChart));
      maxFlowAndPressureChart =
        maxFlowAndPressureChart && (await domtoimage.toPng(maxFlowAndPressureChart));

      this.setState({
        totalGaloonsChart,
        exhaustionChart,
        maxFlowAndPressureChart,
        downloadPDF: true,
      });
    } catch (error) {
      this.setState({ generatePDF: false });
      console.log(error);
    }
  };

  getInitialData = () => {
    const {
      location: { WaterFilterHead },
      getWaterFilterCurrentState,
      getWaterFilterCurrentPressure,
      getTotalConsumptionWaterFilter,
      getTotalConsumptionCartridgeWf,
      getAllTotalConsumptionCartridgeWf,
      getWaterFilterConsumption,
      getWaterFilterMaxFlowRate,
      getWaterFilterPressureForFlowRate,
      getWaterFilterPressureForConsumption,
      getWaterFilterCurrentBypass,
    } = this.props;

    const {
      FlowMeterOfWaterFilters,
      device_id,
      chlorine_or_chloramine,
      id: water_filter_head_id,
      installed_date,
    } = WaterFilterHead;

    if (device_id) {
      const {
        datePickerToConsumption,
        datePickerFromConsumption,
        range,
        datePickerFromMaxFlowRate,
        datePickerToMaxFlowRate,
      } = this.state;

      const catridgeSelectedObjet = FlowMeterOfWaterFilters[0];

      getWaterFilterCurrentBypass({ device_id, from: installed_date });
      getWaterFilterCurrentState({ device_id, from: installed_date });
      getWaterFilterCurrentPressure({ device_id, from: installed_date });
      getTotalConsumptionWaterFilter({ device_id, from: installed_date });
      getWaterFilterConsumption({
        device_id,
        from: datePickerFromConsumption,
        to: datePickerToConsumption,
        range,
      });
      getWaterFilterMaxFlowRate({
        device_id,
        from: datePickerFromMaxFlowRate,
        to: datePickerToMaxFlowRate,
        range,
      });

      getWaterFilterPressureForConsumption({
        device_id,
        from: datePickerFromConsumption,
        to: datePickerToConsumption,
        range,
      });

      getWaterFilterPressureForFlowRate({
        device_id,
        from: datePickerFromConsumption,
        to: datePickerToConsumption,
        range,
      });

      if (catridgeSelectedObjet) {
        const catridgeCapacity = this.calculeCatridgeCapacity(
          catridgeSelectedObjet.SpotsWaterFilter.Catridge,
          chlorine_or_chloramine,
        );

        this.setState({
          catridgeSelectedId: catridgeSelectedObjet.SpotsWaterFilter.Catridge.id,
          catridgeSelectedObjet,
          catridgeCapacity,
        });

        if (catridgeSelectedObjet.last_replaced) {
          getTotalConsumptionCartridgeWf({
            device_id,
            from: catridgeSelectedObjet.last_replaced,
            spot_id: catridgeSelectedObjet.spot_water_filter_head_id,
            water_filter_head_id,
          });

          getAllTotalConsumptionCartridgeWf({
            device_id,
            water_filter_head_id,
          });
        }
      }
    }
  };

  calculeCatridgeCapacity = (catridge, chlorine_or_chloramine) => {
    const capacitySelected =
      chlorine_or_chloramine === 'Chlorine'
        ? catridge.chlorine_capacity
        : catridge.chloramine_capacity;

    return catridge.scale_control_capacity
      ? capacitySelected && catridge.scale_control_capacity > capacitySelected
        ? capacitySelected
        : catridge.scale_control_capacity
      : capacitySelected;
  };

  cartidgeOnChange = evt => {
    const {
      location: {
        WaterFilterHead: {
          id: water_filter_head_id,
          FlowMeterOfWaterFilters,
          device_id,
          chlorine_or_chloramine,
        },
      },
      getTotalConsumptionCartridgeWf,
      getAllTotalConsumptionCartridgeWf,
    } = this.props;

    const catridgeSelectedObjet = FlowMeterOfWaterFilters.find(wf => wf.id === evt.target.value);

    if (catridgeSelectedObjet) {
      const {
        last_replaced,
        SpotsWaterFilter: { Catridge },
      } = catridgeSelectedObjet;
      // get consuptions of catridge
      if (last_replaced) {
        getTotalConsumptionCartridgeWf({
          device_id,
          from: last_replaced,
          spot_id: catridgeSelectedObjet.spot_water_filter_head_id,
          water_filter_head_id,
        });

        getAllTotalConsumptionCartridgeWf({
          device_id,
          water_filter_head_id,
        });
      }
      const catridgeCapacity = this.calculeCatridgeCapacity(Catridge, chlorine_or_chloramine);

      this.setState({
        catridgeSelectedId: evt.target.value,
        catridgeSelectedObjet,
        catridgeCapacity,
      });
    }
  };

  renderActiveShape = props => {
    const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill, payload } = props;
    return (
      <g>
        <text
          x={cx}
          y={cy}
          dy={8}
          textAnchor="middle"
          fontFamily="Gotham, Sans Serif"
          fontSize={25}
          fill={Colors.black}
        >
          {100 - payload.value}%
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
      </g>
    );
  };

  renderPressure = () => {
    const {
      waterFilterCurrentPressure,
      waterFilterCurrentPressureLoading,
      location: {
        WaterFilterHead: { device_id },
      },
    } = this.props;

    const iconTime = <Icon name="weight" color={Colors.yellow} fontSize={22} />;
    return (
      <GraphContainer
        display="flex"
        justifyContent="space-around"
        flexDirection="column"
        height={360}
      >
        {/* INLET pressure */}
        <Col>
          <H2 textAlign="center">Inlet Pressure</H2>
          {waterFilterCurrentPressureLoading ? (
            <Loader size={40} block />
          ) : !device_id ? (
            <Col justifyContent="center">
              <ListEmpty message="Installation Not Complete" />
            </Col>
          ) : (
            <>
              {(waterFilterCurrentPressure || []).map((pressure, index) => (
                <Row>
                  <Col lg={100} md={100} sm={100} xs={100}>
                    <H4>
                      Filter {index + 1}
                      <span style={{ marginLeft: 20, marginRight: 20 }}> {iconTime} </span>
                      <span style={{ color: Colors.blue }}>
                        {Math.round(pressure.inletPressure)}{' '}
                      </span>
                      PSI
                    </H4>
                  </Col>
                </Row>
              ))}
            </>
          )}
        </Col>
        <VerticalLine style={{ marginTop: 5, marginBottom: 5 }} />
        {/* OUTLET Pressure */}
        <Col>
          <H2 textAlign="center">Outlet Pressure</H2>
          {waterFilterCurrentPressureLoading ? (
            <Loader size={40} block />
          ) : !device_id ? (
            <Col justifyContent="center">
              <ListEmpty message="Installation Not Complete" />
            </Col>
          ) : (
            <>
              {(waterFilterCurrentPressure || []).map((pressure, index) => (
                <Row>
                  <Col lg={100} md={100} sm={100} xs={100}>
                    <H4>
                      Filter {index + 1}
                      <span style={{ marginLeft: 20, marginRight: 20 }}> {iconTime} </span>
                      <span style={{ color: Colors.blue }}>
                        {Math.round(pressure.outletPressure)}{' '}
                      </span>
                      PSI
                    </H4>
                  </Col>
                </Row>
              ))}
            </>
          )}
        </Col>
        <VerticalLine style={{ marginTop: 5, marginBottom: 5 }} />
        {/* DIFFERENTIAL Pressure */}
        <Col lg={100} md={100} sm={100} xs={100}>
          <H2 textAlign="center">Differential Pressure</H2>
          {waterFilterCurrentPressureLoading ? (
            <Loader size={40} block />
          ) : !device_id ? (
            <Col justifyContent="center">
              <ListEmpty message="Installation Not Complete" />
            </Col>
          ) : (
            <>
              {(waterFilterCurrentPressure || []).map((pressure, index) => (
                <Row>
                  <Col lg={100} md={100} sm={100} xs={100}>
                    <H4>
                      Filter {index + 1}
                      <span style={{ marginLeft: 20, marginRight: 20 }}> {iconTime} </span>
                      <span style={{ color: Colors.blue }}>
                        {pressure.inletPressure - pressure.outletPressure >= 0
                          ? Math.round(
                              (pressure.inletPressure - pressure.outletPressure).toFixed(2),
                            )
                          : 0}{' '}
                      </span>
                      PSI
                    </H4>
                  </Col>
                </Row>
              ))}
            </>
          )}
        </Col>
      </GraphContainer>
    );
  };

  renderBypassFrequency = () => {
    const {
      waterFilterCurrentBypassLoading,
      waterFilterCurrentBypass,
      location: {
        WaterFilterHead: { device_id },
      },
    } = this.props;

    return (
      <Col lg={50} md={50} sm={100} xs={100}>
        <H2>Bypass Status</H2>
        {waterFilterCurrentBypassLoading ? (
          <Loader size={60} block />
        ) : !device_id ? (
          <Col justifyContent="center">
            <ListEmpty message="Installation Not Complete" />
          </Col>
        ) : (
          <>
            <Row justifyContent="center">
              {!waterFilterCurrentBypass || waterFilterCurrentBypass === 0 ? (
                <H1 color={Colors.green}>Off</H1>
              ) : (
                <H1 color={Colors.red}>On</H1>
              )}
            </Row>
            <H3
              lineHeight="0"
              color={Colors.lightBlue}
              textAlign="center"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                this.switchModal('modalBypass');
                this.calculeWaterFilterBypass();
              }}
            >
              Open More
            </H3>
          </>
        )}
      </Col>
    );
  };

  renderFilterCartridges = catridgeSelectedObjet => {
    const {
      // cartridgeTotalConsumptionWf,
      cartridgeAllTotalConsumptionWf,
      cartridgeTotalConsumptionWfLoading,
      cartridgeAllTotalConsumptionWfLoading,
    } = this.props;
    const { /* catridgeCapacity, */ totalCatridgeCapacity } = this.state;

    if (catridgeSelectedObjet && catridgeSelectedObjet.last_replaced) {
      const {
        SpotsWaterFilter: {
          Catridge: { warranty: warrantyYears },
        },
      } = catridgeSelectedObjet;
      // Calcule weeks to expire
      const filterWeeksDuration = 52 * warrantyYears;
      const lastReplacedMoment = moment(catridgeSelectedObjet.last_replaced);
      const replacedWeeks = moment().diff(lastReplacedMoment, 'weeks');

      // Calcule flow to expire
      const consuptionPercent =
        totalCatridgeCapacity === 0
          ? 0
          : Math.round((cartridgeAllTotalConsumptionWf * 100) / totalCatridgeCapacity);

      const dataFilterExhaustion = [
        { name: 'nocomplete', value: 100 - consuptionPercent, fill: Colors.grayChart },
        { name: 'complete', value: consuptionPercent, fill: Colors.green },
      ];
      return (
        <Row>
          <ResponsiveContainer height={180} id="exhaustionChart">
            <PieChart>
              <Pie
                activeIndex={0}
                activeShape={this.renderActiveShape}
                data={dataFilterExhaustion}
                dataKey="value"
                innerRadius={39}
                outerRadius={59}
              />
            </PieChart>
          </ResponsiveContainer>
          <VerticalLine />

          {cartridgeTotalConsumptionWfLoading ? (
            <Loader size={30} />
          ) : (
            <>
              {!cartridgeAllTotalConsumptionWfLoading && (
                <H4 color={Colors.gray} style={{ margin: 5, textAlign: 'center' }}>
                  Summary cartridges: {cartridgeAllTotalConsumptionWf} /{totalCatridgeCapacity} gal
                </H4>
              )}
              <H5 color={Colors.gray}>
                Last Replaced: {moment(catridgeSelectedObjet.last_replaced).format('MMMM Do yyyy')}
              </H5>
            </>
          )}
          <VerticalLine />

          <Row>
            <Col lg={100} md={100} sm={100} xs={100}>
              <H4 color={Colors.gray} lineHeight="1" style={{ margin: 5 }}>
                Expiration:
              </H4>
              <H4 color={Colors.gray} lineHeight="1" style={{ margin: 5, textAlign: 'center' }}>
                <span style={{ color: Colors.black }}>Installed For: </span> {replacedWeeks}
                {replacedWeeks !== 1 ? ' weeks' : ' week'}
              </H4>
              <H2 textAlign="center" color={Colors.yellow} lineHeight="1" style={{ margin: 5 }}>
                {filterWeeksDuration - replacedWeeks} weeks remaining
              </H2>
            </Col>
          </Row>
        </Row>
      );
    }
    return (
      <ResponsiveContainer height={300} width="100%">
        <Row justifyContent="center" alignItems="center" style={{ height: '100%' }}>
          <ListEmpty message="This cartridge has not been replaced yet" />
        </Row>
      </ResponsiveContainer>
    );
  };

  renderConfigurationModal = () => {
    const { showConfiguration } = this.state;
    const {
      location: {
        WaterFilterHead: {
          WaterFilterConfiguration,
          chlorine_or_chloramine,
          FlowMeterOfWaterFilters,
        },
      },
    } = this.props;
    const { name } = WaterFilterConfiguration;

    return (
      <Modal
        title="Configuration"
        onClose={() => this.switchModal('showConfiguration')}
        isVisible={showConfiguration}
      >
        <H4 lineHeight={2} textAlign="left" color={Colors.gray8} weight={325} style={{ margin: 0 }}>
          Configuration name
        </H4>
        <H4 lineHeight={2} color={Colors.black} weight={500} style={{ marginTop: 5 }}>
          {name}
        </H4>
        <H4 lineHeight={2} color={Colors.black} weight={500} style={{ marginTop: 5 }}>
          <Icon name="check-circle" color={Colors.lightBlue} customStyle={{ marginRight: 5 }} />
          {chlorine_or_chloramine}
        </H4>

        {FlowMeterOfWaterFilters.map(({ SpotsWaterFilter, type, name }) => {
          const { WaterFilter, Catridge } = SpotsWaterFilter;
          return (
            <>
              <H4 lineHeight={2} color={Colors.lightBlue} weight="bold" style={{ marginTop: 5 }}>
                {WaterFilter.name}
              </H4>

              <Row alignItems="left" lg={100} md={100} sm={100} xs={100}>
                <Col lg={30} md={30} sm={30} xs={30} alignItems="left">
                  <H4 lineHeight={2} weight="bold" style={{ marginTop: 5 }}>
                    {SpotsWaterFilter.position} -{Catridge.name}
                  </H4>
                </Col>
                <Col lg={70} md={70} sm={70} xs={70} alignItems="left">
                  <H4 lineHeight={2} weight="bold" style={{ marginTop: 5 }}>
                    Capacity: {this.calculeCatridgeCapacity(Catridge, chlorine_or_chloramine)} gal
                  </H4>
                </Col>
                <>
                  <Col lg={30} md={30} sm={30} xs={30} alignItems="left">
                    <H4
                      lineHeight={2}
                      textAlign="left"
                      color={Colors.gray8}
                      weight={325}
                      style={{ margin: 0 }}
                    >
                      Type
                    </H4>

                    <H4 lineHeight={2} weight="bold" style={{ marginTop: 5 }}>
                      {type}
                    </H4>
                  </Col>
                  <Col lg={70} md={70} sm={70} xs={70} alignItems="left">
                    <H4
                      lineHeight={2}
                      textAlign="left"
                      color={Colors.gray8}
                      weight={325}
                      style={{ margin: 0 }}
                    >
                      Name
                    </H4>
                    <H4 lineHeight={2} weight="bold" style={{ marginTop: 5 }}>
                      {name}
                    </H4>
                  </Col>

                  {Catridge.photo && (
                    <Col alignItems="start" xs={100} md={100} sm={100} lg={100}>
                      <img width={200} src={Catridge.photo} />
                    </Col>
                  )}
                  <VerticalLine />
                </>
              </Row>
            </>
          );
        })}
      </Modal>
    );
  };

  renderBypassConsumptions = () => {
    const {
      waterFilterBypass: { consumption, total_flow, last_updated },
      waterFilterBypassLoading,
      location: {
        WaterFilterHead: { device_id },
      },
    } = this.props;

    const { possibleDayRangesBypass, datePickerFromBypass, datePickerToBypass } = this.state;

    const CustomTooltip = ({ payload, active }) => {
      if (active && payload && payload[0]) {
        const currentPayload = payload[0].payload;
        return (
          <div
            style={{
              backgroundColor: 'white',
              padding: 1,
              borderRadius: 5,
              borderColor: 'gray',
              borderStyle: 'solid',
              borderWidth: 1,
            }}
          >
            <p style={{ color: 'gray' }}>{currentPayload.tooltipLabel}</p>
            <p style={{ color: 'gray' }}>Flow:{Math.round(currentPayload.gal)} Gal</p>
          </div>
        );
      }

      return null;
    };

    return (
      <>
        {waterFilterBypassLoading ? (
          <GraphContainer height={450}>
            <Loader size={160} block />
          </GraphContainer>
        ) : (
          <GraphContainer height={450}>
            <>
              <Col style={{ height: 300 }} justifyContent="center">
                <Row style={{ padding: '2%' }}>
                  <Col lg={40} md={40} sm={40} xs={40}>
                    <Row
                      justifyContent="flex-start"
                      alignItems="center"
                      data
                      style={{ cursor: 'pointer' }}
                    >
                      {possibleDayRangesBypass.map(range => (
                        <H5
                          cursor="pointer"
                          color={range === this.state.range ? Colors.lightBlue : Colors.gray}
                          style={{ marginLeft: 5, marginRight: 5 }}
                          onClick={() => this.calculeWaterFilterBypass(range)}
                        >
                          {range}
                        </H5>
                      ))}
                    </Row>
                  </Col>
                  <Col lg={60} md={60} sm={60} xs={60}>
                    <Row
                      justifyContent="flex-end"
                      alignItems="center"
                      data
                      style={{ cursor: 'pointer' }}
                      onClick={() => this.switchModal('modalDatePickerBypass')}
                    >
                      <H5 color={Colors.lightBlue} style={{ marginLeft: 5, marginRight: 5 }}>
                        {moment(datePickerFromBypass).format('MM/DD/YYYY')} -
                        {moment(datePickerToBypass).format('MM/DD/YYYY')}
                      </H5>
                      <Icon
                        name="calendar-alt"
                        color={Colors.lightBlue}
                        customStyle={{ fontSize: '20px' }}
                      />
                    </Row>
                  </Col>
                </Row>
                <ResponsiveContainer width="100%">
                  {!device_id ? (
                    <Col style={{ height: 300 }} justifyContent="center">
                      <ListEmpty message="Installation Not Complete" />
                    </Col>
                  ) : (
                    <ComposedChart
                      data={consumption}
                      margin={{ right: 20, left: -15, bottom: 0 }}
                      barSize={20}
                    >
                      <XAxis
                        dataKey="name"
                        stroke={Colors.gray}
                        tick={{ fontSize: 12 }}
                        scale="point"
                        padding={{ left: 15, top: 15 }}
                      />

                      <YAxis type="number" unit="" stroke={Colors.gray} tick={{ fontSize: 12 }} />
                      <Tooltip content={<CustomTooltip />} />

                      <CartesianGrid strokeDasharray="3 3" />

                      <Bar dataKey="gal">
                        {consumption.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={Colors.lightBlue}
                            background={{ fill: 'transparent' }}
                          />
                        ))}
                      </Bar>
                    </ComposedChart>
                  )}
                </ResponsiveContainer>
              </Col>
              {last_updated && (
                <H5 color={Colors.gray8}>
                  Last updated: {moment.unix(last_updated).format('h:mma MM/DD/YYYY')}
                </H5>
              )}
            </>
            <VerticalLine style={{ marginTop: 15, marginBottom: 15 }} />
            <Row>
              <Col xs={100} md={100} sm={40} lg={40}>
                <H4
                  textAlign="center"
                  weight="bold"
                  marginTop={5}
                  style={{ letterSpacing: 1, lineHeight: 1 }}
                >
                  Total Flow
                </H4>
                <H5 weight="bold" color={Colors.lightBlue} marginTop={5}>
                  since installation
                </H5>
                <H2 color={Colors.blue}>{total_flow === 0 ? 'N/A' : Math.round(total_flow)}</H2>
                {total_flow !== 0 && <H2>gal</H2>}
              </Col>
            </Row>
          </GraphContainer>
        )}
      </>
    );
  };

  renderFilterConsumptions = () => {
    const {
      waterFilterConsumption: { consumption, last_updated, total_flow },
      waterFilterPressureForConsumption: { pressures },
      waterFilterConsumptionLoading,
      waterFilterPressureForConsumptionLoading,
      location: {
        WaterFilterHead: { device_id },
      },
      totalConsumptionWaterFilterLoading,
      waterFilterTotalConsumption,
    } = this.props;
    const {
      datePickerFromConsumption,
      datePickerToConsumption,
      possibleDayRangesConsumption,
    } = this.state;
    const CustomTooltip = ({ payload, active }) => {
      if (active && payload && payload[0]) {
        const currentPayload = payload[0].payload;
        return (
          <div
            style={{
              backgroundColor: 'white',
              padding: 1,
              borderRadius: 5,
              borderColor: 'gray',
              borderStyle: 'solid',
              borderWidth: 1,
            }}
          >
            <p style={{ color: 'gray' }}>{currentPayload.tooltipLabel}</p>
            <p style={{ color: 'gray' }}>{Math.round(currentPayload.gal)} Gal</p>
            {currentPayload.psi_0 !== undefined ? (
              <p style={{ color: 'gray' }}>
                Filter 1 Max PD: {Math.round(currentPayload.psi_0)} PSI
              </p>
            ) : null}
            {currentPayload.psi_1 !== undefined ? (
              <p style={{ color: 'gray' }}>
                Filter 2 Max PD: {Math.round(currentPayload.psi_1)} PSI
              </p>
            ) : null}
            {currentPayload.psi_2 !== undefined ? (
              <p style={{ color: 'gray' }}>
                Filter 3 Max PD: {Math.round(currentPayload.psi_2)} PSI
              </p>
            ) : null}
          </div>
        );
      }

      return null;
    };

    const dataChart = consumption.map((consumption, index) => ({
      ...pressures[index],
      ...consumption,
    }));

    return (
      <GraphContainer height={530}>
        {waterFilterConsumptionLoading ? (
          <GraphContainer display="flex">
            <Loader size={90} />
          </GraphContainer>
        ) : (
          <GraphContainer>
            <Row style={{ marginBottom: 15 }}>
              <Col lg={40} md={40} sm={40} xs={40}>
                <Row
                  justifyContent="flex-start"
                  alignItems="center"
                  data
                  style={{ cursor: 'pointer' }}
                >
                  {possibleDayRangesConsumption.map(range => (
                    <H5
                      cursor="pointer"
                      color={range === this.state.range ? Colors.lightBlue : Colors.gray}
                      style={{ marginLeft: 5, marginRight: 5 }}
                      onClick={() => this.calculeWaterFilterConsumption(range)}
                    >
                      {range}
                    </H5>
                  ))}
                </Row>
              </Col>
              <Col lg={60} md={60} sm={60} xs={100}>
                <Row>
                  <div
                    style={{ cursor: 'pointer' }}
                    justifyContent="flex-end"
                    alignItems="center"
                    data
                    onClick={() => this.switchModal('modalDatePickerConsumption')}
                  >
                    <Row>
                      <H5 color={Colors.lightBlue} style={{ marginLeft: 5, marginRight: 5 }}>
                        {moment(datePickerFromConsumption).format('MM/DD/YYYY')} -
                        {moment(datePickerToConsumption).format('MM/DD/YYYY')}
                      </H5>
                      <Icon
                        name="calendar-alt"
                        color={Colors.lightBlue}
                        customStyle={{ fontSize: '20px' }}
                      />
                    </Row>
                  </div>
                  <div>
                    <a
                      data-tooltip-id="my-tooltip"
                      data-tooltip-html="<strong>Flow Volume Graph</strong> <br>Displays total flow volume by time period."
                    >
                      <Icon
                        name="info-circle"
                        customStyle={{ marginLeft: 5, marginRight: 5, paddingLeft: 5 }}
                      />
                    </a>
                    <TooltipReact id="my-tooltip" />
                  </div>
                </Row>
              </Col>
            </Row>

            <GraphContainer>
              <Row style={{ minHeight: 60, width: '80%', margin: 'auto' }}>
                <Col xs={100} md={100} sm={40} lg={40}>
                  <H4 weight="bold" marginTop={5} style={{ letterSpacing: 1, lineHeight: 1 }}>
                    Flow
                  </H4>
                  <H5 weight="bold" color={Colors.lightBlue} marginTop={5}>
                    Period selected
                  </H5>
                </Col>

                <Col xs={100} md={100} sm={50} lg={50}>
                  <H2 color={Colors.blue}>
                    {total_flow === 0 ? 'N/A' : Math.round(total_flow)}
                    {total_flow !== 0 && (
                      <span style={{ color: Colors.black, marginLeft: 5 }}> gal</span>
                    )}
                  </H2>
                </Col>
              </Row>
              <>
                {waterFilterPressureForConsumptionLoading && (
                  <Row style={{ width: 200, margin: 'auto' }}>
                    <Loader size={15} block />
                    <H5 color={Colors.lightBlue} marginTop={5}>
                      Loading max pressures...
                    </H5>
                  </Row>
                )}

                <Col style={{ height: 300 }} justifyContent="center">
                  <ResponsiveContainer width="100%" id="totalGaloonsChart">
                    {!device_id ? (
                      <Col style={{ height: 300 }} justifyContent="center">
                        <ListEmpty message="Installation Not Complete" />
                      </Col>
                    ) : (
                      <ComposedChart
                        data={dataChart}
                        margin={{ right: 20, left: -15, bottom: 0 }}
                        barSize={20}
                      >
                        <XAxis
                          dataKey="name"
                          stroke={Colors.gray}
                          tick={{ fontSize: 12 }}
                          scale="point"
                          padding={{ left: 15, top: 15 }}
                        />

                        <YAxis type="number" unit="" stroke={Colors.gray} tick={{ fontSize: 12 }} />
                        <Tooltip content={<CustomTooltip />} />

                        <CartesianGrid strokeDasharray="3 3" />

                        <Bar
                          dataKey="gal"
                          legendType="none"
                          tooltipType="none"
                          fill={Colors.lightBlue}
                        >
                          {consumption.map((entry, index) => (
                            <Cell key={`cell-${index}`} background={{ fill: 'transparent' }} />
                          ))}
                        </Bar>
                        {!waterFilterPressureForConsumptionLoading && (
                          <Line dataKey="psi_0" stroke="#003cff" animationDuration={3000} />
                        )}
                        {!waterFilterPressureForConsumptionLoading && (
                          <Line dataKey="psi_1" stroke="#35d419" animationDuration={3500} />
                        )}
                        {!waterFilterPressureForConsumptionLoading && (
                          <Line dataKey="psi_2" stroke="#ffaa00" animationDuration={4000} />
                        )}
                      </ComposedChart>
                    )}
                  </ResponsiveContainer>
                  {last_updated && (
                    <H5 color={Colors.gray8}>
                      Last updated: {moment.unix(last_updated).format('h:mma MM/DD/YYYY')}
                    </H5>
                  )}
                </Col>
              </>
            </GraphContainer>
          </GraphContainer>
        )}
        <VerticalLine style={{ marginTop: 15, marginBottom: 15 }} />

        {totalConsumptionWaterFilterLoading ? (
          <GraphContainer display="flex">
            <Loader size={90} />
          </GraphContainer>
        ) : (
          <Row style={{ minHeight: 75, width: '80%', margin: 'auto' }}>
            <Col xs={100} md={100} sm={50} lg={50}>
              <H4
                textAlign="center"
                weight="bold"
                marginTop={5}
                style={{ letterSpacing: 1, lineHeight: 1 }}
              >
                Total Flow
              </H4>
              <H5 weight="bold" color={Colors.lightBlue} marginTop={5}>
                since installation
              </H5>
            </Col>

            <Col xs={100} md={100} sm={50} lg={50}>
              <H2 color={Colors.blue}>
                {waterFilterTotalConsumption === 0
                  ? 'N/A'
                  : Math.round(waterFilterTotalConsumption)}
                {waterFilterTotalConsumption !== 0 && (
                  <span style={{ color: Colors.black, marginLeft: 5 }}> gal</span>
                )}
              </H2>
            </Col>
          </Row>
        )}
      </GraphContainer>
    );
  };

  renderMaxFlowAndPressures = () => {
    const {
      waterFilterMaxFlowRate: { consumption },
      waterFilterPressureForFlowRate: { pressures },
      waterFilterMaxFlowRateLoading,
      waterFilterPressureForFlowRateLoading,
      location: {
        WaterFilterHead: { device_id },
      },
    } = this.props;

    const {
      possibleDayRangesMaxFlowRate,
      datePickerFromMaxFlowRate,
      datePickerToMaxFlowRate,
    } = this.state;

    const CustomTooltip = ({ payload, active }) => {
      if (active && payload && payload[0]) {
        const currentPayload = payload[0].payload;
        return (
          <div
            style={{
              backgroundColor: 'white',
              padding: 1,
              borderRadius: 5,
              borderColor: 'gray',
              borderStyle: 'solid',
              borderWidth: 1,
            }}
          >
            <p style={{ color: 'gray' }}>{currentPayload.tooltipLabel}</p>
            <p style={{ color: 'gray' }}>Max Flow Rate:{currentPayload.gal.toFixed(2)} Gal</p>

            {currentPayload.psi_0 !== undefined ? (
              <p style={{ color: 'gray' }}>
                Filter 1 Max PD: {currentPayload.psi_0.toFixed(2)} PSI
              </p>
            ) : null}
            {currentPayload.psi_1 !== undefined ? (
              <p style={{ color: 'gray' }}>
                Filter 2 Max PD: {currentPayload.psi_1.toFixed(2)} PSI
              </p>
            ) : null}
            {currentPayload.psi_2 !== undefined ? (
              <p style={{ color: 'gray' }}>
                Filter 3 Max PD: {currentPayload.psi_2.toFixed(2)} PSI
              </p>
            ) : null}
          </div>
        );
      }

      return null;
    };

    const dataChart = consumption.map((consumption, index) => ({
      ...pressures[index],
      ...consumption,
    }));

    return (
      <GraphContainer height={360}>
        <H2 textAlign="center">Max Pressure Differential & Flow Rate</H2>
        {waterFilterMaxFlowRateLoading ? (
          <GraphContainer>
            <Loader size={100} block />
          </GraphContainer>
        ) : (
          <>
            <Row style={{ marginBottom: 15 }}>
              <Col lg={40} md={40} sm={40} xs={40}>
                <Row
                  justifyContent="flex-start"
                  alignItems="center"
                  data
                  style={{ cursor: 'pointer' }}
                >
                  {possibleDayRangesMaxFlowRate.map(range => (
                    <H5
                      cursor="pointer"
                      color={range === this.state.range ? Colors.lightBlue : Colors.gray}
                      style={{ marginLeft: 5, marginRight: 5 }}
                      onClick={() => this.calculeWaterFilterMaxFlowRate(range)}
                    >
                      {range}
                    </H5>
                  ))}
                </Row>
              </Col>
              <Col lg={60} md={60} sm={60} xs={100}>
                <Row>
                  <div
                    style={{ cursor: 'pointer' }}
                    justifyContent="flex-end"
                    alignItems="center"
                    data
                    onClick={() => this.switchModal('modalDatePickerMaxFlowRate')}
                  >
                    <Row>
                      <H5 color={Colors.lightBlue} style={{ marginLeft: 5, marginRight: 5 }}>
                        {moment(datePickerFromMaxFlowRate).format('MM/DD/YYYY')} -
                        {moment(datePickerToMaxFlowRate).format('MM/DD/YYYY')}
                      </H5>
                      <Icon
                        name="calendar-alt"
                        color={Colors.lightBlue}
                        customStyle={{ fontSize: '20px' }}
                      />
                    </Row>
                  </div>

                  <div>
                    <a
                      data-tooltip-id="my-tooltip"
                      data-tooltip-html="<strong>Filter Pressure Differential & Flow Rate Graph:</strong> <br>Displays daily max flow rate and daily max pressure differential. <br>A “potential filter clog alert” is sent when pressure differential meets or exceeds 25PSI."
                    >
                      <Icon name="info-circle" customStyle={{ marginLeft: 5 }} />
                    </a>
                    <TooltipReact id="my-tooltip" />
                  </div>
                </Row>
              </Col>
            </Row>

            <GraphContainer>
              <Col style={{ height: 300 }} justifyContent="center">
                {waterFilterPressureForFlowRateLoading && (
                  <Row style={{ width: 200, margin: 'auto' }}>
                    <Loader size={15} block />
                    <H5 color={Colors.lightBlue} marginTop={5}>
                      Loading max pressures...
                    </H5>
                  </Row>
                )}
                <ResponsiveContainer width="100%" id="maxFlowAndPressureChart">
                  {!device_id ? (
                    <Col style={{ height: 300 }} justifyContent="center">
                      <ListEmpty message="Installation Not Complete" />
                    </Col>
                  ) : (
                    <ComposedChart
                      data={dataChart}
                      margin={{ right: 20, left: -15, bottom: 0 }}
                      barSize={20}
                    >
                      <XAxis
                        dataKey="name"
                        stroke={Colors.gray}
                        tick={{ fontSize: 12 }}
                        scale="point"
                        padding={{ left: 15, top: 15 }}
                      />

                      <YAxis type="number" unit="" stroke={Colors.gray} tick={{ fontSize: 12 }} />
                      <Tooltip content={<CustomTooltip />} />

                      <CartesianGrid strokeDasharray="3 3" />

                      <Line
                        dataKey="gal"
                        legendType="none"
                        tooltipType="none"
                        fill={Colors.lightBlue}
                      >
                        {consumption.map((entry, index) => (
                          <Cell key={`cell-${index}`} background={{ fill: 'transparent' }} />
                        ))}
                      </Line>
                      {!waterFilterPressureForFlowRateLoading && (
                        <Line dataKey="psi_0" stroke="#003cff" animationDuration={3000} />
                      )}
                      {!waterFilterPressureForFlowRateLoading && (
                        <Line dataKey="psi_1" stroke="#35d419" animationDuration={3500} />
                      )}
                      {!waterFilterPressureForFlowRateLoading && (
                        <Line dataKey="psi_2" stroke="#ffaa00" animationDuration={4000} />
                      )}
                    </ComposedChart>
                  )}
                </ResponsiveContainer>
              </Col>
            </GraphContainer>
          </>
        )}
      </GraphContainer>
    );
  };

  switchModal = name => {
    const isVisible = this.state[name];
    this.setState({ [name]: !isVisible });
  };

  handleChangeDeviceId = evt => {
    this.setState({ new_device_id: evt.target.value });
  };

  generateWaterFilterCsv = () => {
    const {
      generateWaterFilterCsv,
      location: {
        id,
        WaterFilterHead: { device_id },
      },
    } = this.props;
    const { csvTypeString } = this.state;
    const { datePickerFromCsv: from, datePickerToCsv: to } = this.state;
    generateWaterFilterCsv({ device_id, location_id: id, from, to, csvContains: csvTypeString });
  };

  changeCsvType = (csvType, nro) => {
    const csvTypes = ['raw_data', 'calculated_data', 'all_data'];

    this.setState({ [csvType]: nro, csvTypeString: csvTypes[nro - 1] });
  };

  calculeWaterFilterConsumption = (range, wf_for_pfd) => {
    const {
      getWaterFilterConsumption,
      getWaterFilterPressureForConsumption,
      location: {
        WaterFilterHead: { device_id },
      },
    } = this.props;
    const { datePickerFromConsumption: from, datePickerToConsumption: to } = this.state;

    const possibleDayRangesConsumption = this.calculeDaysRange(from, to);

    const currentRange = range || possibleDayRangesConsumption[0];
    getWaterFilterConsumption({ device_id, from, to, range: currentRange, wf_for_pfd });
    getWaterFilterPressureForConsumption({ device_id, from, to, range: currentRange, wf_for_pfd });

    this.setState({ possibleDayRangesConsumption, range: currentRange });
  };

  calculeWaterFilterMaxFlowRate = (range, wf_for_pfd) => {
    const {
      getWaterFilterMaxFlowRate,
      getWaterFilterPressureForFlowRate,
      location: {
        WaterFilterHead: { device_id },
      },
    } = this.props;
    const { datePickerFromMaxFlowRate: from, datePickerToMaxFlowRate: to } = this.state;

    const possibleDayRangesMaxFlowRate = this.calculeDaysRange(from, to);
    const currentRange = range || possibleDayRangesMaxFlowRate[0];
    getWaterFilterMaxFlowRate({ device_id, from, to, range: currentRange, wf_for_pfd });
    getWaterFilterPressureForFlowRate({ device_id, from, to, range: currentRange, wf_for_pfd });

    this.setState({ possibleDayRangesMaxFlowRate, range: currentRange });
  };

  calculeWaterFilterBypass = range => {
    const {
      getWaterFilterBypass,
      location: {
        WaterFilterHead: { device_id, installed_date },
      },
    } = this.props;
    const { datePickerFromBypass: from, datePickerToBypass: to } = this.state;

    const possibleDayRangesBypass = this.calculeDaysRange(from, to);

    const currentRange = range || possibleDayRangesBypass[0];
    getWaterFilterBypass({ device_id, from, to, installed_date, range: currentRange });
    this.setState({ possibleDayRangesBypass, range: currentRange });
  };

  calculeDaysRange = (from, to) => {
    const possibleDayRanges = [];
    const fromDate = moment(from);
    const toDate = moment(to);
    const daysRange = toDate.diff(fromDate, 'days');

    // The maximum of days chosen in each condition is the equivalent of the maximum of 15 bars on the chart
    if (daysRange <= 1) possibleDayRanges.push('hours');
    if (daysRange >= 1 && daysRange < 15) possibleDayRanges.push('days');
    if (daysRange >= 14 && daysRange < 105) possibleDayRanges.push('weeks');
    if (daysRange >= 30 && daysRange < 450) possibleDayRanges.push('months');
    if (daysRange >= 365) possibleDayRanges.push('years');
    return possibleDayRanges;
  };

  replaceWaterFilterCartridgesFunc = () => {
    const {
      location: {
        WaterFilterHead: { id: water_filter_head_id },
      },
      replaceWaterFilterCartridges,
    } = this.props;
    replaceWaterFilterCartridges({ water_filter_head_id });
  };

  handleDate = (value, key) => {
    this.setState({ [key]: value });
  };

  generateReport = async () => {
    try {
      const { datePickerFromReport, datePickerToReport } = this.state;

      this.setState(
        {
          datePickerFromConsumption: datePickerFromReport,
          datePickerToConsumption: datePickerToReport,
          datePickerFromMaxFlowRate: datePickerFromReport,
          datePickerToMaxFlowRate: datePickerToReport,
          generatePDF: true,
        },
        () => {
          this.calculeWaterFilterConsumption(false, true);
          this.calculeWaterFilterMaxFlowRate(false, true);
        },
      );
    } catch (error) {
      this.setState({ generatePDF: false });
      console.log(error);
    }
  };

  downloadPDF = () => {
    const {
      totalGaloonsChart,
      exhaustionChart,
      maxFlowAndPressureChart,
      downloadPDF,
      datePickerFromConsumption,
      datePickerToConsumption,
      catridgeSelectedObjet,
      catridgeCapacity,
      totalCatridgeCapacity,
    } = this.state;
    const {
      location,
      allAlerts,
      waterFilterConsumption,
      waterFilterTotalConsumption,
      waterFilterCurrentPressure,
      cartridgeTotalConsumptionWf,
      cartridgeAllTotalConsumptionWf,
      waterFilterCurrentBypass,
    } = this.props;

    if (downloadPDF) {
      return (
        <PDFDownloadLink
          document={
            <ReportWF
              datePickerFrom={datePickerFromConsumption}
              datePickerTo={datePickerToConsumption}
              totalGaloonsChart={totalGaloonsChart}
              exhaustionChart={exhaustionChart}
              maxFlowAndPressureChart={maxFlowAndPressureChart}
              location={location}
              allAlerts={allAlerts}
              waterFilterConsumption={waterFilterConsumption}
              waterFilterTotalConsumption={waterFilterTotalConsumption}
              catridgeSelectedObjet={catridgeSelectedObjet}
              cartridgeTotalConsumptionWf={cartridgeTotalConsumptionWf}
              cartridgeAllTotalConsumptionWf={cartridgeAllTotalConsumptionWf}
              totalCatridgeCapacity={totalCatridgeCapacity}
              pressures={waterFilterCurrentPressure}
              catridgeCapacity={catridgeCapacity}
              waterFilterCurrentBypass={waterFilterCurrentBypass}
            />
          }
          fileName="ReportWF.pdf"
        >
          {({ loading }) => (
            <H4
              color={Colors.lightBlue}
              style={{ cursor: 'pointer' }}
              onClick={() => this.setState({ generatePDF: false, downloadPDF: false })}
            >
              <Icon
                name={loading ? 'far fa-spinner' : 'far fa-file-download'}
                color={Colors.lightBlue}
                customStyle={{ marginRight: '5px' }}
              />
              {loading ? 'Loading' : 'Download'} Report
            </H4>
          )}
        </PDFDownloadLink>
      );
    }
    return (
      <Row>
        <Loader size={35} block />
        <H4 color={Colors.lightBlue} marginTop={5}>
          Generating PDF ...
        </H4>
      </Row>
    );
  };

  render() {
    const {
      location: { WaterFilterHead, id: location_id },
      renderInstructionsModal,
      waterFilterConsumptionLoading,
      totalConsumptionWaterFilterLoading,
      waterFiltercurrentStateLoading,
      cartridgeTotalConsumptionWfLoading,
      waterFilterCurrentBypassLoading,
      getCsvFiles,
      csvFiles,
      csvFilesLoading,
      setDeviceId,
      role_id,
      waterFilterMaxFlowRateLoading,
      waterFilterPressureForConsumptionLoading,
      waterFilterPressureForFlowRateLoading,
    } = this.props;
    const {
      generatePDF,
      modalDatePickerConsumption,
      datePickerFromConsumption,
      datePickerToConsumption,
      catridgeSelectedId,
      catridgeSelectedObjet,
      modalDatePickerCsv,
      datePickerFromCsv,
      datePickerToCsv,
      csvType,
      new_device_id,
      modalDeviceId,
      modalBypass,
      datePickerFromBypass,
      datePickerToBypass,
      modalDatePickerBypass,
      datePickerFromReport,
      datePickerToReport,
      modalDatePickerReport,
      modalDatePickerMaxFlowRate,
      datePickerFromMaxFlowRate,
      datePickerToMaxFlowRate,
    } = this.state;
    const {
      WaterFilterConfiguration,
      FlowMeterOfWaterFilters,
      device_id,
      installed_date,
    } = WaterFilterHead;
    const { WatherFilterInstallSteps } = WaterFilterConfiguration;

    const getDataCompleted =
      !waterFilterConsumptionLoading &&
      !totalConsumptionWaterFilterLoading &&
      !waterFiltercurrentStateLoading &&
      !waterFilterCurrentBypassLoading &&
      !cartridgeTotalConsumptionWfLoading &&
      !waterFilterMaxFlowRateLoading &&
      !waterFilterPressureForConsumptionLoading &&
      !waterFilterPressureForFlowRateLoading;

    return (
      <Container>
        <Row style={{ marginBottom: 5 }}>
          <Col lg={60} md={60} sm={100} xs={100}>
            <Row justifyContent="flex-start" alignItems="center">
              <H5 color={Colors.black}>
                Model:
                {WaterFilterConfiguration.name}
              </H5>
              <H5 color={Colors.black} style={{ marginLeft: 20 }}>
                Device ID: {device_id || 'Installation Not Complete'}
              </H5>
            </Row>
          </Col>
          <Col lg={20} md={20} sm={100} xs={100} alignItems="flex-end">
            <H4
              onClick={() => this.switchModal('showConfiguration')}
              weight={350}
              color={Colors.lightBlue}
              style={{ cursor: 'pointer' }}
            >
              Open configuration
            </H4>
          </Col>
          <Col lg={20} md={20} sm={100} xs={100} alignItems="flex-end">
            {generatePDF
              ? this.downloadPDF()
              : getDataCompleted && (
                  <H4
                    weight={350}
                    color={Colors.lightBlue}
                    style={{ cursor: 'pointer' }}
                    onClick={() => this.switchModal('modalDatePickerReport')}
                  >
                    <Icon
                      name="far fa-newspaper"
                      color={Colors.lightBlue}
                      customStyle={{ marginRight: '5px' }}
                    />
                    Generate report
                  </H4>
                )}
          </Col>
        </Row>

        <VerticalLine />

        <Row style={{ marginBottom: 5, marginTop: 5 }}>
          <Col lg={100} md={100} sm={100} xs={100} alignItems="start">
            <H5 color={Colors.gray8}>
              {'Installed date: '}
              {installed_date
                ? `${moment(installed_date).format('MMMM Do yyyy')}`
                : 'Installation Not Complete'}
            </H5>
          </Col>
        </Row>
        <VerticalLine />

        <Col alignItems="flex-start" style={{ marginBottom: 10, marginTop: 10 }}>
          <Row justifyContent="end">
            {WatherFilterInstallSteps.length !== 0 && (
              <H5
                style={{ margin: 10 }}
                onClick={() => this.switchModal('modalViewInstructions')}
                cursor="pointer"
                color={Colors.lightBlue}
              >
                <Icon name="tasks" color={Colors.lightBlue} customStyle={{ marginRight: '5px' }} />
                View Instructions
              </H5>
            )}
            <H5
              style={{ margin: 10 }}
              onClick={() => {
                this.switchModal('modalDatePickerCsv');
                getCsvFiles({ type: 'water-filter' });
              }}
              cursor="pointer"
              color={Colors.lightBlue}
            >
              <Icon
                name="file-export"
                color={Colors.lightBlue}
                customStyle={{ marginRight: '5px' }}
              />
              Export Data
            </H5>
            {role_id === 1 && (
              <>
                <H5
                  style={{ margin: 10 }}
                  onClick={() => {
                    this.switchModal('modalDeviceId');
                  }}
                  cursor="pointer"
                  color={Colors.lightBlue}
                >
                  <Icon
                    name="pen-square"
                    color={Colors.lightBlue}
                    customStyle={{ marginRight: '5px' }}
                  />
                  Set Device ID
                </H5>
                <H5
                  style={{ margin: 10 }}
                  onClick={this.replaceWaterFilterCartridgesFunc}
                  cursor="pointer"
                  color={Colors.lightBlue}
                >
                  <Icon name="sync" color={Colors.lightBlue} customStyle={{ marginRight: '5px' }} />
                  Replace Cartridges
                </H5>
              </>
            )}
          </Row>
        </Col>
        <VerticalLine />
        <Row>
          <Col lg={50} md={100} sm={100} xs={100}>
            <BG>{this.renderFilterConsumptions(catridgeSelectedObjet)}</BG>
          </Col>

          <Col lg={50} md={100} sm={100} xs={100}>
            <BG>
              <GraphContainer display="flex" height={245}>
                <Col>
                  <H2>Filter Cartridges</H2>
                  {this.renderFilterCartridges(catridgeSelectedObjet)}
                  <VerticalLine />

                  <Row>
                    <FilterGroup>
                      <FilterContainer>
                        <Filter
                          title="Cartridge:"
                          name="catridgeSelectedId"
                          selectedValue={catridgeSelectedId}
                          options={FlowMeterOfWaterFilters.map(fm => ({
                            label: `${fm.SpotsWaterFilter.position} - ${fm.SpotsWaterFilter.Catridge.name}`,
                            value: fm.id,
                          }))}
                          handleChange={this.cartidgeOnChange}
                        />
                      </FilterContainer>
                    </FilterGroup>
                  </Row>
                </Col>
              </GraphContainer>
            </BG>
            <BG>
              <GraphContainer height={120}>
                <Row>{this.renderBypassFrequency()}</Row>
              </GraphContainer>
            </BG>
          </Col>
        </Row>

        <Row style={{ marginBottom: 5, marginTop: 5 }}>
          <Col lg={60} md={100} sm={100} xs={100} alignItems="start">
            <BG>{this.renderMaxFlowAndPressures()}</BG>
          </Col>
          <Col lg={40} md={100} sm={100} xs={100} alignItems="start">
            <BG>{this.renderPressure()}</BG>
          </Col>
        </Row>

        {this.renderConfigurationModal()}
        <ModalCsvFiles
          minDate={moment(installed_date).toDate()}
          isVisible={modalDatePickerCsv}
          handleDateFrom={value => this.handleDate(value, 'datePickerFromCsv')}
          handleDateTo={value => this.handleDate(value, 'datePickerToCsv')}
          switchModal={() => this.switchModal('modalDatePickerCsv')}
          dateFrom={datePickerFromCsv}
          dateTo={datePickerToCsv}
          changeCsvType={this.changeCsvType}
          csvType={csvType}
          saveChanges={() => {
            this.switchModal('modalDatePickerCsv');
            this.generateWaterFilterCsv();
          }}
          csvFiles={csvFiles}
          csvFilesLoading={csvFilesLoading}
        />

        <ModalDatePicker
          minDate={moment(installed_date).toDate()}
          isVisible={modalDatePickerConsumption}
          handleDateFrom={value => this.handleDate(value, 'datePickerFromConsumption')}
          handleDateTo={value => this.handleDate(value, 'datePickerToConsumption')}
          switchModal={() => this.switchModal('modalDatePickerConsumption')}
          dateFrom={datePickerFromConsumption}
          dateTo={datePickerToConsumption}
          saveChanges={() => {
            this.switchModal('modalDatePickerConsumption');
            this.calculeWaterFilterConsumption();
          }}
        />

        <ModalDatePicker
          minDate={moment(installed_date).toDate()}
          isVisible={modalDatePickerMaxFlowRate}
          handleDateFrom={value => this.handleDate(value, 'datePickerFromMaxFlowRate')}
          handleDateTo={value => this.handleDate(value, 'datePickerToMaxFlowRate')}
          switchModal={() => this.switchModal('modalDatePickerMaxFlowRate')}
          dateFrom={datePickerFromMaxFlowRate}
          dateTo={datePickerToMaxFlowRate}
          saveChanges={() => {
            this.switchModal('modalDatePickerMaxFlowRate');
            this.calculeWaterFilterMaxFlowRate();
          }}
        />

        <ModalDatePicker
          minDate={moment(installed_date).toDate()}
          isVisible={modalDatePickerBypass}
          handleDateFrom={value => this.handleDate(value, 'datePickerFromBypass')}
          handleDateTo={value => this.handleDate(value, 'datePickerToBypass')}
          switchModal={() => this.switchModal('modalDatePickerBypass')}
          dateFrom={datePickerFromBypass}
          dateTo={datePickerToBypass}
          saveChanges={() => {
            this.switchModal('modalDatePickerBypass');
            this.calculeWaterFilterBypass();
          }}
        />

        <ModalDatePicker
          minDate={moment(installed_date).toDate()}
          isVisible={modalDatePickerReport}
          handleDateFrom={value => this.handleDate(value, 'datePickerFromReport')}
          handleDateTo={value => this.handleDate(value, 'datePickerToReport')}
          switchModal={() => this.switchModal('modalDatePickerReport')}
          dateFrom={datePickerFromReport}
          dateTo={datePickerToReport}
          saveChanges={() => {
            this.switchModal('modalDatePickerReport');
            this.generateReport();
          }}
        />

        <Modal
          title="Update Device ID (Serial Number)"
          onClose={() => this.switchModal('modalDeviceId')}
          isVisible={modalDeviceId}
          size="small"
        >
          <Row>
            <Col>
              <Input
                placeholder="Device ID"
                value={new_device_id}
                onChange={this.handleChangeDeviceId}
                style={{ marginBottom: '20px' }}
              />
              <ButtonText
                text="Update"
                theme="primary"
                onClick={() =>
                  setDeviceId({ type: 'water-filter', device_id: new_device_id, location_id })
                }
              />
            </Col>
          </Row>
        </Modal>

        <Modal
          title="Bypass Flow"
          onClose={() => this.switchModal('modalBypass')}
          isVisible={modalBypass}
          size="medium"
        >
          {this.renderBypassConsumptions()}
        </Modal>

        {renderInstructionsModal(
          this.state.modalViewInstructions,
          () => this.switchModal('modalViewInstructions'),
          WatherFilterInstallSteps,
        )}
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    waterFilterCurrentState: state.locations.waterFilterCurrentState,
    waterFilterConsumption: state.locations.waterFilterConsumption,
    waterFilterConsumptionLoading: state.locations.waterFilterConsumptionLoading,
    waterFilterMaxFlowRate: state.locations.waterFilterMaxFlowRate,
    waterFilterMaxFlowRateLoading: state.locations.waterFilterMaxFlowRateLoading,
    waterFilterPressureForFlowRate: state.locations.waterFilterPressureForFlowRate,
    waterFilterPressureForFlowRateLoading: state.locations.waterFilterPressureForFlowRateLoading,
    waterFilterPressureForConsumption: state.locations.waterFilterPressureForConsumption,
    waterFilterPressureForConsumptionLoading:
      state.locations.waterFilterPressureForConsumptionLoading,
    totalConsumptionWaterFilterLoading: state.locations.totalConsumptionWaterFilterLoading,
    waterFiltercurrentStateLoading: state.locations.waterFiltercurrentStateLoading,
    waterFilterTotalConsumption: state.locations.waterFilterTotalConsumption,
    waterFilterBypass: state.locations.waterFilterBypass,
    waterFilterBypassLoading: state.locations.waterFilterBypassLoading,
    csvFiles: state.locations.csvFiles,
    csvFilesLoading: state.locations.csvFilesLoading,
    waterFilterCurrentPressure: state.locations.waterFilterCurrentPressure,
    waterFilterCurrentPressureLoading: state.locations.waterFilterCurrentPressureLoading,
    waterFilterCurrentBypass: state.locations.waterFilterCurrentBypass,
    waterFilterCurrentBypassLoading: state.locations.waterFilterCurrentBypassLoading,
    cartridgeTotalConsumptionWf: state.locations.cartridgeTotalConsumptionWf,
    cartridgeAllTotalConsumptionWf: state.locations.cartridgeAllTotalConsumptionWf,
    cartridgeTotalConsumptionWfLoading: state.locations.cartridgeTotalConsumptionWfLoading,
    cartridgeAllTotalConsumptionWfLoading: state.locations.cartridgeAllTotalConsumptionWfLoading,
    wf_for_pfd: state.locations.wf_for_pfd,
    role_id: state.auth.profile.role_id,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    waterFilterSetInitialState: () => dispatch(waterFilterSetInitialState()),
    getWaterFilterCurrentState: params => dispatch(getWaterFilterCurrentState(params)),
    getWaterFilterCurrentPressure: params => dispatch(getWaterFilterCurrentPressure(params)),
    getWaterFilterConsumption: params => dispatch(getWaterFilterConsumption(params)),
    getWaterFilterMaxFlowRate: params => dispatch(getWaterFilterMaxFlowRate(params)),
    getWaterFilterPressureForConsumption: params =>
      dispatch(getWaterFilterPressureForConsumption(params)),
    getWaterFilterPressureForFlowRate: params =>
      dispatch(getWaterFilterPressureForFlowRate(params)),
    getWaterFilterBypass: params => dispatch(getWaterFilterBypass(params)),
    getTotalConsumptionWaterFilter: params => dispatch(getTotalConsumptionWaterFilter(params)),
    generateWaterFilterCsv: params => dispatch(generateWaterFilterCsv(params)),
    getCsvFiles: params => dispatch(getCsvFiles(params)),
    getWaterFilterCurrentBypass: params => dispatch(getWaterFilterCurrentBypass(params)),
    getTotalConsumptionCartridgeWf: params => dispatch(getTotalConsumptionCartridgeWf(params)),
    getAllTotalConsumptionCartridgeWf: params =>
      dispatch(getAllTotalConsumptionCartridgeWf(params)),
    replaceWaterFilterCartridges: params => dispatch(replaceWaterFilterCartridges(params)),
  };
}

WF.propTypes = {
  active: PropTypes.bool,
  allAlerts: PropTypes.array,
  cartridgeAllTotalConsumptionWf: PropTypes.array,
  cartridgeAllTotalConsumptionWfLoading: PropTypes.bool,
  cartridgeTotalConsumptionWf: PropTypes.array,
  cartridgeTotalConsumptionWfLoading: PropTypes.bool,
  csvFiles: PropTypes.array,
  csvFilesLoading: PropTypes.bool,
  device_id: PropTypes.string,
  fetchAllAlerts: PropTypes.func,
  generateWaterFilterCsv: PropTypes.func,
  getAllTotalConsumptionCartridgeWf: PropTypes.func,
  getCsvFiles: PropTypes.func,
  getTotalConsumptionCartridgeWf: PropTypes.func,
  getTotalConsumptionWaterFilter: PropTypes.func,
  getWaterFilterBypass: PropTypes.func,
  getWaterFilterConsumption: PropTypes.func,
  getWaterFilterCurrentBypass: PropTypes.func,
  getWaterFilterCurrentPressure: PropTypes.func,
  getWaterFilterCurrentState: PropTypes.func,
  getWaterFilterMaxFlowRate: PropTypes.func,
  getWaterFilterPressureForConsumption: PropTypes.func,
  getWaterFilterPressureForFlowRate: PropTypes.func,
  location: PropTypes.object,
  payload: PropTypes.array,
  renderInstructionsModal: PropTypes.func,
  replaceWaterFilterCartridges: PropTypes.func,
  role_id: PropTypes.number,
  setDeviceId: PropTypes.func,
  totalConsumptionWaterFilterLoading: PropTypes.bool,
  waterFilterBypass: PropTypes.array,
  waterFilterBypassLoading: PropTypes.bool,
  waterFilterConsumption: PropTypes.array,
  waterFilterConsumptionLoading: PropTypes.bool,
  waterFilterCurrentBypass: PropTypes.array,
  waterFilterCurrentBypassLoading: PropTypes.bool,
  waterFilterCurrentPressure: PropTypes.array,
  waterFilterCurrentPressureLoading: PropTypes.bool,
  waterFiltercurrentStateLoading: PropTypes.bool,
  waterFilterMaxFlowRate: PropTypes.array,
  waterFilterMaxFlowRateLoading: PropTypes.bool,
  waterFilterPressureForConsumption: PropTypes.array,
  waterFilterPressureForConsumptionLoading: PropTypes.bool,
  waterFilterPressureForFlowRate: PropTypes.array,
  waterFilterPressureForFlowRateLoading: PropTypes.bool,
  waterFilterSetInitialState: PropTypes.func,
  waterFilterTotalConsumption: PropTypes.number,
  wf_for_pfd: PropTypes.bool,
};
export default connect(mapStateToProps, mapDispatchToProps)(WF);
