import React, { Component } from 'react';
import styled from 'styled-components';
import config from 'config';
import { Colors } from 'modules/theme';
import DropZone from 'components/DropZone';
import Table, { THeader, TableTitle, TRow, TableItem, TBody } from 'components/Table';
import H4 from 'components/H4';
import H5 from 'components/H5';
import ButtonText from 'components/ButtonText';
import AlertConfirm from 'components/AlertConfirm';
import Icon from 'components/Icon';
import Line from 'components/Line';
import PropTypes from 'prop-types';

const Container = styled.div``;

const TableContainer = styled.div``;

const ErrorsContainer = styled.div`
  text-align: left;
`;

const ErrorContainer = styled.div`
  flex-direction: row;
  line-height: 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  padding-bottom: 15px;
`;

const LineContainer = styled.div`
  margin-top: 30px;
`;

export default class AccountsForm extends Component {
  state = {
    accounts: [],
    isOpenRedoAlert: false,
    isOpenNextAlert: false,
  };

  submit = () => {
    const { onSubmit } = this.props;
    const { accounts } = this.state;
    const activeTab = 'Locations';
    const accountsApproved = accounts
      .filter(account => !account.errors.length)
      .map(account => ({ ...account, locations: [] }));

    onSubmit({ accounts: accountsApproved }, activeTab);
  };

  renderRodoAlert = () => {
    const { isOpenRedoAlert } = this.state;

    if (!isOpenRedoAlert) {
      return null;
    }

    return (
      <AlertConfirm
        title="Redo the import"
        onCancel={() => this.setState({ isOpenRedoAlert: false })}
        onSuccess={() => this.setState({ accounts: [], isOpenRedoAlert: false, hasErrors: false })}
        successText="Remove"
        isVisible={isOpenRedoAlert}
      >
        <H4 style={{ textAlign: 'center' }}>Are you sure you want to redo the import?</H4>
      </AlertConfirm>
    );
  };

  renderNextAlert = () => {
    const { isOpenNextAlert } = this.state;

    return (
      <AlertConfirm
        title="Alert"
        onCancel={() => this.setState({ isOpenNextAlert: false })}
        onSuccess={() => {
          this.setState({ isOpenNextAlert: false }, () => {
            this.submit();
          });
        }}
        successText="Next"
        isVisible={isOpenNextAlert}
      >
        <H4 style={{ textAlign: 'center' }}>Are you sure you want continue with errors?</H4>
      </AlertConfirm>
    );
  };

  renderAccount = (account, key) => (
    <TRow key={key}>
      <TableItem style={{ minHeight: 130 }} width="15%" isComponent>
        <H5 style={{ overflow: 'hidden' }}>{account.name}</H5>
      </TableItem>
      <TableItem style={{ minHeight: 130 }} width="20%" isComponent>
        <H5 style={{ overflow: 'hidden' }}>
          {`${account.address_line}${account.address_line_1 && ' '}${account.address_line_1},`}
        </H5>
        <H5 style={{ overflow: 'hidden' }}>{`${account.zip_code}`}</H5>
      </TableItem>
      <TableItem style={{ minHeight: 130 }} width="15%" isComponent>
        <H5 style={{ overflow: 'hidden' }}>{account.phone_number}</H5>
      </TableItem>
      <TableItem style={{ minHeight: 130 }} width="15%" isComponent>
        <H5 style={{ overflow: 'hidden' }}>
          {`${account.contact_first_name} ${account.contact_last_name}`}
        </H5>
      </TableItem>
      <TableItem style={{ minHeight: 130 }} width="15%" isComponent>
        <H5 style={{ overflow: 'hidden' }}>{account.contact_email}</H5>
        <H5 style={{ overflow: 'hidden' }}>{account.contact_phone_number}</H5>
      </TableItem>
      <TableItem style={{ minHeight: 130 }} width="20%" isComponent>
        {account.errors && account.errors.length ? (
          <ErrorsContainer>
            {account.errors.map((error, key) => (
              <ErrorContainer key={key}>
                <Icon
                  name="exclamation-circle"
                  color={Colors.red}
                  customStyle={{ marginRight: '10px' }}
                />
                {error}
              </ErrorContainer>
            ))}
          </ErrorsContainer>
        ) : (
          <Icon name="check-circle" fontSize={25} color={Colors.green} />
        )}
      </TableItem>
    </TRow>
  );

  handleResponse = accounts => {
    const hasErrors = accounts.findIndex(account => account.errors && account.errors.length) !== -1;
    this.setState({ accounts, hasErrors });
  };

  render() {
    const { accounts, hasErrors } = this.state;
    const { back, token, dropzoneTitle } = this.props;
    const url = `${config.BASE_URL}/accounts/import`;

    return (
      <Container>
        {accounts.length ? (
          <TableContainer>
            <ButtonContainer>
              <ButtonText
                theme="text"
                icon="redo"
                text="Redo Import"
                onClick={() => this.setState({ isOpenRedoAlert: true })}
              />
            </ButtonContainer>
            <Table>
              <THeader>
                <TableTitle width="15%">Name</TableTitle>
                <TableTitle width="20%">Address</TableTitle>
                <TableTitle width="15%">Phone #</TableTitle>
                <TableTitle width="15%">Admin</TableTitle>
                <TableTitle width="15%">Contact</TableTitle>
                <TableTitle width="20%">Status</TableTitle>
              </THeader>
              <TBody>{accounts.map((account, key) => this.renderAccount(account, key))}</TBody>
            </Table>
          </TableContainer>
        ) : (
          <DropZone
            token={token}
            url={url}
            handleResponse={this.handleResponse}
            link={config.ACCOUNTS_CSV_EXAMPLE_URL}
            title={dropzoneTitle}
          />
        )}
        <LineContainer>
          <Line />
        </LineContainer>
        <ButtonContainer>
          <ButtonText
            text="Back"
            type="button"
            theme="default"
            customStyles={{ marginTop: '20px', marginRight: '20px' }}
            onClick={back}
          />
          <ButtonText
            text={accounts.length ? `Next${hasErrors ? ', with errors' : ''}` : 'Skip'}
            theme="primary"
            customStyles={{ marginTop: '20px' }}
            onClick={() => (hasErrors ? this.setState({ isOpenNextAlert: true }) : this.submit())}
          />
        </ButtonContainer>
        {this.renderRodoAlert()}
        {this.renderNextAlert()}
      </Container>
    );
  }
}

AccountsForm.propTypes = {
  back: PropTypes.func,
  dropzoneTitle: PropTypes.string,
  onSubmit: PropTypes.func,
  token: PropTypes.string,
};
