import React, { Component } from 'react';
import styled from 'styled-components';
import Sidebar from './Sidebar';

const PageWithMenu = styled.div`
  display: flex;
`;

const PageContent = styled.div`
  width: calc(100% - 250px);
  height: calc(100vh - 50px);
  @media (max-width: 700px) {
    width: calc(100% - 60px);
  }
`;

export default class MenuPage extends Component {
  render() {
    const { role_code, path } = this.props;

    return (
      <PageWithMenu>
        <Sidebar path={path} roleCode={role_code} />
        <PageContent>{this.props.children}</PageContent>
      </PageWithMenu>
    );
  }
}
