import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getLibraries, deleteLibrary } from 'actions/index';
import PropTypes from 'prop-types';
import LibraryListView from './LibraryListView';

class LibraryListContainer extends Component {
  componentDidMount() {
    const { fetchLibraries } = this.props;

    fetchLibraries();
  }

  render() {
    const { list, pages, currentPage, loading, fetchLibraries, removeLibrary } = this.props;

    return (
      <LibraryListView
        list={list}
        pages={pages}
        currentPage={currentPage}
        loading={loading}
        fetchLibraries={fetchLibraries}
        removeLibrary={removeLibrary}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    list: state.libraries.list,
    pages: state.libraries.pages,
    currentPage: state.libraries.currentPage,
    loading: state.libraries.loading,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    fetchLibraries: params => dispatch(getLibraries(params)),
    removeLibrary: (id, reference, type) => dispatch(deleteLibrary(id, reference, type)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(LibraryListContainer);

LibraryListContainer.propTypes = {
  currentPage: PropTypes.number,
  fetchLibraries: PropTypes.func,
  list: PropTypes.array,
  loading: PropTypes.bool,
  pages: PropTypes.number,
  removeLibrary: PropTypes.func,
};
