import React, { Component } from 'react';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Colors } from 'modules/theme';
import { push } from 'modules/history';
import config from 'config';
import Input from 'components/InputText';
import Textarea from 'components/Textarea';
import ButtonText from 'components/ButtonText';
import H2 from 'components/H2';
import H4 from 'components/H4';
import Icon from 'components/Icon';
import DropZone from 'components/DropZone';
import ImageUploaded from 'components/ImageUploaded';
import FormError from 'components/FormError';
import PropTypes from 'prop-types';

const Container = styled.div`
  margin: 30px;
`;

const InputContainer = styled.div`
  margin: 20px 0px;
`;

const ButtonContainer = styled.div`
  margin-top: 25px;
  margin-bottom: 25px;
  float: left;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const Column = styled.div`
  flex-direction: column;
`;

const Box = styled.div`
  border: 1px solid #e4e4e4;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 10px;
  width: 100%;
`;

const url = `${config.BASE_URL}/uploads`;

export default class BrineDrumForm extends Component {
  submit = values => {
    const { onSubmit } = this.props;

    onSubmit(values);
  };

  render() {
    const { initialValues, token, title } = this.props;

    return (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          description: Yup.string().required('Required'),
          fair_salt_level: Yup.number()
            .integer()
            .min(0)
            .required('Required'),
          good_salt_level: Yup.number()
            .integer()
            .min(0)
            .required('Required'),
          name: Yup.string().required('Required'),
          poor_salt_level: Yup.number()
            .integer()
            .min(0)
            .required('Required'),
        })}
        onSubmit={this.submit}
      >
        {({ values, errors, touched, handleChange, handleBlur, isValid, setFieldValue }) => (
          <Container>
            <Form>
              <Icon onClick={() => push('/libraries')} name="chevron-left" color={Colors.blue} />
              <H2 color={Colors.black}>{title}</H2>
              <Row>
                <Column style={{ marginRight: '30px', width: '33.33%' }}>
                  <InputContainer>
                    <H4 inputLabel color={Colors.lightBlue}>
                      Brine Drum Model
                    </H4>
                    <Input
                      placeholder="Brine Drum Model"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      icon="bookmark"
                    />
                    <FormError text={errors.name && touched.name && errors.name} />
                  </InputContainer>
                </Column>
              </Row>
              <Row>
                <Column style={{ marginRight: '30px', width: '33.33%' }}>
                  <H4 inputLabel color={Colors.gray8}>
                    {'Poor Salt Level i.e. < Value'}
                  </H4>
                  <Input
                    name="poor_salt_level"
                    type="number"
                    value={values.poor_salt_level}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    icon="hashtag"
                  />
                  <FormError
                    text={
                      errors.poor_salt_level && touched.poor_salt_level && errors.poor_salt_level
                    }
                  />
                  <H4 inputLabel color={Colors.gray8}>
                    {'Fair Salt Level i.e. > Value'}
                  </H4>
                  <Input
                    name="fair_salt_level"
                    type="number"
                    value={values.fair_salt_level}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    icon="hashtag"
                  />
                  <FormError
                    text={
                      errors.fair_salt_level && touched.fair_salt_level && errors.fair_salt_level
                    }
                  />
                  <H4 inputLabel color={Colors.gray8}>
                    {'Good Salt Level i.e. > Value'}
                  </H4>
                  <Input
                    name="good_salt_level"
                    type="number"
                    value={values.good_salt_level}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    icon="hashtag"
                  />
                  <FormError
                    text={
                      errors.good_salt_level && touched.good_salt_level && errors.good_salt_level
                    }
                  />
                </Column>
                <Column style={{ width: '33.33%', paddingRight: '60px' }}>
                  <H4 inputLabel color={Colors.lightBlue}>
                    Salt Level Guide
                  </H4>
                  <Row>
                    <Box>
                      <H4 inputLabel color={Colors.gray8}>
                        <Icon name="circle" color={Colors.red} customStyle={{ marginRight: 10 }} />
                        Poor
                      </H4>
                      <H4 inputLabel color={Colors.gray8}>
                        <Icon
                          name="circle"
                          color={Colors.yellow}
                          customStyle={{ marginRight: 10 }}
                        />
                        Fair
                      </H4>
                      <H4 inputLabel color={Colors.gray8}>
                        <Icon
                          name="circle"
                          color={Colors.green}
                          customStyle={{ marginRight: 10 }}
                        />
                        Good
                      </H4>
                    </Box>
                  </Row>
                </Column>
              </Row>

              <Row>
                <Column style={{ marginRight: '30px', paddingRight: '30px', width: '66.66%' }}>
                  <H4 inputLabel color={Colors.gray8}>
                    Description
                  </H4>
                  <InputContainer>
                    <Textarea
                      value={values.description}
                      onChange={value => setFieldValue('description', value)}
                      higlights={[]}
                      onBlur={handleBlur}
                    />

                    <FormError
                      text={errors.description && touched.description && errors.description}
                    />
                  </InputContainer>
                </Column>
              </Row>
              <Row>
                <Column style={{ width: '33.33%', paddingRight: '30px' }}>
                  <H4 inputLabel color={Colors.black}>
                    Photo
                  </H4>

                  {values.photo ? (
                    <ImageUploaded
                      image={values.photo}
                      onClick={() => setFieldValue('photo', null)}
                    />
                  ) : (
                    <DropZone
                      folder="brine-drum"
                      title="Choose a photo"
                      height="100px"
                      token={token}
                      handleResponse={({ url }) => setFieldValue('photo', url)}
                      url={url}
                    />
                  )}
                </Column>
              </Row>
              <Row>
                <ButtonContainer>
                  <ButtonText text="Save" theme="primary" type="submit" disabled={!isValid} />
                </ButtonContainer>
              </Row>
            </Form>
          </Container>
        )}
      </Formik>
    );
  }
}

BrineDrumForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  title: PropTypes.string,
  token: PropTypes.string,
};
