import React, { Component } from 'react';
import styled from 'styled-components';
import { Colors } from 'modules/theme';
import { push } from 'modules/history';
import { LibraryTypes, LibraryConfigTypes, LibrarySensorTypes } from 'constants/index';
import InputSearch from 'components/InputSearch';
import H4 from 'components/H4';
import ButtonText from 'components/ButtonText';
import PaginationButtons from 'components/PaginationButtons';
import Table, { THeader, TableTitle, TRow, TableItem, TBody } from 'components/Table';
import Filter, { FilterGroup, FilterContainer } from 'components/Filter';
import Dropdown from 'components/Dropdown';
import Modal from 'components/Modal';
import SelectableButton from 'components/SelectableButton';
import AlertConfirm from 'components/AlertConfirm';
import ListEmpty from 'components/ListEmpty';
import Loader from 'components/Loader';
import PropTypes from 'prop-types';

const Container = styled.div``;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 20px;
`;

const SearchContainer = styled.div`
  padding: 10px;
  flex-direction: column;
`;

const ButtonContainer = styled.div`
  flex-direction: column;
  float: right;
`;

const ButtonGroup = styled.div`
  padding: 10px;
  flex-direction: column;
  float: right;
`;

const TableContainer = styled.div`
  padding-bottom: 50px;
  margin-bottom: 50px;
`;

const Footer = styled.div`
  position: fixed;
  bottom: 0;
  padding-left: 25px;
  padding-bottom: 25px;
  background-color: ${Colors.white};
  width: 100%;
`;

const ItemRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const ItemContainer = styled.div`
  flex-direction: column;
  padding: 15px;
  width: ${props => props.width || '50%'};
  @media (max-width: 768px) {
    width: 100% !important;
  }
`;

export default class LibraryListView extends Component {
  state = {
    dropdownOpenId: null,
    librarySelected: null,
    isOpenModal: false,
    isOpenAlert: false,
    search: '',
    type: '',
  };

  filterOnChange = event => {
    const { name, value } = event.target;

    this.setState({ [name]: value }, () => {
      const { fetchLibraries } = this.props;
      const { search, type } = this.state;

      fetchLibraries({ search, type });
    });
  };

  searchOnChange = search => {
    this.setState({ search }, () => {
      const { fetchLibraries } = this.props;
      const { search, type } = this.state;

      fetchLibraries({ search, type });
    });
  };

  switchDropdown = key => {
    const { dropdownOpenId } = this.state;
    const id = dropdownOpenId !== key ? key : null;

    this.setState({ dropdownOpenId: id });
  };

  switchModal = () => {
    const { isOpenModal } = this.state;

    this.setState({ isOpenModal: !isOpenModal });
  };

  switchAlert = (librarySelected = null) => {
    const { isOpenAlert } = this.state;

    this.setState({ isOpenAlert: !isOpenAlert, librarySelected });
  };

  editLibrary = ({ reference, type_code }) => {
    const isConfigType = LibraryConfigTypes.filter(cls => type_code.includes(cls.value)).length > 0;
    const isSensorType = LibrarySensorTypes.filter(cls => type_code.includes(cls.value)).length > 0;

    if (isConfigType) {
      push(`/libraries/edit/configuration/${reference}/${type_code}`);
    } else if (isSensorType) {
      push(`/libraries/edit/sensor/${reference}/${type_code}`);
    } else {
      push(`/libraries/edit/${reference}/${type_code}`);
    }
  };

  remove = () => {
    const { removeLibrary } = this.props;
    const {
      librarySelected: { id, reference, type_code },
    } = this.state;

    removeLibrary(id, reference, type_code);
    this.switchAlert();
  };

  renderItem = (item, key) => (
    <TRow key={key}>
      <TableItem width="50%" color={Colors.lightBlue} textAlign="left">
        {item.name}
      </TableItem>
      <TableItem width="45%" textAlign="left">
        {item.type}
      </TableItem>
      <TableItem width="5%" isComponent>
        <Dropdown
          icon="ellipsis-v"
          options={[
            {
              title: 'Edit',
              icon: 'pencil-alt',
              color: Colors.green,
              action: () => this.editLibrary(item),
            },
            {
              title: 'Remove',
              icon: 'trash',
              color: Colors.red,
              action: () => this.switchAlert(item),
            },
          ]}
          isDropdownOpen={this.state.dropdownOpenId === key}
          onClickDropdownButton={() => this.switchDropdown(key)}
        />
      </TableItem>
    </TRow>
  );

  renderAlertConfirm = () => {
    const { isOpenAlert, librarySelected } = this.state;

    return (
      <AlertConfirm
        title="Remove library"
        onCancel={() => this.switchAlert()}
        onSuccess={this.remove}
        successText="Remove"
        isVisible={isOpenAlert}
      >
        <H4 style={{ textAlign: 'center' }}>
          {`Are you sure that can remove the "${
            librarySelected ? librarySelected.name : ''
          }" library`}
        </H4>
      </AlertConfirm>
    );
  };

  goToPage = page => {
    this.setState({ isOpenModal: false }, () => {
      push(`/libraries/create/${page}`);
    });
  };

  renderModal = () => {
    const { isOpenModal } = this.state;

    return (
      <Modal
        title="What you would like to add?"
        onClose={this.switchModal}
        isVisible={isOpenModal}
        size="small"
      >
        <ItemRow>
          <ItemContainer>
            <SelectableButton action={() => this.goToPage('water-filter')} text="Filter System" />
          </ItemContainer>
          <ItemContainer>
            <SelectableButton
              action={() => this.goToPage('reverse-osmosis-filter')}
              text="Reverse Osmosis"
            />
          </ItemContainer>
        </ItemRow>
        <ItemRow>
          <ItemContainer>
            <SelectableButton
              action={() => this.goToPage('replacement-filter')}
              text="Replacement Filter"
            />
          </ItemContainer>
          <ItemContainer>
            <SelectableButton
              action={() => this.goToPage('device-configuration')}
              text="Device configuration"
            />
          </ItemContainer>
        </ItemRow>
        <ItemRow>
          <ItemContainer>
            <SelectableButton action={() => this.goToPage('softener')} text="Softener" />
          </ItemContainer>
          <ItemContainer>
            <SelectableButton action={() => this.goToPage('brine-drum')} text="Brine Drum" />
          </ItemContainer>
        </ItemRow>
        <ItemRow>
          <ItemContainer>
            <SelectableButton action={() => this.goToPage('ro-membrane')} text="RO Membrane" />
          </ItemContainer>
          <ItemContainer>
            <SelectableButton action={() => this.goToPage('catridge')} text="Cartridge" />
          </ItemContainer>
        </ItemRow>
        <ItemContainer>
          <SelectableButton action={() => this.goToPage('sensor')} text="Sensor" />
        </ItemContainer>
        <ItemRow />
      </Modal>
    );
  };

  render() {
    const { list, currentPage, pages, loading, fetchLibraries } = this.props;
    const { type, search } = this.state;

    return (
      <Container>
        <Header>
          <SearchContainer>
            <InputSearch onChange={this.searchOnChange} value={search} name="search" />
          </SearchContainer>
          <ButtonContainer>
            <ButtonGroup>
              <ButtonText theme="primary" text="New" icon="plus" onClick={this.switchModal} />
            </ButtonGroup>
            {/*
              <ButtonGroup>
                <ButtonText theme="default" text="Settings" icon="sliders-h" />
              </ButtonGroup>
            */}
          </ButtonContainer>
        </Header>
        <FilterGroup>
          <FilterContainer>
            <Filter
              title="Device Type:"
              selectedValue={type}
              name="type"
              handleChange={this.filterOnChange}
              options={[{ label: 'All', value: '' }, ...LibraryTypes]}
            />
          </FilterContainer>
        </FilterGroup>
        <TableContainer>
          <Table>
            <THeader>
              <TableTitle width="50%">Name</TableTitle>
              <TableTitle width="45%">Type</TableTitle>
              <TableTitle width="5%" />
            </THeader>
            <TBody>
              {loading ? (
                <Loader size={100} block />
              ) : (
                list.map((item, key) => this.renderItem(item, key))
              )}
            </TBody>
          </Table>
          {(!list || !list.length) && !loading && (
            <ListEmpty message="There are currently no libraries" />
          )}
        </TableContainer>
        <Footer>
          <PaginationButtons
            currentPage={currentPage}
            changePage={page => fetchLibraries({ page, search, type })}
            totalPages={pages}
          />
        </Footer>
        {this.renderModal()}
        {this.renderAlertConfirm()}
      </Container>
    );
  }
}

LibraryListView.propTypes = {
  currentPage: PropTypes.number,
  fetchLibraries: PropTypes.func,
  list: PropTypes.array,
  loading: PropTypes.bool,
  pages: PropTypes.number,
  removeLibrary: PropTypes.func,
};
