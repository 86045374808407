import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Colors } from 'modules/theme';

const Container = styled.div`
  position: relative;
`;

const SelectComponent = styled.select`
  border: 1px solid #e1edf3;
  background-color: ${Colors.white};
  width: 100%;
  height: 44px;
  box-sizing: border-box;
  border-radius: ${props => props.borderRadius || 5}px;
  font-size: ${props => props.fontSize || 18}px;
  line-height: 17px;
  color: ${Colors.gray};
  padding: 0px 15px;
  outline-style: none;
  line-height: 30px;
  ::placeholder {
    font-size: ${props => props.fontSize || 18}px;
    color: ${props => props.color || Colors.gray};
  }

  -webkit-appearance: none;
  -moz-appearance: none;

  border-color: ${props => (props.hasError ? Colors.red : props.borderColor || Colors.gray2)};

  ::option {
    display: flex;
    white-space: pre;
    min-height: 20px;
  }
`;

const Icon = styled.i`
  position: absolute;
  top: 15px;
  right: 18px;
  color: ${Colors.blue};
  font-size: 12px;
`;

const Select = props => {
  const { placeholder, options } = props;

  return (
    <Container>
      <SelectComponent {...props} placeholder={placeholder}>
        <option value="" hidden>
          {placeholder || 'Options'}
        </option>
        {options.map(({ label, value }, key) => (
          <option value={value} key={key}>
            {label}
          </option>
        ))}
      </SelectComponent>
      <Icon className="fas fa-chevron-down" />
    </Container>
  );
};

Select.propTypes = {
  options: PropTypes.array,
  placeholder: PropTypes.string,
};

Select.defaultProps = {
  options: [],
};

export default Select;
