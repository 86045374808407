import React, { Component } from 'react';
import styled from 'styled-components';
import { Colors } from 'modules/theme';

const Container = styled.p`
  height: 1px;
  width: 100%;
  margin: 0;
  background-color: ${Colors.gray2};
`;

class Line extends Component {
  render() {
    return <Container style={this.props.style} />;
  }
}

export default Line;
