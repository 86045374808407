import React, { Component } from 'react';
import { connect } from 'react-redux';
import { recoverPassword } from 'actions/index';
import PropTypes from 'prop-types';
import RecoverPasswordView from './RecoverPasswordView';

class RecoverPasswordContainer extends Component {
  recoverPassword = email => {
    const { onRecoverPassword } = this.props;
    onRecoverPassword(email);
  };

  render() {
    return (
      <RecoverPasswordView recoverPassword={this.recoverPassword} loading={this.props.loading} />
    );
  }
}

function mapStateToProps() {
  return {
    // loading: state.loginReducer.loading,
    loading: false,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    onRecoverPassword: email => dispatch(recoverPassword(email)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(RecoverPasswordContainer);

RecoverPasswordContainer.propTypes = {
  loading: PropTypes.bool,
  onRecoverPassword: PropTypes.func,
};
