import React, { Component } from 'react';
import { connect } from 'react-redux';
import history from 'modules/history';
import { resetPassword } from 'actions/index';
import PropTypes from 'prop-types';
import ResetPasswordView from './ResetPasswordView';

class ResetPasswordContainer extends Component {
  resetPassword = password => {
    const { onResetPassword } = this.props;
    const {
      location: { query },
    } = history;
    onResetPassword(query.token_pass_recovery, password);
  };

  render() {
    return <ResetPasswordView resetPassword={this.resetPassword} loading={this.props.loading} />;
  }
}

function mapStateToProps() {
  return {
    // loading: state.loginReducer.loading,
    loading: false,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    onResetPassword: (token_pass_recovery, password) =>
      dispatch(resetPassword(token_pass_recovery, password)),
  };
}

ResetPasswordContainer.propTypes = {
  loading: PropTypes.bool,
  onResetPassword: PropTypes.func,
};
export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordContainer);
