import { REHYDRATE } from 'redux-persist/lib/constants';
import { handleActions } from 'modules/helpers';

import { ActionTypes } from 'constants/index';

export const appState = {
  list: [],
  listForFilter: [],
  item: null,
  pages: 0,
  currentPage: 0,
  loading: false,
  csvFiles: [],
  csvFilesLoading: false,
  // water filter dynamo
  waterFilterCurrentState: { flow_3: null },
  waterFilterConsumption: { consumption: [], last_updated: null, total_flow: 0 },
  waterFilterTotalConsumption: 0,
  waterFilterConsumptionLoading: false,
  waterFilterMaxFlowRate: { consumption: [] },
  waterFilterMaxFlowRateLoading: false,
  waterFilterPressureForFlowRate: { pressures: [] },
  waterFilterPressureLoadingForFlowRate: false,
  waterFilterPressureForConsumption: { pressures: [] },
  waterFilterPressureLoadingForConsumption: false,
  totalConsumptionWaterFilterLoading: false,
  waterFilterCurrentStateLoading: false,
  waterFilterBypass: { consumption: [], last_updated: null, total_flow: 0 },
  waterFilterBypassLoading: false,
  consumptionFromInstallWf: 0,
  consumptionFromInstallWfLoading: false,
  waterFilterCurrentPressure: [],
  waterFilterCurrentPressureLoading: false,
  waterFilterCurrentBypass: null,
  waterFilterCurrentBypassLoading: false,
  cartridgeTotalConsumptionWf: null,
  cartridgeTotalConsumptionWfLoading: false,
  cartridgeAllTotalConsumptionWf: null,
  cartridgeAllTotalConsumptionWfLoading: false,
  replaceWaterFilterCartridgeLoading: false,
  wf_for_pfd: false,

  // reverse osmosis dynamo
  reverseOsmosisCurrentState: { flow_3: 0, psi_1: null, psi_2: null },
  reverseOsmosisConsumption: { consumption: [], last_updated: null, total_flow: 0 },
  reverseOsmosisTotalConsumption: 0,
  reverseOsmosisConsumptionLoading: false,
  reverseOsmosisCurrentStateLoading: false,
  reverseOsmosisWaste: { waste: [] },
  reverseOsmosisWasteLoading: false,
  reverseOsmosisTds: { tds: [], lastHour: 0 },
  reverseOsmosisTdsLoading: false,
  consumptionFromMembraneChangeRo: 0,
  consumptionFromMembraneChangeRoLoading: false,
  cartridgeTotalConsumptionRo: null,
  cartridgeTotalConsumptionRoLoading: false,
  cartridgeAllTotalConsumptionRo: null,
  cartridgeAllTotalConsumptionRoLoading: false,
  replaceReverseOsmosisCartridgeLoading: false,
  reverseOsmosisBypass: { consumption: [], last_updated: null, total_flow: 0 },
  reverseOsmosisBypassLoading: false,
  reverseOsmosisCurrentBypass: null,
  reverseOsmosisCurrentBypassLoading: false,
  ro_for_pfd: false,
  reverseOsmosisCurrentPressure: { inletPressure: null, tankPressure: null, systemPressure: null },
  reverseOsmosisCurrentPressureLoading: false,
  reverseOsmosisCurrentTemperature: null,
  reverseOsmosisCurrentTemperatureLoading: false,
  // softener dynamo
  softenerCurrentState: { flow_2: null, distance: 0 },
  softenerCurrentStateLoading: false,
  softenerConsumption: { consumption: [], last_updated: null, total_flow: 0 },
  softenerConsumptionLoading: false,
  softenerPressure: { pressures: [] },
  softenerPressureLoading: false,
  softenerBypass: { consumption: [], last_updated: null, total_flow: 0 },
  softenerBypassLoading: false,
  softenerWaste: { waste: [] },
  softenerWasteLoading: false,
  consumptionFromInstallSo: 0,
  consumptionFromInstallSoLoading: false,
  softenerCurrentPressure: 0,
  softenerCurrentPressureLoading: false,
  softenerCurrentBypass: null,
  softenerCurrentBypassLoading: false,
};

export default {
  locations: handleActions(
    {
      [REHYDRATE]: draft => {
        draft.list = [];
      },
      [ActionTypes.GET_LOCATIONS]: draft => {
        draft.loading = true;
      },
      [ActionTypes.GET_LOCATIONS_SUCCESS]: (draft, { payload: { rows, pages, currentPage } }) => {
        draft.list = rows;
        draft.pages = pages;
        draft.currentPage = currentPage;
        draft.loading = false;
      },
      [ActionTypes.GET_LOCATIONS_FAILURE]: draft => {
        draft.loading = false;
      },
      [ActionTypes.GET_CSV_FILES]: draft => {
        draft.csvFilesLoading = true;
        draft.csvFiles = [];
      },
      [ActionTypes.GET_CSV_FILES_SUCCESS]: (draft, { payload: { csvFiles } }) => {
        draft.csvFilesLoading = false;
        draft.csvFiles = csvFiles;
      },
      [ActionTypes.GET_CSV_FILES_FAILURE]: draft => {
        draft.csvFilesLoading = false;
      },
      [ActionTypes.GET_LOCATION]: draft => {
        draft.item = null;
        draft.loading = true;
        // waterFilter dyamo
        draft.waterFilterCurrentState = appState.waterFilterCurrentState;
        draft.waterFilterConsumption = appState.waterFilterConsumption;
        draft.waterFilterTotalConsumption = appState.waterFilterTotalConsumption;
        draft.waterFilterConsumptionLoading = appState.waterFilterConsumptionLoading;
        draft.waterFilterMaxFlowRate = appState.waterFilterMaxFlowRate;
        draft.waterFilterMaxFlowRateLoading = appState.waterFilterMaxFlowRateLoading;
        draft.waterFilterPressureForFlowRate = appState.waterFilterPressureForFlowRate;
        draft.waterFilterPressureLoadingForFlowRate =
          appState.waterFilterPressureLoadingForFlowRate;
        draft.waterFilterPressureForConsumption = appState.waterFilterPressureForConsumption;
        draft.waterFilterPressureLoadingForConsumption =
          appState.waterFilterPressureLoadingForConsumption;
        draft.totalConsumptionWaterFilterLoading = appState.totalConsumptionWaterFilterLoading;
        draft.waterFilterCurrentStateLoading = appState.waterFilterCurrentStateLoading;
        draft.waterFilterCurrentPressure = appState.waterFilterCurrentPressure;
        draft.waterFilterCurrentPressureLoading = appState.waterFilterCurrentPressureLoading;
        draft.waterFilterCurrentBypass = appState.waterFilterCurrentBypass;
        draft.waterFilterCurrentBypassLoading = appState.waterFilterCurrentBypassLoading;
        draft.cartridgeTotalConsumptionWf = appState.cartridgeTotalConsumptionWf;
        draft.cartridgeTotalConsumptionWfLoading = appState.cartridgeTotalConsumptionWfLoading;
        draft.cartridgeAllTotalConsumptionWf = appState.cartridgeAllTotalConsumptionWf;
        draft.cartridgeAllTotalConsumptionWfLoading =
          appState.cartridgeAllTotalConsumptionWfLoading;
        // reverse osmosis dynamo
        draft.reverseOsmosisCurrentState = appState.reverseOsmosisCurrentState;
        draft.reverseOsmosisConsumption = appState.reverseOsmosisConsumption;
        draft.reverseOsmosisTotalConsumption = appState.reverseOsmosisTotalConsumption;
        draft.reverseOsmosisConsumptionLoading = appState.reverseOsmosisConsumptionLoading;
        draft.reverseOsmosisCurrentStateLoading = appState.reverseOsmosisCurrentStateLoading;
        draft.reverseOsmosisWaste = appState.reverseOsmosisWaste;
        draft.reverseOsmosisWasteLoading = appState.reverseOsmosisWasteLoading;
        draft.reverseOsmosisTds = appState.reverseOsmosisTds;
        draft.reverseOsmosisTdsLoading = appState.reverseOsmosisTdsLoading;
        draft.cartridgeTotalConsumptionRo = appState.cartridgeTotalConsumptionRo;
        draft.cartridgeTotalConsumptionRoLoading = appState.cartridgeTotalConsumptionRoLoading;
        // softener dynamo
        draft.softenerCurrentState = appState.softenerCurrentState;
        draft.softenerCurrentStateLoading = appState.softenerCurrentStateLoading;
        draft.softenerConsumption = appState.softenerConsumption;
        draft.softenerConsumptionLoading = appState.softenerConsumptionLoading;
        draft.softenerPressure = appState.softenerPressure;
        draft.softenerPressureLoading = appState.softenerPressureLoading;
        draft.softenerWaste = appState.softenerWaste;
        draft.softenerWasteLoading = appState.softenerWasteLoading;
        draft.softenerCurrentPressure = appState.softenerCurrentPressure;
        draft.softenerCurrentPressureLoading = appState.softenerCurrentPressureLoading;
        draft.softenerCurrentBypass = appState.softenerCurrentBypass;
        draft.softenerCurrentBypassLoading = appState.softenerCurrentBypassLoading;
      },
      [ActionTypes.GET_LOCATION_SUCCESS]: (draft, { payload }) => {
        draft.item = payload;
        draft.loading = false;
      },
      [ActionTypes.GET_LOCATION_FAILURE]: draft => {
        draft.loading = false;
      },
      [ActionTypes.GET_LOCATIONS_FOR_FILTER]: draft => {
        draft.loading = true;
      },
      [ActionTypes.GET_LOCATIONS_FOR_FILTER_SUCCESS]: (draft, { payload }) => {
        draft.listForFilter = payload;
        draft.loading = false;
      },
      [ActionTypes.GET_LOCATIONS_FOR_FILTER_FAILURE]: draft => {
        draft.loading = false;
      },
      [ActionTypes.EDIT_LOCATION]: draft => {
        draft.loading = true;
      },
      [ActionTypes.EDIT_LOCATION_RESOLVED]: draft => {
        draft.loading = false;
      },
      [ActionTypes.DELETE_LOCATION]: draft => {
        draft.loading = true;
      },
      [ActionTypes.DELETE_LOCATION_SUCCESS]: (draft, { payload: { id } }) => {
        draft.list = draft.list.filter(item => item.id !== id);
        draft.loading = false;
      },
      [ActionTypes.DELETE_LOCATION_FAILURE]: draft => {
        draft.loading = false;
      },
      [ActionTypes.CREATE_LOCATION]: draft => {
        draft.loading = true;
      },
      [ActionTypes.CREATE_LOCATION_RESOLVED]: draft => {
        draft.loading = false;
      },

      // water filter dynamo
      [ActionTypes.GET_WATER_FILTER_CURRENT_STATE]: draft => {
        draft.waterFilterCurrentState = appState.waterFilterCurrentState;
        draft.waterFilterCurrentStateLoading = true;
      },
      [ActionTypes.GET_WATER_FILTER_CURRENT_STATE_SUCCESS]: (draft, { payload }) => {
        draft.waterFilterCurrentState = payload || appState.waterFilterCurrentState;
        draft.waterFilterCurrentStateLoading = false;
      },
      [ActionTypes.GET_WATER_FILTER_CURRENT_STATE_FAILURE]: draft => {
        draft.waterFilterCurrentStateLoading = false;
      },
      [ActionTypes.GET_WATER_FILTER_CONSUMPTION]: draft => {
        draft.waterFilterConsumptionLoading = true;
        draft.wf_for_pfd = false;
      },
      [ActionTypes.GET_WATER_FILTER_CONSUMPTION_SUCCESS]: (draft, { payload }) => {
        draft.waterFilterConsumption = payload;
        draft.waterFilterConsumptionLoading = false;
      },
      [ActionTypes.GET_WATER_FILTER_CONSUMPTION_FAILURE]: draft => {
        draft.waterFilterConsumptionLoading = false;
      },
      [ActionTypes.GET_WATER_FILTER_MAX_FLOW_RATE]: draft => {
        draft.waterFilterMaxFlowRateLoading = true;
      },
      [ActionTypes.GET_WATER_FILTER_MAX_FLOW_RATE_SUCCESS]: (draft, { payload }) => {
        draft.waterFilterMaxFlowRate = payload;
        draft.waterFilterMaxFlowRateLoading = false;
      },
      [ActionTypes.GET_WATER_FILTER_MAX_FLOW_RATE_FAILURE]: draft => {
        draft.waterFilterMaxFlowRateLoading = false;
      },
      [ActionTypes.GET_WATER_FILTER_PRESSURE_FOR_FLOW_RATE]: draft => {
        draft.waterFilterPressureForFlowRateLoading = true;
        draft.waterFilterPressureForFlowRate = appState.waterFilterPressureForFlowRate;
      },
      [ActionTypes.GET_WATER_FILTER_PRESSURE_FOR_FLOW_RATE_SUCCESS]: (draft, { payload }) => {
        draft.waterFilterPressureForFlowRate = payload;
        draft.waterFilterPressureForFlowRateLoading = false;
      },
      [ActionTypes.GET_WATER_FILTER_PRESSURE_FOR_FLOW_RATE_FAILURE]: draft => {
        draft.waterFilterPressureForFlowRateLoading = false;
      },
      [ActionTypes.GET_WATER_FILTER_PRESSURE_FOR_CONSUMPTION]: draft => {
        draft.waterFilterPressureForConsumptionLoading = true;
        draft.waterFilterPressureForConsumption = appState.waterFilterPressureForConsumption;
      },
      [ActionTypes.GET_WATER_FILTER_PRESSURE_FOR_CONSUMPTION_SUCCESS]: (draft, { payload }) => {
        draft.waterFilterPressureForConsumption = payload;
        draft.waterFilterPressureForConsumptionLoading = false;
      },
      [ActionTypes.GET_WATER_FILTER_PRESSURE_FOR_CONSUMPTION_FAILURE]: draft => {
        draft.waterFilterPressureForConsumptionLoading = false;
      },
      [ActionTypes.WF_FOR_PDF]: (draft, { wf_for_pfd }) => {
        draft.wf_for_pfd =
          wf_for_pfd &&
          !draft.waterFilterPressureForConsumptionLoading &&
          !draft.waterFilterPressureForFlowRateLoading &&
          !draft.waterFilterMaxFlowRateLoading &&
          !draft.waterFilterConsumptionLoading;
      },
      [ActionTypes.GET_TOTAL_CONSUMPTION_WATER_FILTER]: draft => {
        draft.totalConsumptionWaterFilterLoading = true;
      },
      [ActionTypes.GET_TOTAL_CONSUMPTION_WATER_FILTER_SUCCESS]: (
        draft,
        { payload: { waterFilterTotalConsumption } },
      ) => {
        draft.waterFilterTotalConsumption = waterFilterTotalConsumption;
        draft.totalConsumptionWaterFilterLoading = false;
      },
      [ActionTypes.GET_TOTAL_CONSUMPTION_WATER_FILTER_FAILURE]: draft => {
        draft.totalConsumptionWaterFilterLoading = false;
      },
      [ActionTypes.GET_WATER_FILTER_BYPASS]: draft => {
        draft.waterFilterBypassLoading = true;
      },
      [ActionTypes.GET_WATER_FILTER_BYPASS_SUCCESS]: (draft, { payload }) => {
        draft.waterFilterBypass = payload;
        draft.waterFilterBypassLoading = false;
      },
      [ActionTypes.GET_WATER_FILTER_BYPASS_FAILURE]: draft => {
        draft.waterFilterBypassLoading = false;
      },
      [ActionTypes.GET_WATER_FILTER_CURRENT_PRESSURE]: draft => {
        draft.waterFilterCurrentPressure = appState.waterFilterCurrentPressure;
        draft.waterFilterCurrentPressureLoading = true;
      },
      [ActionTypes.GET_WATER_FILTER_CURRENT_PRESSURE_SUCCESS]: (draft, { payload }) => {
        draft.waterFilterCurrentPressure = payload || appState.waterFilterCurrentPressure;
        draft.waterFilterCurrentPressureLoading = false;
      },
      [ActionTypes.GET_WATER_FILTER_CURRENT_PRESSURE_FAILURE]: draft => {
        draft.waterFilterCurrentPressureLoading = false;
      },
      [ActionTypes.GET_WATER_FILTER_CURRENT_BYPASS]: draft => {
        draft.waterFilterCurrentBypass = appState.waterFilterCurrentBypass;
        draft.waterFilterCurrentBypassLoading = true;
      },
      [ActionTypes.GET_WATER_FILTER_CURRENT_BYPASS_SUCCESS]: (draft, { payload }) => {
        draft.waterFilterCurrentBypass = payload
          ? payload.bypass
          : appState.waterFilterCurrentBypass;
        draft.waterFilterCurrentBypassLoading = false;
      },
      [ActionTypes.GET_WATER_FILTER_CURRENT_BYPASS_FAILURE]: draft => {
        draft.waterFilterCurrentBypassLoading = false;
      },

      [ActionTypes.GET_TOTAL_CONSUMPTION_CARTRIDGE_WF]: draft => {
        draft.cartridgeTotalConsumptionWf = appState.cartridgeTotalConsumptionWf;
        draft.cartridgeTotalConsumptionWfLoading = true;
      },
      [ActionTypes.GET_TOTAL_CONSUMPTION_CARTRIDGE_WF_SUCCESS]: (draft, { payload }) => {
        draft.cartridgeTotalConsumptionWf = payload
          ? payload.cartridgeTotalConsumptionWf
          : appState.cartridgeTotalConsumptionWf;
        draft.cartridgeTotalConsumptionWfLoading = false;
      },
      [ActionTypes.GET_TOTAL_CONSUMPTION_CARTRIDGE_WF_FAILURE]: draft => {
        draft.cartridgeTotalConsumptionWfLoading = false;
      },
      [ActionTypes.GET_ALL_TOTAL_CONSUMPTION_CARTRIDGE_WF]: draft => {
        draft.cartridgeAllTotalConsumptionWf = appState.cartridgeAllTotalConsumptionWf;
        draft.cartridgeAllTotalConsumptionWfLoading = true;
      },
      [ActionTypes.GET_ALL_TOTAL_CONSUMPTION_CARTRIDGE_WF_SUCCESS]: (draft, { payload }) => {
        draft.cartridgeAllTotalConsumptionWf = payload
          ? payload.cartridgeTotalConsumptionWf
          : appState.cartridgeAllTotalConsumptionWf;
        draft.cartridgeAllTotalConsumptionWfLoading = false;
      },
      [ActionTypes.GET_ALL_TOTAL_CONSUMPTION_CARTRIDGE_WF_FAILURE]: draft => {
        draft.cartridgeAllTotalConsumptionWfLoading = false;
      },
      [ActionTypes.REPLACE_WATER_FILTER_CARTRIDGES]: draft => {
        draft.replaceWaterFilterCartridgeLoading = true;
      },
      [ActionTypes.REPLACE_WATER_FILTER_CARTRIDGES_FAILURE]: draft => {
        draft.replaceWaterFilterCartridgeLoading = false;
      },
      [ActionTypes.REPLACE_WATER_FILTER_CARTRIDGES_SUCCESS]: draft => {
        draft.replaceWaterFilterCartridgeLoading = false;
      },

      [ActionTypes.WATER_FILTER_SET_INITIAL_STATE]: draft => {
        draft.waterFilterCurrentState = appState.waterFilterCurrentState;
        draft.waterFilterConsumption = appState.waterFilterConsumption;
        draft.waterFilterTotalConsumption = appState.waterFilterTotalConsumption;
        draft.waterFilterConsumptionLoading = appState.waterFilterConsumptionLoading;
        draft.waterFilterMaxFlowRate = appState.waterFilterMaxFlowRate;
        draft.waterFilterMaxFlowRateLoading = appState.waterFilterMaxFlowRateLoading;
        draft.waterFilterPressureForFlowRate = appState.waterFilterPressureForFlowRate;
        draft.waterFilterPressureForFlowRateLoading =
          appState.waterFilterPressureForFlowRateLoading;
        draft.waterFilterPressureForConsumption = appState.waterFilterPressureForConsumption;
        draft.waterFilterPressureForConsumptionLoading =
          appState.waterFilterPressureForConsumptionLoading;
        draft.totalConsumptionWaterFilterLoading = appState.totalConsumptionWaterFilterLoading;
        draft.waterFilterCurrentStateLoading = appState.waterFilterCurrentStateLoading;
        draft.consumptionFromInstallWf = appState.consumptionFromInstallWf;
        draft.consumptionFromInstallWfLoading = appState.consumptionFromInstallWfLoading;
        draft.waterFilterCurrentPressure = appState.waterFilterCurrentPressure;
        draft.waterFilterCurrentPressureLoading = appState.waterFilterCurrentPressureLoading;
        draft.waterFilterCurrentBypass = appState.waterFilterCurrentBypass;
        draft.waterFilterCurrentBypassLoading = appState.waterFilterCurrentBypassLoading;
        draft.cartridgeTotalConsumptionWf = appState.cartridgeTotalConsumptionWf;
        draft.cartridgeTotalConsumptionWfLoading = appState.cartridgeTotalConsumptionWfLoading;
      },

      // reverse osmosis dynamo
      [ActionTypes.GET_REVERSE_OSMOSIS_CURRENT_STATE]: draft => {
        draft.reverseOsmosisCurrentState = appState.reverseOsmosisCurrentState;
        draft.reverseOsmosisCurrentStateLoading = true;
      },
      [ActionTypes.GET_REVERSE_OSMOSIS_CURRENT_STATE_SUCCESS]: (draft, { payload }) => {
        draft.reverseOsmosisCurrentState = payload || appState.reverseOsmosisCurrentState;
        draft.reverseOsmosisCurrentStateLoading = false;
      },
      [ActionTypes.GET_REVERSE_OSMOSIS_CURRENT_STATE_FAILURE]: draft => {
        draft.reverseOsmosisCurrentStateLoading = false;
      },
      [ActionTypes.GET_REVERSE_OSMOSIS_CONSUMPTION]: draft => {
        draft.reverseOsmosisConsumptionLoading = true;
        draft.ro_for_pfd = false;
      },
      [ActionTypes.GET_REVERSE_OSMOSIS_CONSUMPTION_SUCCESS]: (draft, { payload }) => {
        draft.reverseOsmosisConsumption = payload;
        draft.reverseOsmosisConsumptionLoading = false;
      },
      [ActionTypes.RO_FOR_PDF]: (draft, { ro_for_pfd }) => {
        draft.ro_for_pfd =
          ro_for_pfd &&
          !draft.reverseOsmosisConsumptionLoading &&
          !draft.reverseOsmosisWasteLoading &&
          !draft.reverseOsmosisTdsLoading;
      },
      [ActionTypes.GET_REVERSE_OSMOSIS_CONSUMPTION_FAILURE]: draft => {
        draft.reverseOsmosisConsumptionLoading = false;
      },
      [ActionTypes.GET_TOTAL_CONSUMPTION_REVERSE_OSMOSIS]: draft => {
        draft.totalConsumptionReverseOsmosisLoading = true;
      },
      [ActionTypes.GET_TOTAL_CONSUMPTION_REVERSE_OSMOSIS_SUCCESS]: (
        draft,
        { payload: { reverseOsmosisTotalConsumption } },
      ) => {
        draft.reverseOsmosisTotalConsumption = reverseOsmosisTotalConsumption;
        draft.totalConsumptionReverseOsmosisLoading = false;
      },
      [ActionTypes.GET_TOTAL_CONSUMPTION_REVERSE_OSMOSIS_FAILURE]: draft => {
        draft.totalConsumptionReverseOsmosisLoading = false;
      },

      [ActionTypes.GET_REVERSE_OSMOSIS_WASTE]: draft => {
        draft.reverseOsmosisWasteLoading = true;
      },
      [ActionTypes.GET_REVERSE_OSMOSIS_WASTE_SUCCESS]: (draft, { payload }) => {
        draft.reverseOsmosisWaste = payload;
        draft.reverseOsmosisWasteLoading = false;
      },
      [ActionTypes.GET_REVERSE_OSMOSIS_WASTE_FAILURE]: draft => {
        draft.reverseOsmosisWasteLoading = false;
      },

      [ActionTypes.GET_REVERSE_OSMOSIS_TDS]: draft => {
        draft.reverseOsmosisTdsLoading = true;
      },
      [ActionTypes.GET_REVERSE_OSMOSIS_TDS_SUCCESS]: (draft, { payload }) => {
        draft.reverseOsmosisTds = payload;
        draft.reverseOsmosisTdsLoading = false;
      },
      [ActionTypes.GET_REVERSE_OSMOSIS_TDS_FAILURE]: draft => {
        draft.reverseOsmosisTdsLoading = false;
      },
      [ActionTypes.GET_CONSUMPTION_FROM_MEMBRANE_CHANGE_RO]: draft => {
        draft.consumptionFromMembraneChangeRoLoading = true;
      },
      [ActionTypes.GET_CONSUMPTION_FROM_MEMBRANE_CHANGE_RO_SUCCESS]: (
        draft,
        { payload: { reverseOsmosisTotalConsumption } },
      ) => {
        draft.consumptionFromMembraneChangeRo = reverseOsmosisTotalConsumption;
        draft.consumptionFromMembraneChangeRoLoading = false;
      },
      [ActionTypes.GET_CONSUMPTION_FROM_MEMBRANE_CHANGE_RO_FAILURE]: draft => {
        draft.consumptionFromMembraneChangeRoLoading = false;
      },
      [ActionTypes.GET_TOTAL_CONSUMPTION_CARTRIDGE_RO]: draft => {
        draft.cartridgeTotalConsumptionRo = appState.cartridgeTotalConsumptionRo;
        draft.cartridgeTotalConsumptionRoLoading = true;
      },
      [ActionTypes.GET_TOTAL_CONSUMPTION_CARTRIDGE_RO_SUCCESS]: (draft, { payload }) => {
        draft.cartridgeTotalConsumptionRo = payload
          ? payload.cartridgeTotalConsumptionRo
          : appState.cartridgeTotalConsumptionRo;
        draft.cartridgeTotalConsumptionRoLoading = false;
      },
      [ActionTypes.GET_TOTAL_CONSUMPTION_CARTRIDGE_RO_FAILURE]: draft => {
        draft.cartridgeTotalConsumptionRoLoading = false;
      },
      [ActionTypes.GET_ALL_TOTAL_CONSUMPTION_CARTRIDGE_RO]: draft => {
        draft.cartridgeAllTotalConsumptionRo = appState.cartridgeAllTotalConsumptionRo;
        draft.cartridgeAllTotalConsumptionRoLoading = true;
      },
      [ActionTypes.GET_ALL_TOTAL_CONSUMPTION_CARTRIDGE_RO_SUCCESS]: (draft, { payload }) => {
        draft.cartridgeAllTotalConsumptionRo = payload
          ? payload.cartridgeTotalConsumptionRo
          : appState.cartridgeAllTotalConsumptionRo;
        draft.cartridgeAllTotalConsumptionRoLoading = false;
      },
      [ActionTypes.GET_ALL_TOTAL_CONSUMPTION_CARTRIDGE_RO_FAILURE]: draft => {
        draft.cartridgeAllTotalConsumptionRoLoading = false;
      },
      [ActionTypes.REPLACE_REVERSE_OSMOSIS_CARTRIDGES]: draft => {
        draft.replaceReverseOsmosisCartridgeLoading = true;
      },
      [ActionTypes.REPLACE_REVERSE_OSMOSIS_CARTRIDGES_FAILURE]: draft => {
        draft.replaceReverseOsmosisCartridgeLoading = false;
      },
      [ActionTypes.REPLACE_REVERSE_OSMOSIS_CARTRIDGES_SUCCESS]: draft => {
        draft.replaceReverseOsmosisCartridgeLoading = false;
      },

      [ActionTypes.REVERSE_OSMOSIS_SET_INITIAL_STATE]: draft => {
        draft.reverseOsmosisCurrentState = appState.reverseOsmosisCurrentState;
        draft.reverseOsmosisConsumption = appState.reverseOsmosisConsumption;
        draft.reverseOsmosisTotalConsumption = appState.reverseOsmosisTotalConsumption;
        draft.reverseOsmosisConsumptionLoading = appState.reverseOsmosisConsumptionLoading;
        draft.totalConsumptionReverseOsmosisLoading =
          appState.totalConsumptionReverseOsmosisLoading;
        draft.reverseOsmosisCurrentStateLoading = appState.reverseOsmosisCurrentStateLoading;
        draft.reverseOsmosisWaste = appState.reverseOsmosisWaste;
        draft.reverseOsmosisWasteLoading = appState.reverseOsmosisWasteLoading;
        draft.reverseOsmosisTds = appState.reverseOsmosisTds;
        draft.reverseOsmosisTdsLoading = appState.reverseOsmosisTdsLoading;
        draft.consumptionFromMembraneChangeRo = appState.consumptionFromMembraneChangeRo;
        draft.consumptionFromMembraneChangeRoLoading =
          appState.consumptionFromMembraneChangeRoLoading;
        draft.cartridgeTotalConsumptionRo = appState.cartridgeTotalConsumptionRo;
        draft.cartridgeTotalConsumptionRoLoading = appState.cartridgeTotalConsumptionRoLoading;
      },

      [ActionTypes.GET_REVERSE_OSMOSIS_BYPASS]: draft => {
        draft.reverseOsmosisBypassLoading = true;
      },
      [ActionTypes.GET_REVERSE_OSMOSIS_BYPASS_SUCCESS]: (draft, { payload }) => {
        draft.reverseOsmosisBypass = payload;
        draft.reverseOsmosisBypassLoading = false;
      },
      [ActionTypes.GET_REVERSE_OSMOSIS_BYPASS_FAILURE]: draft => {
        draft.reverseOsmosisBypassLoading = false;
      },

      [ActionTypes.GET_REVERSE_OSMOSIS_CURRENT_BYPASS]: draft => {
        draft.reverseOsmosisCurrentBypass = appState.reverseOsmosisCurrentBypass;
        draft.reverseOsmosisCurrentBypassLoading = true;
      },
      [ActionTypes.GET_REVERSE_OSMOSIS_CURRENT_BYPASS_SUCCESS]: (draft, { payload }) => {
        draft.reverseOsmosisCurrentBypass = payload
          ? payload.bypass
          : appState.reverseOsmosisCurrentBypass;
        draft.reverseOsmosisCurrentBypassLoading = false;
      },
      [ActionTypes.GET_REVERSE_OSMOSIS_CURRENT_BYPASS_FAILURE]: draft => {
        draft.reverseOsmosisCurrentBypassLoading = false;
      },
      [ActionTypes.GET_REVERSE_OSMOSIS_CURRENT_PRESSURE]: draft => {
        draft.reverseOsmosisCurrentPressure = appState.reverseOsmosisCurrentPressure;
        draft.reverseOsmosisCurrentPressureLoading = true;
      },
      [ActionTypes.GET_REVERSE_OSMOSIS_CURRENT_PRESSURE_SUCCESS]: (draft, { payload }) => {
        draft.reverseOsmosisCurrentPressure = payload || appState.reverseOsmosisCurrentPressure;
        draft.reverseOsmosisCurrentPressureLoading = false;
      },
      [ActionTypes.GET_REVERSE_OSMOSIS_CURRENT_PRESSURE_FAILURE]: draft => {
        draft.reverseOsmosisCurrentPressureLoading = false;
      },
      [ActionTypes.GET_REVERSE_OSMOSIS_CURRENT_TEMPERATURE]: draft => {
        draft.reverseOsmosisCurrentPressure = appState.reverseOsmosisCurrentPressure;
        draft.reverseOsmosisCurrentPressureLoading = true;
      },
      [ActionTypes.GET_REVERSE_OSMOSIS_CURRENT_TEMPERATURE_SUCCESS]: (draft, { payload }) => {
        draft.reverseOsmosisCurrentTemperature = payload
          ? payload.tempValue
          : appState.reverseOsmosisCurrentTemperature;
        draft.reverseOsmosisCurrentTemperatureLoading = false;
      },
      [ActionTypes.GET_REVERSE_OSMOSIS_CURRENT_TEMPERATURE_FAILURE]: draft => {
        draft.reverseOsmosisCurrentTemperatureLoading = false;
      },
      // softener dynamo
      [ActionTypes.GET_SOFTENER_CURRENT_STATE]: draft => {
        draft.softenerCurrentState = appState.softenerCurrentState;
        draft.softenerCurrentStateLoading = true;
      },
      [ActionTypes.GET_SOFTENER_CURRENT_STATE_SUCCESS]: (draft, { payload }) => {
        draft.softenerCurrentState = payload || appState.softenerCurrentState;
        draft.softenerCurrentStateLoading = false;
      },
      [ActionTypes.GET_SOFTENER_CURRENT_STATE_FAILURE]: draft => {
        draft.softenerCurrentStateLoading = false;
      },
      [ActionTypes.GET_SOFTENER_CONSUMPTION]: draft => {
        draft.softenerConsumptionLoading = true;
      },
      [ActionTypes.GET_SOFTENER_CONSUMPTION_SUCCESS]: (draft, { payload }) => {
        draft.softenerConsumption = payload;
        draft.softenerConsumptionLoading = false;
      },
      [ActionTypes.GET_SOFTENER_CONSUMPTION_FAILURE]: draft => {
        draft.softenerConsumptionLoading = false;
      },
      [ActionTypes.GET_SOFTENER_PRESSURE]: draft => {
        draft.softenerPressureLoading = true;
        draft.softenerPressure = appState.softenerPressure;
      },
      [ActionTypes.GET_SOFTENER_PRESSURE_SUCCESS]: (draft, { payload }) => {
        draft.softenerPressure = payload;
        draft.softenerPressureLoading = false;
      },
      [ActionTypes.GET_SOFTENER_PRESSURE_FAILURE]: draft => {
        draft.softenerPressureLoading = false;
      },
      [ActionTypes.GET_SOFTENER_BYPASS]: draft => {
        draft.softenerBypassLoading = true;
      },
      [ActionTypes.GET_SOFTENER_BYPASS_SUCCESS]: (draft, { payload }) => {
        draft.softenerBypass = payload;
        draft.softenerBypassLoading = false;
      },
      [ActionTypes.GET_SOFTENER_BYPASS_FAILURE]: draft => {
        draft.softenerBypassLoading = false;
      },
      [ActionTypes.GET_SOFTENER_WASTE]: draft => {
        draft.softenerWasteLoading = true;
      },
      [ActionTypes.GET_SOFTENER_WASTE_SUCCESS]: (draft, { payload }) => {
        draft.softenerWaste = payload;
        draft.softenerWasteLoading = false;
      },
      [ActionTypes.GET_SOFTENER_WASTE_FAILURE]: draft => {
        draft.softenerWasteLoading = false;
      },

      [ActionTypes.GET_CONSUMPTION_FROM_INSTALL_SO]: draft => {
        draft.consumptionFromInstallSoLoading = true;
      },
      [ActionTypes.GET_CONSUMPTION_FROM_INSTALL_SO_SUCCESS]: (
        draft,
        { payload: { softenerTotalConsumption } },
      ) => {
        draft.consumptionFromInstallSo = softenerTotalConsumption;
        draft.consumptionFromInstallSoLoading = false;
      },
      [ActionTypes.GET_CONSUMPTION_FROM_INSTALL_SO_FAILURE]: draft => {
        draft.consumptionFromInstallSoLoading = false;
      },
      [ActionTypes.GET_SOFTENER_CURRENT_PRESSURE]: draft => {
        draft.softenerCurrentPressure = appState.softenerCurrentPressure;
        draft.softenerCurrentPressureLoading = true;
      },
      [ActionTypes.GET_SOFTENER_CURRENT_PRESSURE_SUCCESS]: (draft, { payload }) => {
        draft.softenerCurrentPressure = payload
          ? payload.pressure
          : appState.softenerCurrentPressure;
        draft.softenerCurrentPressureLoading = false;
      },
      [ActionTypes.GET_SOFTENER_CURRENT_PRESSURE_FAILURE]: draft => {
        draft.softenerCurrentPressureLoading = false;
      },
      [ActionTypes.GET_SOFTENER_CURRENT_BYPASS]: draft => {
        draft.softenerCurrentBypass = appState.softenerCurrentBypass;
        draft.softenerCurrentBypassLoading = true;
      },
      [ActionTypes.GET_SOFTENER_CURRENT_BYPASS_SUCCESS]: (draft, { payload }) => {
        draft.softenerCurrentBypass = payload ? payload.bypass : appState.softenerCurrentBypass;
        draft.softenerCurrentBypassLoading = false;
      },
      [ActionTypes.GET_SOFTENER_CURRENT_BYPASS_FAILURE]: draft => {
        draft.softenerCurrentBypassLoading = false;
      },
      [ActionTypes.SOFTENER_SET_INITIAL_STATE]: draft => {
        draft.softenerCurrentState = appState.softenerCurrentState;
        draft.softenerCurrentStateLoading = appState.softenerCurrentStateLoading;
        draft.softenerConsumption = appState.softenerConsumption;
        draft.softenerConsumptionLoading = appState.softenerConsumptionLoading;
        draft.softenerPressure = appState.softenerPressure;
        draft.softenerPressureLoading = appState.softenerPressureLoading;
        draft.softenerWaste = appState.softenerWaste;
        draft.softenerWasteLoading = appState.softenerWasteLoading;
        draft.consumptionFromInstallSo = appState.consumptionFromInstallSo;
        draft.consumptionFromInstallSoLoading = appState.consumptionFromInstallSoLoading;
        draft.softenerCurrentPressure = appState.softenerCurrentPressure;
        draft.softenerCurrentPressureLoading = appState.softenerCurrentPressureLoading;
        draft.softenerCurrentBypass = appState.softenerCurrentBypass;
        draft.softenerCurrentBypassLoading = appState.softenerCurrentBypassLoading;
      },
    },
    appState,
  ),
};
