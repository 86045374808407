import { put, call } from 'redux-saga/effects';
import { showAlert } from 'actions/index';
import { ActionTypes } from 'constants/index';
import { replace } from 'modules/history';

export function* getAccounts(api, action) {
  const response = yield call(api.getAccounts, action.payload);

  const { status, data } = response;

  if (status === 200) {
    const { rows, pages, currentPage } = data;
    yield put({
      type: ActionTypes.GET_ACCOUNTS_SUCCESS,
      payload: { rows, pages, currentPage },
    });
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.GET_ACCOUNTS_FAILURE,
    });
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger', timeout: 9 }));
    }
  }
}

export function* getAccount(api, action) {
  const { id } = action.payload;
  const response = yield call(api.getAccount, id);
  const { status, data } = response;

  if (status === 200) {
    yield put({
      type: ActionTypes.GET_ACCOUNT_SUCCESS,
      payload: data,
    });
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.GET_ACCOUNT_FAILURE,
    });
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger', timeout: 9 }));
    }
  }
}

export function* getAccountsForFilter(api, action) {
  const response = yield call(api.getAccountsForFilter, action.payload);

  const { status, data } = response;

  if (status === 200) {
    yield put({
      type: ActionTypes.GET_ACCOUNTS_FOR_FILTER_SUCCESS,
      payload: data,
    });
  } else {
    const { errors } = data;
    yield put({
      type: ActionTypes.GET_ACCOUNTS_FOR_FILTER_FAILURE,
    });
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger', timeout: 9 }));
    }
  }
}

export function* createAccount(api, action) {
  const response = yield call(api.createAccount, action.payload);

  const { status, data } = response;

  if (status === 200) {
    yield put(showAlert('Account created', { variant: 'success' }));
    replace('/accounts');
  } else {
    const { errors } = data;
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger', timeout: 9 }));
    }
  }

  yield put({
    type: ActionTypes.CREATE_ACCOUNT_RESOLVED,
  });
}

export function* editAccount(api, action) {
  const { id, account } = action.payload;
  const response = yield call(api.editAccount, id, account);

  const { status, data } = response;

  if (status === 200) {
    yield put(showAlert('Account edited', { variant: 'success' }));
    replace('/accounts');
  } else {
    const { errors } = data;
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger', timeout: 9 }));
    }
  }

  yield put({
    type: ActionTypes.EDIT_ACCOUNT_RESOLVED,
  });
}

export function* deleteAccount(api, action) {
  const { id } = action.payload;
  const response = yield call(api.deleteAccount, id);

  const { status, data } = response;

  if (status === 200) {
    yield put(showAlert('Account deleted', { variant: 'success' }));
    yield put({
      type: ActionTypes.DELETE_ACCOUNT_SUCCESS,
      payload: { id },
    });
  } else {
    const { errors } = data;
    if (status !== 500 && status !== 401) {
      yield put(showAlert(errors.message, { variant: 'danger', timeout: 9 }));
    }
    yield put({
      type: ActionTypes.DELETE_ACCOUNT_FAILURE,
      payload: { id },
    });
  }
}
