import React, { Component } from 'react';
import { connect } from 'react-redux';
import { editAlertType, getAlertType, getRoles } from 'actions/index';
import Loader from 'components/Loader';
import PropTypes from 'prop-types';
import AlertTypeForm from './AlertTypeForm';

class EditAlertType extends Component {
  state = {
    alertType: '',
  };

  componentDidMount() {
    const {
      match: { params },
    } = this.props;

    const { id } = params;
    const { fetchRoles, fetchAlertType } = this.props;

    fetchAlertType(id);
    fetchRoles();
  }

  onSubmit = data => {
    const {
      match: { params },
      submitAlertType,
    } = this.props;

    const { id } = params;

    submitAlertType(id, data);
  };

  render() {
    const { loading, alertType } = this.props;

    if (loading) {
      return <Loader size={200} block />;
    }

    if (!alertType) {
      return null;
    }

    const initialValues = {
      device_type: alertType.device_type,
      trigger: alertType.trigger,
      repeat: alertType.repeat,
      type: alertType.type,
      title: alertType.title,
      enabled: alertType.enabled,
      allow_email_notifications: alertType.allow_email_notifications,
      allow_sms_notifications: alertType.allow_sms_notifications,
      message: alertType.message,
      recipient_type: alertType.recipient_type || [],
    };

    return (
      <AlertTypeForm title="Edit alert" initialValues={initialValues} onSubmit={this.onSubmit} />
    );
  }
}

function mapStateToProps(state) {
  return {
    alertType: state.alertsTypes.item,
    loading: state.alertsTypes.loading,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    submitAlertType: (id, alertType) => dispatch(editAlertType(id, alertType)),
    fetchAlertType: id => dispatch(getAlertType(id)),
    fetchRoles: () => dispatch(getRoles()),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(EditAlertType);

EditAlertType.propTypes = {
  alertType: PropTypes.object,
  fetchAlertType: PropTypes.func,
  fetchRoles: PropTypes.func,
  loading: PropTypes.bool,
  match: PropTypes.object,
  submitAlertType: PropTypes.func,
};
