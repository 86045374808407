import styled from 'styled-components';

const Col = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${props => props.alignItems || 'center'};
  justify-content: ${props => props.justifyContent || 'flex-start'};

  @media (max-width: 767px) {
    ${props => `width: ${props.xs || 100}%;`}
    ${props => (props.divisor ? 'border-bottom: 1px solid #E3E4E5; margin-bottom: 12px;' : null)}
  }

  @media (min-width: 768px) and (max-width: 991px) {
    ${props => `width: ${props.sm || 100}%;`}
    ${props => (props.divisor ? 'border-bottom: 1px solid #E3E4E5; margin-bottom: 12px;' : null)}
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    ${props => `width: ${props.md || 100}%;`}
    ${props => (props.divisor ? 'border-right: 1px solid #E3E4E5;' : null)}
  }

  @media (min-width: 1200px) {
    ${props => `width: ${props.lg || 100}%;`}
    ${props => (props.divisor ? 'border-right: 1px solid #E3E4E5;' : null)}
  }
`;
export default Col;
