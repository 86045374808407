import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  getLocation,
  getAlerts,
  getAllAlerts,
  setDeviceId,
  updateInstallationStatus,
  markAlertAsViewed,
} from 'actions/index';
import Loader from 'components/Loader';
import PropTypes from 'prop-types';
import LocationDetailView from './LocationDetailView';

class LocationDetailContainer extends Component {
  componentDidMount() {
    const {
      fetchLocation,
      fetchAlerts,
      match: { params },
    } = this.props;
    const { id } = params;

    fetchLocation(id);
    fetchAlerts({ location_id: id });
  }

  render() {
    const {
      location,
      loading,
      alerts,
      alertsPages,
      alertsCurrentPage,
      fetchAlerts,
      fetchAllAlerts,
      allAlerts,
      setDeviceId,
      updateInstallationStatus,
      markAlertAsViewed,
      role_id,
    } = this.props;

    if (loading) {
      return <Loader size={200} block />;
    }

    if (!location) {
      return null;
    }

    return (
      <LocationDetailView
        loading={loading}
        location={location}
        alerts={alerts}
        alertsPages={alertsPages}
        alertsCurrentPage={alertsCurrentPage}
        fetchAlerts={fetchAlerts}
        fetchAllAlerts={fetchAllAlerts}
        allAlerts={allAlerts}
        setDeviceId={setDeviceId}
        updateInstallationStatus={updateInstallationStatus}
        markAlertAsViewed={markAlertAsViewed}
        role_id={role_id}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    location: state.locations.item,
    loading: state.locations.loading,
    allAlerts: state.alerts.allAlerts,
    alerts: state.alerts.list,
    alertsPages: state.alerts.pages,
    alertsCurrentPage: state.alerts.currentPage,
    role_id: state.auth.profile.role_id,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchAlerts: params => dispatch(getAlerts(params)),
    fetchAllAlerts: params => dispatch(getAllAlerts(params)),
    fetchLocation: id => dispatch(getLocation(id)),
    setDeviceId: params => dispatch(setDeviceId(params)),
    updateInstallationStatus: (id, data) => dispatch(updateInstallationStatus(id, data)),
    markAlertAsViewed: params => dispatch(markAlertAsViewed(params)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(LocationDetailContainer);

LocationDetailContainer.propTypes = {
  alerts: PropTypes.array,
  alertsCurrentPage: PropTypes.number,
  alertsPages: PropTypes.number,
  allAlerts: PropTypes.array,
  fetchAlerts: PropTypes.func,
  fetchAllAlerts: PropTypes.func,
  fetchLocation: PropTypes.func,
  loading: PropTypes.bool,
  location: PropTypes.object,
  markAlertAsViewed: PropTypes.func,
  match: PropTypes.object,
  role_id: PropTypes.number,
  setDeviceId: PropTypes.func,
  updateInstallationStatus: PropTypes.func,
};
