import React, { Component } from 'react';
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import styled from 'styled-components';
import { Colors } from 'modules/theme';

const Body = styled.div`
  position: absolute;
  align-self: center;
  text-align: center;
  z-index: 100;
`;

const TextTitle = styled.div`
  color: ${Colors.gray};
  font-weight: 350;
  font-size: 18px;
  line-height: 22px;
`;
const DownloadExample = styled.a`
  color: ${Colors.lightBlue};
  font-weight: 350;
  font-size: 24px;
  line-height: 29px;
  text-decoration-line: underline;
`;
const Container = styled.div`
  display: flex;
  justify-content: center;
`;

export default class Tabs extends Component {
  state = {
    status: 'empty',
  };

  getUploadParams = ({ file }) => {
    const { url, token, bucket, folder } = this.props;

    const body = new FormData();
    bucket && body.append('bucket', bucket);
    folder && body.append('folder', folder);
    body.append('url', url);
    body.append('file', file);
    return {
      url,
      body,
      headers: {
        jwt: token,
      },
    };
  };

  handleChangeStatus = ({ xhr, remove }, status) => {
    if (status === 'done') {
      const { handleResponse } = this.props;
      handleResponse(JSON.parse(xhr.response));
    }
  };

  render() {
    const { status } = this.state;
    const { title, link, height, disabled, accept } = this.props;

    return (
      <Container>
        {status === 'empty' && (
          <Body>
            <TextTitle>{title || 'Choose CSV File'}</TextTitle>
            {link && (
              <DownloadExample href={link} download>
                Download example
              </DownloadExample>
            )}
          </Body>
        )}
        <Dropzone
          getUploadParams={this.getUploadParams}
          onChangeStatus={this.handleChangeStatus}
          maxFiles={1}
          multiple={false}
          canCancel={false}
          disabled={disabled}
          inputContent=""
          styles={{
            dropzone: {
              width: '100%',
              height: height || '270px',
              overflow: 'auto',
              borderStyle: 'dashed',
            },
            inputLabel: { color: 'yellow' },
            dropzoneActive: { borderColor: 'green' },
          }}
          accept={accept}
        />
      </Container>
    );
  }
}
