import React, { Component } from 'react';
import { AlertTypes } from 'constants/index';
import { connect } from 'react-redux';
import { createAlertType, getRoles } from 'actions/index';
import PropTypes from 'prop-types';
import AlertTypeForm from './AlertTypeForm';

class CreateAlertType extends Component {
  componentDidMount() {
    const { fetchRoles } = this.props;

    fetchRoles();
  }

  render() {
    const { submitAlertType } = this.props;

    const initialValues = {
      device_type: '',
      trigger: '',
      repeat: '',
      type: AlertTypes[0].value,
      title: '',
      enabled: false,
      allow_email_notifications: false,
      allow_sms_notifications: false,
      message: '',
      recipient_type: [''],
    };

    return (
      <AlertTypeForm
        title="Create alert"
        initialValues={initialValues}
        onSubmit={submitAlertType}
      />
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    submitAlertType: alertType => dispatch(createAlertType(alertType)),
    fetchRoles: () => dispatch(getRoles()),
  };
}

export default connect(null, mapDispatchToProps)(CreateAlertType);

CreateAlertType.propTypes = {
  fetchRoles: PropTypes.func,
  submitAlertType: PropTypes.func,
};
