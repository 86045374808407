import { REHYDRATE } from 'redux-persist/lib/constants';
import { handleActions } from 'modules/helpers';

import { ActionTypes } from 'constants/index';

export const appState = {
  list: [],
  pages: 0,
  currentPage: 0,
  loading: false,
  listWfAndSoftenerVersions: [],
  listRoVersions: [],
  wfAndSoftenerVersionsPages: 0,
  roVersionsPages: 0,
  wfAndSoftenerVersionsCurrentPage: 1,
  roVersionsCurrentPage: 1,
  loadingWfAndSoftenerVersions: false,
  loadingRoVersions: false,
  upgradesList: [],
  upgradesPages: 0,
  upgradesCurrentPage: 0,
  upgradesLoading: false,
  item: null,
  upgradesListDevices: [],
  loadingGetPreviousInstallationInfo: false,
  previousInstallationInfo: null,
  loadingActiveDevices: false,
  rowsActiveDevices: [],
  listActiveDevices: [],
};

export default {
  ota: handleActions(
    {
      [REHYDRATE]: draft => {
        draft.list = [];
      },
      [ActionTypes.GET_OTAS]: draft => {
        draft.loading = true;
      },
      [ActionTypes.GET_OTAS_SUCCESS]: (draft, { payload: { rows, pages, currentPage } }) => {
        draft.list = rows;
        draft.pages = pages;
        draft.currentPage = currentPage;
        draft.loading = false;
      },
      [ActionTypes.GET_OTAS_FAILURE]: draft => {
        draft.loading = false;
      },
      [ActionTypes.GET_OTA]: draft => {
        draft.item = null;
        draft.loading = true;
      },
      [ActionTypes.GET_OTA_SUCCESS]: (draft, { payload }) => {
        draft.item = payload;
        draft.loading = false;
      },
      [ActionTypes.GET_OTA_FAILURE]: draft => {
        draft.loading = false;
      },
      [ActionTypes.GET_OTA_VERSIONS_WF_AND_SOFTENER]: draft => {
        draft.loadingWfAndSoftenerVersions = true;
      },
      [ActionTypes.GET_OTA_VERSIONS_WF_AND_SOFTENER_SUCCESS]: (
        draft,
        { payload: { rows, pages, currentPage } },
      ) => {
        draft.listWfAndSoftenerVersions = rows;
        draft.wfAndSoftenerVersionsPages = pages;
        draft.wfAndSoftenerVersionsCurrentPage = currentPage;
        draft.loadingWfAndSoftenerVersions = false;
      },
      [ActionTypes.GET_OTA_VERSIONS_WF_AND_SOFTENER_FAILURE]: draft => {
        draft.loadingWfAndSoftenerVersions = false;
      },
      [ActionTypes.GET_OTA_VERSIONS_RO]: draft => {
        draft.loadingRoVersions = true;
      },
      [ActionTypes.GET_OTA_VERSIONS_RO_SUCCESS]: (
        draft,
        { payload: { rows, pages, currentPage } },
      ) => {
        draft.listRoVersions = rows;
        draft.roVersionsPages = pages;
        draft.roVersionsCurrentPage = currentPage;
        draft.loadingRoVersions = false;
      },
      [ActionTypes.GET_OTA_VERSIONS_RO_FAILURE]: draft => {
        draft.loadingRoVersions = false;
      },
      [ActionTypes.CREATE_OTA_VERSION]: draft => {
        draft.loading = true;
      },
      [ActionTypes.CREATE_OTA_VERSION_SUCCESS]: draft => {
        draft.loading = false;
      },
      [ActionTypes.CREATE_OTA_VERSION_FAILURE]: draft => {
        draft.loading = false;
      },
      [ActionTypes.DELETE_OTA_VERSION]: draft => {
        draft.loading = true;
      },
      [ActionTypes.DELETE_OTA_VERSION_SUCCESS]: (draft, { payload: { id } }) => {
        draft.listRoVersions = draft.listRoVersions.filter(item => item.id !== id);
        draft.listWfAndSoftenerVersions = draft.listWfAndSoftenerVersions.filter(
          item => item.id !== id,
        );

        draft.loading = false;
      },
      [ActionTypes.DELETE_OTA_VERSION_FAILURE]: (draft, { payload: { id } }) => {
        draft.loading = false;
      },

      [ActionTypes.GET_PREVIOUS_INSTALLATION_INFO]: (draft, { payload }) => {
        draft.loadingGetPreviousInstallationInfo = true;
      },
      [ActionTypes.GET_PREVIOUS_INSTALLATION_INFO_SUCCESS]: (draft, { payload }) => {
        draft.loadingGetPreviousInstallationInfo = false;
        draft.previousInstallationInfo = payload;
      },
      [ActionTypes.GET_PREVIOUS_INSTALLATION_INFO_FAILURE]: (draft, { payload }) => {
        draft.loading = false;
      },

      [ActionTypes.GET_UPGRADE_STATUS]: draft => {
        draft.upgradesLoading = true;
      },
      [ActionTypes.GET_UPGRADE_STATUS_SUCCESS]: (
        draft,
        { payload: { rows, pages, currentPage } },
      ) => {
        draft.upgradesList = rows;
        draft.upgradesPages = pages;
        draft.upgradesCurrentPage = currentPage;
        draft.upgradesLoading = false;
      },
      [ActionTypes.GET_UPGRADE_STATUS_FAILURE]: draft => {
        draft.upgradesLoading = false;
      },
      [ActionTypes.DELETE_UPGRADE_STATUS_SUCCESS]: (draft, { id }) => {
        draft.upgradesList = draft.upgradesList.filter(item => item.id !== id);
        draft.loading = false;
      },
      [ActionTypes.GET_UPGRADE_STATUS_DEVICES]: draft => {
        draft.upgradesLoadingDevices = true;
      },
      [ActionTypes.GET_UPGRADE_STATUS_DEVICES_SUCCESS]: (
        draft,
        { payload: { rows, pages, currentPage } },
      ) => {
        draft.upgradesListDevices = rows;
        draft.upgradesPagesDevices = pages;
        draft.upgradesCurrentPageDevices = currentPage;
        draft.upgradesLoadingDevices = false;
      },
      [ActionTypes.GET_UPGRADE_STATUS_DEVICES_FAILURE]: draft => {
        draft.upgradesLoadingDevices = false;
      },
      [ActionTypes.CREATE_UPGRADE_STATUS]: draft => {
        draft.loading = true;
      },
      [ActionTypes.CREATE_UPGRADE_STATUS_SUCCESS]: draft => {
        draft.loading = false;
      },
      [ActionTypes.CREATE_UPGRADE_STATUS_FAILURE]: draft => {
        draft.loading = false;
      },
      [ActionTypes.GET_UPDATE_ACTIVE_DEVICES]: draft => {
        draft.loadingActiveDevices = true;
      },
      [ActionTypes.GET_UPDATE_ACTIVE_DEVICES_SUCCESS]: (draft, { payload: { rows } }) => {
        draft.listActiveDevices = rows;
        draft.loadingActiveDevices = false;
      },
      [ActionTypes.GET_UPDATE_ACTIVE_DEVICES_FAILURE]: draft => {
        draft.loadingActiveDevices = true;
      },
    },
    appState,
  ),
};
