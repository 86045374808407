import React, { Component } from 'react';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {
  NonInternalRoles,
  CustomerRoleId,
  AdminsRolesId,
  AccountAdminRoleId,
  LocationAdminRoleId,
} from 'constants/index';
import MultiSelect from '@khanacademy/react-multi-select';
import { Colors } from 'modules/theme';
import Select from 'components/Select';
import H4 from 'components/H4';
import FormError from 'components/FormError';
import PropTypes from 'prop-types';
import Checkbox from 'components/Checkbox';

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const Column = styled.div`
  flex-direction: column;
  width: ${props => props.width || 24}%;
  padding-right: 10px;
  padding-bottom: 10px;
  justify-content: flex-end;
  align-content: flex-end;
  @media (max-width: 1000px) {
    width: 100% !important;
  }
`;

export default class PermissionsForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      brandsSelected: props.brandsSelected || [],
      accountsSelected: props.accountsSelected || [],
      locationsSelected: props.locationsSelected || [],
    };
  }

  submit = values => {
    const { onSubmit } = this.props;
    const { brandsSelected, accountsSelected, locationsSelected } = this.state;
    const activeTab = 'Permissions';

    onSubmit(
      {
        ...values,
        brands: brandsSelected,
        accounts: accountsSelected,
        locations: locationsSelected,
      },
      activeTab,
    );
  };

  reset = () => {
    this.setState({
      brandsSelected: [],
      accountsSelected: [],
      locationsSelected: [],
    });
  };

  render() {
    const {
      initialValues,
      isInitialValid,
      roles,
      brands,
      accounts,
      locations,
      fetchAccounts,
      fetchLocations,
      fetchBrands,
      updateUserState,
    } = this.props;
    const { brandsSelected, accountsSelected, locationsSelected } = this.state;

    return (
      <Formik
        enableReinitialize
        isInitialValid={isInitialValid}
        initialValues={initialValues}
        onSubmit={this.submit}
        validationSchema={Yup.object().shape({
          role_id: Yup.number().required('Required'),
        })}
      >
        {({ values, errors, touched, handleChange, handleBlur }) => {
          const isNoInternal = NonInternalRoles.includes(parseInt(values.role_id));
          const isAdminOrNonInternal =
            NonInternalRoles.includes(parseInt(values.role_id)) ||
            AdminsRolesId.includes(parseInt(values.role_id));
          const isCustomerOrAdmin =
            parseInt(values.role_id) === CustomerRoleId ||
            AdminsRolesId.includes(parseInt(values.role_id));
          const isCustomerOrLocationAdmin =
            parseInt(values.role_id) === CustomerRoleId ||
            parseInt(values.role_id) === LocationAdminRoleId;
          const isCustomerOrLocationOrAccountAdmin =
            parseInt(values.role_id) === CustomerRoleId ||
            parseInt(values.role_id) === LocationAdminRoleId ||
            parseInt(values.role_id) === AccountAdminRoleId;

          return (
            <Form>
              <Row>
                <Column>
                  <H4 inputLabel color={Colors.gray8}>
                    Role
                  </H4>
                  <Select
                    placeholder="Role"
                    name="role_id"
                    options={roles}
                    value={values.role_id}
                    onChange={event => {
                      handleChange(event);
                      fetchBrands();
                      updateUserState({
                        role_id: event.target.value,
                      });
                      this.reset();
                    }}
                    onBlur={handleBlur}
                  />
                  <FormError text={errors.role_id && touched.role_id && errors.role_id} />
                </Column>
              </Row>
              {isAdminOrNonInternal && (
                <Row>
                  <Column>
                    <H4 inputLabel color={Colors.gray8}>
                      Brands
                    </H4>
                    {isCustomerOrAdmin ? (
                      <Select
                        placeholder="Select a brand..."
                        options={brands}
                        value={brandsSelected.length ? brandsSelected[0] : ''}
                        onChange={event => {
                          const brandsSelected = [event.target.value];
                          fetchAccounts({ brands: brandsSelected });
                          this.setState({
                            brandsSelected,
                            accountsSelected: [],
                            locationsSelected: [],
                          });

                          updateUserState({
                            brands: brandsSelected,
                            accounts: [],
                            locations: [],
                          });
                        }}
                        onBlur={handleBlur}
                      />
                    ) : (
                      <MultiSelect
                        options={brands}
                        selected={brandsSelected}
                        hasSelectAll={brands.length > 0}
                        overrideStrings={{
                          selectSomeItems: 'Select some brands...',
                        }}
                        onSelectedChanged={brandsSelected => {
                          fetchAccounts({ brands: brandsSelected });
                          this.setState({
                            brandsSelected,
                            accountsSelected: [],
                            locationsSelected: [],
                          });
                          updateUserState({
                            brands: brandsSelected,
                            accounts: [],
                            locations: [],
                          });
                        }}
                      />
                    )}
                  </Column>

                  {(isCustomerOrLocationOrAccountAdmin || isNoInternal) && (
                    <Column>
                      <H4 inputLabel color={Colors.gray8}>
                        Accounts
                      </H4>
                      <MultiSelect
                        options={accounts}
                        selected={accountsSelected}
                        hasSelectAll={accounts.length > 0}
                        overrideStrings={{
                          selectSomeItems: 'Select some accounts...',
                        }}
                        onSelectedChanged={accountsSelected => {
                          fetchLocations({
                            accounts: accountsSelected,
                          });
                          this.setState({
                            accountsSelected,
                            locationsSelected: [],
                          });
                          updateUserState({
                            accounts: accountsSelected,
                            locations: [],
                          });
                        }}
                      />
                    </Column>
                  )}
                  {(isCustomerOrLocationAdmin || isNoInternal) && (
                    <Column>
                      <H4 inputLabel color={Colors.gray8}>
                        Locations
                      </H4>
                      <MultiSelect
                        options={locations}
                        selected={locationsSelected}
                        hasSelectAll={locations.length > 0}
                        overrideStrings={{
                          selectSomeItems: 'Select some locations...',
                        }}
                        onSelectedChanged={locationsSelected => {
                          this.setState({ locationsSelected });
                          updateUserState({
                            locations: locationsSelected,
                          });
                        }}
                      />
                    </Column>
                  )}
                  <Column>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Checkbox
                        isChecked={values.auto_add_to_new_locations}
                        onClick={() => {
                          handleChange({
                            target: {
                              name: 'auto_add_to_new_locations',
                              value: !values.auto_add_to_new_locations,
                            },
                          });

                          updateUserState({
                            auto_add_to_new_locations: !values.auto_add_to_new_locations,
                          });
                        }}
                      />
                      <H4 inputLabel color={Colors.gray8}>
                        Automatically add this user to new locations
                      </H4>
                    </div>
                  </Column>
                </Row>
              )}
            </Form>
          );
        }}
      </Formik>
    );
  }
}

PermissionsForm.propTypes = {
  accounts: PropTypes.array,
  accountsSelected: PropTypes.array,
  brands: PropTypes.array,
  brandsSelected: PropTypes.array,
  fetchAccounts: PropTypes.func,
  fetchBrands: PropTypes.func,
  fetchLocations: PropTypes.func,
  initialValues: PropTypes.object,
  isInitialValid: PropTypes.bool,
  locations: PropTypes.array,
  locationsSelected: PropTypes.array,
  onSubmit: PropTypes.func,
  roles: PropTypes.array,
  updateUserState: PropTypes.func,
};
