import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createReverseOsmosisMembrane } from 'actions/index';
import PropTypes from 'prop-types';
import ROMembraneForm from './ROMembraneForm';

class CreateROMembrane extends Component {
  render() {
    const { submitROMembrane, loading, token } = this.props;

    const initialValues = {
      name: '',
      part: '',
      price: '',
      scale_control_capacity: '',
      chlorine_capacity: '',
      chloramine_capacity: '',
      sediment: false,
      taste_odor: false,
      contaminant: false,
      scale: false,
      photo: '',
    };

    return (
      <ROMembraneForm
        onSubmit={submitROMembrane}
        title="Create New Water ROMembrane"
        initialValues={initialValues}
        loading={loading}
        token={token}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    loading: state.libraries.loading,
    token: state.auth.token,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    submitROMembrane: library => dispatch(createReverseOsmosisMembrane(library)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(CreateROMembrane);

CreateROMembrane.propTypes = {
  loading: PropTypes.bool,
  submitROMembrane: PropTypes.func,
  token: PropTypes.string,
};
