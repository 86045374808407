import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  createReverseOsmosis,
  getCatridgesForFilter,
  getTanks,
  getReverseOsmosisMembraneForFilter,
} from 'actions/index';
import PropTypes from 'prop-types';
import ReverseOsmosisFilterForm from './ReverseOsmosisFilterForm';

class CreateReverseOsmosisFilter extends Component {
  componentDidMount() {
    const { fetchCatridges, fetchTanks, fetchReverseOsmosisMembrane } = this.props;
    fetchCatridges();
    fetchTanks();
    fetchReverseOsmosisMembrane();
  }

  render() {
    const {
      submitReverseOsmosisFilter,
      catridges,
      loading,
      token,
      tanks,
      ROMembranes,
    } = this.props;

    const initialValues = {
      name: '',
      ro_model_name: '',
      sediment_prefilter: '',
      prefilter: '',
      postfilter: '',
      taste_odor: false,
      contaminant: false,
      scale: false,
      photo: '',
      description: '',
      spots: [
        {
          catridge_id: '',
          position: '',
          outlet: '',
          series_or_parallel: '',
        },
      ],
      spots: [
        {
          catridge_id: '',
          position: '',
        },
      ],
      membranes: [
        {
          reverse_osmosis_membrane_id: '',
        },
      ],
    };

    return (
      <ReverseOsmosisFilterForm
        title="Add New Reverse Osmosis"
        onSubmit={submitReverseOsmosisFilter}
        initialValues={initialValues}
        loading={loading}
        catridges={catridges}
        token={token}
        tanks={tanks}
        ROMembranes={ROMembranes}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    loading: state.libraries.loading,
    token: state.auth.token,
    catridges: state.libraries.catridgesForFilter,
    tanks: state.libraries.tanks,
    ROMembranes: state.libraries.reverseOsmosisMembraneForFilter,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    submitReverseOsmosisFilter: library => dispatch(createReverseOsmosis(library)),
    fetchCatridges: () => dispatch(getCatridgesForFilter()),
    fetchTanks: () => dispatch(getTanks()),
    fetchReverseOsmosisMembrane: () => dispatch(getReverseOsmosisMembraneForFilter()),
  };
}

CreateReverseOsmosisFilter.propTypes = {
  catridges: PropTypes.array,
  fetchCatridges: PropTypes.func,
  fetchReverseOsmosisMembrane: PropTypes.func,
  fetchTanks: PropTypes.func,
  loading: PropTypes.bool,
  ROMembranes: PropTypes.array,
  submitReverseOsmosisFilter: PropTypes.func,
  tanks: PropTypes.array,
  token: PropTypes.string,
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateReverseOsmosisFilter);
