import React, { Component } from 'react';
import styled from 'styled-components';
import { Colors } from 'modules/theme';
import H4 from 'components/H4';
import H5 from 'components/H5';
import FormError from 'components/FormError';
import Textarea from 'components/Textarea';
import { PressureTraducerFields } from 'constants/index';
import PropTypes from 'prop-types';

const Container = styled.div``;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const Column = styled.div`
  flex-direction: column;
`;

export default class SoftenerForm extends Component {
  render() {
    const { values, errors, setFieldValue, handleBlur } = this.props;

    return (
      <Container>
        <Row>
          <Column style={{ width: '33.3%', paddingRight: '30px' }}>
            <H4 inputLabel color={Colors.gray8}>
              Conversion Formula
            </H4>
            <Textarea
              value={values.conversion_formula}
              onChange={value => setFieldValue('conversion_formula', value)}
              higlights={PressureTraducerFields}
              onBlur={handleBlur}
            />

            <FormError
              text={
                errors.conversion_formula &&
                values.conversion_formula &&
                values.conversion_formula.length > 0 &&
                errors.conversion_formula
              }
            />
            <H5 inputLabel color={Colors.lightBlue}>
              To add a field, type something starting with ":"
            </H5>
          </Column>
        </Row>
      </Container>
    );
  }
}

SoftenerForm.propTypes = {
  errors: PropTypes.object,
  handleBlur: PropTypes.func,
  setFieldValue: PropTypes.func,
  values: PropTypes.object,
};
