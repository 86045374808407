import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Colors } from 'modules/theme';
import Select from 'components/Select';
import { AlertTrigger, AlertTypes, AlertRecipients, MATCH_DEVICE_TYPE } from 'constants/index';
import Table, { TRow, TableItemMinimal, TBody } from 'components/Table';
import Loader from 'components/Loader';
import Checkbox from 'components/Checkbox';
import ListEmpty from 'components/ListEmpty';
import ButtonText from 'components/ButtonText';
import { getAlertsTypes } from 'actions/index';
import PropTypes from 'prop-types';
import H4 from 'components/H4';
import RadioInput from 'components/RadioInput';
import Row from 'components/Row';

export const Image = styled.img`
  width: 25px;
  height: 25px;
  margin-right: 10px;
`;

const FiltersDiv = styled.div`
  display: flex;
  background-color: ${Colors.lightGray};
  display: flex;
  flex-wrap: wrap;
`;

const SelectContainer = styled.div`
  margin-right: 10px;
  margin-bottom: 10px;
  width: ${props => props.width}px;
`;

const HeaderText = styled.div`
  font-size: 18px;
  color: ${Colors.lightBlue};
  background-color: ${Colors.lightGray};
  display: flex;
  padding-bottom: 20px;
  padding-right: 20px;
`;

const Text = styled.p`
  font-size: 16px;
  font-style: normal;
  font-weight: bold;
  margin-top: 0px;
`;

const TableContainer = styled.div`
  overflow-y: auto;
`;

const ButtonContainer = styled.div`
  display: flex;
  padding-bottom: 15px;
  margin-top: 30px;
`;

const blacklistForLocationsAndAccounts = {
  admin_users: [],
  general_users: [],
  account_owner: [],
  location_owner: [],
  brand_owner: [],
  customer: [],
  installers: [],
};

const blacklistForUsers = [];

class AlertsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 'admin_users',
      blacklist: props.userBlackList || blacklistForLocationsAndAccounts,
      device_family_filter: '',
      trigger_filter: '',
      alert_type_filter: '',
      radioOption: 1,
    };
  }

  componentDidMount() {
    const { fetchAlertsTypes, deviceTypes, userRole, userBlackList } = this.props;
    const deviceTypeValues = deviceTypes.map(device => device.value);
    fetchAlertsTypes({ device_type: deviceTypeValues, elementsPerPage: 1000 });

    this.updateUserRole(userRole);

    if (userBlackList && userBlackList.length > 0) {
      this.setState({
        blacklist: userBlackList,
        radioOption: 2,
      });
    } else {
      this.setState({
        blacklist: userBlackList || [],
        radioOption: 1,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.userRole !== this.props.userRole) {
      this.updateUserRole(this.props.userRole);
    }

    if (prevProps.userBlackList !== this.props.userBlackList) {
      this.setState({ blacklist: this.props.userBlackList });
    }
  }

  updateUserRole(userRole) {
    if (userRole) {
      const activeTab = AlertRecipients.find(tab => tab.label === userRole.label);
      this.setState({ activeTab: activeTab?.value, blacklist: blacklistForUsers });
    } else {
      this.setState({ blacklist: blacklistForLocationsAndAccounts });
    }
  }

  submit = () => {
    const { onSubmit } = this.props;
    const { blacklist } = this.state;
    onSubmit({ alertsBlackList: blacklist });
  };

  updateState = (key, value) => {
    this.setState({ [key]: value });
  };

  updateDeviceFamilyFilter = v => {
    this.setState({ device_family_filter: v.target.value });
    this.setState({ trigger_filter: '' });
  };

  getKeyByValue = valueToFind => {
    for (const key in AlertTrigger) {
      if (Object.prototype.hasOwnProperty.call(AlertTrigger, key)) {
        const items = AlertTrigger[key];
        if (Array.isArray(items)) {
          for (const item of items) {
            if (item.value === valueToFind) {
              return item.label;
            }
          }
        }
      }
    }
  };

  capitalize = string => string.charAt(0).toUpperCase() + string.slice(1);

  applyFiltersToAlerts = () => {
    const { alertsTypes } = this.props;
    let alerts = [...alertsTypes];
    const { device_family_filter, trigger_filter, alert_type_filter, activeTab } = this.state;

    // Filter by device family
    if (device_family_filter) {
      alerts = alerts.filter(alert => alert.device_type === device_family_filter);
    }
    // Filter by trigger
    if (trigger_filter) {
      alerts = alerts.filter(alert => alert.trigger === trigger_filter);
    }
    // Filter by alert type
    if (alert_type_filter) {
      alerts = alerts.filter(alert => alert.type === alert_type_filter);
    }
    // Filter by active tab
    alerts = alerts.filter(alert => alert.recipient_type.includes(activeTab));

    return alerts;
  };

  checkboxAction = alert_type_id => {
    this.setState(prevState => {
      const { blacklist } = prevState;

      const updateList = list => {
        const index = list.indexOf(alert_type_id);
        if (index > -1) {
          list.splice(index, 1);
        } else {
          list.push(alert_type_id);
        }
        return list;
      };

      const newBlacklist = updateList([...blacklist]);

      const radioOption = newBlacklist.length > 0 ? 2 : 1;

      return { blacklist: newBlacklist, radioOption };
    });
  };

  isChecked = alert_type_id => {
    const { blacklist } = this.state;

    if (!Array.isArray(blacklist)) return false;

    return !blacklist.includes(alert_type_id);
  };

  renderItem = ({ device_type, trigger, type, title, id }, key) => (
    <TRow key={key} minimal>
      <TableItemMinimal width="5%" fontweight="bold" noBorder>
        <Checkbox isChecked={this.isChecked(id)} onClick={() => this.checkboxAction(id)} />
      </TableItemMinimal>
      <TableItemMinimal width="38%" fontweight="bold" color={Colors.gray}>
        {this.getKeyByValue(trigger)}
      </TableItemMinimal>
      <TableItemMinimal color={Colors.gray} width="14%">
        {MATCH_DEVICE_TYPE[device_type]}
      </TableItemMinimal>
      <TableItemMinimal color={Colors.gray} width="10%">
        {this.capitalize(type)}
      </TableItemMinimal>
      <TableItemMinimal color={Colors.gray} width="38%" noBorder>
        {title.length > 40 ? `${title.substring(0, 40)}...` : title}
      </TableItemMinimal>
    </TRow>
  );

  handleRadioChange = (name, numericValue) => {
    if (numericValue === 1) {
      this.setState({ blacklist: [], radioOption: numericValue });
    } else {
      this.setState({ radioOption: numericValue });
    }
  };

  render() {
    const { device_family_filter, trigger_filter, alert_type_filter, radioOption } = this.state;
    const { loadingAlertType, back, deviceTypes, subtitle } = this.props;
    const alertTypes = this.applyFiltersToAlerts();

    return (
      <div>
        <Row alignItems="end" justifyContent="start">
          <HeaderText>{`Alerts (${alertTypes.length})`}</HeaderText>
          <RadioInput
            options={['All alerts', 'Custom alerts']}
            handleChange={this.handleRadioChange}
            currentValue={radioOption}
            name="radioOption"
            verticalAlign={false}
          />
        </Row>

        <H4 inputLabel color={Colors.gray8}>
          Filter alerts
        </H4>
        <FiltersDiv>
          <SelectContainer width={200}>
            <Select
              placeholder="Alert Type"
              name="alert_type_filter"
              options={[{ label: 'All', value: '' }, ...AlertTypes]}
              value={alert_type_filter}
              onChange={e => this.updateState('alert_type_filter', e.target.value)}
            />
          </SelectContainer>
          <SelectContainer width={200}>
            <Select
              style={{ marginRight: 10 }}
              placeholder="Device Family"
              name="device_family_filter"
              options={[{ label: 'All', value: '' }, ...deviceTypes]}
              value={device_family_filter}
              onChange={this.updateDeviceFamilyFilter}
            />
          </SelectContainer>

          <SelectContainer width={470}>
            <Select
              style={{ marginRight: 10 }}
              placeholder={
                !device_family_filter ? 'Select a device to filter by trigger' : 'Alert trigger'
              }
              name="trigger_filter"
              options={
                !device_family_filter
                  ? []
                  : [{ label: 'All', value: '' }, ...AlertTrigger[device_family_filter]]
              }
              onChange={e => this.updateState('trigger_filter', e.target.value)}
              value={trigger_filter}
              disabled={!device_family_filter}
            />
          </SelectContainer>
        </FiltersDiv>

        {radioOption === 2 && ( // All alerts selected
          <>
            <Text>{subtitle}</Text>

            <TableContainer>
              <Table>
                <TBody>
                  {loadingAlertType ? (
                    <Loader size={100} block />
                  ) : (
                    alertTypes.map((item, key) => this.renderItem(item, key))
                  )}
                </TBody>
              </Table>
              {(!alertTypes || !alertTypes.length) && !loadingAlertType && (
                <>
                  <ListEmpty message="There are no alerts with the selected roles and filters." />
                  <ListEmpty message="Try to update the roles and/or filters. " />
                </>
              )}
            </TableContainer>
          </>
        )}

        <ButtonContainer>
          <ButtonText
            text="Back"
            type="button"
            theme="default"
            customStyles={{ marginTop: '20px', marginRight: '20px' }}
            onClick={back}
          />
          <ButtonText
            text="Save"
            theme="primary"
            customStyles={{ marginTop: '20px' }}
            onClick={this.submit}
          />
        </ButtonContainer>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    alertsTypes: state.alertsTypes.list,
    loadingAlertType: state.alertsTypes.loading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchAlertsTypes: params => dispatch(getAlertsTypes(params)),
  };
}

AlertsForm.propTypes = {
  alertsTypes: PropTypes.arrayOf(
    PropTypes.shape({
      device_type: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      recipient_type: PropTypes.arrayOf(PropTypes.string).isRequired,
      title: PropTypes.string.isRequired,
      trigger: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    }),
  ).isRequired,
  back: PropTypes.func.isRequired,
  deviceTypes: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  fetchAlertsTypes: PropTypes.func.isRequired,
  loadingAlertType: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  subtitle: PropTypes.string,
  userBlackList: PropTypes.arrayOf(PropTypes.string),
  userRole: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
};

export default connect(mapStateToProps, mapDispatchToProps)(AlertsForm);
