import React, { Component } from 'react';
import { connect } from 'react-redux';
import { editReverseOsmosisMembrane, getReverseOsmosisMembrane } from 'actions/index';
import Loader from 'components/Loader';
import PropTypes from 'prop-types';
import ROMembraneForm from './ROMembraneForm';

class EditROMembrane extends Component {
  componentDidMount() {
    const {
      match: { params },
      fetchROMembrane,
    } = this.props;
    const { id } = params;

    fetchROMembrane(id);
  }

  onSubmit = data => {
    const {
      submitROMembrane,
      match: { params },
    } = this.props;
    const { id } = params;

    submitROMembrane(id, data);
  };

  render() {
    const { library, loading, token } = this.props;

    if (loading) {
      return <Loader size={200} block />;
    }

    if (!library) {
      return null;
    }

    const initialValues = {
      name: library.name,
      warranty: library.warranty,
    };

    return (
      <ROMembraneForm
        onSubmit={this.onSubmit}
        title="Edit RO Membrane"
        initialValues={initialValues}
        loading={loading}
        token={token}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    library: state.libraries.item,
    loading: state.libraries.loading,
    token: state.auth.token,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    submitROMembrane: (id, library) => dispatch(editReverseOsmosisMembrane(id, library)),
    fetchROMembrane: id => dispatch(getReverseOsmosisMembrane(id)),
  };
}

EditROMembrane.propTypes = {
  fetchROMembrane: PropTypes.func,
  library: PropTypes.object,
  loading: PropTypes.bool,
  match: {
    params: {
      id: PropTypes.string,
    },
  },
  submitROMembrane: PropTypes.func,
  token: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditROMembrane);
