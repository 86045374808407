import React from 'react';
import Modal from 'components/Modal';
import DatePickerComponent from 'components/Datepicker/DatePickerComponent';
import Row from 'components/Row';
import Col from 'components/Col';
import H4 from 'components/H4';
import { Colors } from 'modules/theme';
import Icon from 'components/Icon';
import ButtonText from 'components/ButtonText';
import PropTypes from 'prop-types';

export const ModalDatePicker = ({
  isVisible,
  switchModal,
  dateFrom,
  dateTo,
  handleDateFrom,
  handleDateTo,
  saveChanges,
  minDate,
}) => (
  <Modal
    title="Choose a time period"
    onClose={switchModal}
    isVisible={isVisible}
    size="medium"
    height="little"
    zIndex={3500}
  >
    <Row style={{ marginBottom: 15 }}>
      <Col lg={30} md={30} sm={30} xs={30} alignItems="flex-end">
        <H4 color={Colors.lightBlue} style={{ marginRight: 10 }}>
          From:
        </H4>
      </Col>
      <Col lg={44} md={44} sm={44} xs={44} alignItems="flex-start">
        <DatePickerComponent minDate={minDate} date={dateFrom} onChange={handleDateFrom} />
      </Col>
      <Col lg={20} md={20} sm={20} xs={20} alignItems="flex-start">
        <Icon name="calendar-alt" color={Colors.lightBlue} customStyle={{ fontSize: '20px' }} />
      </Col>
    </Row>
    <Row style={{ marginBottom: 15 }}>
      <Col lg={30} md={30} sm={30} xs={30} alignItems="flex-end">
        <H4 color={Colors.lightBlue} style={{ marginRight: 10 }}>
          To:
        </H4>
      </Col>
      <Col lg={44} md={44} sm={44} xs={44} alignItems="flex-start">
        <DatePickerComponent minDate={minDate} date={dateTo} onChange={handleDateTo} />
      </Col>
      <Col lg={20} md={20} sm={20} xs={20} alignItems="flex-start">
        <Icon name="calendar-alt" color={Colors.lightBlue} customStyle={{ fontSize: '20px' }} />
      </Col>
    </Row>
    <Row>
      <ButtonText
        onClick={saveChanges}
        text="Save"
        theme="primary"
        customStyles={{ marginTop: 25 }}
      />
    </Row>
  </Modal>
);

ModalDatePicker.propTypes = {
  dateFrom: PropTypes.string,
  dateTo: PropTypes.string,
  handleDateFrom: PropTypes.func,
  handleDateTo: PropTypes.func,
  isVisible: PropTypes.bool,
  minDate: PropTypes.string,
  saveChanges: PropTypes.func,
  switchModal: PropTypes.func,
};
