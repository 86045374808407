import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getBrands, deleteBrand } from 'actions/index';
import PropTypes from 'prop-types';
import BrandListView from './BrandListView';

class BrandListContainer extends Component {
  componentDidMount() {
    const { fetchBrands } = this.props;

    fetchBrands();
  }

  render() {
    const { list, pages, currentPage, loading, fetchBrands, removeBrand } = this.props;

    return (
      <BrandListView
        list={list}
        pages={pages}
        currentPage={currentPage}
        fetchBrands={fetchBrands}
        loading={loading}
        removeBrand={removeBrand}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    list: state.brands.list,
    pages: state.brands.pages,
    currentPage: state.brands.currentPage,
    loading: state.brands.loading,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    fetchBrands: params => dispatch(getBrands(params)),
    removeBrand: id => dispatch(deleteBrand(id)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(BrandListContainer);

BrandListContainer.propTypes = {
  currentPage: PropTypes.number,
  fetchBrands: PropTypes.func,
  list: PropTypes.array,
  loading: PropTypes.bool,
  pages: PropTypes.number,
  removeBrand: PropTypes.func,
};
