import React, { Component } from 'react';
import styled from 'styled-components';
import { Colors } from 'modules/theme';
import Input from 'components/InputText';
import H4 from 'components/H4';
import FormError from 'components/FormError';
import PropTypes from 'prop-types';

const Container = styled.div``;

const InputContainer = styled.div`
  margin: 20px 0px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const Column = styled.div`
  flex-direction: column;
`;

export default class SoftenerForm extends Component {
  render() {
    const { values, errors, touched, handleChange, handleBlur } = this.props;

    return (
      <Container>
        <Row>
          <Column style={{ width: '33.3%', paddingRight: '30px' }}>
            <InputContainer>
              <H4 inputLabel color={Colors.gray8}>
                Pulses per gallon
              </H4>
              <Input
                type="number"
                placeholder="Pulses per gallon"
                onChange={handleChange}
                name="pulses_per_galon"
                value={values.pulses_per_galon}
                onBlur={handleBlur}
              />
              <FormError
                text={
                  errors.pulses_per_galon && touched.pulses_per_galon && errors.pulses_per_galon
                }
              />
            </InputContainer>
          </Column>
        </Row>
      </Container>
    );
  }
}

SoftenerForm.propTypes = {
  errors: PropTypes.object,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  touched: PropTypes.object,
  values: PropTypes.object,
};
