// Webpack Polyfill Injector
function main() {
    require("/home/ubuntu/actions-runner/_work/kinetico-pro-web/kinetico-pro-web/src/index.jsx");
}
var polyfills = function() {
    return [
        /* Array.prototype.find */ ("find"in Array.prototype
) ? 0 : 1,
        /* Promise */ ("Promise"in self
) ? 0 : 1
    ];
}.call(window);
if (polyfills.indexOf(1) === -1) {
    main();
} else {
    var js = document.createElement('script');
    js.src = "/polyfills.[git-hash]." + polyfills.join('') + '.js';
    js.onload = main;
    js.onerror = function onError(message) {
        console.error('Could not load the polyfills: ' + message);
    };
    document.head.appendChild(js);
}