// @flow
/**
 * @module Actions/Alert
 * @desc Alert Actions
 */
import { createActions } from 'redux-actions';

import { ActionTypes } from 'constants/index';

export const {
  getAlertsTypes,
  getAlertType,
  createAlertType,
  editAlertType,
  deleteAlertType,
} = createActions({
  [ActionTypes.GET_ALERTS_TYPES]: params => params,
  [ActionTypes.GET_ALERT_TYPE]: id => ({ id }),
  [ActionTypes.CREATE_ALERT_TYPE]: data => data,
  [ActionTypes.EDIT_ALERT_TYPE]: (id, alertType) => ({ id, alertType }),
  [ActionTypes.DELETE_ALERT_TYPE]: id => ({ id }),
});
