import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getUpdateActiveDevices } from 'actions/index';
import PropTypes from 'prop-types';
import OtaActiveDevicesListView from './OtaActiveDevicesListView';

class OtaActiveDevicesListContainer extends Component {
  componentDidMount() {
    const {
      match: { params },
      fetchUpdateActiveDevices,
    } = this.props;

    fetchUpdateActiveDevices({ id: params.update_id });
  }

  render() {
    const {
      match: { params },
      fetchUpdateActiveDevices,
      loadingActiveDevices,
      listActiveDevices,
    } = this.props;
    return (
      <OtaActiveDevicesListView
        loadingActiveDevices={loadingActiveDevices}
        listActiveDevices={listActiveDevices}
        fetchUpdateActiveDevices={fetchUpdateActiveDevices}
        update_id={params.update_id}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    loadingActiveDevices: state.ota.loadingActiveDevices,
    listActiveDevices: state.ota.listActiveDevices,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    fetchUpdateActiveDevices: params => dispatch(getUpdateActiveDevices(params)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(OtaActiveDevicesListContainer);

OtaActiveDevicesListContainer.propTypes = {
  fetchUpdateActiveDevices: PropTypes.func,
  listActiveDevices: PropTypes.array,
  loadingActiveDevices: PropTypes.bool,
  match: {
    params: {
      update_id: PropTypes.string,
    },
  },
};
