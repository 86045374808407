import { handleActions } from 'modules/helpers';

import { ActionTypes } from 'constants/index';

export const authState = {
  isAuthenticated: false,
  profile: null,
  token: null,
  loading: false,
  requireLogout: false,
  token_expired: false,
};

export default {
  auth: handleActions(
    {
      [ActionTypes.USER_LOGIN]: draft => {
        draft.loading = true;
      },
      [ActionTypes.USER_LOGIN_SUCCESS]: (draft, { payload }) => {
        draft.isAuthenticated = true;
        draft.profile = payload.profile;
        draft.token = payload.token;
        draft.loading = false;
      },
      [ActionTypes.USER_LOGIN_FAILURE]: draft => {
        draft.loading = false;
      },
      [ActionTypes.USER_LOGOUT]: draft => {
        draft.loading = true;
      },
      [ActionTypes.USER_LOGOUT_SUCCESS]: draft => {
        draft.isAuthenticated = false;
        draft.loading = true;
        draft.requireLogout = false;
      },
      [ActionTypes.UPDATE_PROFILE_SUCCESS]: (draft, { payload }) => {
        draft.profile = { ...draft.profile, ...payload.profile };
      },
      [ActionTypes.REQUIRE_LOGOUT]: draft => {
        draft.requireLogout = true;
      },
      [ActionTypes.UPDATE_INVITATION_TOKEN_EXPIRED]: (draft, { payload: { token_expired } }) => {
        draft.token_expired = token_expired;
      },
    },
    authState,
  ),
};
