import React from 'react';
import styled from 'styled-components';
import { Colors } from 'modules/theme';

const SelectableButton = styled.a`
  width: 100%;
  display: inline-block;
  outline: none;
  *zoom: 1;
  text-align: center;
  text-decoration: none;

  font-style: normal;
  font-weight: 350;
  font-size: 18px;
  line-height: 22px;
  color: ${Colors.black};
  border: 1px solid ${Colors.gray7};
  box-sizing: border-box;
  border-radius: 9px;
  padding: 1.3rem 1.3rem;
  min-height: 60px;
  vertical-align: middle;
  transition: all 0.2s ease;
  cursor: pointer;
  &: hover {
    opacity: 0.5;
  }
`;

export default props => (
  <SelectableButton {...props} selected={props.selected} onClick={props.action}>
    {props.text}
  </SelectableButton>
);
