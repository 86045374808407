import React, { Component } from 'react';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Colors } from 'modules/theme';
import { push } from 'modules/history';
import config from 'config';
import Input from 'components/InputText';
import Select from 'components/Select';
import Textarea from 'components/Textarea';
import ButtonText from 'components/ButtonText';
import H2 from 'components/H2';
import H4 from 'components/H4';
import Icon from 'components/Icon';
import DropZone from 'components/DropZone';
import ImageUploaded from 'components/ImageUploaded';
import FormError from 'components/FormError';
import PropTypes from 'prop-types';

const Container = styled.div`
  margin: 30px;
`;

const InputContainer = styled.div`
  margin: 20px 0px;
`;

const ButtonContainer = styled.div`
  margin-top: 25px;
  margin-bottom: 25px;
  float: left;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const Column = styled.div`
  flex-direction: column;
`;

const url = `${config.BASE_URL}/uploads`;

export default class SoftenerForm extends Component {
  submit = values => {
    const { onSubmit } = this.props;

    onSubmit(values);
  };

  render() {
    const { initialValues, token, title, brineDrums, editMode, editable } = this.props;
    const disabledForm = editMode && !editable;

    return (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          brine_drum_id: Yup.string().required('Required'),
          description: Yup.string().required('Required'),
          name: Yup.string().required('Required'),
          overdrive: Yup.string().required('Required'),
        })}
        onSubmit={this.submit}
      >
        {({ values, errors, touched, handleChange, handleBlur, isValid, setFieldValue }) => (
          <Container>
            <Form>
              <Icon onClick={() => push('/libraries')} name="chevron-left" color={Colors.blue} />
              <H2 color={Colors.black}>{title}</H2>
              {disabledForm && (
                <H4 inputLabel color={Colors.red}>
                  You cannot edit this softener because it is assigned to a location.
                </H4>
              )}
              <Row>
                <Column style={{ marginRight: '30px', width: '33.33%' }}>
                  <InputContainer>
                    <H4 inputLabel color={Colors.lightBlue}>
                      Model
                    </H4>
                    <Input
                      placeholder="Model"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      icon="bookmark"
                      disabled={disabledForm}
                    />
                    <FormError text={errors.name && touched.name && errors.name} />
                  </InputContainer>
                </Column>
                <Column style={{ marginRight: '30px', width: '33.33%' }}>
                  <InputContainer>
                    <H4 inputLabel color={Colors.gray8}>
                      Brine Drum Model
                    </H4>
                    <Select
                      placeholder="Brine Drum Model"
                      name="brine_drum_id"
                      options={brineDrums}
                      value={values.brine_drum_id}
                      onChange={handleChange}
                      disabled={disabledForm}
                    />
                    <FormError
                      text={errors.brine_drum_id && touched.brine_drum_id && errors.brine_drum_id}
                    />
                  </InputContainer>
                </Column>
                <Column style={{ marginRight: '30px', width: '33.33%' }}>
                  <InputContainer>
                    <H4 inputLabel color={Colors.gray8}>
                      Bypass Key
                    </H4>
                    <Select
                      placeholder="Bypass Key"
                      name="bypass_key"
                      options={[
                        { label: 'N/A', value: null },
                        { label: 'flow_1', value: 'flow_1' },
                        { label: 'flow_2', value: 'flow_2' },
                        { label: 'flow_3', value: 'flow_3' },
                      ]}
                      value={values.bypass_key}
                      onChange={handleChange}
                      disabled={disabledForm}
                    />
                    <FormError
                      text={errors.bypass_key && touched.bypass_key && errors.bypass_key}
                    />
                  </InputContainer>
                </Column>
              </Row>
              <Row>
                <Column style={{ marginRight: '30px', width: '33.33%' }}>
                  <InputContainer>
                    <H4 inputLabel color={Colors.gray8}>
                      Flow Key
                    </H4>
                    <Select
                      placeholder="Flow Key"
                      name="flow_key"
                      options={[
                        { label: 'flow_1', value: 'flow_1' },
                        { label: 'flow_2', value: 'flow_2' },
                        { label: 'flow_3', value: 'flow_3' },
                      ]}
                      value={values.flow_key}
                      onChange={handleChange}
                      disabled={disabledForm}
                    />
                    <FormError text={errors.flow_key && touched.flow_key && errors.flow_key} />
                  </InputContainer>
                </Column>
                <Column style={{ marginRight: '30px', width: '33.33%' }}>
                  <InputContainer>
                    <H4 inputLabel color={Colors.gray8}>
                      Distance Key
                    </H4>
                    <Select
                      placeholder="Distance Key"
                      name="distance_key"
                      options={[{ label: 'distance', value: 'distance' }]}
                      value={values.distance_key}
                      onChange={handleChange}
                      disabled={disabledForm}
                    />
                    <FormError
                      text={errors.distance_key && touched.distance_key && errors.distance_key}
                    />
                  </InputContainer>
                </Column>
                <Column style={{ marginRight: '30px', width: '33.33%' }}>
                  <InputContainer>
                    <H4 inputLabel color={Colors.gray8}>
                      TDS Key
                    </H4>
                    <Select
                      placeholder="TDS Key"
                      name="tds_key"
                      options={[{ label: 'tds', value: 'tds' }]}
                      value={values.tds_key}
                      onChange={handleChange}
                      disabled={disabledForm}
                    />
                    <FormError text={errors.tds_key && touched.tds_key && errors.tds_key} />
                  </InputContainer>
                </Column>
              </Row>

              <Row>
                <Column style={{ paddingRight: '30px', width: '33%' }}>
                  <H4 inputLabel color={Colors.gray8}>
                    Inlet Pressure Key
                  </H4>
                  <Select
                    placeholder="Inlet Pressure Key"
                    name="inlet_pressure_key"
                    options={[
                      { label: 'psi_1', value: 'psi_1' },
                      { label: 'psi_2', value: 'psi_2' },
                      { label: 'psi_3', value: 'psi_3' },
                      { label: 'psi_4', value: 'psi_4' },
                    ]}
                    value={values.inlet_pressure_key}
                    onChange={handleChange}
                    disabled={disabledForm}
                  />
                  <FormError text={errors.inlet_pressure_key && errors.inlet_pressure_key} />
                </Column>
                <Column style={{ paddingRight: '30px', width: '33%' }}>
                  <H4 inputLabel color={Colors.gray8}>
                    Outlet Pressure Key
                  </H4>
                  <Select
                    placeholder="Outlet Pressure Key"
                    name="outlet_pressure_key"
                    options={[
                      { label: 'psi_1', value: 'psi_1' },
                      { label: 'psi_2', value: 'psi_2' },
                      { label: 'psi_3', value: 'psi_3' },
                      { label: 'psi_4', value: 'psi_4' },
                    ]}
                    value={values.outlet_pressure_key}
                    onChange={handleChange}
                    disabled={disabledForm}
                  />
                  <FormError text={errors.outlet_pressure_key && errors.outlet_pressure_key} />
                </Column>
              </Row>
              <Row>
                <Column style={{ marginRight: '30px', paddingRight: '30px', width: '66.66%' }}>
                  <InputContainer>
                    <H4 inputLabel color={Colors.gray8}>
                      Overdrive
                    </H4>
                    <Input
                      placeholder="overdrive"
                      name="overdrive"
                      value={values.overdrive}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={disabledForm}
                    />
                    <FormError text={errors.overdrive && touched.overdrive && errors.overdrive} />
                  </InputContainer>
                </Column>
              </Row>
              <Row>
                <Column style={{ marginRight: '30px', paddingRight: '30px', width: '66.66%' }}>
                  <InputContainer>
                    <H4 inputLabel color={Colors.gray8}>
                      Description
                    </H4>
                    <Textarea
                      value={values.description}
                      onChange={value => setFieldValue('description', value)}
                      higlights={[]}
                      onBlur={handleBlur}
                      disabled={disabledForm}
                    />

                    <FormError
                      text={errors.description && touched.description && errors.description}
                    />
                  </InputContainer>
                </Column>
              </Row>
              <Row>
                <Column style={{ width: '33.33%', paddingRight: '30px' }}>
                  <H4 inputLabel color={Colors.black}>
                    Photo
                  </H4>

                  {values.photo ? (
                    <ImageUploaded
                      image={values.photo}
                      onClick={() => setFieldValue('photo', null)}
                    />
                  ) : (
                    <DropZone
                      folder="softener"
                      title="Choose a photo"
                      height="100px"
                      token={token}
                      handleResponse={({ url }) => setFieldValue('photo', url)}
                      url={url}
                      disabled={disabledForm}
                    />
                  )}
                </Column>
              </Row>
              <Row>
                <ButtonContainer>
                  <ButtonText text="Save" theme="primary" type="submit" disabled={!isValid} />
                </ButtonContainer>
              </Row>
            </Form>
          </Container>
        )}
      </Formik>
    );
  }
}

SoftenerForm.propTypes = {
  brineDrums: PropTypes.array,
  editable: PropTypes.bool,
  editMode: PropTypes.bool,
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  title: PropTypes.string,
  token: PropTypes.string,
};
