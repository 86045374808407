import { REHYDRATE } from 'redux-persist/lib/constants';
import { handleActions } from 'modules/helpers';

import { ActionTypes } from 'constants/index';

export const appState = {
  list: [],
  pages: 0,
  currentPage: 0,
  loading: false,
  catridgesForFilter: [],
  waterFiltersForFilter: [],
  reverseOsmosisMembraneForFilter: [],
  reverseOsmosisForFilter: [],
  softenerForFilter: [],
  brineDrumForFilter: [],
  waterFilterConfigurationsForFilter: [],
  reverseOsmosisConfigurationsForFilter: [],
  softenerConfigurationsForFilter: [],
  tanks: [],
  item: null,
  flowSensorsForSelect: [],
  pressureSensorsForSelect: [],
  distanceSensorsForSelect: [],
  tdsProbeSensorsForSelect: [],
  tempSensorsForSelect: [],
};

export default {
  libraries: handleActions(
    {
      [REHYDRATE]: draft => {
        draft.list = [];
      },
      [ActionTypes.GET_LIBRARIES]: draft => {
        draft.loading = true;
      },
      [ActionTypes.GET_LIBRARIES_SUCCESS]: (draft, { payload: { rows, pages, currentPage } }) => {
        draft.list = rows;
        draft.pages = pages;
        draft.currentPage = currentPage;
        draft.loading = false;
      },
      [ActionTypes.GET_LIBRARIES_FAILURE]: draft => {
        draft.loading = false;
      },
      [ActionTypes.DELETE_LIBRARY]: draft => {
        draft.loading = true;
      },
      [ActionTypes.DELETE_LIBRARY_SUCCESS]: (draft, { payload: { id } }) => {
        draft.list = draft.list.filter(item => item.id !== id);
        draft.loading = false;
      },
      [ActionTypes.DELETE_LIBRARY_FAILURE]: draft => {
        draft.loading = false;
      },
      [ActionTypes.GET_CATRIDGE]: draft => {
        draft.item = null;
        draft.loading = true;
      },
      [ActionTypes.GET_CATRIDGE_SUCCESS]: (draft, { payload }) => {
        draft.item = payload;
        draft.loading = false;
      },
      [ActionTypes.GET_CATRIDGE_FAILURE]: draft => {
        draft.loading = false;
      },
      [ActionTypes.GET_WATER_FILTER]: draft => {
        draft.item = null;
        draft.loading = true;
      },
      [ActionTypes.GET_WATER_FILTER_SUCCESS]: (draft, { payload }) => {
        draft.item = payload;
        draft.loading = false;
      },
      [ActionTypes.GET_WATER_FILTER_FAILURE]: draft => {
        draft.loading = false;
      },
      [ActionTypes.GET_REVERSE_OSMOSIS_MEMBRANE]: draft => {
        draft.item = null;
        draft.loading = true;
      },
      [ActionTypes.GET_REVERSE_OSMOSIS_MEMBRANE_SUCCESS]: (draft, { payload }) => {
        draft.item = payload;
        draft.loading = false;
      },
      [ActionTypes.GET_REVERSE_OSMOSIS_MEMBRANE_FAILURE]: draft => {
        draft.loading = false;
      },
      [ActionTypes.GET_REVERSE_OSMOSIS]: draft => {
        draft.item = null;
        draft.loading = true;
      },
      [ActionTypes.GET_REVERSE_OSMOSIS_SUCCESS]: (draft, { payload }) => {
        draft.item = payload;
        draft.loading = false;
      },
      [ActionTypes.GET_REVERSE_OSMOSIS_FAILURE]: draft => {
        draft.loading = false;
      },
      [ActionTypes.GET_SOFTENER]: draft => {
        draft.item = null;
        draft.loading = true;
      },
      [ActionTypes.GET_SOFTENER_SUCCESS]: (draft, { payload }) => {
        draft.item = payload;
        draft.loading = false;
      },
      [ActionTypes.GET_SOFTENER_FAILURE]: draft => {
        draft.loading = false;
      },
      [ActionTypes.GET_BRINE_DRUM]: draft => {
        draft.item = null;
        draft.loading = true;
      },
      [ActionTypes.GET_BRINE_DRUM_SUCCESS]: (draft, { payload }) => {
        draft.item = payload;
        draft.loading = false;
      },
      [ActionTypes.GET_BRINE_DRUM_FAILURE]: draft => {
        draft.loading = false;
      },
      [ActionTypes.GET_TANKS]: draft => {
        draft.loading = true;
      },
      [ActionTypes.GET_TANKS_SUCCESS]: (draft, { payload }) => {
        draft.tanks = payload;
        draft.loading = false;
      },
      [ActionTypes.GET_TANKS_FAILURE]: draft => {
        draft.loading = false;
      },
      [ActionTypes.GET_CONFIGURATION]: draft => {
        draft.item = null;
        draft.loading = true;
      },
      [ActionTypes.GET_CONFIGURATION_SUCCESS]: (draft, { payload }) => {
        draft.item = payload;
        draft.loading = false;
      },
      [ActionTypes.GET_CONFIGURATION_FAILURE]: draft => {
        draft.loading = false;
      },
      [ActionTypes.GET_SENSOR]: draft => {
        draft.item = null;
        draft.loading = true;
      },
      [ActionTypes.GET_SENSOR_SUCCESS]: (draft, { payload }) => {
        draft.item = payload;
        draft.loading = false;
      },
      [ActionTypes.GET_SENSOR_FAILURE]: draft => {
        draft.loading = false;
      },
      [ActionTypes.GET_CATRIDGES_FOR_FILTER]: draft => {
        draft.loading = true;
      },
      [ActionTypes.GET_CATRIDGES_FOR_FILTER_SUCCESS]: (draft, { payload }) => {
        draft.catridgesForFilter = payload;
        draft.loading = false;
      },
      [ActionTypes.GET_CATRIDGES_FOR_FILTER_FAILURE]: draft => {
        draft.loading = false;
      },
      [ActionTypes.GET_WATER_FILTERS_FOR_FILTER]: draft => {
        draft.loading = true;
      },
      [ActionTypes.GET_WATER_FILTERS_FOR_FILTER_SUCCESS]: (draft, { payload }) => {
        draft.waterFiltersForFilter = payload;
        draft.loading = false;
      },
      [ActionTypes.GET_WATER_FILTERS_FOR_FILTER_FAILURE]: draft => {
        draft.loading = false;
      },

      [ActionTypes.GET_REVERSE_OSMOSIS_MEMBRANE_FOR_FILTER]: draft => {
        draft.loading = true;
      },
      [ActionTypes.GET_REVERSE_OSMOSIS_MEMBRANE_FOR_FILTER_SUCCESS]: (draft, { payload }) => {
        draft.reverseOsmosisMembraneForFilter = payload;
        draft.loading = false;
      },
      [ActionTypes.GET_REVERSE_OSMOSIS_MEMBRANE_FOR_FILTER_FAILURE]: draft => {
        draft.loading = false;
      },

      [ActionTypes.GET_REVERSE_OSMOSIS_FOR_FILTER]: draft => {
        draft.loading = true;
      },
      [ActionTypes.GET_REVERSE_OSMOSIS_FOR_FILTER_SUCCESS]: (draft, { payload }) => {
        draft.reverseOsmosisForFilter = payload;
        draft.loading = false;
      },
      [ActionTypes.GET_REVERSE_OSMOSIS_FOR_FILTER_FAILURE]: draft => {
        draft.loading = false;
      },

      [ActionTypes.GET_SOFTENER_FOR_FILTER]: draft => {
        draft.loading = true;
      },
      [ActionTypes.GET_SOFTENER_FOR_FILTER_SUCCESS]: (draft, { payload }) => {
        draft.softenerForFilter = payload;
        draft.loading = false;
      },
      [ActionTypes.GET_SOFTENER_FOR_FILTER_FAILURE]: draft => {
        draft.loading = false;
      },

      [ActionTypes.GET_BRINE_DRUM_FOR_FILTER]: draft => {
        draft.loading = true;
      },
      [ActionTypes.GET_BRINE_DRUM_FOR_FILTER_SUCCESS]: (draft, { payload }) => {
        draft.brineDrumForFilter = payload;
        draft.loading = false;
      },
      [ActionTypes.GET_BRINE_DRUM_FOR_FILTER_FAILURE]: draft => {
        draft.loading = false;
      },

      [ActionTypes.GET_WATER_FILTER_CONFIGURATIONS_FOR_FILTER]: draft => {
        draft.loading = true;
      },
      [ActionTypes.GET_WATER_FILTER_CONFIGURATIONS_FOR_FILTER_SUCCESS]: (draft, { payload }) => {
        draft.waterFilterConfigurationsForFilter = payload;
        draft.loading = false;
      },
      [ActionTypes.GET_WATER_FILTER_CONFIGURATIONS_FOR_FILTER_FAILURE]: draft => {
        draft.loading = false;
      },
      [ActionTypes.GET_REVERSE_OSMOSIS_CONFIGURATIONS_FOR_FILTER]: draft => {
        draft.loading = true;
      },
      [ActionTypes.GET_REVERSE_OSMOSIS_CONFIGURATIONS_FOR_FILTER_SUCCESS]: (draft, { payload }) => {
        draft.reverseOsmosisConfigurationsForFilter = payload;
        draft.loading = false;
      },
      [ActionTypes.GET_REVERSE_OSMOSIS_CONFIGURATIONS_FOR_FILTER_FAILURE]: draft => {
        draft.loading = false;
      },
      [ActionTypes.GET_SOFTENER_CONFIGURATIONS_FOR_FILTER]: draft => {
        draft.loading = true;
      },
      [ActionTypes.GET_SOFTENER_CONFIGURATIONS_FOR_FILTER_SUCCESS]: (draft, { payload }) => {
        draft.softenerConfigurationsForFilter = payload;
        draft.loading = false;
      },
      [ActionTypes.GET_SOFTENER_CONFIGURATIONS_FOR_FILTER_FAILURE]: draft => {
        draft.loading = false;
      },
      [ActionTypes.CREATE_WATER_CATRIDGE]: draft => {
        draft.loading = true;
      },
      [ActionTypes.CREATE_WATER_CATRIDGE_SUCCESS]: draft => {
        draft.loading = false;
      },
      [ActionTypes.CREATE_WATER_CATRIDGE_FAILURE]: draft => {
        draft.loading = false;
      },

      [ActionTypes.CREATE_WATER_FILTER]: draft => {
        draft.loading = true;
      },
      [ActionTypes.CREATE_WATER_FILTER_SUCCESS]: draft => {
        draft.loading = false;
      },
      [ActionTypes.CREATE_WATER_FILTER_FAILURE]: draft => {
        draft.loading = false;
      },
      [ActionTypes.CREATE_CONFIGURATION]: draft => {
        draft.loading = true;
      },
      [ActionTypes.CREATE_CONFIGURATION_SUCCESS]: draft => {
        draft.loading = false;
      },
      [ActionTypes.CREATE_CONFIGURATION_FAILURE]: draft => {
        draft.loading = false;
      },
      [ActionTypes.CREATE_SOFTENER]: draft => {
        draft.loading = true;
      },
      [ActionTypes.CREATE_SOFTENER_SUCCESS]: draft => {
        draft.loading = false;
      },
      [ActionTypes.CREATE_SOFTENER_FAILURE]: draft => {
        draft.loading = false;
      },
      [ActionTypes.CREATE_REVERSE_OSMOSIS]: draft => {
        draft.loading = true;
      },
      [ActionTypes.CREATE_REVERSE_OSMOSIS_SUCCESS]: draft => {
        draft.loading = false;
      },
      [ActionTypes.CREATE_REVERSE_OSMOSIS_FAILURE]: draft => {
        draft.loading = false;
      },
      [ActionTypes.CREATE_BRINE_DRUM]: draft => {
        draft.loading = true;
      },
      [ActionTypes.CREATE_BRINE_DRUM_SUCCESS]: draft => {
        draft.loading = false;
      },
      [ActionTypes.CREATE_BRINE_DRUM_FAILURE]: draft => {
        draft.loading = false;
      },
      [ActionTypes.CREATE_REVERSE_OSMOSIS_MEMBRANE]: draft => {
        draft.loading = true;
      },
      [ActionTypes.CREATE_REVERSE_OSMOSIS_MEMBRANE_SUCCESS]: draft => {
        draft.loading = false;
      },
      [ActionTypes.CREATE_REVERSE_OSMOSIS_MEMBRANE_FAILURE]: draft => {
        draft.loading = false;
      },
      [ActionTypes.EDIT_WATER_CATRIDGE]: draft => {
        draft.loading = true;
      },
      [ActionTypes.EDIT_WATER_CATRIDGE_SUCCESS]: draft => {
        draft.loading = false;
      },
      [ActionTypes.EDIT_WATER_CATRIDGE_FAILURE]: draft => {
        draft.loading = false;
      },
      [ActionTypes.EDIT_WATER_FILTER]: draft => {
        draft.loading = true;
      },
      [ActionTypes.EDIT_WATER_FILTER_SUCCESS]: draft => {
        draft.loading = false;
      },
      [ActionTypes.EDIT_WATER_FILTER_FAILURE]: draft => {
        draft.loading = false;
      },
      [ActionTypes.EDIT_CONFIGURATION]: draft => {
        draft.loading = true;
      },
      [ActionTypes.EDIT_CONFIGURATION_SUCCESS]: draft => {
        draft.loading = false;
      },
      [ActionTypes.EDIT_CONFIGURATION_FAILURE]: draft => {
        draft.loading = false;
      },
      [ActionTypes.EDIT_SOFTENER]: draft => {
        draft.loading = true;
      },
      [ActionTypes.EDIT_SOFTENER_SUCCESS]: draft => {
        draft.loading = false;
      },
      [ActionTypes.EDIT_SOFTENER_FAILURE]: draft => {
        draft.loading = false;
      },
      [ActionTypes.EDIT_REVERSE_OSMOSIS]: draft => {
        draft.loading = true;
      },
      [ActionTypes.EDIT_REVERSE_OSMOSIS_SUCCESS]: draft => {
        draft.loading = false;
      },
      [ActionTypes.EDIT_REVERSE_OSMOSIS_FAILURE]: draft => {
        draft.loading = false;
      },
      [ActionTypes.EDIT_BRINE_DRUM]: draft => {
        draft.loading = true;
      },
      [ActionTypes.EDIT_BRINE_DRUM_SUCCESS]: draft => {
        draft.loading = false;
      },
      [ActionTypes.EDIT_BRINE_DRUM_FAILURE]: draft => {
        draft.loading = false;
      },
      [ActionTypes.EDIT_REVERSE_OSMOSIS_MEMBRANE]: draft => {
        draft.loading = true;
      },
      [ActionTypes.EDIT_REVERSE_OSMOSIS_MEMBRANE_SUCCESS]: draft => {
        draft.loading = false;
      },
      [ActionTypes.EDIT_REVERSE_OSMOSIS_MEMBRANE_FAILURE]: draft => {
        draft.loading = false;
      },
      [ActionTypes.GET_FLOW_SENSORS_FOR_SELECT]: draft => {
        draft.loading = true;
      },
      [ActionTypes.GET_FLOW_SENSORS_FOR_SELECT_SUCCESS]: (draft, { payload }) => {
        draft.flowSensorsForSelect = payload;
        draft.loading = false;
      },
      [ActionTypes.GET_FLOW_SENSORS_FOR_SELECT_FAILURE]: draft => {
        draft.loading = false;
      },
      [ActionTypes.GET_PRESSURE_SENSORS_FOR_SELECT]: draft => {
        draft.loading = true;
      },
      [ActionTypes.GET_PRESSURE_SENSORS_FOR_SELECT_SUCCESS]: (draft, { payload }) => {
        draft.pressureSensorsForSelect = payload;
        draft.loading = false;
      },
      [ActionTypes.GET_DISTANCE_SENSORS_FOR_SELECT_FAILURE]: draft => {
        draft.loading = false;
      },
      [ActionTypes.GET_DISTANCE_SENSORS_FOR_SELECT]: draft => {
        draft.loading = true;
      },
      [ActionTypes.GET_DISTANCE_SENSORS_FOR_SELECT_SUCCESS]: (draft, { payload }) => {
        draft.distanceSensorsForSelect = payload;
        draft.loading = false;
      },
      [ActionTypes.GET_DISTANCE_SENSORS_FOR_SELECT_FAILURE]: draft => {
        draft.loading = false;
      },
      [ActionTypes.GET_TDS_PROBE_SENSORS_FOR_SELECT]: draft => {
        draft.loading = true;
      },
      [ActionTypes.GET_TDS_PROBE_SENSORS_FOR_SELECT_SUCCESS]: (draft, { payload }) => {
        draft.tdsProbeSensorsForSelect = payload;
        draft.loading = false;
      },
      [ActionTypes.GET_TDS_PROBE_SENSORS_FOR_SELECT_FAILURE]: draft => {
        draft.loading = false;
      },
      [ActionTypes.GET_TEMPERATURE_SENSORS_FOR_SELECT]: draft => {
        draft.loading = true;
      },
      [ActionTypes.GET_TEMPERATURE_SENSORS_FOR_SELECT_SUCCESS]: (draft, { payload }) => {
        draft.tempSensorsForSelect = payload;
        draft.loading = false;
      },
      [ActionTypes.GET_TEMPERATURE_SENSORS_FOR_SELECT_FAILURE]: draft => {
        draft.loading = false;
      },
    },
    appState,
  ),
};
