import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  createConfiguration,
  getWaterFiltersForFilter,
  getReverseOsmosisForFilter,
  getSoftenerForFilter,
  getFlowSensorsForSelect,
  getPressureSensorsForSelect,
  getDistanceSensorsForSelect,
  getTdsProbeSensorsForSelect,
  getTemperatureSensorsForSelect,
} from 'actions/index';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import DeviceConfigurationForm from './DeviceConfigurationForm';

const initialValues = {
  name: '',
  device_type: '',
  waterFilters: [
    {
      water_filter_id: '',
    },
  ],
  reverseOsmosisFilters: [
    {
      reverse_osmosis_filter_id: '',
    },
  ],
  installationSteps: [{ title: '', description: '', photo: null }],
};

class CreateDeviceConfiguration extends Component {
  componentDidMount() {
    const {
      fetchWaterFilters,
      fetchReverseOsmosisFilters,
      fetchSofteners,
      fetchFlowSensorsForSelect,
      fetchPressureSensorsForSelect,
      fetchDistanceSensorsForSelect,
      fetchTdsProbeSensorsForSelect,
      fetchTempSensorsForSelect,
    } = this.props;
    fetchWaterFilters();
    fetchReverseOsmosisFilters();
    fetchSofteners();
    fetchFlowSensorsForSelect();
    fetchPressureSensorsForSelect();
    fetchDistanceSensorsForSelect();
    fetchTdsProbeSensorsForSelect();
    fetchTempSensorsForSelect();
  }

  onSubmitConfig = values => {
    const { submitDeviceConfiguration } = this.props;
    if (isEqual(values.installationSteps, initialValues.installationSteps)) {
      values.installationSteps = [];
    }
    submitDeviceConfiguration(values);
  };

  render() {
    const {
      waterFilters,
      reverseOsmosisFilters,
      softeners,
      loading,
      token,
      flowSensorsForSelect,
      pressureSensorsForSelect,
      distanceSensorsForSelect,
      tdsProbeSensorsForSelect,
      tempSensorsForSelect,
    } = this.props;

    return (
      <DeviceConfigurationForm
        title="Add New Configuration"
        initialValues={initialValues}
        onSubmit={this.onSubmitConfig}
        loading={loading}
        waterFilters={waterFilters}
        reverseOsmosisFilters={reverseOsmosisFilters}
        softeners={softeners}
        token={token}
        flowSensorsForSelect={flowSensorsForSelect}
        pressureSensorsForSelect={pressureSensorsForSelect}
        distanceSensorsForSelect={distanceSensorsForSelect}
        tdsProbeSensorsForSelect={tdsProbeSensorsForSelect}
        tempSensorsForSelect={tempSensorsForSelect}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    loading: state.libraries.loading,
    token: state.auth.token,
    waterFilters: state.libraries.waterFiltersForFilter,
    reverseOsmosisFilters: state.libraries.reverseOsmosisForFilter,
    softeners: state.libraries.softenerForFilter,
    flowSensorsForSelect: state.libraries.flowSensorsForSelect,
    pressureSensorsForSelect: state.libraries.pressureSensorsForSelect,
    distanceSensorsForSelect: state.libraries.distanceSensorsForSelect,
    tdsProbeSensorsForSelect: state.libraries.tdsProbeSensorsForSelect,
    tempSensorsForSelect: state.libraries.tempSensorsForSelect,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    submitDeviceConfiguration: library => dispatch(createConfiguration(library)),
    fetchWaterFilters: () => dispatch(getWaterFiltersForFilter()),
    fetchReverseOsmosisFilters: () => dispatch(getReverseOsmosisForFilter()),
    fetchSofteners: () => dispatch(getSoftenerForFilter()),
    fetchFlowSensorsForSelect: () => dispatch(getFlowSensorsForSelect()),
    fetchPressureSensorsForSelect: () => dispatch(getPressureSensorsForSelect()),
    fetchDistanceSensorsForSelect: () => dispatch(getDistanceSensorsForSelect()),
    fetchTdsProbeSensorsForSelect: () => dispatch(getTdsProbeSensorsForSelect()),
    fetchTempSensorsForSelect: () => dispatch(getTemperatureSensorsForSelect()),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(CreateDeviceConfiguration);

CreateDeviceConfiguration.propTypes = {
  distanceSensorsForSelect: PropTypes.array,
  fetchDistanceSensorsForSelect: PropTypes.func,
  fetchFlowSensorsForSelect: PropTypes.func,
  fetchPressureSensorsForSelect: PropTypes.func,
  fetchReverseOsmosisFilters: PropTypes.func,
  fetchSofteners: PropTypes.func,
  fetchTdsProbeSensorsForSelect: PropTypes.func,
  fetchTempSensorsForSelect: PropTypes.func,
  fetchWaterFilters: PropTypes.func,
  flowSensorsForSelect: PropTypes.array,
  loading: PropTypes.bool,
  pressureSensorsForSelect: PropTypes.array,
  reverseOsmosisFilters: PropTypes.array,
  softeners: PropTypes.array,
  submitDeviceConfiguration: PropTypes.func,
  tdsProbeSensorsForSelect: PropTypes.array,
  tempSensorsForSelect: PropTypes.array,
  token: PropTypes.string,
  waterFilters: PropTypes.array,
};
