import React from 'react';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Modal from 'components/Modal';
import H2 from 'components/H2';
import H3 from 'components/H3';
import H4 from 'components/H4';
import FormError from 'components/FormError';
import Select from 'components/Select';
import { Colors } from 'modules/theme';
import Icon from 'components/Icon';
import ButtonText from 'components/ButtonText';
import Switch from 'components/Switch';
import Loader from 'components/Loader';
import Table, { THeader, TableTitle, TRow, TableItem, TableSubItem, TBody } from 'components/Table';
import ListEmpty from 'components/ListEmpty';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import { DeviceTypes, MATCH_DEVICE_TYPE } from '../constants/index';
import Row from './Row';

const Container = styled.div``;

const InputContainer = styled.div`
  margin: 20px 0px;
`;
const ButtonContainer = styled.div`
  flex-direction: column;
  float: right;
`;

const TableContainer = styled.div`
  padding-bottom: 5px;
  margin-bottom: 5px;
`;

const renderItemLocation = (item, key) => (
  <TRow minWidth={200} key={key}>
    {/* <TableSubItem color={Colors.gray} width="8%">
      <input style={{ verticalAlign: 'top' }} type="checkbox" />
    </TableSubItem> */}
    <TableSubItem color={Colors.gray} width="30%">
      {item.type}
    </TableSubItem>
    <TableSubItem color={Colors.gray} width="33.3%">
      {item.current_version || 'Not Found'}
    </TableSubItem>
    <TableSubItem color={Colors.gray} width="33.3%">
      {item.upgrade_to || 'Not Found'}
    </TableSubItem>
  </TRow>
);

const renderItemDeviceFamily = (item, key) => (
  <TRow minWidth={200} key={key}>
    <TableSubItem color={Colors.gray} width="33.3%">
      {item.location_name}
    </TableSubItem>
    <TableSubItem color={Colors.gray} width="33.3%">
      {item.account_name}
    </TableSubItem>
    <TableSubItem color={Colors.gray} width="33.3%">
      {item.current_version || 'Not Found'}
    </TableSubItem>
  </TRow>
);

const renderItemBrand = (item, key) => (
  <TRow minWidth={200} key={key}>
    <TableItem color={Colors.gray} width="25%">
      {item.location_name}
    </TableItem>
    <TableItem color={Colors.gray} width="25%">
      {item.account_name}
    </TableItem>
    <TableItem color={Colors.gray} width="25%">
      {item.type}
    </TableItem>
    <TableItem color={Colors.gray} width="25%">
      {item.current_version || 'Not Found'}
    </TableItem>
  </TRow>
);

const renderStep1 = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
  locationsForFilter,
  brandsForFilter,
}) => (
  <div>
    <InputContainer>
      <Switch
        name="check_location"
        isChecked={values.check_location}
        titleChecked="Perform OTA at a specific location"
        titleUnchecked="Perform OTA at a specific location"
        onChange={evt => {
          setFieldValue(evt.target.name, evt.target.checked);
          if (evt.target.checked) {
            setFieldValue('check_device_family', false);
            setFieldValue('check_brand', false);
            setFieldValue('brand_id', '');
            setFieldValue('device_family', '');
          }
        }}
      />
      <div style={{ paddingLeft: '10px' }}>
        <H3 inputLabel color={Colors.gray8}>
          Used for upgrading one or many devices at a specific location.
        </H3>

        <Select
          placeholder="Location"
          name="location_id"
          options={locationsForFilter}
          value={values.location_id}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={!values.check_location}
        />

        <FormError text={errors.location_id && touched.location_id && errors.location_id} />
      </div>
    </InputContainer>
    <InputContainer>
      <Switch
        name="check_device_family"
        isChecked={values.check_device_family}
        titleChecked="Perform OTA across one device family."
        titleUnchecked="Perform OTA across one device family."
        onChange={evt => {
          setFieldValue(evt.target.name, evt.target.checked);
          if (evt.target.checked) {
            setFieldValue('check_location', false);
            setFieldValue('check_brand', false);
            setFieldValue('location_id', '');
            setFieldValue('brand_id', '');
          }
        }}
      />
      <div style={{ paddingLeft: '10px' }}>
        <H3 inputLabel color={Colors.gray8}>
          Used for upgrading all devices of a specific type across all locations in the entire
          system
        </H3>
        <Select
          placeholder="Device Family"
          name="device_family"
          options={DeviceTypes}
          value={values.device_family}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={!values.check_device_family}
        />
        <FormError text={errors.device_family && touched.device_family && errors.device_family} />
      </div>
    </InputContainer>

    <InputContainer>
      <Switch
        name="check_brand"
        isChecked={values.check_brand}
        titleChecked="Perform OTA by Brand"
        titleUnchecked="Perform OTA by Brand"
        onChange={evt => {
          setFieldValue(evt.target.name, evt.target.checked);
          if (evt.target.checked) {
            setFieldValue('check_location', false);
            setFieldValue('check_device_family', false);
            setFieldValue('location_id', '');
            setFieldValue('device_family', '');
          }
        }}
      />
      <div style={{ paddingLeft: '10px' }}>
        <H3 inputLabel color={Colors.gray8}>
          Used for upgrading all devices of a specific brand_id
        </H3>
        <Select
          placeholder="Brand"
          name="brand_id"
          options={brandsForFilter}
          value={values.brand_id}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={!values.check_brand}
        />
        <FormError text={errors.brand_id && touched.brand_id && errors.brand_id} />
      </div>
    </InputContainer>
  </div>
);

const renderLocation = ({
  values,
  previousInstallationInfo,
  loadingGetPreviousInstallationInfo,
  locationsForFilter,
}) => (
  <>
    {loadingGetPreviousInstallationInfo ? (
      <Loader size={125} block />
    ) : (
      <>
        {/* <InputContainer style={{ width: '75%' }}>
          <Input
            placeholder="Enter Address or Location Name"
            value={locationsForFilter.find(e => e.value === values.location_id).label}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled
          />
          <FormError text={errors.location_id && touched.location_id && errors.location_id} />
        </InputContainer> */}
        <Row justifyContent="start">
          <H4 color={Colors.gray}>Location:</H4>
          <H4>{locationsForFilter.find(e => e.value === parseInt(values.location_id))?.label}</H4>
        </Row>
        <Row justifyContent="start">
          <H4 color={Colors.gray}>Account:</H4>
          <H4>{previousInstallationInfo.location.account_name}</H4>
        </Row>
        <Row justifyContent="start">
          <H4 color={Colors.gray}>Brand:</H4>
          <H4>{previousInstallationInfo.location.brand_name}</H4>
        </Row>
        <Row justifyContent="start">
          <H4 color={Colors.gray}>Location ID:</H4>
          <H4>{previousInstallationInfo.location.identificator}</H4>
        </Row>
        <TableContainer>
          <Table>
            <THeader minWidth={200}>
              <TableTitle width="33.3%">Device</TableTitle>
              <TableTitle width="33.3%">Current Version</TableTitle>
              <TableTitle width="33.3%">Upgrade to</TableTitle>
            </THeader>
            <TBody>
              {previousInstallationInfo.devices.map((item, key) => renderItemLocation(item, key))}
            </TBody>
          </Table>
          {!previousInstallationInfo.devices ||
            (!previousInstallationInfo.devices.length && (
              <ListEmpty message="There are currently no OTA" />
            ))}
        </TableContainer>
      </>
    )}
  </>
);

const renderDeviceFamily = ({
  values,

  previousInstallationInfo,
  loadingGetPreviousInstallationInfo,
}) => (
  <>
    {loadingGetPreviousInstallationInfo ? (
      <Loader size={125} block />
    ) : (
      <>
        {/* <InputContainer style={{ width: '75%' }}>
          <Select
            placeholder="Device Family"
            name="device_family"
            options={DeviceTypes}
            value={values.device_family}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled
          />
          <FormError text={errors.device_family && touched.device_family && errors.device_family} />
        </InputContainer> */}
        <Row justifyContent="start">
          <H4 color={Colors.gray}>Device Family:</H4>
          <H4> {MATCH_DEVICE_TYPE[values.device_family]}</H4>
        </Row>
        <Row justifyContent="start">
          <H4 color={Colors.gray}>Active locations:</H4>
          <H4> {previousInstallationInfo.devices.length}</H4>
        </Row>
        <Row justifyContent="start">
          <H4 color={Colors.gray}>Latest version:</H4>
          <H4>{previousInstallationInfo.upgrade_to || 'Not Found'}</H4>
        </Row>
        <TableContainer>
          <Table>
            <THeader minWidth={200}>
              <TableTitle width="33.3%">Location</TableTitle>
              <TableTitle width="33.3%">Account</TableTitle>
              <TableTitle width="33.3%">Current Version</TableTitle>
            </THeader>
            <TBody>
              {previousInstallationInfo.devices.map((item, key) =>
                renderItemDeviceFamily(item, key),
              )}
            </TBody>
          </Table>
          {!previousInstallationInfo.devices ||
            (!previousInstallationInfo.devices.length && (
              <ListEmpty message="There are currently no OTA" />
            ))}
        </TableContainer>
      </>
    )}
  </>
);

const renderBrand = ({
  values,

  brandsForFilter,
  previousInstallationInfo,
  loadingGetPreviousInstallationInfo,
}) => (
  <>
    {loadingGetPreviousInstallationInfo ? (
      <Loader size={125} block />
    ) : (
      <>
        {/* <InputContainer style={{ width: '75%' }}>
          <Select
            placeholder="Device Family"
            name="brand_id"
            options={OptionsBrands}
            value={values.brand_id}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <FormError text={errors.brand_id && touched.brand_id && errors.brand_id} />
        </InputContainer> */}
        <Row justifyContent="start">
          <H4 color={Colors.gray}>Brand:</H4>
          <H4>{brandsForFilter.find(e => e.value === values.brand_id).label}</H4>
        </Row>
        <Row justifyContent="start">
          <H4 color={Colors.gray}>Active locations:</H4>
          <H4>{previousInstallationInfo.wf_softener_count + previousInstallationInfo.ro_count}</H4>
        </Row>
        <Row justifyContent="start">
          <H4 marginRight="10" color={Colors.gray}>
            Reverse Osmosis:
          </H4>
          <H4 marginRight="10"> {previousInstallationInfo.wf_softener_count}</H4>
          <Icon name="arrow-right" color={Colors.gray2} />
          <H4 marginLeft="10" color={Colors.blue}>
            {previousInstallationInfo.upgrade_to_ro || 'Not Found'}
          </H4>
        </Row>
        <Row justifyContent="start">
          <H4 color={Colors.gray} marginRight="10">
            Filter/ Softener:
          </H4>
          <H4 marginRight="10">{previousInstallationInfo.ro_count}</H4>
          <Icon name="arrow-right" color={Colors.gray2} />
          <H4 marginLeft="10" color={Colors.blue}>
            {previousInstallationInfo.upgrade_to_wf_softener || 'Not Found'}
          </H4>
        </Row>
        <TableContainer>
          <Table>
            <THeader minWidth={200}>
              <TableTitle width="25%">Location</TableTitle>
              <TableTitle width="25%">Account</TableTitle>
              <TableTitle width="25%">Device Type</TableTitle>
              <TableTitle width="25%">Current Version</TableTitle>
            </THeader>
            <TBody>
              {previousInstallationInfo.devices.map((item, key) => renderItemBrand(item, key))}
            </TBody>
          </Table>
          {!previousInstallationInfo.devices ||
            (!previousInstallationInfo.devices.length && (
              <ListEmpty message="There are currently no OTA" />
            ))}
        </TableContainer>
      </>
    )}
  </>
);

export const ModalUpgrade = ({
  stepActive,
  isOpenModal,
  switchModal,
  saveStep1,
  saveData,
  upgradeOptions,
  backStep,
  locationsForFilter,
  brandsForFilter,
  previousInstallationInfo,
  loadingGetPreviousInstallationInfo,
}) => (
  <Modal
    title={
      <H4 marginLeft={20} color={Colors.darkBlue}>
        <span style={{ marginRight: '10px' }}>
          <Icon name="upload" color={Colors.darkBlue} />
        </span>
        OTA Upgrade
      </H4>
    }
    onClose={switchModal}
    isVisible={isOpenModal}
    size="medium"
  >
    <Formik
      enableReinitialize
      initialValues={{ ...upgradeOptions, installation_date: new Date() }}
      validationSchema={Yup.object().shape({
        brand_id: Yup.string().when('check_brand', {
          is: true,
          then: Yup.string().required('This field is required'),
          otherwise: Yup.number(),
        }),
        check_brand: Yup.boolean().required('Required'),
        check_device_family: Yup.boolean().required('Required'),
        check_location: Yup.boolean(),
        device_family: Yup.string().when('check_device_family', {
          is: true,
          then: Yup.string().required('This field is required'),
          otherwise: Yup.string(),
        }),
        installation_date: Yup.string().required('This field is required'),
        location_id: Yup.number().when('check_location', {
          is: true,
          then: Yup.string().required('This field is required'),
          otherwise: Yup.number(),
        }),
      })}
      onSubmit={saveData}
    >
      {({ values, errors, touched, handleChange, handleBlur, isValid, setFieldValue }) => (
        <Container>
          <H2 color={Colors.gray8} weight={325} style={{ marginBottom: 5, textAlign: 'left' }}>
            {`Step ${stepActive}`}
          </H2>
          <Form>
            {stepActive === 1 &&
              renderStep1({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                isValid,
                setFieldValue,
                locationsForFilter,
                brandsForFilter,
              })}

            {stepActive === 2 && (
              <>
                {upgradeOptions.check_location &&
                  renderLocation({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    isValid,
                    setFieldValue,
                    previousInstallationInfo,
                    loadingGetPreviousInstallationInfo,
                    locationsForFilter,
                  })}
                {upgradeOptions.check_device_family &&
                  renderDeviceFamily({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    isValid,
                    setFieldValue,
                    previousInstallationInfo,
                    loadingGetPreviousInstallationInfo,
                  })}
                {upgradeOptions.check_brand &&
                  renderBrand({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    isValid,
                    setFieldValue,
                    brandsForFilter,
                    previousInstallationInfo,
                    loadingGetPreviousInstallationInfo,
                  })}

                <H4 color={Colors.gray}>Set Date & Time</H4>
                <Row justifyContent="start">
                  <DatePicker
                    dateFormat="MM/dd/yyyy h:mm aa"
                    showTimeSelect
                    selected={values.installation_date}
                    onChange={value => {
                      setFieldValue('installation_date', value);
                    }}
                  />
                  <Icon
                    name="calendar-alt"
                    color={Colors.lightBlue}
                    customStyle={{ fontSize: '20px', marginLeft: '10px' }}
                  />
                </Row>
                <FormError
                  text={
                    errors.installation_date &&
                    touched.installation_date &&
                    errors.installation_date
                  }
                />
              </>
            )}

            {stepActive === 1 ? (
              <Row justifyContent="right" style={{ marginTop: '20px' }}>
                <ButtonContainer>
                  <ButtonText
                    text="Next"
                    theme="primary"
                    type="button"
                    // disabled={!isValid}
                    onClick={() => saveStep1(values)}
                  />
                </ButtonContainer>
              </Row>
            ) : (
              <Row style={{ marginTop: '20px' }}>
                <ButtonContainer>
                  <ButtonText text="Back" theme="primary" type="button" onClick={backStep} />
                </ButtonContainer>
                <ButtonContainer>
                  <ButtonText
                    text="Upgrade"
                    theme="primary-blue"
                    type="submit"
                    disabled={!isValid}
                  />
                </ButtonContainer>
              </Row>
            )}
          </Form>
        </Container>
      )}
    </Formik>
  </Modal>
);

ModalUpgrade.propTypes = {
  backStep: PropTypes.func,
  brandsForFilter: PropTypes.array,
  isOpenModal: PropTypes.bool,
  loadingGetPreviousInstallationInfo: PropTypes.bool,
  locationsForFilter: PropTypes.array,
  previousInstallationInfo: PropTypes.object,
  saveData: PropTypes.func,
  saveStep1: PropTypes.func,
  stepActive: PropTypes.number,
  switchModal: PropTypes.func,
  upgradeOptions: PropTypes.object,
};
