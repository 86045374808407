import React from 'react';
import styled from 'styled-components';
import { Colors } from 'modules/theme';
import PropTypes from 'prop-types';
import Modal from './Modal';

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  font-family: 'Josefin Sans', sans-serif;
`;

const Button = styled.button`
  border-radius: 5px;
  color: white;
  background-color: ${props => (props.success ? Colors.lightBlue : 'inherit')};
  border: 1px solid ${Colors.lightBlue};
  width: 150px;
  height: 50px;
  color: ${props => (props.success ? Colors.white : Colors.lightBlue)};
  margin: 30px 5px;
  cursor: pointer;
`;

const AlertConfirm = props => {
  const { children, title, onCancel, cancelText, onSuccess, successText, isVisible } = props;

  if (!isVisible) {
    document.body.style.overflow = 'unset';
    return null;
  }
  window.scrollTo(0, 0);
  document.body.style.overflow = 'hidden';

  return (
    <Modal title={title} onClose={onCancel} isVisible={isVisible}>
      {children}
      <ButtonGroup>
        {onCancel && <Button onClick={onCancel}>{cancelText || 'Cancel'}</Button>}
        {onSuccess && (
          <Button onClick={onSuccess} success>
            {successText || 'Success'}
          </Button>
        )}
      </ButtonGroup>
    </Modal>
  );
};

export default AlertConfirm;

AlertConfirm.propTypes = {
  cancelText: PropTypes.string,
  children: PropTypes.node,
  isVisible: PropTypes.bool,
  onCancel: PropTypes.func,
  onSuccess: PropTypes.func,
  successText: PropTypes.string,
  title: PropTypes.string,
};
