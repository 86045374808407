import React, { Component } from 'react';
import { connect } from 'react-redux';
import { editBrineDrum, getBrineDrum } from 'actions/index';
import Loader from 'components/Loader';
import PropTypes from 'prop-types';
import BrineDrumForm from './BrineDrumForm';

class EditBrineDrum extends Component {
  componentDidMount() {
    const {
      match: { params },
      fetchBrineDrum,
    } = this.props;
    const { id } = params;

    fetchBrineDrum(id);
  }

  onSubmit = data => {
    const {
      submitBrineDrum,
      match: { params },
    } = this.props;
    const { id } = params;

    submitBrineDrum(id, data);
  };

  render() {
    const { library, loading, token } = this.props;

    if (loading) {
      return <Loader size={200} block />;
    }

    if (!library) {
      return null;
    }

    const initialValues = {
      name: library.name,
      good_salt_level: library.good_salt_level,
      fair_salt_level: library.fair_salt_level,
      poor_salt_level: library.poor_salt_level,
      description: library.description,
      photo: library.photo,
    };

    return (
      <BrineDrumForm
        title="Edit Brine Drum"
        onSubmit={this.onSubmit}
        initialValues={initialValues}
        loading={loading}
        token={token}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    loading: state.libraries.loading,
    token: state.auth.token,
    library: state.libraries.item,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    submitBrineDrum: (id, library) => dispatch(editBrineDrum(id, library)),
    fetchBrineDrum: id => dispatch(getBrineDrum(id)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(EditBrineDrum);

EditBrineDrum.propTypes = {
  fetchBrineDrum: PropTypes.func,
  library: PropTypes.object,
  loading: PropTypes.bool,
  match: PropTypes.object,
  submitBrineDrum: PropTypes.func,
  token: PropTypes.string,
};
