import React, { Component } from 'react';
import styled from 'styled-components';
import { Colors } from 'modules/theme';
import {
  FlowPort1Options,
  FlowPort2Options,
  FlowPort3Options,
  AnalogPort1Options,
  AnalogPort2Options,
  AnalogPort3Options,
  AnalogPort4Options,
} from 'constants/index';
import Input from 'components/InputText';
import Select from 'components/Select';
import Switch from 'components/Switch';
import H4 from 'components/H4';
import FormError from 'components/FormError';

const Container = styled.div``;

const InputContainer = styled.div`
  margin: 20px 0px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const Column = styled.div`
  flex-direction: column;
`;

export default class SoftenerForm extends Component {
  submit = values => {
    const { onSubmit } = this.props;

    onSubmit(values);
  };

  render() {
    const {
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      softeners,
      flowSensorsForSelect,
      pressureSensorsForSelect,
      distanceSensorsForSelect,
      tdsProbeSensorsForSelect,
    } = this.props;

    return (
      <Container>
        <Row>
          <Column style={{ width: '33.33%', paddingRight: '30px' }}>
            <InputContainer>
              <Select
                placeholder="Select Model"
                name="softener_id"
                value={values.softener_id}
                options={softeners}
                onChange={handleChange}
              />
              <FormError text={errors.softener_id && errors.softener_id} />
            </InputContainer>
          </Column>
          <Column style={{ width: '33.33%', paddingRight: '30px', paddingTop: '30px' }}>
            <Switch
              name="overdrive_enabled"
              isChecked={values.overdrive_enabled}
              titleChecked="Overdrive Enabled"
              titleUnchecked="Overdrive Enabled"
              onChange={handleChange}
            />
          </Column>
        </Row>
        <Row>
          <Column style={{ width: '33.33%', paddingRight: '30px' }}>
            <H4 inputLabel color={Colors.gray8}>
              Flow sensor 1
            </H4>
            <InputContainer>
              <Select
                placeholder="Flow sensor 1"
                type="number"
                name="flow_port_1_id"
                value={values.flow_port_1_id}
                onChange={handleChange}
                options={flowSensorsForSelect}
              />
              <FormError
                text={errors.flow_port_1_id && touched.flow_port_1_id && errors.flow_port_1_id}
              />
            </InputContainer>
          </Column>
          <Column style={{ width: '33.33%', paddingRight: '30px' }}>
            <H4 inputLabel color={Colors.gray8}>
              Flow sensor 2
            </H4>
            <InputContainer>
              <Select
                placeholder="Flow sensor 2"
                type="number"
                name="flow_port_2_id"
                value={values.flow_port_2_id}
                onChange={handleChange}
                options={flowSensorsForSelect}
              />
              <FormError
                text={errors.flow_port_2_id && touched.flow_port_2_id && errors.flow_port_2_id}
              />
            </InputContainer>
          </Column>
          <Column style={{ width: '33.33%', paddingRight: '30px' }}>
            <H4 inputLabel color={Colors.gray8}>
              Flow sensor 3
            </H4>
            <InputContainer>
              <Select
                placeholder="Flow sensor 3"
                type="number"
                name="flow_port_3_id"
                value={values.flow_port_3_id}
                onChange={handleChange}
                options={flowSensorsForSelect}
              />
              <FormError
                text={errors.flow_port_3_id && touched.flow_port_3_id && errors.flow_port_3_id}
              />
            </InputContainer>
          </Column>
        </Row>
        <Row>
          <Column style={{ width: '33.33%', paddingRight: '30px' }}>
            <H4 inputLabel color={Colors.gray8}>
              Analog sensor 1
            </H4>
            <InputContainer>
              <Select
                placeholder="Analog sensor 1"
                type="number"
                name="analog_port_1_id"
                value={values.analog_port_1_id}
                onChange={handleChange}
                options={pressureSensorsForSelect}
              />
              <FormError
                text={
                  errors.analog_port_1_id && touched.analog_port_1_id && errors.analog_port_1_id
                }
              />
            </InputContainer>
          </Column>
          <Column style={{ width: '33.33%', paddingRight: '30px' }}>
            <H4 inputLabel color={Colors.gray8}>
              Analog sensor 2
            </H4>
            <InputContainer>
              <Select
                placeholder="Analog sensor 2"
                type="number"
                name="analog_port_2_id"
                value={values.analog_port_2_id}
                onChange={handleChange}
                options={pressureSensorsForSelect}
              />
              <FormError
                text={
                  errors.analog_port_2_id && touched.analog_port_2_id && errors.analog_port_2_id
                }
              />
            </InputContainer>
          </Column>
          <Column style={{ width: '33.33%', paddingRight: '30px' }}>
            <H4 inputLabel color={Colors.gray8}>
              Analog sensor 3
            </H4>
            <InputContainer>
              <Select
                placeholder="Analog sensor 3"
                type="number"
                name="analog_port_3_id"
                value={values.analog_port_3_id}
                onChange={handleChange}
                options={pressureSensorsForSelect}
              />
              <FormError
                text={
                  errors.analog_port_3_id && touched.analog_port_3_id && errors.analog_port_3_id
                }
              />
            </InputContainer>
          </Column>
        </Row>
        <Row>
          <Column style={{ width: '33.33%', paddingRight: '30px' }}>
            <H4 inputLabel color={Colors.gray8}>
              Analog sensor 4
            </H4>
            <InputContainer>
              <Select
                placeholder="Analog sensor 4"
                type="number"
                name="analog_port_4_id"
                value={values.analog_port_4_id}
                onChange={handleChange}
                options={pressureSensorsForSelect}
              />
              <FormError
                text={
                  errors.analog_port_4_id && touched.analog_port_4_id && errors.analog_port_4_id
                }
              />
            </InputContainer>
          </Column>
          <Column style={{ width: '33.33%', paddingRight: '30px' }}>
            <H4 inputLabel color={Colors.gray8}>
              Salt Level Laser
            </H4>
            <InputContainer>
              <Select
                placeholder="Salt Level Laser"
                type="number"
                name="salt_level_laser_id"
                value={values.salt_level_laser_id}
                onChange={handleChange}
                options={distanceSensorsForSelect}
              />
              <FormError
                text={
                  errors.salt_level_laser_id &&
                  touched.salt_level_laser_id &&
                  errors.salt_level_laser_id
                }
              />
            </InputContainer>
          </Column>

          <Column style={{ width: '33.33%', paddingRight: '30px' }}>
            <H4 inputLabel color={Colors.gray8}>
              TDS port
            </H4>
            <InputContainer>
              <Select
                placeholder="TDS port"
                type="number"
                name="tds_probe_sensor_id"
                value={values.tds_probe_sensor_id}
                onChange={handleChange}
                options={tdsProbeSensorsForSelect}
              />
              <FormError
                text={
                  errors.tds_probe_sensor_id &&
                  touched.tds_probe_sensor_id &&
                  errors.tds_probe_sensor_id
                }
              />
            </InputContainer>
          </Column>
        </Row>
        <Row>
          <Column style={{ width: '33.33%', paddingRight: '30px' }}>
            <H4 inputLabel color={Colors.gray8}>
              Low Inlet Pressure
            </H4>
            <InputContainer>
              <Input
                placeholder="Low Inlet Pressure"
                name="low_inlet_pressure"
                value={values.low_inlet_pressure}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <FormError
                text={
                  errors.low_inlet_pressure &&
                  touched.low_inlet_pressure &&
                  errors.low_inlet_pressure
                }
              />
            </InputContainer>
          </Column>
        </Row>
      </Container>
    );
  }
}
