import React, { Component } from 'react';
import styled from 'styled-components';
import { Colors } from 'modules/theme';
import { push } from 'modules/history';
import { KineticoRolesCode, BrandsRolesCode } from 'constants/index';
import InputSearch from 'components/InputSearch';
import H4 from 'components/H4';
import ButtonText from 'components/ButtonText';
import PaginationButtons from 'components/PaginationButtons';
import Table, { THeader, TableTitle, TRow, TableItem, TBody } from 'components/Table';
import Filter, { FilterGroup, FilterContainer } from 'components/Filter';
import Dropdown from 'components/Dropdown';
import AlertConfirm from 'components/AlertConfirm';
import ListEmpty from 'components/ListEmpty';
import Loader from 'components/Loader';
import PropTypes from 'prop-types';

const Container = styled.div``;

const Header = styled.div`
  flex-wrap: wrap;
  padding-bottom: 20px;
`;

const SearchContainer = styled.div`
  padding: 10px;
  flex-direction: column;
`;

const ButtonContainer = styled.div`
  flex-direction: column;
  float: right;
`;

const ButtonGroup = styled.div`
  padding: 10px;
  flex-direction: column;
  float: right;
`;

const TableContainer = styled.div`
  padding-bottom: 50px;
  margin-bottom: 50px;
`;

const Footer = styled.div`
  position: fixed;
  bottom: 0;
  padding-left: 25px;
  padding-bottom: 25px;
  background-color: ${Colors.white};
  width: 100%;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export default class UserListView extends Component {
  state = {
    dropdownOpenId: null,
    userSelected: null,
    isOpenAlert: false,
    roles: BrandsRolesCode,
    search: '',
    brand_id: '',
    account_id: '',
    location_id: '',
    status: '',
    tabSelected: 'brands',
  };

  searchOnChange = search => {
    this.setState({ search }, () => {
      const { fetchUsers } = this.props;
      const { roles, brand_id, account_id, location_id, search, status } = this.state;

      fetchUsers({ roles, search, brand_id, account_id, location_id, status });
    });
  };

  filterOnChange = event => {
    const { name, value } = event.target;
    const { fetchUsers, fetchAccounts, fetchLocations } = this.props;

    if (name === 'brand_id') {
      fetchAccounts({ brands: [value] });
    }

    if (name === 'account_id') {
      fetchLocations({ accounts: [value] });
    }

    this.setState({ [name]: value }, () => {
      const { roles, brand_id, account_id, location_id, search, status } = this.state;

      fetchUsers({ roles, search, brand_id, account_id, location_id, status });
    });
  };

  switchDropdown = key => {
    const { dropdownOpenId } = this.state;
    const id = dropdownOpenId !== key ? key : null;

    this.setState({ dropdownOpenId: id });
  };

  switchAlert = (userSelected = null) => {
    const { isOpenAlert } = this.state;

    this.setState({ isOpenAlert: !isOpenAlert, userSelected });
  };

  editUser = ({ id }) => {
    push(`/users/${id}/edit`);
  };

  resendEmail = ({ id }) => {
    const { resendInvitation } = this.props;

    resendInvitation(id);
  };

  remove = () => {
    const { removeUser } = this.props;
    const { userSelected } = this.state;

    removeUser(userSelected.id);
    this.switchAlert();
  };

  renderItem = (item, key) => (
    <TRow key={key}>
      <TableItem width="25%">{item.full_name}</TableItem>
      <TableItem width="10%">{item.role_type}</TableItem>
      <TableItem width="10%">{item.role_name}</TableItem>
      <TableItem width="20%">{item.email}</TableItem>
      <TableItem width="20%">{item.phone_number}</TableItem>
      <TableItem width="10%">{item.status}</TableItem>
      <TableItem width="5%" isComponent>
        <Dropdown
          icon="ellipsis-v"
          options={
            item.status === 'pending'
              ? [
                  {
                    title: 'Edit',
                    icon: 'pencil-alt',
                    color: Colors.green,
                    action: () => this.editUser(item),
                  },
                  {
                    title: 'Resend invitation',
                    icon: 'envelope',
                    color: Colors.yellow,
                    action: () => this.resendEmail(item),
                  },
                  {
                    title: 'Remove',
                    icon: 'trash',
                    color: Colors.red,
                    action: () => this.switchAlert(item),
                  },
                ]
              : [
                  {
                    title: 'Edit',
                    icon: 'pencil-alt',
                    color: Colors.green,
                    action: () => this.editUser(item),
                  },
                  {
                    title: 'Remove',
                    icon: 'trash',
                    color: Colors.red,
                    action: () => this.switchAlert(item),
                  },
                ]
          }
          isDropdownOpen={this.state.dropdownOpenId === key}
          onClickDropdownButton={() => this.switchDropdown(key)}
        />
      </TableItem>
    </TRow>
  );

  renderAlertConfirm = () => {
    const { isOpenAlert, userSelected } = this.state;

    return (
      <AlertConfirm
        title="Remove user"
        onCancel={() => this.switchAlert()}
        onSuccess={this.remove}
        successText="Remove"
        isVisible={isOpenAlert}
      >
        <H4 style={{ textAlign: 'center' }}>
          {`Are you sure that want remove the "${userSelected ? userSelected.full_name : ''}" user`}
        </H4>
      </AlertConfirm>
    );
  };

  switchView = tabSelected => {
    this.setState(
      {
        tabSelected,
        roles: tabSelected === 'brands' ? BrandsRolesCode : KineticoRolesCode,
        status: '',
        search: '',
        brand_id: '',
        account_id: '',
        location_id: '',
      },
      () => {
        const { fetchUsers } = this.props;
        const { roles } = this.state;

        fetchUsers({ roles });
      },
    );
  };

  render() {
    const {
      list,
      currentPage,
      pages,
      loading,
      brands,
      accounts,
      locations,
      fetchUsers,
    } = this.props;
    const { tabSelected, roles, brand_id, account_id, location_id, search, status } = this.state;

    return (
      <Container>
        <Header>
          <Row>
            <ButtonContainer>
              <ButtonGroup float="left">
                <ButtonText
                  theme={tabSelected === 'kinetico' ? 'round-alternative' : 'text'}
                  color={tabSelected !== 'kinetico' ? Colors.black : undefined}
                  customStyles={tabSelected !== 'kinetico' ? { padding: '7px 60px' } : {}}
                  text="Kinetico"
                  onClick={() => this.switchView('kinetico')}
                />
              </ButtonGroup>
              <ButtonGroup float="left">
                <ButtonText
                  theme={tabSelected === 'brands' ? 'round-alternative' : 'text'}
                  color={tabSelected !== 'brands' ? Colors.black : undefined}
                  customStyles={tabSelected !== 'brands' ? { padding: '7px 60px' } : {}}
                  text="Brands"
                  onClick={() => this.switchView('brands')}
                />
              </ButtonGroup>
            </ButtonContainer>
          </Row>
          <Row>
            <SearchContainer>
              <InputSearch onChange={this.searchOnChange} value={search} name="search" />
            </SearchContainer>
            <ButtonContainer>
              <ButtonGroup>
                <ButtonText
                  theme="primary"
                  text="New"
                  icon="plus"
                  onClick={() => push('/users/create')}
                />
              </ButtonGroup>
            </ButtonContainer>
          </Row>
        </Header>

        <FilterGroup>
          {tabSelected === 'brands' && (
            <>
              <FilterContainer>
                <Filter
                  title="Brand:"
                  selectedValue={brand_id}
                  name="brand_id"
                  handleChange={this.filterOnChange}
                  options={brands}
                />
              </FilterContainer>
              <FilterContainer>
                <Filter
                  title="Account:"
                  selectedValue={account_id}
                  name="account_id"
                  handleChange={this.filterOnChange}
                  options={accounts}
                />
              </FilterContainer>
              <FilterContainer>
                <Filter
                  title="Installation:"
                  selectedValue={location_id}
                  name="location_id"
                  handleChange={this.filterOnChange}
                  options={locations}
                />
              </FilterContainer>
            </>
          )}
          <FilterContainer>
            <Filter
              title="Status:"
              selectedValue={status}
              name="status"
              handleChange={this.filterOnChange}
              options={[
                { label: 'All', value: '' },
                { label: 'Active', value: 'active' },
                { label: 'Pending', value: 'pending' },
              ]}
            />
          </FilterContainer>
        </FilterGroup>

        <TableContainer>
          <Table>
            <THeader>
              <TableTitle width="25%">Name</TableTitle>
              <TableTitle width="10%">Type</TableTitle>
              <TableTitle width="10%">Permisions</TableTitle>
              <TableTitle width="20%">Email</TableTitle>
              <TableTitle width="20%">Phone</TableTitle>
              <TableTitle width="10%">Status</TableTitle>
              <TableTitle width="5%" />
            </THeader>
            <TBody>
              {loading ? (
                <Loader size={100} block />
              ) : (
                list.map((item, key) => this.renderItem(item, key))
              )}
            </TBody>
          </Table>
          {(!list || !list.length) && !loading && (
            <ListEmpty message="There are currently no users" />
          )}
        </TableContainer>
        <Footer>
          <PaginationButtons
            currentPage={currentPage}
            changePage={page =>
              fetchUsers({ page, roles, brand_id, account_id, location_id, search, status })
            }
            totalPages={pages}
          />
        </Footer>
        {this.renderAlertConfirm()}
      </Container>
    );
  }
}

UserListView.propTypes = {
  accounts: PropTypes.array,
  brands: PropTypes.array,
  currentPage: PropTypes.number,
  fetchAccounts: PropTypes.func,
  fetchLocations: PropTypes.func,
  fetchUsers: PropTypes.func,
  list: PropTypes.array,
  loading: PropTypes.bool,
  locations: PropTypes.array,
  pages: PropTypes.number,
  removeUser: PropTypes.func,
  resendInvitation: PropTypes.func,
};
