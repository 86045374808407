// @flow
/**
 * @module Actions/Support
 * @desc Support Actions
 */
import { createActions } from 'redux-actions';

import { ActionTypes } from 'constants/index';

export const { getSupports, checkAsRead, deleteSupport } = createActions({
  [ActionTypes.GET_SUPPORTS]: params => params,
  [ActionTypes.CHECK_AS_READ]: id => ({ id }),
  [ActionTypes.DELETE_SUPPORT]: id => ({ id }),
});
