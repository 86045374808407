import React, { Component } from 'react';
import styled from 'styled-components';
import { Colors } from 'modules/theme';

const Text = styled.span`
  width: 100px;
  margin-left: 20px;
  vertical-align: middle;
`;

const Label = styled.label`
  display: inline-block;
  font-size: 20px;
  height: 1em;
  width: 2em;
  background-color: ${props => (props.isChecked ? Colors.lightBlue : Colors.gray2)};
  border-radius: 1em;
  cursor: pointer;
  vertical-align: middle;
  & input {
    position: absolute;
    cursor: pointer;
    opacity: 0;
    width: 5em;
  }
  & div {
    height: 1em;
    width: 1em;
    border-radius: 1em;
    background-color: ${Colors.white};
    box-shadow: 0 0.1em 0.3em rgba(0, 0, 0, 0.3);
    -webkit-transition: all 300ms;
    -moz-transition: all 300ms;
    transition: all 300ms;
  }
  & input:checked + div {
    -webkit-transform: translate3d(100%, 0, 0);
    -moz-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
`;

const Slide = styled.div``;

export default class Switch extends Component {
  render() {
    const { isChecked, titleChecked, titleUnchecked, onChange, name } = this.props;

    return (
      <div>
        <Label {...this.props}>
          <input type="checkbox" name={name} checked={isChecked} onChange={onChange} />
          <Slide />
        </Label>
        <Text>{isChecked ? titleChecked : titleUnchecked}</Text>
      </div>
    );
  }
}
