// @flow
/**
 * @module Actions/User
 * @desc User Actions
 */
import { createActions } from 'redux-actions';

import { ActionTypes } from 'constants/index';

export const {
  userLogin: login,
  userLogout: logOut,
  recoverPassword,
  resetPassword,
  newPassword,
  updateProfile,
  changePassword,
  closeAccount,
  updateInvitationTokenExpired,
  resendInvitation,
} = createActions({
  [ActionTypes.USER_LOGIN]: (email, password) => ({ email, password }),
  [ActionTypes.USER_LOGOUT]: () => ({}),
  [ActionTypes.RECOVER_PASSWORD]: email => ({ email }),
  [ActionTypes.RESET_PASSWORD]: (token_pass_recovery, password) => ({
    token_pass_recovery,
    password,
  }),
  [ActionTypes.NEW_PASSWORD]: (token_validate, password) => ({
    token_validate,
    password,
  }),
  [ActionTypes.UPDATE_PROFILE]: data => ({ data }),
  [ActionTypes.CHANGE_PASSWORD]: data => ({ data }),
  [ActionTypes.CLOSE_ACCOUNT]: () => ({}),
  [ActionTypes.UPDATE_INVITATION_TOKEN_EXPIRED]: token_expired => ({
    token_expired,
  }),
  [ActionTypes.RESEND_INVITATION]: token_validate => ({
    token_validate,
  }),
});
