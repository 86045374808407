import { REHYDRATE } from 'redux-persist/lib/constants';
import { handleActions } from 'modules/helpers';

import { ActionTypes } from 'constants/index';

export const appState = {
  list: [],
  pages: 0,
  currentPage: 0,
  loading: false,
  item: null,
};

export default {
  alertsTypes: handleActions(
    {
      [REHYDRATE]: draft => {
        draft.list = [];
      },
      [ActionTypes.GET_ALERTS_TYPES]: draft => {
        draft.loading = true;
      },
      [ActionTypes.GET_ALERTS_TYPES_SUCCESS]: (
        draft,
        { payload: { rows, pages, currentPage } },
      ) => {
        draft.list = rows;
        draft.pages = pages;
        draft.currentPage = currentPage;
        draft.loading = false;
      },
      [ActionTypes.GET_ALERTS_TYPES_FAILURE]: draft => {
        draft.loading = false;
      },
      [ActionTypes.GET_ALERT_TYPE]: draft => {
        draft.loading = true;
        draft.item = null;
      },
      [ActionTypes.GET_ALERT_TYPE_SUCCESS]: (draft, { payload }) => {
        draft.item = payload;
        draft.loading = false;
      },
      [ActionTypes.GET_ALERT_TYPE_FAILURE]: draft => {
        draft.loading = false;
      },
      [ActionTypes.CREATE_ALERT_TYPE]: draft => {
        draft.loading = true;
      },
      [ActionTypes.CREATE_ALERT_TYPE_RESOLVED]: draft => {
        draft.loading = false;
      },
      [ActionTypes.EDIT_ALERT_TYPE]: draft => {
        draft.loading = true;
      },
      [ActionTypes.EDIT_ALERT_TYPE_RESOLVED]: draft => {
        draft.loading = false;
      },
      [ActionTypes.DELETE_ALERT_TYPE]: draft => {
        draft.loading = true;
      },
      [ActionTypes.DELETE_ALERT_TYPE_SUCCESS]: (draft, { payload: { id } }) => {
        draft.list = draft.list.filter(item => item.id !== id);
        draft.loading = false;
      },
      [ActionTypes.DELETE_ALERT_TYPE_FAILURE]: (draft, { payload: { id } }) => {
        draft.loading = false;
      },
    },
    appState,
  ),
};
