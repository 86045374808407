import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Colors } from 'modules/theme';

const Text = styled.h4`
  font-family: Gotham, sans-serif;
  font-style: normal;
  font-size: 18px;
  margin-top: ${props => props.marginTop || 3}px;
  margin-bottom: ${props => props.marginBottom || 3}px;
  margin-left: ${props => props.marginLeft || 3}px;
  margin-right: ${props => props.marginRight || 3}px;
  line-height: ${props => (props.lineHeight ? props.lineHeight : 'initial')};
  color: ${props => props.color || Colors.black};
  font-weight: ${props => props.weight || '300'};
  ${props => props.uppercase && 'text-transform: uppercase'};
  ${props => props.inputLabel && 'padding-left: 4px'};
  text-align: ${props => props.textAlign || 'initial'};
`;

const H4 = props => <Text {...props}>{props.children}</Text>;

export default H4;
