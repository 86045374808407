import React from 'react';
import styled from 'styled-components';
import { Colors } from 'modules/theme';

const Container = styled.div`
  background-color: ${Colors.white};
  border-radius: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: ${props => props.padding || 15}px;
  width: ${props => props.width ?? '98%'};
  margin: 5px;
  height: 100%;
`;

const BG = props => <Container width={props.width}>{props.children}</Container>;

export default BG;
