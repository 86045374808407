import React, { Component } from 'react';
import { connect } from 'react-redux';
import history from 'modules/history';
import { newPassword, updateInvitationTokenExpired, resendInvitation } from 'actions/index';
import PropTypes from 'prop-types';
import NewPasswordView from './NewPasswordView';

class NewPasswordContainer extends Component {
  componentDidMount() {
    const { updateInvitationTokenExpired } = this.props;
    updateInvitationTokenExpired(false);
  }

  newPassword = password => {
    const { onNewPassword } = this.props;
    const {
      location: { query },
    } = history;
    onNewPassword(query.token_validate, password);
  };

  onResendInvitation = () => {
    const { resendInvitation } = this.props;

    const {
      location: { query },
    } = history;

    resendInvitation(query.token_validate);
  };

  render() {
    const { token_expired } = this.props;
    return (
      <NewPasswordView
        newPassword={this.newPassword}
        loading={this.props.loading}
        token_expired={token_expired}
        resendInvitation={this.onResendInvitation}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    // loading: state.loginReducer.loading,
    loading: false,
    token_expired: state.auth.token_expired,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    onNewPassword: (token_validate, password) => dispatch(newPassword(token_validate, password)),
    updateInvitationTokenExpired: token_expired =>
      dispatch(updateInvitationTokenExpired(token_expired)),
    resendInvitation: token_validate => dispatch(resendInvitation(token_validate)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(NewPasswordContainer);

NewPasswordContainer.propTypes = {
  loading: PropTypes.bool,
  onNewPassword: PropTypes.func,
  resendInvitation: PropTypes.func,
  token_expired: PropTypes.bool,
  updateInvitationTokenExpired: PropTypes.func,
};
