import React, { Component } from 'react';
import { connect } from 'react-redux';
import { editWaterCatridge, getCatridge } from 'actions/index';
import Loader from 'components/Loader';
import PropTypes from 'prop-types';
import CatridgeForm from './CatridgeForm';

class EditCatridge extends Component {
  componentDidMount() {
    const {
      match: { params },
      fetchCatridge,
    } = this.props;
    const { id } = params;

    fetchCatridge(id);
  }

  onSubmit = data => {
    const {
      submitCatridge,
      match: { params },
    } = this.props;
    const { id } = params;

    submitCatridge(id, data);
  };

  render() {
    const { library, loading, token } = this.props;

    if (loading) {
      return <Loader size={200} block />;
    }

    if (!library) {
      return null;
    }

    const initialValues = {
      name: library.name,
      part: library.part,
      price: library.price,
      scale_control_capacity: library.scale_control_capacity,
      chlorine_capacity: library.chlorine_capacity,
      chloramine_capacity: library.chloramine_capacity,
      sediment: library.sediment,
      taste_odor: library.taste_odor,
      contaminant: library.contaminant,
      scale: library.scale,
      photo: library.photo,
      max_pressure: library.max_pressure,
      low_inlet_pressure: library.low_inlet_pressure,
      warranty: library.warranty,
    };

    return (
      <CatridgeForm
        onSubmit={this.onSubmit}
        title="Edit Water Cartridge"
        initialValues={initialValues}
        loading={loading}
        token={token}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    library: state.libraries.item,
    loading: state.libraries.loading,
    token: state.auth.token,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    submitCatridge: (id, library) => dispatch(editWaterCatridge(id, library)),
    fetchCatridge: id => dispatch(getCatridge(id)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(EditCatridge);

EditCatridge.propTypes = {
  fetchCatridge: PropTypes.func,
  library: PropTypes.object,
  loading: PropTypes.bool,
  match: PropTypes.object,
  submitCatridge: PropTypes.func,
  token: PropTypes.string,
};
