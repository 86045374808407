import { REHYDRATE } from 'redux-persist/lib/constants';
import { handleActions } from 'modules/helpers';

import { ActionTypes } from 'constants/index';

export const appState = {
  list: [],
  listForFilter: [],
  adminsBrands: [],
  adminsAccounts: [],
  adminsLocations: [],
  pages: 0,
  currentPage: 0,
  loading: false,
  item: null,
};

export default {
  brands: handleActions(
    {
      [REHYDRATE]: draft => {
        draft.list = [];
      },
      [ActionTypes.GET_BRANDS]: draft => {
        draft.loading = true;
      },
      [ActionTypes.GET_BRANDS_SUCCESS]: (draft, { payload: { rows, pages, currentPage } }) => {
        draft.list = rows;
        draft.pages = pages;
        draft.currentPage = currentPage;
        draft.loading = false;
      },
      [ActionTypes.GET_BRANDS_FAILURE]: draft => {
        draft.loading = false;
      },
      [ActionTypes.GET_BRAND]: draft => {
        draft.item = null;
        draft.loading = true;
      },
      [ActionTypes.GET_BRAND_SUCCESS]: (draft, { payload }) => {
        draft.item = payload;
        draft.loading = false;
      },
      [ActionTypes.GET_BRAND_FAILURE]: draft => {
        draft.loading = false;
      },
      [ActionTypes.GET_BRANDS_FOR_FILTER]: draft => {
        draft.loading = true;
      },
      [ActionTypes.GET_BRANDS_FOR_FILTER_SUCCESS]: (draft, { payload }) => {
        draft.listForFilter = payload;
        draft.loading = false;
      },
      [ActionTypes.GET_BRANDS_FOR_FILTER_FAILURE]: draft => {
        draft.loading = false;
      },
      [ActionTypes.CREATE_BRAND]: draft => {
        draft.loading = true;
      },
      [ActionTypes.CREATE_BRAND_RESOLVED]: draft => {
        draft.loading = false;
      },
      [ActionTypes.EDIT_BRAND]: draft => {
        draft.loading = true;
      },
      [ActionTypes.EDIT_BRAND_RESOLVED]: draft => {
        draft.loading = false;
      },
      [ActionTypes.DELETE_BRAND]: draft => {
        draft.loading = true;
      },
      [ActionTypes.DELETE_BRAND_SUCCESS]: (draft, { payload: { id } }) => {
        draft.list = draft.list.filter(item => item.id !== id);
        draft.loading = false;
      },
      [ActionTypes.DELETE_BRAND_FAILURE]: (draft, { payload: { id } }) => {
        draft.loading = false;
      },
      [ActionTypes.GET_ADMINS_BRANDS]: draft => {
        draft.loading = true;
      },
      [ActionTypes.GET_ADMINS_BRANDS_SUCCESS]: (draft, { payload }) => {
        draft.adminsBrands = payload;
        draft.loading = false;
      },
      [ActionTypes.GET_ADMINS_BRANDS_FAILURE]: draft => {
        draft.loading = false;
      },
      [ActionTypes.GET_ADMINS_ACCOUNTS]: draft => {
        draft.loading = true;
      },
      [ActionTypes.GET_ADMINS_ACCOUNTS_SUCCESS]: (draft, { payload }) => {
        draft.adminsAccounts = payload;
        draft.loading = false;
      },
      [ActionTypes.GET_ADMINS_ACCOUNTS_FAILURE]: draft => {
        draft.loading = false;
      },
      [ActionTypes.GET_ADMINS_LOCATIONS]: draft => {
        draft.loading = true;
      },
      [ActionTypes.GET_ADMINS_LOCATIONS_SUCCESS]: (draft, { payload }) => {
        draft.adminsLocations = payload;
        draft.loading = false;
      },
      [ActionTypes.GET_ADMINS_LOCATIONS_FAILURE]: draft => {
        draft.loading = false;
      },
    },
    appState,
  ),
};
