import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  getConfiguration,
  editConfiguration,
  getWaterFiltersForFilter,
  getReverseOsmosisForFilter,
  getSoftenerForFilter,
  getFlowSensorsForSelect,
  getPressureSensorsForSelect,
  getDistanceSensorsForSelect,
  getTdsProbeSensorsForSelect,
  getTemperatureSensorsForSelect,
} from 'actions/index';
import Loader from 'components/Loader';
import PropTypes from 'prop-types';
import DeviceConfigurationForm from './DeviceConfigurationForm';

const initInstallationSteps = [{ title: '', description: '', photo: null }];
class EditDeviceConfiguration extends Component {
  componentDidMount() {
    const {
      match: { params },
      fetchConfiguration,
      fetchWaterFilters,
      fetchReverseOsmosisFilters,
      fetchSofteners,
      fetchFlowSensorsForSelect,
      fetchPressureSensorsForSelect,
      fetchDistanceSensorsForSelect,
      fetchTdsProbeSensorsForSelect,
      fetchTempSensorsForSelect,
    } = this.props;
    const { id, type } = params;

    fetchConfiguration(id, type);
    fetchWaterFilters();
    fetchReverseOsmosisFilters();
    fetchSofteners();
    fetchFlowSensorsForSelect();
    fetchPressureSensorsForSelect();
    fetchDistanceSensorsForSelect();
    fetchTdsProbeSensorsForSelect();
    fetchTempSensorsForSelect();
  }

  onSubmit = data => {
    const {
      submitDeviceConfiguration,
      match: { params },
    } = this.props;
    const { id, type } = params;

    submitDeviceConfiguration(id, type, data);
  };

  setInitialValues = (library, device_type) => {
    if (device_type === 'water-filter') {
      const installationSteps =
        library.WatherFilterInstallSteps && library.WatherFilterInstallSteps.length === 0
          ? initInstallationSteps
          : library.WatherFilterInstallSteps;
      return {
        name: library.name,
        device_type,
        flow_port_1_id: library.flow_port_1_id,
        flow_port_2_id: library.flow_port_2_id,
        flow_port_3_id: library.flow_port_3_id,
        analog_port_1_id: library.analog_port_1_id,
        analog_port_2_id: library.analog_port_2_id,
        analog_port_3_id: library.analog_port_3_id,
        analog_port_4_id: library.analog_port_4_id,
        salt_level_laser_id: library.salt_level_laser_id,
        tds_probe_sensor_id: library.tds_probe_sensor_id,
        water_filter_id: library.water_filter_id,
        installationSteps,
      };
    }

    if (device_type === 'reverse-osmosis') {
      const installationSteps =
        library.ReverseOsmosisFilterInstallSteps &&
        library.ReverseOsmosisFilterInstallSteps.length === 0
          ? initInstallationSteps
          : library.ReverseOsmosisFilterInstallSteps;
      return {
        name: library.name,
        device_type,
        flow_port_1_id: library.flow_port_1_id,
        flow_port_2_id: library.flow_port_2_id,
        flow_port_3_id: library.flow_port_3_id,
        analog_port_1_id: library.analog_port_1_id,
        analog_port_2_id: library.analog_port_2_id,
        analog_port_3_id: library.analog_port_3_id,
        analog_port_4_id: library.analog_port_4_id,
        salt_level_laser_id: library.salt_level_laser_id,
        tds_probe_sensor_id: library.tds_probe_sensor_id,
        ro_filter_id: library.ro_filter_id,
        low_tank_pressure: library.low_tank_pressure,
        low_inlet_pressure: library.low_inlet_pressure,
        installationSteps,
      };
    }

    if (device_type === 'water-softener') {
      const installationSteps =
        library.SoftenerInstallSteps && library.SoftenerInstallSteps.length === 0
          ? initInstallationSteps
          : library.SoftenerInstallSteps;
      return {
        name: library.name,
        device_type,
        flow_port_1_id: library.flow_port_1_id,
        flow_port_2_id: library.flow_port_2_id,
        flow_port_3_id: library.flow_port_3_id,
        analog_port_1_id: library.analog_port_1_id,
        analog_port_2_id: library.analog_port_2_id,
        analog_port_3_id: library.analog_port_3_id,
        analog_port_4_id: library.analog_port_4_id,
        salt_level_laser_id: library.salt_level_laser_id,
        tds_probe_sensor_id: library.tds_probe_sensor_id,
        low_inlet_pressure: library.low_inlet_pressure,
        installationSteps,
      };
    }

    return {};
  };

  render() {
    const {
      match: { params },
      library,
      waterFilters,
      loading,
      token,
      reverseOsmosisFilters,
      softeners,
      flowSensorsForSelect,
      pressureSensorsForSelect,
      distanceSensorsForSelect,
      tdsProbeSensorsForSelect,
      tempSensorsForSelect,
    } = this.props;
    const { type } = params;
    const device_type = type ? type.replace('-configuration', '') : '';

    if (loading) {
      return <Loader size={200} block />;
    }

    if (!library) {
      return null;
    }

    const initialValues = this.setInitialValues(library, device_type);

    return (
      <DeviceConfigurationForm
        title="Edit Configuration"
        isEdit
        onSubmit={this.onSubmit}
        initialValues={initialValues}
        loading={loading}
        waterFilters={waterFilters}
        token={token}
        reverseOsmosisFilters={reverseOsmosisFilters}
        softeners={softeners}
        flowSensorsForSelect={flowSensorsForSelect}
        pressureSensorsForSelect={pressureSensorsForSelect}
        distanceSensorsForSelect={distanceSensorsForSelect}
        tdsProbeSensorsForSelect={tdsProbeSensorsForSelect}
        tempSensorsForSelect={tempSensorsForSelect}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    loading: state.libraries.loading,
    token: state.auth.token,
    waterFilters: state.libraries.waterFiltersForFilter,
    library: state.libraries.item,
    reverseOsmosisFilters: state.libraries.reverseOsmosisForFilter,
    softeners: state.libraries.softenerForFilter,
    flowSensorsForSelect: state.libraries.flowSensorsForSelect,
    pressureSensorsForSelect: state.libraries.pressureSensorsForSelect,
    distanceSensorsForSelect: state.libraries.distanceSensorsForSelect,
    tdsProbeSensorsForSelect: state.libraries.tdsProbeSensorsForSelect,
    tempSensorsForSelect: state.libraries.tempSensorsForSelect,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    submitDeviceConfiguration: (id, type, library) =>
      dispatch(editConfiguration(id, type, library)),
    fetchWaterFilters: () => dispatch(getWaterFiltersForFilter()),
    fetchConfiguration: (id, type) => dispatch(getConfiguration(id, type)),
    fetchReverseOsmosisFilters: () => dispatch(getReverseOsmosisForFilter()),
    fetchSofteners: () => dispatch(getSoftenerForFilter()),
    fetchFlowSensorsForSelect: () => dispatch(getFlowSensorsForSelect()),
    fetchPressureSensorsForSelect: () => dispatch(getPressureSensorsForSelect()),
    fetchDistanceSensorsForSelect: () => dispatch(getDistanceSensorsForSelect()),
    fetchTdsProbeSensorsForSelect: () => dispatch(getTdsProbeSensorsForSelect()),
    fetchTempSensorsForSelect: () => dispatch(getTemperatureSensorsForSelect()),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(EditDeviceConfiguration);

EditDeviceConfiguration.propTypes = {
  distanceSensorsForSelect: PropTypes.array,
  fetchConfiguration: PropTypes.func,
  fetchDistanceSensorsForSelect: PropTypes.func,
  fetchFlowSensorsForSelect: PropTypes.func,
  fetchPressureSensorsForSelect: PropTypes.func,
  fetchReverseOsmosisFilters: PropTypes.func,
  fetchSofteners: PropTypes.func,
  fetchTdsProbeSensorsForSelect: PropTypes.func,
  fetchTempSensorsForSelect: PropTypes.func,
  fetchWaterFilters: PropTypes.func,
  flowSensorsForSelect: PropTypes.array,
  library: PropTypes.object,
  loading: PropTypes.bool,
  match: PropTypes.object,
  pressureSensorsForSelect: PropTypes.array,
  reverseOsmosisFilters: PropTypes.array,
  softeners: PropTypes.array,
  submitDeviceConfiguration: PropTypes.func,
  tdsProbeSensorsForSelect: PropTypes.array,
  tempSensorsForSelect: PropTypes.array,
  token: PropTypes.string,
  waterFilters: PropTypes.array,
};
