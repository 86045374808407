import React from 'react';
import styled from 'styled-components';

import { Container, Heading, Screen, Text, utils } from 'styled-minimal';

const Header = styled.div`
  margin-bottom: ${utils.spacer(3)};
  text-align: center;
`;

const ComingSoon = () => (
  <Screen key="Private" data-testid="PrivateWrapper">
    <Container verticalPadding>
      <Header>
        <Heading>Coming Soon...</Heading>
        <Text>This page will complete on the next milestones</Text>
      </Header>
    </Container>
  </Screen>
);

export default ComingSoon;
