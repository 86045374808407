import React, { Component } from 'react';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Colors } from 'modules/theme';
import { push } from 'modules/history';
import config from 'config';
import Input from 'components/InputText';
import ButtonText from 'components/ButtonText';
import H2 from 'components/H2';
import H4 from 'components/H4';
import Icon from 'components/Icon';
import Switch from 'components/Switch';
import Select from 'components/Select';
import DropZone from 'components/DropZone';
import ImageUploaded from 'components/ImageUploaded';
import FormError from 'components/FormError';
import { Warranties } from 'constants/index';
import PropTypes from 'prop-types';

const Container = styled.div`
  margin: 30px;
`;

const InputContainer = styled.div`
  margin: 20px 0px;
`;

const ButtonContainer = styled.div`
  margin-top: 25px;
  margin-bottom: 25px;
  float: left;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const Column = styled.div`
  flex-direction: column;
`;

const url = `${config.BASE_URL}/uploads`;

export default class CatridgeForm extends Component {
  submit = values => {
    const { onSubmit } = this.props;

    onSubmit(values);
  };

  render() {
    const { title, initialValues, token } = this.props;

    return (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          chloramine_capacity: Yup.string().required('Required'),
          chlorine_capacity: Yup.string().required('Required'),
          low_inlet_pressure: Yup.number().required('Required'),
          max_pressure: Yup.number().required('Required'),
          name: Yup.string().required('Required'),
          part: Yup.string().required('Required'),
          price: Yup.string().required('Required'),
          scale_control_capacity: Yup.string().required('Required'),
          warranty: Yup.number().required('Required'),
        })}
        onSubmit={this.submit}
      >
        {({ values, errors, touched, handleChange, handleBlur, isValid, setFieldValue }) => (
          <Container>
            <Form>
              <Icon onClick={() => push('/libraries')} name="chevron-left" color={Colors.blue} />
              <H2 color={Colors.black}>{title}</H2>
              <Row>
                <Column style={{ paddingRight: '30px', width: '33.33%' }}>
                  <InputContainer>
                    <H4 inputLabel color={Colors.lightBlue}>
                      Model
                    </H4>
                    <Input
                      placeholder="Model"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      icon="bookmark"
                    />
                    <FormError text={errors.name && touched.name && errors.name} />
                  </InputContainer>
                </Column>
                <Column style={{ paddingRight: '30px', width: '33.33%' }}>
                  <InputContainer>
                    <H4 inputLabel style={{ paddingTop: '10px' }}>
                      &nbsp;
                    </H4>
                    <Switch
                      name="sediment"
                      isChecked={values.sediment}
                      titleChecked="Sediment (Sets All Capacities to Zero)"
                      titleUnchecked="Sediment (Sets All Capacities to Zero)"
                      onChange={evt => {
                        const { name, checked } = evt.target;
                        setFieldValue(name, checked);
                        if (checked) {
                          setFieldValue('scale_control_capacity', 0);
                          setFieldValue('chloramine_capacity', 0);
                          setFieldValue('chlorine_capacity', 0);
                        }
                      }}
                    />
                  </InputContainer>
                </Column>
              </Row>
              <Row>
                <Column>
                  <InputContainer>
                    <Switch
                      name="taste_odor"
                      isChecked={values.taste_odor}
                      titleChecked="Taste/Odor"
                      titleUnchecked="Taste/Odor"
                      onChange={evt => setFieldValue(evt.target.name, evt.target.checked)}
                    />
                  </InputContainer>
                </Column>
              </Row>
              <Row>
                <Column>
                  <InputContainer>
                    <Switch
                      name="contaminant"
                      isChecked={values.contaminant}
                      titleChecked="Contaminant"
                      titleUnchecked="Contaminant"
                      onChange={evt => setFieldValue(evt.target.name, evt.target.checked)}
                    />
                  </InputContainer>
                </Column>
              </Row>
              <Row>
                <Column>
                  <InputContainer>
                    <Switch
                      name="scale"
                      isChecked={values.scale}
                      titleChecked="Scale"
                      titleUnchecked="Scale"
                      onChange={evt => setFieldValue(evt.target.name, evt.target.checked)}
                    />
                  </InputContainer>
                </Column>
              </Row>
              <Row>
                <Column style={{ paddingRight: '30px', width: '33.33%' }}>
                  <H4 inputLabel color={Colors.gray8}>
                    Part #
                  </H4>
                  <InputContainer>
                    <Input
                      placeholder="Part"
                      type="text"
                      name="part"
                      value={values.part}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      icon="hashtag"
                    />
                    <FormError text={errors.part && touched.part && errors.part} />
                  </InputContainer>
                </Column>
                <Column style={{ width: '33.33%', paddingRight: '30px' }}>
                  <InputContainer>
                    <H4 inputLabel color={Colors.gray8}>
                      Cartridge price
                    </H4>
                    <Input
                      placeholder="Cartridge price"
                      type="number"
                      name="price"
                      value={values.price}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      icon="hashtag"
                    />
                    <FormError text={errors.price && touched.price && errors.price} />
                  </InputContainer>
                </Column>
                <Column style={{ width: '33.33%', paddingRight: '30px' }}>
                  <InputContainer>
                    <H4 inputLabel color={Colors.lightBlue}>
                      Warranty
                    </H4>
                    <Select
                      placeholder="Choose a warranty "
                      name="warranty"
                      options={Warranties}
                      value={values.warranty}
                      onChange={handleChange}
                    />
                    <FormError text={errors.warranty && touched.warranty && errors.warranty} />
                  </InputContainer>
                </Column>
              </Row>
              <Row>
                <Column style={{ width: '50%', paddingRight: '30px' }}>
                  <H4 inputLabel color={Colors.gray8}>
                    Scale control Capacity
                  </H4>
                  <InputContainer>
                    <Input
                      placeholder="Scale control Capacity"
                      type="number"
                      name="scale_control_capacity"
                      value={values.scale_control_capacity}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={values.sediment}
                      icon="hashtag"
                    />
                    <FormError
                      text={
                        errors.scale_control_capacity &&
                        touched.scale_control_capacity &&
                        errors.scale_control_capacity
                      }
                    />
                  </InputContainer>
                </Column>
                <Column style={{ width: '50%', paddingRight: '30px' }}>
                  <InputContainer>
                    <H4 inputLabel color={Colors.gray8}>
                      Chloramine Capacity
                    </H4>
                    <Input
                      placeholder="Chloramine Capacity"
                      type="number"
                      name="chloramine_capacity"
                      value={values.chloramine_capacity}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={values.sediment}
                      icon="hashtag"
                    />
                    <FormError
                      text={
                        errors.chloramine_capacity &&
                        touched.chloramine_capacity &&
                        errors.chloramine_capacity
                      }
                    />
                  </InputContainer>
                </Column>
              </Row>

              <Row>
                <Column style={{ width: '50%', paddingRight: '30px' }}>
                  <InputContainer>
                    <H4 inputLabel color={Colors.gray8}>
                      Chlorine Capacity
                    </H4>
                    <Input
                      placeholder="Chlorine Capacity"
                      type="number"
                      name="chlorine_capacity"
                      value={values.chlorine_capacity}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={values.sediment}
                      icon="hashtag"
                    />
                    <FormError
                      text={
                        errors.chlorine_capacity &&
                        touched.chlorine_capacity &&
                        errors.chlorine_capacity
                      }
                    />
                  </InputContainer>
                </Column>

                <Column style={{ width: '50%', paddingRight: '30px' }}>
                  <InputContainer>
                    <H4 inputLabel color={Colors.gray8}>
                      Max Pressure
                    </H4>
                    <Input
                      placeholder="Max Pressure"
                      type="number"
                      name="max_pressure"
                      value={values.max_pressure}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      icon="hashtag"
                    />
                    <FormError
                      text={errors.max_pressure && touched.max_pressure && errors.max_pressure}
                    />
                  </InputContainer>
                </Column>
              </Row>
              <Row>
                <Column style={{ width: '50%', paddingRight: '30px' }}>
                  <InputContainer>
                    <H4 inputLabel color={Colors.gray8}>
                      Low Inlet Pressure
                    </H4>
                    <Input
                      placeholder="Low Inlet Pressure"
                      type="number"
                      name="low_inlet_pressure"
                      value={values.low_inlet_pressure}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      icon="hashtag"
                    />
                    <FormError
                      text={
                        errors.low_inlet_pressure &&
                        touched.low_inlet_pressure &&
                        errors.low_inlet_pressure
                      }
                    />
                  </InputContainer>
                </Column>
              </Row>

              <Row>
                <Column style={{ width: '33.33%', paddingRight: '30px' }}>
                  <H4 inputLabel color={Colors.black}>
                    Photo
                  </H4>

                  {values.photo ? (
                    <ImageUploaded
                      image={values.photo}
                      onClick={() => setFieldValue('photo', null)}
                    />
                  ) : (
                    <DropZone
                      folder="catridge"
                      title="Choose a photo"
                      height="100px"
                      token={token}
                      handleResponse={({ url }) => setFieldValue('photo', url)}
                      url={url}
                    />
                  )}
                </Column>
              </Row>
              <Row>
                <ButtonContainer>
                  <ButtonText text="Save" theme="primary" type="submit" disabled={!isValid} />
                </ButtonContainer>
              </Row>
            </Form>
          </Container>
        )}
      </Formik>
    );
  }
}

CatridgeForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  title: PropTypes.string,
  token: PropTypes.string,
};
