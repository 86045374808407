import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ButtonText from './ButtonText';

const Container = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  background: #fff;
  max-width: 100%;
  flex-wrap: wrap;
`;

const ButtonContainer = styled.div`
  padding-right: 10px;
  padding-bottom: 10px;
`;

class Tabs extends Component {
  render() {
    const { tabs, active } = this.props;
    return (
      <Container>
        {tabs.map((tab, key) => (
          <ButtonContainer key={key}>
            <ButtonText
              onClick={tab.action}
              theme={active === tab.title ? 'primary' : 'basic'}
              text={tab.title}
              icon={tab.icon}
            />
          </ButtonContainer>
        ))}
      </Container>
    );
  }
}

Tabs.propTypes = {
  active: PropTypes.string,
  tabs: PropTypes.array,
};

export default Tabs;
