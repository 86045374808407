import React, { Component } from 'react';
import styled from 'styled-components';
import { Formik, Form, FieldArray } from 'formik';
import * as Yup from 'yup';
import { Colors } from 'modules/theme';
import { push } from 'modules/history';
import { PositionLabelsRO, ValuesFilter } from 'constants/index';
import config from 'config';
import Input from 'components/InputText';
import Select from 'components/Select';
import Textarea from 'components/Textarea';
import ButtonText from 'components/ButtonText';
import H2 from 'components/H2';
import H4 from 'components/H4';
import Icon from 'components/Icon';
import DropZone from 'components/DropZone';
import ImageUploaded from 'components/ImageUploaded';
import FormError from 'components/FormError';
import PropTypes from 'prop-types';

const Container = styled.div`
  margin: 30px;
`;

const InputContainer = styled.div`
  margin: 20px 0px;
`;

const ButtonContainer = styled.div`
  margin-top: 25px;
  margin-bottom: 25px;
  float: left;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const Column = styled.div`
  flex-direction: column;
`;

const SpotsContainer = styled.div``;

const membraneDefault = {
  reverse_osmosis_membrane_id: '',
};

const url = `${config.BASE_URL}/uploads`;

export default class ReverseOsmosisFilterForm extends Component {
  submit = values => {
    const { onSubmit } = this.props;

    onSubmit(values);
  };

  renderSpot = (spot, key, form, push, remove, errors = {}, disabledForm) => {
    const { catridges } = this.props;
    const spotSize = form.values.spots.length;
    const changeValue = e => {
      e.stopPropagation();
      form.setFieldValue(e.target.name, e.target.value);
    };

    const updateFlowsKeys = e => {
      e.stopPropagation();
      const newSpots = form.values.spots.map(spot => ({
        ...spot,
        [e.target.name]: e.target.value,
      }));
      form.setFieldValue('spots', newSpots);
    };

    return (
      <Row>
        <Column style={{ width: '95%' }}>
          {key === 0 && (
            <Row key={key} style={{ paddingBottom: '20px' }}>
              <Column style={{ marginRight: '10px', width: '25%' }}>
                <H4 inputLabel color={Colors.gray8}>
                  Inlet Flow Key
                </H4>
                <Select
                  placeholder="Inlet Flow Key"
                  name="inlet_flow_key"
                  options={[
                    { label: 'flow_1', value: 'flow_1' },
                    { label: 'flow_2', value: 'flow_2' },
                    { label: 'flow_3', value: 'flow_3' },
                  ]}
                  value={spot.inlet_flow_key}
                  onChange={updateFlowsKeys}
                  disabled={disabledForm}
                />
                <FormError text={errors.inlet_flow_key && errors.inlet_flow_key} />
              </Column>
              <Column style={{ marginRight: '10px', width: '25%' }}>
                <H4 inputLabel color={Colors.gray8}>
                  Product Flow key
                </H4>
                <Select
                  placeholder="Product flow key"
                  name="flow_key"
                  options={[
                    { label: 'flow_1', value: 'flow_1' },
                    { label: 'flow_2', value: 'flow_2' },
                    { label: 'flow_3', value: 'flow_3' },
                  ]}
                  value={spot.flow_key}
                  onChange={updateFlowsKeys}
                  disabled={disabledForm}
                />
                <FormError text={errors.flow_key && errors.flow_key} />
              </Column>
              <Column style={{ marginRight: '10px', width: '25%' }}>
                <H4 inputLabel color={Colors.gray8}>
                  Inlet Pressure Key
                </H4>
                <Select
                  placeholder="Inlet Pressure Key"
                  name="inlet_pressure_key"
                  options={[
                    { label: 'psi_1', value: 'psi_1' },
                    { label: 'psi_2', value: 'psi_2' },
                    { label: 'psi_3', value: 'psi_3' },
                    { label: 'psi_4', value: 'psi_4' },
                  ]}
                  value={spot.inlet_pressure_key}
                  onChange={updateFlowsKeys}
                  disabled={disabledForm}
                />
                <FormError text={errors.inlet_pressure_key && errors.inlet_pressure_key} />
              </Column>
              <Column style={{ marginRight: '10px', width: '25%' }}>
                <H4 inputLabel color={Colors.gray8}>
                  Outlet Pressure Key
                </H4>
                <Select
                  placeholder="Outlet Pressure Key"
                  name="outlet_pressure_key"
                  options={[
                    { label: 'psi_1', value: 'psi_1' },
                    { label: 'psi_2', value: 'psi_2' },
                    { label: 'psi_3', value: 'psi_3' },
                    { label: 'psi_4', value: 'psi_4' },
                  ]}
                  value={spot.outlet_pressure_key}
                  onChange={updateFlowsKeys}
                  disabled={disabledForm}
                />
                <FormError text={errors.outlet_pressure_key && errors.outlet_pressure_key} />
              </Column>
              <Column style={{ marginRight: '10px', width: '25%' }}>
                <H4 inputLabel color={Colors.gray8}>
                  Tank Pressure Key
                </H4>
                <Select
                  placeholder="Tank Pressure Key"
                  name="tank_pressure_key"
                  options={[
                    { label: 'psi_1', value: 'psi_1' },
                    { label: 'psi_2', value: 'psi_2' },
                    { label: 'psi_3', value: 'psi_3' },
                    { label: 'psi_4', value: 'psi_4' },
                  ]}
                  value={spot.tank_pressure_key}
                  onChange={updateFlowsKeys}
                  disabled={disabledForm}
                />
                <FormError text={errors.tank_pressure_key && errors.tank_pressure_key} />
              </Column>
              <Column style={{ marginRight: '20px', width: '30%' }}>
                <H4 inputLabel color={Colors.gray8}>
                  System Pressure Key
                </H4>
                <Select
                  placeholder="System Pressure Key"
                  name="system_pressure_key"
                  options={[
                    { label: 'psi_1', value: 'psi_1' },
                    { label: 'psi_2', value: 'psi_2' },
                    { label: 'psi_3', value: 'psi_3' },
                    { label: 'psi_4', value: 'psi_4' },
                  ]}
                  value={spot.system_pressure_key}
                  onChange={updateFlowsKeys}
                  disabled={disabledForm}
                />
                <FormError text={errors.system_pressure_key && errors.system_pressure_key} />
              </Column>
            </Row>
          )}
          <Row key={key} style={{ paddingBottom: '10px' }}>
            <Column style={{ marginRight: '30px', width: '50%' }}>
              <H4 inputLabel color={Colors.gray8}>
                Catridge
              </H4>
              <Select
                placeholder="Choose a catridge"
                name={`spots.${key}.catridge_id`}
                value={spot.catridge_id}
                options={catridges}
                onChange={changeValue}
                disabled={disabledForm}
              />
              <FormError text={errors.catridge_id && errors.catridge_id} />
            </Column>
            <Column style={{ marginRight: '10px', width: '50%' }}>
              <H4 inputLabel color={Colors.gray8}>
                Position
              </H4>
              <Select
                placeholder="Choose Position"
                name={`spots.${key}.position`}
                options={PositionLabelsRO}
                value={spot.position}
                onChange={changeValue}
                disabled={disabledForm}
              />
              <FormError text={errors.position && errors.position} />
            </Column>
          </Row>
        </Column>
        {!disabledForm && (
          <Column style={{ width: '5%', paddingTop: '10px' }}>
            {spotSize - 1 === key ? (
              <Icon
                name="plus-circle"
                color={Colors.lightBlue}
                fontSize={22}
                onClick={() => push({ ...spot, catridge_id: '', position: '' })}
              />
            ) : (
              <Icon
                name="minus-circle"
                color={Colors.red}
                fontSize={22}
                onClick={() => remove(key)}
              />
            )}
          </Column>
        )}
      </Row>
    );
  };

  renderMembrane = (membrane, key, form, push, remove, errors = {}, disabledForm) => {
    const { ROMembranes } = this.props;
    const membraneSize = form.values.membranes.length;

    const changeValue = e => {
      e.stopPropagation();
      form.setFieldValue(e.target.name, e.target.value);
    };

    return (
      <Row key={key} style={{ paddingBottom: '20px' }}>
        <Column style={{ paddingRight: '30px', width: '33.33%' }}>
          <Select
            placeholder="Choose a membrane"
            name={`membranes.${key}.reverse_osmosis_membrane_id`}
            value={membrane.reverse_osmosis_membrane_id}
            options={ROMembranes}
            onChange={changeValue}
            disabled={disabledForm}
          />
          <FormError
            text={errors.reverse_osmosis_membrane_id && errors.reverse_osmosis_membrane_id}
          />
        </Column>
        {!disabledForm && (
          <Column style={{ marginRight: '30px', width: '33.33%', paddingTop: '10px' }}>
            {membraneSize - 1 === key ? (
              membraneSize <= 1 && (
                <Icon
                  name="plus-circle"
                  color={Colors.lightBlue}
                  fontSize={22}
                  onClick={() => push(membraneDefault)}
                />
              )
            ) : (
              <Icon
                name="minus-circle"
                color={Colors.red}
                fontSize={22}
                onClick={() => remove(key)}
              />
            )}
          </Column>
        )}
      </Row>
    );
  };

  render() {
    const { initialValues, token, title, editMode, editable } = this.props;
    const disabledForm = editMode && !editable;

    return (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          description: Yup.string().required('Required'),
          membranes: Yup.array().of(
            Yup.object().shape({
              reverse_osmosis_membrane_id: Yup.string().required('Required'),
            }),
          ),
          name: Yup.string().required('Required'),
          postfilter: Yup.string().required('Required'),
          prefilter: Yup.string().required('Required'),
          ro_model_name: Yup.string().required('Required'),
          sediment_prefilter: Yup.string().required('Required'),
          spots: Yup.array()
            .of(
              Yup.object().shape({
                catridge_id: Yup.string().required('Required'),
                position: Yup.string().required('Required'),
              }),
            )
            .test(
              'Exist Pre-Carbon Filter',
              'A cartridge of position Pre-Carbon Filter is required.',
              spots => spots.some(cart => cart.position === 'Pre-carbon filter'),
            ),
        })}
        onSubmit={this.submit}
      >
        {({ values, errors, touched, handleChange, handleBlur, isValid, setFieldValue }) => (
          <Container>
            <Form>
              <Icon onClick={() => push('/libraries')} name="chevron-left" color={Colors.blue} />
              <H2 color={Colors.black}>{title}</H2>
              {disabledForm && (
                <H4 inputLabel color={Colors.red}>
                  You cannot edit this reverse osmosis filter because it is assigned to a location.
                </H4>
              )}
              <Row>
                <Column style={{ marginRight: '30px', width: '33.33%' }}>
                  <InputContainer>
                    <H4 inputLabel color={Colors.lightBlue}>
                      RO model
                    </H4>
                    <Input
                      placeholder="RO model"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      icon="bookmark"
                      disabled={disabledForm}
                    />
                    <FormError text={errors.name && touched.name && errors.name} />
                  </InputContainer>
                </Column>
                <Column style={{ marginRight: '30px', width: '33.33%' }}>
                  <InputContainer>
                    <H4 inputLabel color={Colors.gray8}>
                      RO model name
                    </H4>
                    <Input
                      placeholder="RO model name"
                      name="ro_model_name"
                      value={values.ro_model_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      icon="bookmark"
                      disabled={disabledForm}
                    />
                    <FormError
                      text={errors.ro_model_name && touched.ro_model_name && errors.ro_model_name}
                    />
                  </InputContainer>
                </Column>
                <Column style={{ marginRight: '30px', width: '33.33%' }}>
                  <InputContainer>
                    <H4 inputLabel color={Colors.gray8}>
                      Bypass Key
                    </H4>
                    <Select
                      placeholder="Bypass Key"
                      name="bypass_key"
                      options={[
                        { label: 'flow_1', value: 'flow_1' },
                        { label: 'flow_2', value: 'flow_2' },
                        { label: 'flow_3', value: 'flow_3' },
                      ]}
                      value={values.bypass_key}
                      onChange={handleChange}
                      disabled={disabledForm}
                    />
                    <FormError
                      text={errors.bypass_key && touched.bypass_key && errors.bypass_key}
                    />
                  </InputContainer>
                </Column>
              </Row>

              <Row style={{ marginBottom: '5px', marginTop: '20px' }}>
                <H4 inputLabel color={Colors.gray8}>
                  Select Cartridges
                </H4>
              </Row>
              <FieldArray
                name="spots"
                render={({ form, push, remove }) => (
                  <SpotsContainer>
                    {values.spots.map((spot, key) =>
                      this.renderSpot(
                        spot,
                        key,
                        form,
                        push,
                        remove,
                        errors && errors.spots && typeof errors.spots === 'object'
                          ? errors.spots[key]
                          : {},
                        disabledForm,
                      ),
                    )}
                    <FormError
                      text={errors.spots && typeof errors.spots === 'string' && errors.spots}
                    />
                  </SpotsContainer>
                )}
              />
              <Row style={{ marginTop: '30px' }}>
                <H4 inputLabel color={Colors.gray8}>
                  Select Membranes
                </H4>
              </Row>
              <FieldArray
                name="membranes"
                render={({ form, push, remove }) => (
                  <SpotsContainer>
                    {values.membranes.map((membrane, key) =>
                      this.renderMembrane(
                        membrane,
                        key,
                        form,
                        push,
                        remove,
                        errors && errors.membranes ? errors.membranes[key] : {},
                        disabledForm,
                      ),
                    )}
                  </SpotsContainer>
                )}
              />
              <Row style={{ paddingBottom: '20px' }}>
                <Column style={{ marginRight: '30px', width: '33.33%' }}>
                  <InputContainer>
                    <H4 inputLabel color={Colors.gray8}>
                      Sediment prefilter
                    </H4>
                    <Select
                      placeholder="Choose a Sediment prefilter"
                      name="sediment_prefilter"
                      value={values.sediment_prefilter}
                      options={ValuesFilter}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={disabledForm}
                    />
                    <FormError text={errors.sediment_prefilter && errors.sediment_prefilter} />
                  </InputContainer>
                </Column>
                <Column style={{ marginRight: '30px', width: '33.33%' }}>
                  <InputContainer>
                    <H4 inputLabel color={Colors.gray8}>
                      Prefilter
                    </H4>
                    <Select
                      placeholder="Choose a Sediment prefilter"
                      name="prefilter"
                      value={values.prefilter}
                      options={ValuesFilter}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={disabledForm}
                    />
                    <FormError text={errors.prefilter && errors.prefilter} />
                  </InputContainer>
                </Column>
                <Column style={{ marginRight: '30px', width: '33.33%' }}>
                  <InputContainer>
                    <H4 inputLabel color={Colors.gray8}>
                      Postfilter
                    </H4>
                    <Select
                      placeholder="Choose a Sediment prefilter"
                      name="postfilter"
                      value={values.postfilter}
                      options={ValuesFilter}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={disabledForm}
                    />
                    <FormError text={errors.postfilter && errors.postfilter} />
                  </InputContainer>
                </Column>
              </Row>
              <Row>
                <Column style={{ marginRight: '30px', paddingRight: '30px', width: '66.66%' }}>
                  <H4 inputLabel color={Colors.gray8}>
                    Description
                  </H4>
                  <InputContainer>
                    <Textarea
                      value={values.description}
                      onChange={value => setFieldValue('description', value)}
                      higlights={[]}
                      onBlur={handleBlur}
                      disabled={disabledForm}
                    />

                    <FormError
                      text={errors.description && touched.description && errors.description}
                    />
                  </InputContainer>
                </Column>
              </Row>

              <Row>
                <Column style={{ width: '33.33%', paddingRight: '30px' }}>
                  <H4 inputLabel color={Colors.black}>
                    Photo
                  </H4>

                  {values.photo ? (
                    <ImageUploaded
                      image={values.photo}
                      onClick={() => setFieldValue('photo', null)}
                    />
                  ) : (
                    <DropZone
                      folder="reverse-osmosis"
                      title="Choose a photo"
                      height="100px"
                      token={token}
                      handleResponse={({ url }) => setFieldValue('photo', url)}
                      url={url}
                      disabled={disabledForm}
                    />
                  )}
                </Column>
              </Row>
              <Row>
                <ButtonContainer>
                  <ButtonText text="Save" theme="primary" type="submit" disabled={!isValid} />
                </ButtonContainer>
              </Row>
            </Form>
          </Container>
        )}
      </Formik>
    );
  }
}

ReverseOsmosisFilterForm.propTypes = {
  catridges: PropTypes.array,
  editable: PropTypes.bool,
  editMode: PropTypes.bool,
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  ROMembranes: PropTypes.array,
  title: PropTypes.string,
  token: PropTypes.string,
};
