import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Colors } from 'modules/theme';

const InputText = styled.input`
  border: 1px solid #e1edf3;
  box-sizing: border-box;
  border-radius: ${props => props.borderRadius || 5}px;
  font-size: ${props => props.fontSize || 18}px;
  line-height: 17px;
  color: ${Colors.gray};
  padding: 7px 15px 7px 15px;
  outline-style: none;
  ::placeholder {
    font-size: ${props => props.fontSize || 18}px;
    color: ${props => props.color || Colors.gray};
  }
  border-color: ${props => (props.hasError ? Colors.red : props.borderColor || Colors.gray2)};
`;

const Container = styled.div`
  position: relative;
`;

const Icon = styled.i`
  position: absolute;
  margin-left: -29px;
  margin-top: 15px;
  color: #1498cb;
  transform: matrix(-1, 0, 0, 1, 0, 0);
  font-size: 12px;
`;

const InputTextComponent = props => {
  const { icon, placeholder } = props;
  return (
    <Container>
      <InputText {...props} placeholder={placeholder} />
      <Icon className={`fas fa-${icon}`} />
    </Container>
  );
};

InputTextComponent.propTypes = {
  icon: PropTypes.string,
  InputText: PropTypes.string,
  placeholder: PropTypes.string,
};

export default InputTextComponent;
