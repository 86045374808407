import React from 'react';
import styled from 'styled-components';
import logo from 'assets/media/images/logo.png';

export const Image = styled.img`
  width: 200px;
  heigth: 200px;
`;

const Logo = () => <Image src={logo} />;

export default Logo;
