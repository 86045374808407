import React, { Component } from 'react';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Colors } from 'modules/theme';
import { push } from 'modules/history';
import { Warranties } from 'constants/index';
import Input from 'components/InputText';
import Select from 'components/Select';
import ButtonText from 'components/ButtonText';
import H2 from 'components/H2';
import H4 from 'components/H4';
import Icon from 'components/Icon';

import FormError from 'components/FormError';
import PropTypes from 'prop-types';

const Container = styled.div`
  margin: 30px;
`;

const InputContainer = styled.div`
  margin: 20px 0px;
`;

const ButtonContainer = styled.div`
  margin-top: 25px;
  margin-bottom: 25px;
  float: left;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const Column = styled.div`
  flex-direction: column;
`;

export default class ROMembraneForm extends Component {
  submit = values => {
    const { onSubmit } = this.props;

    onSubmit(values);
  };

  render() {
    const { title, initialValues } = this.props;

    return (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          name: Yup.string().required('Required'),
          warranty: Yup.number()
            .integer()
            .min(0)
            .required('Required'),
        })}
        onSubmit={this.submit}
      >
        {({ values, errors, touched, handleChange, handleBlur, isValid }) => (
          <Container>
            <Form>
              <Icon onClick={() => push('/libraries')} name="chevron-left" color={Colors.blue} />
              <H2 color={Colors.black}>{title}</H2>
              <Row>
                <Column style={{ paddingRight: '30px', width: '33.33%' }}>
                  <InputContainer>
                    <H4 inputLabel color={Colors.lightBlue}>
                      Membrane Model Number
                    </H4>
                    <Input
                      placeholder="Model"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      icon="bookmark"
                    />
                    <FormError text={errors.name && touched.name && errors.name} />
                  </InputContainer>
                </Column>
                <Column style={{ paddingRight: '30px', width: '33.33%' }}>
                  <InputContainer>
                    <H4 inputLabel color={Colors.lightBlue}>
                      Warranty
                    </H4>
                    <Select
                      placeholder="Choose a warranty "
                      name="warranty"
                      options={Warranties}
                      value={values.warranty}
                      onChange={handleChange}
                    />
                    <FormError text={errors.warranty && touched.warranty && errors.warranty} />
                  </InputContainer>
                </Column>
              </Row>

              <Row>
                <ButtonContainer>
                  <ButtonText text="Save" theme="primary" type="submit" disabled={!isValid} />
                </ButtonContainer>
              </Row>
            </Form>
          </Container>
        )}
      </Formik>
    );
  }
}

ROMembraneForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  title: PropTypes.string,
};
