// @flow
/**
 * @module Actions/User
 * @desc User Actions
 */
import { createActions } from 'redux-actions';

import { ActionTypes } from 'constants/index';

export const {
  getUsers,
  getUser,
  getInstallersByAccount,
  createUser,
  editUser,
  deleteUser,
  resendEmail,
} = createActions({
  [ActionTypes.GET_USERS]: params => params,
  [ActionTypes.GET_USER]: id => ({ id }),
  [ActionTypes.GET_INSTALLERS_BY_ACCOUNT]: accountId => ({ accountId }),
  [ActionTypes.CREATE_USER]: data => data,
  [ActionTypes.EDIT_USER]: (id, user) => ({ id, user }),
  [ActionTypes.DELETE_USER]: id => ({ id }),
  [ActionTypes.RESEND_EMAIL]: id => ({ id }),
});
