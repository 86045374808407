import React, { Component } from 'react';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Colors } from 'modules/theme';
import { push } from 'modules/history';
import { SensorTypes } from 'constants/index';
import Input from 'components/InputText';
import Select from 'components/Select';
import ButtonText from 'components/ButtonText';
import H2 from 'components/H2';
import H4 from 'components/H4';
import Icon from 'components/Icon';
import FormError from 'components/FormError';
import PropTypes from 'prop-types';
import FlowMeterSensorForm from './FlowMeterSensorForm';
import PressureTransducerForm from './PressureTransducerForm';
import TdsProbeForm from './TdsProbeForm';
import TemperatureForm from './TemperatureForm';

const { Parser } = require('expr-eval');

const Container = styled.div`
  margin: 30px;
`;

const InputContainer = styled.div`
  margin: 20px 0px;
`;

const ButtonContainer = styled.div`
  margin-top: 25px;
  margin-bottom: 25px;
  float: left;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const Column = styled.div`
  flex-direction: column;
`;

const defaultValidation = {
  name: Yup.string().required('Required'),
  sensor_type: Yup.string().required('Required'),
};

const pressureTransducerValidation = {
  name: Yup.string().required('Required'),
  sensor_type: Yup.string().required('Required'),
  conversion_formula: Yup.string()
    .required('Required')
    .test('formula-valid', 'Math expression incorrrect', value => {
      try {
        const validateString = value.replace(/</g, '').replace(/>/g, '');
        Parser.evaluate(validateString, { psi_avg: 1 });
        return true;
      } catch (ex) {
        return false;
      }
    }),
};

const tdsProbeValidation = {
  name: Yup.string().required('Required'),
  sensor_type: Yup.string().required('Required'),
  conversion_formula: Yup.string()
    .required('Required')
    .test('formula-valid', 'Math expression incorrrect', value => {
      try {
        const validateString = value.replace(/</g, '').replace(/>/g, '');
        Parser.evaluate(validateString, { tds_avg: 1 });
        return true;
      } catch (ex) {
        return false;
      }
    }),
};

const flowMeterValidation = {
  name: Yup.string().required('Required'),
  sensor_type: Yup.string().required('Required'),
  pulses_per_galon: Yup.number().required('Required'),
};

const temperatureSensorValidation = {
  name: Yup.string().required('Required'),
  sensor_type: Yup.string().required('Required'),
  conversion_formula: Yup.string()
    .required('Required')
    .test('formula-valid', 'Math expression incorrrect', value => {
      try {
        const validateString = value.replace(/</g, '').replace(/>/g, '');
        Parser.evaluate(validateString, { temp_avg: 1 });
        return true;
      } catch (ex) {
        return false;
      }
    }),
};

export default class SensorForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sensor_type: props.initialValues.sensor_type || '',
    };
  }

  submit = values => {
    const { onSubmit } = this.props;

    onSubmit(values);
  };

  getRules = () => {
    const { sensor_type } = this.state;

    if (sensor_type === 'flow-meter') {
      return flowMeterValidation;
    }

    if (sensor_type === 'pressure-transducer') {
      return pressureTransducerValidation;
    }

    if (sensor_type === 'tds-probe') {
      return tdsProbeValidation;
    }

    if (sensor_type === 'temperature') {
      return temperatureSensorValidation;
    }

    return defaultValidation;
  };

  render() {
    const { initialValues, title, isEdit } = this.props;
    const { sensor_type } = this.state;

    return (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={Yup.object().shape(this.getRules())}
        onSubmit={this.submit}
      >
        {({ values, errors, touched, handleChange, handleBlur, isValid, setFieldValue }) => (
          <Container>
            <Form>
              <Icon onClick={() => push('/libraries')} name="chevron-left" color={Colors.blue} />
              <H2 color={Colors.black}>{title}</H2>
              <Row>
                <Column style={{ width: '33.33%', paddingRight: '30px' }}>
                  <InputContainer>
                    <H4 inputLabel color={Colors.lightBlue}>
                      Choose Sensor Type
                    </H4>
                    <Select
                      placeholder="Choose Sensor Type "
                      name="sensor_type"
                      options={SensorTypes}
                      disabled={isEdit}
                      value={values.sensor_type}
                      onChange={evt => {
                        this.setState({ [evt.target.name]: evt.target.value });
                        handleChange(evt);
                      }}
                    />
                    <FormError
                      text={errors.sensor_type && touched.sensor_type && errors.sensor_type}
                    />
                  </InputContainer>
                </Column>
                <Column style={{ width: '33.33%', paddingRight: '30px' }}>
                  <H4 inputLabel color={Colors.gray8}>
                    Sensor Name
                  </H4>
                  <InputContainer>
                    <Input
                      placeholder="Name"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      icon="bookmark"
                    />
                    <FormError text={errors.name && touched.name && errors.name} />
                  </InputContainer>
                </Column>
              </Row>
              {sensor_type === 'flow-meter' && (
                <FlowMeterSensorForm
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
              )}
              {sensor_type === 'pressure-transducer' && (
                <PressureTransducerForm
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  setFieldValue={setFieldValue}
                />
              )}
              {sensor_type === 'tds-probe' && (
                <TdsProbeForm
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  setFieldValue={setFieldValue}
                />
              )}
              {sensor_type === 'temperature' && (
                <TemperatureForm
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  setFieldValue={setFieldValue}
                />
              )}
              <Row>
                <ButtonContainer>
                  <ButtonText text="Save" theme="primary" type="submit" disabled={!isValid} />
                </ButtonContainer>
              </Row>
            </Form>
          </Container>
        )}
      </Formik>
    );
  }
}

SensorForm.propTypes = {
  initialValues: PropTypes.object,
  isEdit: PropTypes.bool,
  onSubmit: PropTypes.func,
  title: PropTypes.string,
};
