import React, { Component } from 'react';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Colors } from 'modules/theme';
import { push } from 'modules/history';
import Input from 'components/InputText';
import InputPassword from 'components/InputPassword';
import ButtonText from 'components/ButtonText';
import FormError from 'components/FormError';
import BG from 'components/BG';
import H3 from 'components/H3';
import Logo from 'components/Logo';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px auto 50px auto;
  padding 20px;
  width: 40%;
  text-align: center;
  @media (max-width: 970px) {
    width: 100%;
  }
`;

const LogoContainer = styled.div`
  margin-top: 80px;
  margin-bottom: 80px;
  text-align: center;
`;

const InputContainer = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
`;

const ErrorContainer = styled.div`
  text-align: left;
`;

const TextButtonContainer = styled.div`
  margin-bottom: 18px;
  margin-top: 5px;
  float: left;
`;

export default class LoginView extends Component {
  submit = values => {
    const { login } = this.props;
    const { email, password } = values;

    login(email, password);
  };

  render() {
    const initialValues = {
      email: '',
      password: '',
    };

    return (
      <Formik
        enableReinitialize
        onSubmit={this.submit}
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .required('Required')
            .email('Invalid Format'),
          password: Yup.string()
            .required('Required')
            .min(8, 'The password must have more than eight characters'),
        })}
      >
        {({ values, errors, touched, handleChange, handleBlur, isValid, setFieldValue }) => (
          <Container>
            <LogoContainer>
              <Logo />
            </LogoContainer>
            <BG>
              <Form>
                <H3 color={Colors.lightBlue}>LOG IN</H3>
                <InputContainer>
                  <Input
                    placeholder="Email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    icon="envelope"
                  />
                  <ErrorContainer>
                    <FormError text={errors.email && touched.email && errors.email} />
                  </ErrorContainer>
                </InputContainer>
                <InputContainer>
                  <InputPassword
                    placeholder="Password"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    secureTextEntry={true}
                    onBlur={handleBlur}
                  />
                  <ErrorContainer>
                    <FormError text={errors.password && touched.password && errors.password} />
                  </ErrorContainer>
                  <TextButtonContainer>
                    <ButtonText
                      text="Forgot password?"
                      type="button"
                      color={Colors.blue}
                      theme="text"
                      small
                      onClick={() => push('/recover-password')}
                    />
                  </TextButtonContainer>
                </InputContainer>

                <ButtonText text="Login" theme="primary" full type="submit" />
              </Form>
            </BG>
          </Container>
        )}
      </Formik>
    );
  }
}
