import React, { Component } from 'react';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Colors } from 'modules/theme';
import { push } from 'modules/history';
import Input from 'components/InputText';
import ButtonText from 'components/ButtonText';
import BG from 'components/BG';
import H3 from 'components/H3';
import Icon from 'components/Icon';
import Logo from 'components/Logo';
import FormError from 'components/FormError';
import PropTypes from 'prop-types';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px auto 50px auto;
  padding 20px;
  width: 40%;
  text-align: center;
  @media (max-width: 970px) {
    width: 100%;
  }
`;

const LogoContainer = styled.div`
  margin-top: 80px;
  margin-bottom: 80px;
  text-align: center;
`;

const Header = styled.div`
  display: flex;
`;

const InputContainer = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
`;

const ErrorContainer = styled.div`
  text-align: left;
`;

export default class RecoverPasswordView extends Component {
  submit = (values, { resetForm }) => {
    const { recoverPassword } = this.props;
    const { email } = values;
    recoverPassword(email);
    resetForm();
  };

  render() {
    const initialValues = {
      email: '',
    };

    return (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={this.submit}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .required('Required')
            .email('Invalid Format'),
        })}
      >
        {({ values, handleChange, handleBlur, errors, touched }) => (
          <Form>
            <Container>
              <LogoContainer>
                <Logo />
              </LogoContainer>
              <BG>
                <Header>
                  <Icon
                    customStyle={{ alignSelf: 'center', marginRight: '20px' }}
                    onClick={() => push('/login')}
                    name="chevron-left"
                    color={Colors.lightBlue}
                  />
                  <H3 color={Colors.lightBlue}>SEND PASSWORD RESET</H3>
                </Header>
                <InputContainer>
                  <Input
                    placeholder="Email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    icon="envelope"
                  />
                  <ErrorContainer>
                    <FormError text={errors.email && touched.email && errors.email} />
                  </ErrorContainer>
                </InputContainer>

                <ButtonText text="Send Reset" theme="primary" full type="submit" />
              </BG>
            </Container>
          </Form>
        )}
      </Formik>
    );
  }
}

RecoverPasswordView.propTypes = {
  recoverPassword: PropTypes.func,
};
