import React from 'react';
import styled from 'styled-components';
import { Colors } from 'modules/theme';
import PropTypes from 'prop-types';

const Text = styled.p`
  font-family: Gotham, sans-serif;
  font-style: normal;
  font-size: 9px;
  line-height: 11px;
  color: ${props => (props.color ? props.color : Colors.black)};
  text-transform: ${props => (props.uppercase ? 'uppercase' : '')};
  font-weight: ${props => (props.weight ? props.weight : '325')};
`;

const P = props => <Text {...props}>{props.children}</Text>;

P.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string,
  uppercase: PropTypes.bool,
  weight: PropTypes.string,
};
export default P;
