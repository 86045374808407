import React, { Component } from 'react';
import styled from 'styled-components';
import { Colors } from 'modules/theme';
import Switch from 'components/Switch';
import ButtonText from 'components/ButtonText';
import H2 from 'components/H2';
import PropTypes from 'prop-types';

const Container = styled.div`
  margin: 30px;
`;

const InputContainer = styled.div`
  margin: 20px 0px;
`;

const ButtonContainer = styled.div`
  margin-top: 25px;
  margin-bottom: 25px;
  float: left;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const Column = styled.div`
  flex-direction: column;
  width: 50%;
  margin-rigth: 30px;
`;

export default class AlertSetupView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      allow_email_notifications: props.profile.allow_email_notifications,
      allow_sms_notifications: props.profile.allow_sms_notifications,
    };
  }

  submit = () => {
    const { editProfile } = this.props;

    editProfile(this.state);
  };

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  render() {
    const { allow_email_notifications, allow_sms_notifications } = this.state;

    return (
      <Container>
        <H2 color={Colors.black}>Alerts</H2>
        <Row>
          <Column>
            <InputContainer>
              <Switch
                name="allow_email_notifications"
                isChecked={allow_email_notifications}
                titleChecked="Email notifications"
                titleUnchecked="Email notifications"
                onChange={this.handleChange}
              />
            </InputContainer>
          </Column>
        </Row>
        <Row>
          <Column>
            <InputContainer>
              <Switch
                name="allow_sms_notifications"
                isChecked={allow_sms_notifications}
                titleChecked="SMS notifications"
                titleUnchecked="SMS notifications"
                onChange={this.handleChange}
              />
            </InputContainer>
          </Column>
        </Row>
        <Row>
          <ButtonContainer>
            <ButtonText text="Save" theme="primary" onClick={this.submit} />
          </ButtonContainer>
        </Row>
      </Container>
    );
  }
}

AlertSetupView.propTypes = {
  editProfile: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
};
