import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Colors } from 'modules/theme';
import Icon from './Icon';

const Container = styled.div`
  position: relative;
  border: 1px solid #e1edf3;
  background-color: ${Colors.white};
  width: 100%;
  box-sizing: border-box;
  border-radius: 5px;
  font-size: 18px;
  line-height: 17px;
  padding: 0px 15px;
  outline-style: none;
  line-height: 30px;
  -webkit-appearance: none;
  -moz-appearance: none;
  border-color: ${props => (props.hasError ? Colors.red : props.borderColor || Colors.gray2)};
`;

const Item = styled.div`
  color: ${props => (props.isSelected ? Colors.black : Colors.gray)};
  padding: 5px;
  cursor: pointer;
  ${props => !props.isSelected && !props.isOpen && 'display: none'};
`;

const IconChevron = styled.i`
  position: absolute;
  top: 15px;
  right: 18px;
  color: ${Colors.blue};
  font-size: 12px;
  cursor: pointer;
`;

class Dropbox extends Component {
  state = {
    isOpen: false,
  };

  handleChange = value => {
    const { onChange } = this.props;
    onChange(value);
    this.setState({ isOpen: false });
  };

  switchList = () => {
    const { isOpen } = this.state;
    this.setState({ isOpen: !isOpen });
  };

  render() {
    const { options, currentValue } = this.props;
    const { isOpen } = this.state;

    return (
      <Container>
        {options.map(({ label, value, icon, iconColor }, key) => (
          <Item
            value={value}
            key={key}
            onClick={() => (isOpen ? this.handleChange(value) : this.switchList())}
            isSelected={value === currentValue}
            isOpen={isOpen}
          >
            <Icon name={icon} color={iconColor} customStyle={{ paddingRight: '5px' }} />
            {label}
          </Item>
        ))}
        <IconChevron
          className={`fas fa-chevron-${isOpen ? 'up' : 'down'} `}
          onClick={this.switchList}
        />
      </Container>
    );
  }
}

Dropbox.propTypes = {
  currentValue: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
};

Dropbox.defaultProps = {
  options: [],
};

export default Dropbox;
