import React, { Component } from 'react';
import { connect } from 'react-redux';
import { editSoftener, getSoftener, getBrineDrumForFilter } from 'actions/index';
import Loader from 'components/Loader';
import PropTypes from 'prop-types';
import SoftenerForm from './SoftenerForm';

class EditSoftener extends Component {
  componentDidMount() {
    const {
      match: { params },
      fetchSoftener,
      fetchBrineDrums,
    } = this.props;
    const { id } = params;

    fetchSoftener(id);
    fetchBrineDrums();
  }

  onSubmit = data => {
    const {
      submitSoftener,
      match: { params },
    } = this.props;
    const { id } = params;

    submitSoftener(id, data);
  };

  render() {
    const { library, loading, token, brineDrums } = this.props;

    if (loading) {
      return <Loader size={200} block />;
    }

    if (!library) {
      return null;
    }

    const initialValues = {
      name: library.name,
      brine_drum_id: library.brine_drum_id,
      overdrive: library.overdrive,
      description: library.description,
      photo: library.photo,
      bypass_key: library.bypass_key,
      flow_key: library.flow_key,
      distance_key: library.distance_key,
      tds_key: library.tds_key,
      inlet_pressure_key: library.inlet_pressure_key,
      outlet_pressure_key: library.outlet_pressure_key,
    };

    return (
      <SoftenerForm
        title="Edit Softener"
        onSubmit={this.onSubmit}
        initialValues={initialValues}
        loading={loading}
        token={token}
        brineDrums={brineDrums}
        editMode
        editable={library.editable}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    loading: state.libraries.loading,
    token: state.auth.token,
    library: state.libraries.item,
    brineDrums: state.libraries.brineDrumForFilter,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    submitSoftener: (id, library) => dispatch(editSoftener(id, library)),
    fetchSoftener: id => dispatch(getSoftener(id)),
    fetchBrineDrums: () => dispatch(getBrineDrumForFilter()),
  };
}

EditSoftener.propTypes = {
  brineDrums: PropTypes.array,
  fetchBrineDrums: PropTypes.func,
  fetchSoftener: PropTypes.func,
  library: PropTypes.object,
  loading: PropTypes.bool,
  match: PropTypes.object,
  submitSoftener: PropTypes.func,
  token: PropTypes.string,
};
export default connect(mapStateToProps, mapDispatchToProps)(EditSoftener);
