import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Colors } from 'modules/theme';
import Icon from './Icon';

const DropdownContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Button = styled.div`
  height: 38px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: transparent;
`;

const MenuItems = styled.div`
  display: ${props => (props.isDropdownOpen ? '' : 'none')};
  top: 30px;
  right: 0;
  background-color: ${Colors.white};
  min-width: 160px;
  position: absolute;
  z-index: 3;
  justify-content: flex-start;
  box-shadow: 0 2px 3px 0 rgba(39, 39, 39, 0.08), 0 1px 1px 0 rgba(39, 39, 39, 0.23);
`;

const Box = styled.div`
  position: relative;
  padding: 20px;
`;

const MenuItem = styled.div`
  padding: 10px;
  text-align: left;
  cursor: pointer;

  &: hover {
    opacity: 0.5;
  }
`;

const Title = styled.span`
  font-style: normal;
  font-weight: 350;
  font-size: 14px;
  line-height: 17px;
  color: ${Colors.black};
`;

const Label = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: ${props => props.color};
`;

const CloseButton = styled.button`
  pointer-events: all;
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  outline: none;
`;

const Dropdown = props => {
  const {
    onClickDropdownButton,
    isDropdownOpen,
    options,
    dropdownId,
    title,
    icon,
    iconColor,
  } = props;

  return (
    <DropdownContainer id={dropdownId}>
      <Button onClick={onClickDropdownButton}>
        {icon && <Icon name={icon} color={iconColor || Colors.black} />}
        {title && <Title bold={true}>{title}</Title>}
      </Button>
      <MenuItems isDropdownOpen={isDropdownOpen}>
        <Box>
          <CloseButton onClick={onClickDropdownButton}>
            <Icon name="times" fontSize={10} color={Colors.black} />
          </CloseButton>

          {options.map(({ id, title, icon, color, action }, key) => (
            <MenuItem onClick={action} color={color} key={key}>
              {icon && (
                <Icon
                  name={icon}
                  color={color || Colors.black}
                  customStyle={{ marginRight: '10px' }}
                />
              )}
              {title && <Label color={color || Colors.black}>{title}</Label>}
            </MenuItem>
          ))}
        </Box>
      </MenuItems>
    </DropdownContainer>
  );
};

Dropdown.propTypes = {
  onClickDropdownButton: PropTypes.func.isRequired,
  isDropdownOpen: PropTypes.bool.isRequired,
  title: PropTypes.string,
  icon: PropTypes.string,
  iconColor: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      action: PropTypes.func.isRequired,
      icon: PropTypes.string,
      color: PropTypes.string,
    }).isRequired,
  ).isRequired,
  dropdownId: PropTypes.string,
};

export default Dropdown;
