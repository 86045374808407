import React, { Component } from 'react';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import config from 'config';
import { Colors } from 'modules/theme';
import Input from 'components/InputText';
import ButtonText from 'components/ButtonText';
import H4 from 'components/H4';
import FormError from 'components/FormError';
import Line from 'components/Line';
import Textarea from 'components/Textarea';
import DropZone from 'components/DropZone';
import PropTypes from 'prop-types';

const url = `${config.BASE_URL}/uploads`;

const InputContainer = styled.div`
  width: 100%;
`;

const ButtonContainer = styled.div`
  margin-top: 25px;
  margin-bottom: 25px;
  float: left;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Column = styled.div`
  flex-direction: column;
  width: 50%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export default class DetailsForm extends Component {
  submit = values => {
    const { onSubmit } = this.props;

    onSubmit(values);
  };

  render() {
    const { initialValues, isInitialValid, token } = this.props;

    return (
      <Formik
        enableReinitialize
        isInitialValid={isInitialValid}
        initialValues={initialValues}
        onSubmit={this.submit}
        validationSchema={Yup.object().shape({
          description: Yup.string().required('Required'),
          device_type: Yup.string().required('Required'),
          file: Yup.string().required('Required'),
          title: Yup.string().required('Required'),
          update_type: Yup.string().required('Required'),
          version: Yup.string().required('Required'),
        })}
      >
        {({ values, errors, touched, handleChange, handleBlur, setFieldValue, isValid }) => (
          <Form>
            <Row>
              <Column style={{ marginRight: '15px', marginTop: '30px' }}>
                <H4 inputLabel color={Colors.gray8}>
                  Device type
                </H4>
                <Input
                  placeholder="Device type"
                  name="device_type"
                  value={values.device_type}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  icon="bookmark"
                  disabled
                />
                <FormError text={errors.device_type && touched.device_type && errors.device_type} />
                <H4 inputLabel color={Colors.gray8} style={{ marginTop: '20px' }}>
                  Update type
                </H4>
                <Input
                  placeholder="Patch"
                  name="update_type"
                  value={values.update_type}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  icon="bookmark"
                />
                <FormError text={errors.update_type && touched.update_type && errors.update_type} />
              </Column>
              <Column style={{ marginLeft: '15px', marginTop: '30px' }}>
                <H4 inputLabel color={Colors.gray8}>
                  Version to update
                </H4>
                <Input
                  placeholder="Version to update"
                  name="version"
                  value={values.version}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  icon="bookmark"
                  disabled
                />
                <FormError text={errors.version && touched.version && errors.version} />
                <H4 inputLabel color={Colors.gray8} style={{ marginTop: '20px' }}>
                  Update title
                </H4>
                <Input
                  placeholder="Update title"
                  name="title"
                  value={values.title}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  icon="bookmark"
                />
                <FormError text={errors.title && touched.title && errors.title} />
              </Column>
            </Row>

            <Row style={{ marginBottom: '20px', marginTop: '20px' }}>
              <Column>
                <H4 inputLabel color={Colors.gray8}>
                  Upload file
                </H4>
                <DropZone
                  bucket={config.BUCKET_NAME}
                  folder={null}
                  title="Choose a file"
                  height="230px"
                  token={token}
                  handleResponse={({ originalName, urlLocation }) => {
                    const regex = /(\d+)/g;
                    const numbers = originalName.match(regex);
                    if (numbers && numbers.length === 3) {
                      const version = `${numbers[0]}.${numbers[1]}.${numbers[2]}`;
                      setFieldValue('version', version);
                    }
                    setFieldValue('file', urlLocation);
                  }}
                  url={url}
                  accept=".bin"
                />
                <FormError text={errors.version && touched.version && errors.version} />
              </Column>
            </Row>

            <Row>
              <Column>
                <H4 inputLabel color={Colors.gray8}>
                  Description
                </H4>
                <InputContainer>
                  <Textarea
                    name="description"
                    value={values.description}
                    onChange={value => setFieldValue('description', value)}
                    onBlur={handleBlur}
                    higlights={[]}
                  />

                  <FormError
                    text={errors.description && touched.description && errors.description}
                  />
                </InputContainer>
              </Column>
            </Row>

            <Row style={{ marginTop: '30px' }}>
              <Line />
            </Row>

            <Row>
              <ButtonContainer>
                <ButtonText text="Save Version" theme="primary" type="submit" disabled={!isValid} />
              </ButtonContainer>
            </Row>
          </Form>
        )}
      </Formik>
    );
  }
}

DetailsForm.propTypes = {
  initialValues: PropTypes.object,
  isInitialValid: PropTypes.bool,
  onSubmit: PropTypes.func,
  token: PropTypes.string,
};
