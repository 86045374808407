import React, { Component } from 'react';
import styled from 'styled-components';
import { Colors } from 'modules/theme';
import ButtonText from 'components/ButtonText';
import H2 from 'components/H2';
import H4 from 'components/H4';
import H5 from 'components/H5';
import AlertConfirm from 'components/AlertConfirm';
import PropTypes from 'prop-types';

const Container = styled.div`
  margin: 30px;
`;

const ButtonContainer = styled.div`
  margin-top: 25px;
  margin-bottom: 25px;
  float: left;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;
export default class CloseAccountView extends Component {
  state = {
    isOpenAlert: false,
  };

  closeAccount = () => {
    const { deleteAccount } = this.props;
    deleteAccount();
  };

  switchAlert = () => {
    const { isOpenAlert } = this.state;

    this.setState({ isOpenAlert: !isOpenAlert });
  };

  render() {
    const { isOpenAlert } = this.state;

    return (
      <Container>
        <H2 color={Colors.black}>Delete account</H2>
        <Row>
          <ButtonContainer>
            <ButtonText text="Close account" theme="danger" onClick={() => this.switchAlert()} />
          </ButtonContainer>
        </Row>
        <AlertConfirm
          title="Close Account"
          onCancel={this.switchAlert}
          onSuccess={this.closeAccount}
          successText="Close"
          isVisible={isOpenAlert}
        >
          <H4 style={{ textAlign: 'center' }}>Are you sure that can close your account?</H4>
          <H5 style={{ textAlign: 'center' }}>
            Once you close the account, there is no going back. Please be certain.
          </H5>
        </AlertConfirm>
      </Container>
    );
  }
}

CloseAccountView.propTypes = {
  deleteAccount: PropTypes.func.isRequired,
};
