import React from 'react';
import styled from 'styled-components';
import { Colors } from 'modules/theme';
import PropTypes from 'prop-types';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const Text = styled.div`
  color: ${Colors.gray};
  font-weight: 350;
  font-size: 18px;
  line-height: 22px;
  flex-direction: column;
`;

const Select = styled.select`
  flex-direction: column;
  width: 100%;
  margin-left: 5px;
  background-color: white;
  border-width: 0px;
  outline: none;
  ::placeholder {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    background-color: rgba(0, 0, 0, 0);
    color: ${Colors.blue};
  }
  :not(:checked) {
    color: ${Colors.blue};
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    background-color: rgba(0, 0, 0, 0);
  }
`;

export const FilterGroup = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-left: 10px;
  padding-right: 10px;
`;

export const FilterContainer = styled.div`
  flex-direction: column;
  margin-right: 15px;
`;

const FilterComponent = props => {
  const { title, name, selectedValue, handleChange, options } = props;
  return (
    <Container>
      <Text>{title}</Text>
      <Select name={name} value={selectedValue} onChange={handleChange}>
        {options.map(({ value, label }, key) => (
          <option value={value} key={key}>
            {label}
          </option>
        ))}
      </Select>
    </Container>
  );
};

FilterComponent.propTypes = {
  handleChange: PropTypes.func,
  options: PropTypes.array,
  selectedValue: PropTypes.string,
};

export default FilterComponent;
