import React, { Component } from 'react';
import { connect } from 'react-redux';
import { login } from 'actions/index';
import PropTypes from 'prop-types';
import LoginView from './LoginView';

class LoginContainer extends Component {
  render() {
    const { onLocalLogin, loading } = this.props;

    return <LoginView login={onLocalLogin} loading={loading} />;
  }
}

function mapStateToProps(state) {
  return {
    loading: state.auth.loading,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    onLocalLogin: (email, password) => dispatch(login(email, password)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);

LoginContainer.propTypes = {
  loading: PropTypes.bool,
  onLocalLogin: PropTypes.func,
};
