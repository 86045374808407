import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  editReverseOsmosis,
  getReverseOsmosis,
  getCatridgesForFilter,
  getTanks,
  getReverseOsmosisMembraneForFilter,
} from 'actions/index';
import PropTypes from 'prop-types';
import ReverseOsmosisFilterForm from './ReverseOsmosisFilterForm';

class EditReverseOsmosisFilter extends Component {
  componentDidMount() {
    const {
      match: { params },
      fetchReverseOsmosisFilter,
      fetchCatridges,
      fetchTanks,
      fetchReverseOsmosisMembrane,
    } = this.props;
    const { id } = params;

    fetchReverseOsmosisFilter(id);
    fetchCatridges();
    fetchTanks();
    fetchReverseOsmosisMembrane();
  }

  onSubmit = data => {
    const {
      submitReverseOsmosisFilter,
      match: { params },
    } = this.props;
    const { id } = params;

    submitReverseOsmosisFilter(id, data);
  };

  render() {
    const { library, catridges, loading, token, tanks, ROMembranes } = this.props;

    if (!library) {
      return null;
    }
    const initialValues = {
      name: library.name,
      ro_model_name: library.ro_model_name,
      sediment_prefilter: library.sediment_prefilter,
      prefilter: library.prefilter,
      postfilter: library.postfilter,
      taste_odor: library.taste_odor,
      contaminant: library.contaminant,
      scale: library.scale,
      photo: library.photo,
      spots: library.SpotsReverseOsmosisFilters || [],
      membranes: library.MembranePerROFilters || [],
      description: library.description,
    };

    return (
      <ReverseOsmosisFilterForm
        title="Edit Reverse Osmosis"
        onSubmit={this.onSubmit}
        initialValues={initialValues}
        loading={loading}
        catridges={catridges}
        token={token}
        tanks={tanks}
        ROMembranes={ROMembranes}
        editMode
        editable={library.editable}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    loading: state.libraries.loading,
    token: state.auth.token,
    catridges: state.libraries.catridgesForFilter,
    library: state.libraries.item,
    tanks: state.libraries.tanks,
    ROMembranes: state.libraries.reverseOsmosisMembraneForFilter,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    submitReverseOsmosisFilter: (id, library) => dispatch(editReverseOsmosis(id, library)),
    fetchReverseOsmosisFilter: id => dispatch(getReverseOsmosis(id)),
    fetchCatridges: () => dispatch(getCatridgesForFilter()),
    fetchTanks: () => dispatch(getTanks()),
    fetchReverseOsmosisMembrane: () => dispatch(getReverseOsmosisMembraneForFilter()),
  };
}

EditReverseOsmosisFilter.propTypes = {
  catridges: PropTypes.array,
  fetchCatridges: PropTypes.func,
  fetchReverseOsmosisFilter: PropTypes.func,
  fetchReverseOsmosisMembrane: PropTypes.func,
  fetchTanks: PropTypes.func,
  library: PropTypes.object,
  loading: PropTypes.bool,
  match: {
    params: {
      id: PropTypes.string,
    },
  },
  ROMembranes: PropTypes.array,
  submitReverseOsmosisFilter: PropTypes.func,
  tanks: PropTypes.array,
  token: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditReverseOsmosisFilter);
