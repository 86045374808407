import React, { Component } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { Colors } from 'modules/theme';
import { Link } from 'react-router-dom';
import Row from 'components/Row';
import H2 from 'components/H2';
import H4 from 'components/H4';
import ButtonText from 'components/ButtonText';
import Modal from 'components/Modal';
import ButtonStatus from 'components/ButtonStatus';
import PaginationButtons from 'components/PaginationButtons';
import Table, { THeader, TableTitle, TRow, TableItem, TableSubItem, TBody } from 'components/Table';
import Filter, { FilterContainer } from 'components/Filter';
import ListEmpty from 'components/ListEmpty';
import { ModalUpgrade } from 'components/ModalUpgrade';
import Loader from 'components/Loader';
import PropTypes from 'prop-types';
import { StatusTypesCreationJob } from '../../../constants/index';

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 20px;
`;

const SearchContainer = styled.div`
  padding: 10px;
  flex-direction: column;
`;

const ButtonContainer = styled.div`
  flex-direction: column;
  float: right;
`;

const ButtonGroup = styled.div`
  padding: 10px;
  flex-direction: column;
  float: right;
`;

const TableContainer = styled.div`
  padding-bottom: 50px;
  margin-bottom: 50px;
`;

const Footer = styled.div`
  position: fixed;
  bottom: 0;
  padding-left: 25px;
  padding-bottom: 25px;
  background-color: ${Colors.white};
  width: 100%;
`;

const Container = styled.div`
  margin-top: 10px;
`;

const updateTypeMatch = {
  device_family: 'Device Family',
  location: 'Location',
  brand: 'Brand',
  single: 'Single',
};

export default class OtaUpgradeStatusView extends Component {
  state = {
    dropdownOpenId: null,
    isOpenModal: false,
    stepActive: 1,
    upgradeOptions: {},
    search: '',
    type: '',
    filterStatus: '',
    isOpenAlert: false,
  };

  filterOnChange = event => {
    const { name, value } = event.target;

    this.setState({ [name]: value }, () => {
      const { fetchUpgradeStatus } = this.props;

      fetchUpgradeStatus({ filterStatus: value });
    });
  };

  switchModal = () => {
    const { isOpenModal } = this.state;
    this.setState({ isOpenModal: !isOpenModal, stepActive: 1, upgradeOptions: {} });
  };

  saveStep1 = values => {
    const { check_brand, check_device_family, check_location } = values;
    const { fetchPreviousInstallationInfo } = this.props;
    if (check_location) {
      const { location_id } = values;
      fetchPreviousInstallationInfo({ location_id });
    }
    if (check_device_family) {
      const { device_family } = values;
      fetchPreviousInstallationInfo({ device_family });
    }
    if (check_brand) {
      const { brand_id } = values;
      fetchPreviousInstallationInfo({ brand_id });
    }

    this.setState({ upgradeOptions: values, stepActive: 2 });
  };

  onSubmit = values => {
    const { submitUpgradeStatus } = this.props;
    this.switchModal();
    submitUpgradeStatus(values);
  };

  backStep = () => {
    this.setState({ stepActive: 1 });
  };

  deleteOta = versionSelected => {
    this.setState({ isOpenAlert: true, versionSelected });
  };

  renderAlertConfirm = () => {
    const { isOpenAlert, versionSelected } = this.state;
    const { deleteUpgradeStatus } = this.props;

    return (
      <Modal
        title={
          <H2 marginLeft={20} color={Colors.darkBlue}>
            Delete Job
          </H2>
        }
        onClose={() => this.setState({ isOpenAlert: false })}
        isVisible={isOpenAlert}
        size="small"
      >
        <Row>
          <H4 color={Colors.lightBlue}>Are you sure you want to delete this job?</H4>
          <ButtonText
            text="Delete"
            theme="danger"
            type="button"
            onClick={() => {
              deleteUpgradeStatus(versionSelected);
              this.setState({ isOpenAlert: false });
            }}
          />
        </Row>
      </Modal>
    );
  };

  renderItem = (item, key) => (
    <TRow key={key}>
      <TableItem width="25%">
        <H4>{updateTypeMatch[item.type]}</H4>
      </TableItem>
      <TableItem width="25%">
        {item.status === 'CREATE_COMPLETE' ? (
          <Link to={`/ota/upgrade-status/locations/${item.id}`}> {item.active_devices}</Link>
        ) : (
          item.active_devices
        )}
      </TableItem>
      <TableItem width="25%">
        {moment(item.installation_date).format('MM/DD/YYYY hh:mm a')}
      </TableItem>
      <TableSubItem color={Colors.gray} width="25%">
        <ButtonStatus
          theme="round"
          text={item.status}
          onClick={() => console.log('/accounts/create')}
          iconAction={() => this.deleteOta(item.id)}
          renderTimes={item.status === 'QUEUED'}
        />
      </TableSubItem>
    </TRow>
  );

  render() {
    const {
      upgradesList,
      upgradesPages,
      upgradesCurrentPage,
      fetchUpgradeStatus,
      upgradesLoading,
      locationsForFilter,
      brandsForFilter,
      previousInstallationInfo,
      loadingGetPreviousInstallationInfo,
    } = this.props;
    const { filterStatus, isOpenModal, stepActive, upgradeOptions } = this.state;

    return (
      <Container>
        <Header style={{ paddingBottom: '0px' }}>
          <SearchContainer />
          <ButtonContainer>
            <Row>
              <ButtonGroup style={{ paddingBottom: '0px' }}>
                <ButtonText
                  theme="primary"
                  text="New Upgrade"
                  icon="plus"
                  onClick={() => this.switchModal()}
                />
              </ButtonGroup>
            </Row>
          </ButtonContainer>
        </Header>
        <Row justifyContent="start">
          <FilterContainer>
            <H4 color={Colors.blue}>Upgrade Status</H4>
          </FilterContainer>

          <FilterContainer>
            <Filter
              title="Status:"
              selectedValue={filterStatus}
              name="filterStatus"
              handleChange={this.filterOnChange}
              options={[{ label: 'All', value: '' }, ...StatusTypesCreationJob]}
            />
          </FilterContainer>
        </Row>
        <TableContainer>
          <Table>
            <THeader>
              <TableTitle width="25%">Type</TableTitle>
              <TableTitle width="25%">Quantity of devices</TableTitle>
              <TableTitle width="25%">Starting date</TableTitle>
              <TableTitle width="25%">Status</TableTitle>
            </THeader>
            <TBody>
              {upgradesLoading ? (
                <Loader size={100} block />
              ) : (
                upgradesList.map((item, key) => this.renderItem(item, key))
              )}
            </TBody>
          </Table>
          {(!upgradesList || !upgradesList.length) && !upgradesLoading && (
            <ListEmpty message="There are currently no OTA" />
          )}
        </TableContainer>
        <Footer>
          <PaginationButtons
            currentPage={upgradesCurrentPage}
            changePage={page => fetchUpgradeStatus({ page })}
            totalPages={upgradesPages}
          />
        </Footer>
        <ModalUpgrade
          isOpenModal={isOpenModal}
          switchModal={() => this.switchModal()}
          stepActive={stepActive}
          saveStep1={this.saveStep1}
          backStep={this.backStep}
          upgradeOptions={upgradeOptions}
          saveData={this.onSubmit}
          locationsForFilter={locationsForFilter}
          brandsForFilter={brandsForFilter}
          previousInstallationInfo={previousInstallationInfo}
          loadingGetPreviousInstallationInfo={loadingGetPreviousInstallationInfo}
        />
        {this.renderAlertConfirm()}
      </Container>
    );
  }
}

OtaUpgradeStatusView.propTypes = {
  brandsForFilter: PropTypes.array,
  deleteUpgradeStatus: PropTypes.func,
  fetchPreviousInstallationInfo: PropTypes.func,
  fetchUpgradeStatus: PropTypes.func,
  loadingGetPreviousInstallationInfo: PropTypes.bool,
  locationsForFilter: PropTypes.array,
  previousInstallationInfo: PropTypes.object,
  submitUpgradeStatus: PropTypes.func,
  upgradesCurrentPage: PropTypes.number,
  upgradesList: PropTypes.array,
  upgradesLoading: PropTypes.bool,
  upgradesPages: PropTypes.number,
};
