// @flow
/**
 * @module Actions/Alert
 * @desc Alert Actions
 */
import { createActions } from 'redux-actions';

import { ActionTypes } from 'constants/index';

export const { getAlerts, getAlert, getAllAlerts, markAlertAsViewed } = createActions({
  [ActionTypes.GET_ALERTS]: params => params,
  [ActionTypes.GET_ALERT]: id => ({ id }),
  [ActionTypes.GET_ALL_ALERTS]: params => params,
  [ActionTypes.MARK_ALERT_AS_VIEWED]: params => params,
});
