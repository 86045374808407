import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { push } from 'modules/history';
import { getAlerts } from 'actions/index'; // Import the getAlerts action
import Logo from 'assets/media/images/logo.png';
import LogoSmall from 'assets/media/images/og-image.png';
import './style.css';

const Menu = styled.div`
  width: 250px;
  @media (max-width: 700px) {
    width: 60px;
  }
`;

const Image = styled.img`
  display: none;
  @media (min-width: 700px) {
    display: block;
  }
`;

const ImageSmall = styled.img`
  display: none;
  @media (max-width: 700px) {
    display: block;
  }
`;

class Sidebar extends Component {
  componentDidMount() {
    const { fetchAlerts } = this.props;

    fetchAlerts();
  }

  render() {
    const { path, roleCode, countAlerts } = this.props;

    return (
      <Menu>
        <nav className="main-menu">
          <ul className="on-top-button">
            <li className="menu-item">
              <Image src={Logo} width="172" />
              <ImageSmall src={LogoSmall} width="40" />
            </li>
            {['admin', 'general'].includes(roleCode) && (
              <li className="menu-item">
                <a
                  onClick={() => push('/libraries')}
                  className={path === '/libraries' ? 'activeMenu' : undefined}
                >
                  <i className="far fas fa-qrcode" />
                  <span className="nav-text">Equipment</span>
                </a>
              </li>
            )}
            <li className="menu-item">
              <a
                onClick={() => push('/locations')}
                className={path === '/locations' || path === '/' ? 'activeMenu' : undefined}
              >
                <i className="fa fa-map-marker-alt" />
                <span className="nav-text">Locations</span>
              </a>
            </li>
            {['admin', 'general'].includes(roleCode) && (
              <li className="menu-item">
                <a
                  onClick={() => push('/brands')}
                  className={path === '/brands' ? 'activeMenu' : undefined}
                >
                  <i className="fa fa-copyright" />
                  <span className="nav-text">Brands</span>
                </a>
              </li>
            )}
            {['admin', 'general', 'brand_admin'].includes(roleCode) && (
              <li className="menu-item">
                <a
                  onClick={() => push('/accounts')}
                  className={path === '/accounts' ? 'activeMenu' : undefined}
                >
                  <i className="fa fa-bookmark" />
                  <span className="nav-text">Accounts</span>
                </a>
              </li>
            )}
            {['admin', 'general'].includes(roleCode) && (
              <li className="menu-item">
                <a
                  onClick={() => push('/users')}
                  className={path === '/users' ? 'activeMenu' : undefined}
                >
                  <i className="far fas fa-users" />
                  <span className="nav-text">Users</span>
                </a>
              </li>
            )}
            {['admin'].includes(roleCode) && (
              <li className="menu-item">
                <a
                  onClick={() => push('/ota')}
                  className={path === '/ota' ? 'activeMenu' : undefined}
                >
                  <i className="fa fa-bookmark" />
                  <span className="nav-text">OTA</span>
                </a>
              </li>
            )}
          </ul>

          <ul className="footer-button">
            <li className="menu-item">
              <a
                onClick={() => push('/alerts')}
                className={path === '/alerts' ? 'activeMenu' : undefined}
              >
                <i className="fas fa-bell menu-icon" style={{ paddingTop: 10 }} />
                <span className="nav-text">Alerts {countAlerts > 0 && `(${countAlerts})`}</span>
              </a>
            </li>
            {['admin', 'general'].includes(roleCode) && (
              <li className="menu-item">
                <a
                  onClick={() => push('/supports')}
                  className={path === '/supports' ? 'activeMenu' : undefined}
                >
                  <i className="fas fa-envelope menu-icon" style={{ paddingTop: 10 }} />
                  <span className="nav-text">Support</span>
                </a>
              </li>
            )}
            <li className="menu-item">
              <a
                onClick={() => push('/settings')}
                className={path === '/settings' ? 'activeMenu' : undefined}
              >
                <i className="fas fa-cog menu-icon" style={{ paddingTop: 10 }} />
                <span className="nav-text">Settings</span>
              </a>
            </li>
          </ul>
        </nav>
      </Menu>
    );
  }
}

Sidebar.propTypes = {
  children: PropTypes.string,
  icon: PropTypes.string,
  path: PropTypes.string,
  roleCode: PropTypes.string,
  fetchAlerts: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    countAlerts: state.alerts.count,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchAlerts: params => dispatch(getAlerts(params)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
