import React, { Component } from 'react';
import styled from 'styled-components';
import { Colors } from 'modules/theme';
import { push } from 'modules/history';
import InputSearch from 'components/InputSearch';
import H4 from 'components/H4';
import ButtonText from 'components/ButtonText';
import PaginationButtons from 'components/PaginationButtons';
import Table, { THeader, TableTitle, TRow, TableItem, TBody } from 'components/Table';
import Dropdown from 'components/Dropdown';
import AlertConfirm from 'components/AlertConfirm';
import ListEmpty from 'components/ListEmpty';
import Loader from 'components/Loader';
import PropTypes from 'prop-types';

const Container = styled.div``;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 20px;
`;

const SearchContainer = styled.div`
  padding: 10px;
  flex-direction: column;
`;

const ButtonContainer = styled.div`
  flex-direction: column;
  float: right;
`;

const ButtonGroup = styled.div`
  padding: 10px;
  flex-direction: column;
  float: right;
`;

const TableContainer = styled.div`
  padding-bottom: 50px;
  margin-bottom: 50px;
`;

const Footer = styled.div`
  position: fixed;
  bottom: 0;
  padding-left: 25px;
  padding-bottom: 25px;
  background-color: ${Colors.white};
  width: 100%;
`;

export default class BrandListView extends Component {
  state = {
    dropdownOpenId: null,
    brandSelected: null,
    isOpenAlert: false,
  };

  switchDropdown = key => {
    const { dropdownOpenId } = this.state;
    const id = dropdownOpenId !== key ? key : null;

    this.setState({ dropdownOpenId: id });
  };

  switchAlert = (brandSelected = null) => {
    const { isOpenAlert } = this.state;

    this.setState({ isOpenAlert: !isOpenAlert, brandSelected });
  };

  editBrand = ({ id }) => {
    push(`/brands/${id}/edit`);
  };

  remove = () => {
    const { removeBrand } = this.props;
    const {
      brandSelected: { id },
    } = this.state;

    removeBrand(id);
    this.switchAlert();
  };

  renderItem = (item, key) => (
    <TRow key={key}>
      <TableItem width="15%">{item.name}</TableItem>
      <TableItem width="15%">{item.full_address}</TableItem>
      <TableItem width="20%">
        {item.contact_phone_number || item.brandAdmin?.phone_number || '-'}
      </TableItem>
      <TableItem width="20%">
        {item.contact_first_name && item.contact_last_name
          ? `${item.contact_first_name} ${item.contact_last_name}`
          : item.brandAdmin
          ? `${item.brandAdmin.first_name} ${item.brandAdmin.last_name}`
          : '-'}
      </TableItem>
      <TableItem width="20%">{item.amount_accounts}</TableItem>
      <TableItem width="5%">{item.amount_locations}</TableItem>
      <TableItem width="5%" isComponent>
        <Dropdown
          icon="ellipsis-v"
          options={[
            {
              title: 'Edit',
              icon: 'pencil-alt',
              color: Colors.green,
              action: () => this.editBrand(item),
            },
            {
              title: 'Remove',
              icon: 'trash',
              color: Colors.red,
              action: () => this.switchAlert(item),
            },
          ]}
          isDropdownOpen={this.state.dropdownOpenId === key}
          onClickDropdownButton={() => this.switchDropdown(key)}
        />
      </TableItem>
    </TRow>
  );

  searchOnChange = search => {
    this.setState({ search }, () => {
      const { fetchBrands } = this.props;
      const { search } = this.state;

      fetchBrands({ search });
    });
  };

  renderAlertConfirm = () => {
    const { isOpenAlert, brandSelected } = this.state;

    return (
      <AlertConfirm
        title="Remove brand"
        onCancel={() => this.switchAlert()}
        onSuccess={this.remove}
        successText="Remove"
        isVisible={isOpenAlert}
      >
        <H4 style={{ textAlign: 'center' }}>
          {`Are you sure that want remove the "${brandSelected ? brandSelected.name : ''}" brand`}
        </H4>
      </AlertConfirm>
    );
  };

  render() {
    const { list, pages, currentPage, loading, fetchBrands } = this.props;
    const { search } = this.state;

    return (
      <Container>
        <Header>
          <SearchContainer>
            <InputSearch onChange={this.searchOnChange} value={search} name="search" />
          </SearchContainer>
          <ButtonContainer>
            <ButtonGroup>
              <ButtonText
                theme="primary"
                text="New"
                icon="plus"
                onClick={() => push('/brands/create')}
              />
            </ButtonGroup>
          </ButtonContainer>
        </Header>
        <TableContainer>
          <Table>
            <THeader>
              <TableTitle width="15%">Name</TableTitle>
              <TableTitle width="15%">HQ Address</TableTitle>
              <TableTitle width="20%">HQ phone #</TableTitle>
              <TableTitle width="20%">Point of Contact</TableTitle>
              <TableTitle width="20%">Accounts</TableTitle>
              <TableTitle width="10%">Locations</TableTitle>
            </THeader>
            <TBody>
              {loading ? (
                <Loader size={100} block />
              ) : (
                list.map((item, key) => this.renderItem(item, key))
              )}
            </TBody>
          </Table>
          {(!list || !list.length) && !loading && (
            <ListEmpty message="There are currently no brands" />
          )}
        </TableContainer>
        <Footer>
          <PaginationButtons
            currentPage={currentPage}
            changePage={page => fetchBrands({ page })}
            totalPages={pages}
          />
        </Footer>
        {this.renderAlertConfirm()}
      </Container>
    );
  }
}

BrandListView.propTypes = {
  currentPage: PropTypes.number,
  fetchBrands: PropTypes.func,
  list: PropTypes.array,
  loading: PropTypes.bool,
  pages: PropTypes.number,
  removeBrand: PropTypes.func,
};
