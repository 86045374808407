import { REHYDRATE } from 'redux-persist/lib/constants';
import { handleActions } from 'modules/helpers';

import { ActionTypes } from 'constants/index';

export const appState = {
  roles: [],
  states: [],
  cities: [],
};

export default {
  utils: handleActions(
    {
      [ActionTypes.GET_ROLES_SUCCESS]: (draft, { payload: { roles } }) => {
        draft.roles = roles;
      },
      [ActionTypes.GET_ROLES_FAILURE]: draft => {
        draft.roles = [];
      },
      [ActionTypes.GET_STATES_SUCCESS]: (draft, { payload: { states } }) => {
        draft.states = states;
      },
      [ActionTypes.GET_STATES_FAILURE]: draft => {
        draft.states = [];
      },
      [ActionTypes.GET_CITIES_BY_STATE_SUCCESS]: (draft, { payload: { cities } }) => {
        draft.cities = cities;
      },

      [ActionTypes.GET_CITIES_BY_STATE_FAILURE]: draft => {
        draft.cities = [];
      },
    },
    appState,
  ),
};
