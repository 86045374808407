import React, { Component } from 'react';
import styled from 'styled-components';
import { Colors } from 'modules/theme';
import Select from 'components/Select';
import H4 from 'components/H4';
import FormError from 'components/FormError';
import Input from 'components/InputText';
import PropTypes from 'prop-types';

const Container = styled.div``;

const InputContainer = styled.div`
  margin: 20px 0px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const Column = styled.div`
  flex-direction: column;
`;

export default class ReverseOsmosisForm extends Component {
  submit = values => {
    const { onSubmit } = this.props;

    onSubmit(values);
  };

  render() {
    const {
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      /*  setFieldValue,
      token,
      url, */
      reverseOsmosisFilters,
      flowSensorsForSelect,
      pressureSensorsForSelect,
      distanceSensorsForSelect,
      tdsProbeSensorsForSelect,
      tempSensorsForSelect,
    } = this.props;

    return (
      <Container>
        <Column style={{ width: '33.33%', paddingRight: '30px' }}>
          <H4 inputLabel color={Colors.gray8}>
            Select Model
          </H4>
          <InputContainer>
            <Select
              placeholder="Select Model"
              name="ro_filter_id"
              value={values.ro_filter_id}
              options={reverseOsmosisFilters}
              onChange={handleChange}
            />
            <FormError text={errors.ro_filter_id && errors.ro_filter_id} />
          </InputContainer>
        </Column>
        <Row>
          <Column style={{ marginRight: '30px', width: '33.33%' }}>
            <InputContainer>
              <H4 inputLabel color={Colors.gray8}>
                Low tank pressure
              </H4>
              <Input
                placeholder="Low tank pressure"
                name="low_tank_pressure"
                type="number"
                value={values.low_tank_pressure}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <FormError
                text={
                  errors.low_tank_pressure && touched.low_tank_pressure && errors.low_tank_pressure
                }
              />
            </InputContainer>
          </Column>
        </Row>

        <Row>
          <Column style={{ width: '33.33%', paddingRight: '30px' }}>
            <H4 inputLabel color={Colors.gray8}>
              Flow sensor 1
            </H4>
            <InputContainer>
              <Select
                placeholder="Flow sensor"
                type="number"
                name="flow_port_1_id"
                value={values.flow_port_1_id}
                onChange={handleChange}
                options={flowSensorsForSelect}
              />
              <FormError
                text={errors.flow_port_1_id && touched.flow_port_1_id && errors.flow_port_1_id}
              />
            </InputContainer>
          </Column>
          <Column style={{ width: '33.33%', paddingRight: '30px' }}>
            <H4 inputLabel color={Colors.gray8}>
              Flow sensor 2
            </H4>
            <InputContainer>
              <Select
                placeholder="Flow sensor 2"
                type="number"
                name="flow_port_2_id"
                value={values.flow_port_2_id}
                onChange={handleChange}
                options={flowSensorsForSelect}
              />
              <FormError
                text={errors.flow_port_2_id && touched.flow_port_2_id && errors.flow_port_2_id}
              />
            </InputContainer>
          </Column>
          <Column style={{ width: '33.33%', paddingRight: '30px' }}>
            <H4 inputLabel color={Colors.gray8}>
              Flow sensor 3
            </H4>
            <InputContainer>
              <Select
                placeholder="Flow sensor 3"
                type="number"
                name="flow_port_3_id"
                value={values.flow_port_3_id}
                onChange={handleChange}
                options={flowSensorsForSelect}
              />
              <FormError
                text={errors.flow_port_3_id && touched.flow_port_3_id && errors.flow_port_3_id}
              />
            </InputContainer>
          </Column>
        </Row>
        <Row>
          <Column style={{ width: '33.33%', paddingRight: '30px' }}>
            <H4 inputLabel color={Colors.gray8}>
              Analog sensor 1
            </H4>
            <InputContainer>
              <Select
                placeholder="Analog sensor 1"
                type="number"
                name="analog_port_1_id"
                value={values.analog_port_1_id}
                onChange={handleChange}
                options={pressureSensorsForSelect}
              />
              <FormError
                text={
                  errors.analog_port_1_id && touched.analog_port_1_id && errors.analog_port_1_id
                }
              />
            </InputContainer>
          </Column>
          <Column style={{ width: '33.33%', paddingRight: '30px' }}>
            <H4 inputLabel color={Colors.gray8}>
              Analog sensor 2
            </H4>
            <InputContainer>
              <Select
                placeholder="Analog sensor 2"
                type="number"
                name="analog_port_2_id"
                value={values.analog_port_2_id}
                onChange={handleChange}
                options={pressureSensorsForSelect}
              />
              <FormError
                text={
                  errors.analog_port_2_id && touched.analog_port_2_id && errors.analog_port_2_id
                }
              />
            </InputContainer>
          </Column>
          <Column style={{ width: '33.33%', paddingRight: '30px' }}>
            <H4 inputLabel color={Colors.gray8}>
              Analog sensor 3
            </H4>
            <InputContainer>
              <Select
                placeholder="Analog sensor 3"
                type="number"
                name="analog_port_3_id"
                value={values.analog_port_3_id}
                onChange={handleChange}
                options={pressureSensorsForSelect}
              />
              <FormError
                text={
                  errors.analog_port_3_id && touched.analog_port_3_id && errors.analog_port_3_id
                }
              />
            </InputContainer>
          </Column>
        </Row>
        <Row>
          <Column style={{ width: '33.33%', paddingRight: '30px' }}>
            <H4 inputLabel color={Colors.gray8}>
              Analog sensor 4
            </H4>
            <InputContainer>
              <Select
                placeholder="Analog sensor 4"
                type="number"
                name="analog_port_4_id"
                value={values.analog_port_4_id}
                onChange={handleChange}
                options={pressureSensorsForSelect}
              />
              <FormError
                text={
                  errors.analog_port_4_id && touched.analog_port_4_id && errors.analog_port_4_id
                }
              />
            </InputContainer>
          </Column>

          <Column style={{ width: '33.33%', paddingRight: '30px' }}>
            <H4 inputLabel color={Colors.gray8}>
              Salt Level Laser
            </H4>
            <InputContainer>
              <Select
                placeholder="Salt Level Laser"
                type="number"
                name="salt_level_l_id"
                value={values.salt_level_l_id}
                onChange={handleChange}
                options={distanceSensorsForSelect}
              />
              <FormError
                text={errors.salt_level_l_id && touched.salt_level_l_id && errors.salt_level_l_id}
              />
            </InputContainer>
          </Column>

          <Column style={{ width: '33.33%', paddingRight: '30px' }}>
            <H4 inputLabel color={Colors.gray8}>
              TDS port
            </H4>
            <InputContainer>
              <Select
                placeholder="TDS port"
                type="number"
                name="tds_probe_s_id"
                value={values.tds_probe_s_id}
                onChange={handleChange}
                options={tdsProbeSensorsForSelect}
              />
              <FormError
                text={errors.tds_probe_s_id && touched.tds_probe_s_id && errors.tds_probe_s_id}
              />
            </InputContainer>
          </Column>
          <Column style={{ width: '33.33%', paddingRight: '30px' }}>
            <H4 inputLabel color={Colors.gray8}>
              Temperature sensor
            </H4>
            <InputContainer>
              <Select
                placeholder="Temperature sensor"
                type="number"
                name="temp_sensor_id"
                value={values.temp_sensor_id}
                onChange={handleChange}
                options={tempSensorsForSelect}
              />
              <FormError
                text={errors.temp_sensor_id && touched.temp_sensor_id && errors.temp_sensor_id}
              />
            </InputContainer>
          </Column>
        </Row>
      </Container>
    );
  }
}

ReverseOsmosisForm.propTypes = {
  distanceSensorsForSelect: PropTypes.array,
  errors: PropTypes.object,
  flowSensorsForSelect: PropTypes.array,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  onSubmit: PropTypes.func,
  pressureSensorsForSelect: PropTypes.array,
  reverseOsmosisFilters: PropTypes.array,
  tdsProbeSensorsForSelect: PropTypes.array,
  tempSensorsForSelect: PropTypes.array,
  touched: PropTypes.object,
  values: PropTypes.object,
};
