import React, { Component } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { Colors } from 'modules/theme';
import { push } from 'modules/history';
import { Link } from 'react-router-dom';
import InputSearch from 'components/InputSearch';
import Icon from 'components/Icon';
import Row from 'components/Row';
import H2 from 'components/H2';
import H4 from 'components/H4';
import Line from 'components/Line';
import Loader from 'components/Loader';
import ButtonText from 'components/ButtonText';
import PaginationButtons from 'components/PaginationButtons';
import Table, { THeader, TableTitle, TRow, TableItem, TableSubItem, TBody } from 'components/Table';
import ListEmpty from 'components/ListEmpty';
import Modal from 'components/Modal';
import PropTypes from 'prop-types';

const Container = styled.div``;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 20px;
`;

const SearchContainer = styled.div`
  padding: 10px;
  flex-direction: column;
`;

const ButtonContainer = styled.div`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
`;

const ButtonGroup = styled.div`
  align-items: center;
  justify-content: space-between;
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
`;

const TableContainer = styled.div`
  padding-bottom: 50px;
  margin-bottom: 50px;
`;

export default class OtaManageVersionsView extends Component {
  state = {
    isOpenAlert: false,
    otaSelected: null,
    search: '',
  };

  searchOnChange = search => {
    const { fetchOtaVersionsWfAndSoftener, fetchOtaVersionsRo } = this.props;
    this.setState({ search }, () => {
      fetchOtaVersionsWfAndSoftener({ search });
      fetchOtaVersionsRo({ search });
    });
  };

  switchAlert = (otaSelected = null) => {
    const { isOpenAlert } = this.state;

    this.setState({ isOpenAlert: !isOpenAlert, otaSelected });
  };

  remove = () => {
    const { removeOtaVersion } = this.props;

    const {
      otaSelected: {
        device: { id },
      },
    } = this.state;

    removeOtaVersion({ id });
    this.switchAlert();
  };

  renderAlertConfirm = () => {
    const { isOpenAlert, otaSelected } = this.state;

    return (
      <Modal
        title={
          <H2 marginLeft={20} color={Colors.darkBlue}>
            Version Details
          </H2>
        }
        onClose={() => this.switchAlert()}
        isVisible={isOpenAlert}
        size="small"
      >
        <H4 style={{ textAlign: 'start', marginBottom: '20px' }}>
          {otaSelected && otaSelected.device_type}
        </H4>
        <Row justifyContent="start">
          <H4 color={Colors.gray} style={{ textAlign: 'start', marginRight: '80px' }}>
            {otaSelected && otaSelected.device.version}
          </H4>
          <H4 color={Colors.gray} style={{ textAlign: 'start' }}>
            {otaSelected && moment(otaSelected.device.created_at).format('MM/DD/YYYY')}
          </H4>
        </Row>
        <H4 color={Colors.gray} style={{ marginTop: '20px' }}>
          {otaSelected && otaSelected.device.description}
        </H4>

        <Row style={{ marginTop: '20px' }}>
          {otaSelected && otaSelected.device.OtaUpdateDevices.length === 0 && (
            <ButtonContainer>
              <H4 color={Colors.lightBlue} style={{ marginTop: '20px' }}>
                Are you sure you want to delete the version of this firmware?
              </H4>

              <ButtonText text="Delete" theme="danger" type="button" onClick={this.remove} />
            </ButtonContainer>
          )}
        </Row>
      </Modal>
    );
  };

  renderItem = (items, device_type) => {
    const {
      roVersionsCurrentPage,
      wfAndSoftenerVersionsCurrentPage,
      wfAndSoftenerVersionsPages,
      roVersionsPages,
      fetchOtaVersionsWfAndSoftener,
      fetchOtaVersionsRo,
    } = this.props;
    return (
      <TRow>
        <TableSubItem width="20%">
          <H4>{device_type}</H4>
        </TableSubItem>
        <TableItem width="80%">
          {items && items.length ? (
            items.map((device, key) => (
              <Row key={key} style={{ borderBottom: `0.5px solid ${Colors.gray2}` }}>
                <TableSubItem color={Colors.gray} width="20%">
                  {device.version}
                </TableSubItem>
                <TableSubItem color={Colors.gray} width="20%">
                  {moment(device.created_at).format('MM/DD/YYYY')}
                </TableSubItem>
                <TableSubItem width="20%">
                  <Link to={`/ota/active-devices/${device.id}`}>{device.activeDevices}</Link>
                </TableSubItem>

                <TableSubItem color={Colors.gray} width="20%">
                  {device.description}
                </TableSubItem>
                <TableSubItem color={Colors.gray} width="20%">
                  <Icon
                    name="eye"
                    style={{ cursor: 'pointer' }}
                    onClick={() => this.switchAlert({ device_type, device })}
                    color={Colors.lightBlue}
                  />
                </TableSubItem>
              </Row>
            ))
          ) : (
            <ListEmpty message="There are currently no versions" />
          )}

          <ButtonGroup>
            {device_type === 'Reverse Osmosis' && (
              <PaginationButtons
                currentPage={roVersionsCurrentPage}
                changePage={page => fetchOtaVersionsRo({ page })}
                totalPages={roVersionsPages}
              />
            )}

            {device_type === 'Filter - Softener' && (
              <PaginationButtons
                currentPage={wfAndSoftenerVersionsCurrentPage}
                changePage={page => fetchOtaVersionsWfAndSoftener({ page })}
                totalPages={wfAndSoftenerVersionsPages}
              />
            )}

            <ButtonText
              theme="primary"
              text="+ New Version"
              onClick={() => push(`/ota/manage-versions/new/${device_type}`)}
            />
          </ButtonGroup>
        </TableItem>
      </TRow>
    );
  };

  render() {
    const {
      /* list, currentPage, pages, */

      listRoVersions,
      listWfAndSoftenerVersions,
      loadingRoVersions,
      loadingWfAndSoftenerVersions,
    } = this.props;
    const { search } = this.state;

    return (
      <Container>
        <Header style={{ paddingBottom: '0px' }}>
          <SearchContainer>
            <InputSearch onChange={this.searchOnChange} value={search} name="search" />
          </SearchContainer>
        </Header>

        <TableContainer>
          <Table>
            <THeader>
              <TableTitle width="20%">Device type</TableTitle>
              <TableTitle width="16%">Version</TableTitle>
              <TableTitle width="16%">Date</TableTitle>
              <TableTitle width="16%">Active devices</TableTitle>
              <TableTitle width="16%">Comment</TableTitle>
              <TableTitle width="16%" />
            </THeader>
            <TBody>
              {loadingWfAndSoftenerVersions ? (
                <Loader size={100} block />
              ) : (
                this.renderItem(listWfAndSoftenerVersions, 'Filter - Softener')
              )}
              <Line />

              {loadingRoVersions ? (
                <Loader size={100} block />
              ) : (
                this.renderItem(listRoVersions, 'Reverse Osmosis')
              )}
            </TBody>
          </Table>
        </TableContainer>

        {this.renderAlertConfirm()}
      </Container>
    );
  }
}

OtaManageVersionsView.propTypes = {
  fetchOtaVersionsRo: PropTypes.func,
  fetchOtaVersionsWfAndSoftener: PropTypes.func,
  listRoVersions: PropTypes.array,
  listWfAndSoftenerVersions: PropTypes.array,
  loadingRoVersions: PropTypes.bool,
  loadingWfAndSoftenerVersions: PropTypes.bool,
  removeOtaVersion: PropTypes.func,
  roVersionsCurrentPage: PropTypes.number,
  roVersionsPages: PropTypes.number,
  wfAndSoftenerVersionsCurrentPage: PropTypes.number,
  wfAndSoftenerVersionsPages: PropTypes.number,
};
