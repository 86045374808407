import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  getBrandsForFilter,
  getAccountsForFilter,
  getStates,
  getOtas,
  createUpgradeStatus,
  getLocationsForFilter,
  getPreviousInstallationInfo,
} from 'actions/index';
import PropTypes from 'prop-types';
import OtaListView from './OtaListView';

class OtaListContainer extends Component {
  componentDidMount() {
    const {
      fetchBrandsForFilter,
      fetchStates,
      fetchOtas,
      fetchLocationsForFilter,
      fetchAccountsForFilter,
    } = this.props;

    fetchOtas();
    fetchBrandsForFilter();
    fetchStates();
    fetchLocationsForFilter();
    fetchAccountsForFilter();
  }

  render() {
    const {
      states,
      accounts,
      fetchOtas,
      list,
      pages,
      currentPage,
      submitUpgradeStatus,
      locationsForFilter,
      brandsForFilter,
      loading,
      fetchPreviousInstallationInfo,
      loadingGetPreviousInstallationInfo,
      previousInstallationInfo,
    } = this.props;

    return (
      <OtaListView
        states={states}
        accounts={accounts}
        list={list}
        pages={pages}
        loading={loading}
        currentPage={currentPage}
        fetchOtas={fetchOtas}
        submitUpgradeStatus={submitUpgradeStatus}
        locationsForFilter={locationsForFilter}
        brandsForFilter={brandsForFilter}
        fetchPreviousInstallationInfo={fetchPreviousInstallationInfo}
        previousInstallationInfo={previousInstallationInfo}
        loadingGetPreviousInstallationInfo={loadingGetPreviousInstallationInfo}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    list: state.ota.list,
    pages: state.ota.pages,
    currentPage: state.ota.currentPage,
    loading: state.ota.loading,
    brandsForFilter: state.brands.listForFilter,
    accounts: [{ label: 'All', value: '' }, ...state.accounts.listForFilter],
    states: [{ label: 'All', value: '' }, ...state.utils.states],
    locationsForFilter: state.locations.listForFilter,
    loadingGetPreviousInstallationInfo: state.ota.loadingGetPreviousInstallationInfo,
    previousInstallationInfo: state.ota.previousInstallationInfo,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    fetchOtas: params => dispatch(getOtas(params)),
    fetchBrandsForFilter: () => dispatch(getBrandsForFilter()),
    fetchStates: () => dispatch(getStates()),
    submitUpgradeStatus: data => dispatch(createUpgradeStatus(data)),
    fetchLocationsForFilter: params => dispatch(getLocationsForFilter(params)),
    fetchAccountsForFilter: params => dispatch(getAccountsForFilter(params)),
    fetchPreviousInstallationInfo: params => dispatch(getPreviousInstallationInfo(params)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(OtaListContainer);

OtaListContainer.propTypes = {
  // add the propTypes here and sort them alphabetically
  accounts: PropTypes.array,
  brandsForFilter: PropTypes.array,
  currentPage: PropTypes.number,
  fetchAccountsForFilter: PropTypes.func,
  fetchBrandsForFilter: PropTypes.func,
  fetchLocationsForFilter: PropTypes.func,
  fetchOtas: PropTypes.func,
  fetchPreviousInstallationInfo: PropTypes.func,
  fetchStates: PropTypes.func,
  list: PropTypes.array,
  loading: PropTypes.bool,
  loadingGetPreviousInstallationInfo: PropTypes.bool,
  locationsForFilter: PropTypes.array,
  pages: PropTypes.number,
  previousInstallationInfo: PropTypes.object,
  states: PropTypes.array,
  submitUpgradeStatus: PropTypes.func,
};
