import React, { Component } from 'react';
import ReactTextareaAutocomplete from '@webscopeio/react-textarea-autocomplete';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Colors } from 'modules/theme';

const ItemComponent = styled.div`
  color: ${Colors.black2};
  padding-bottom: 5px;
  cursor: pointer;

  &: hover {
    opacity: 0.5;
  }
`;

const Item = ({ entity }) => <ItemComponent>{entity}</ItemComponent>;

Item.propTypes = {
  entity: PropTypes.string,
};
const Loading = () => <div>Loading</div>;

class TextareaComponent extends Component {
  getHiglight = token => {
    const { higlights } = this.props;

    return higlights.filter(higlight => new RegExp(token, 'i').test(higlight));
  };

  handleChange = event => {
    const { onChange } = this.props;

    onChange(event.target.value);
  };

  render() {
    const { hasError, value, customStyles, disabled } = this.props;

    return (
      <ReactTextareaAutocomplete
        className="my-textarea"
        value={value}
        loadingComponent={Loading}
        onChange={this.handleChange}
        disabled={disabled}
        ref={rta => {
          this.rta = rta;
        }}
        innerRef={textarea => {
          this.textarea = textarea;
        }}
        rows={6}
        style={{
          border: '1px solid #e1edf3',
          boxSizing: 'border-box',
          borderRadius: '5px',
          fontSize: '18px',
          lineHeight: '17px',
          width: '100%',
          color: Colors.gray,
          padding: '7px 15px 7px 15px',
          outlineStyle: 'none',
          borderColor: hasError ? Colors.red : Colors.gray2,
          ...customStyles,
        }}
        listStyle={{
          backgroundColor: Colors.white,
          boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
          padding: '15px',
          listStyleType: 'none',
        }}
        minChar={0}
        trigger={{
          ':': {
            dataProvider: token => this.getHiglight(token),
            component: Item,
            output: item => `<${item}>`,
          },
        }}
      />
    );
  }
}

TextareaComponent.propTypes = {
  customStyles: PropTypes.object,
  disabled: PropTypes.bool,
  entity: PropTypes.string,
  hasError: PropTypes.bool,
  higlights: PropTypes.array,
  onChange: PropTypes.func,
  value: PropTypes.string,
};

export default TextareaComponent;
