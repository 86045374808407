import React from 'react';
import styled from 'styled-components';
import { Container, Heading, Screen, utils } from 'styled-minimal';
import { Colors } from 'modules/theme';
import Logo from 'components/Logo';
import BG from 'components/BG';
import H1 from 'components/H1';
import H2 from 'components/H2';
import H3 from 'components/H3';
import H4 from 'components/H4';
import H5 from 'components/H5';
import P from 'components/P';
import FormError from 'components/FormError';
import ListEmpty from 'components/ListEmpty';
import ButtonText from 'components/ButtonText';
import DropZone from 'components/DropZone';
import InputText from 'components/InputText';
import InputSearch from 'components/InputSearch';
import Icon from 'components/Icon';
import Modal from 'components/Modal';
import AlertConfirm from 'components/AlertConfirm';
import Dropdown from 'components/Dropdown';
import Line from 'components/Line';
import PaginationButtons from 'components/PaginationButtons';
import Switch from 'components/Switch';
import Table, { THeader, TableTitle, TRow, TableItem, TBody } from 'components/Table';
import Datepicker from 'components/Datepicker/index';
import Filter from 'components/Filter';
import Tabs from 'components/Tabs';

const Header = styled.div`
  margin-bottom: ${utils.spacer(3)};
  text-align: center;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  align-items: stretch;
`;

const Column = styled.div`
  flex-direction: column;
  padding: 15px;
  align-self: flex-start;
  width: 50%;
`;

const Item = styled.span`
  margin: 15px;
  padding: 15px;
`;

const DropZoneContainer = styled.div`
  width: 100%;
`;
const selectOptions = [
  { name: 'All', value: 'all' },
  { name: 'Other', value: 'other' },
];

class UIComponents extends React.PureComponent {
  state = {
    isVisible: false,
    isVisibleAlertConfirm: false,
    isChecked: true,
    date: new Date(),
    selectedValue: '',
    activeTab: 'Configuration',
    isOpenDropdown: false,
  };

  selectOnChange = evt => {
    this.setState({ selectedValue: evt.target.value });
  };

  changeTab = activeTab => {
    this.setState({
      activeTab,
    });
  };

  render() {
    const tabs = [
      {
        action: () => this.changeTab('Details'),
        title: 'Details',
      },
      {
        action: () => this.changeTab('Configuration'),
        title: 'Configuration',
      },
      {
        action: () => this.changeTab('Installer'),
        title: 'Installer',
      },
    ];

    const {
      isVisible,
      isVisibleAlertConfirm,
      isChecked,
      selectedValue,
      activeTab,
      isOpenDropdown,
    } = this.state;

    return (
      <Screen key="UIComponents" data-testid="UIComponentsWrapper">
        <Container verticalPadding>
          <Header>
            <Heading>Components</Heading>
          </Header>
          <Row>
            <Column>
              <BG>
                <Logo />
              </BG>
            </Column>
            <Column>
              <BG>
                <H2 color={Colors.lightBlue}>Icons (font-awesome):</H2>
                <Icon name="search" />
                <Icon name="home" />
                <Icon name="dot" />
              </BG>
            </Column>
          </Row>
          <Row>
            <Column>
              <BG>
                <H2 color={Colors.lightBlue}>Texts:</H2>
                <H1>H1</H1>
                <H2>H2</H2>
                <H3>H3</H3>
                <H4>H4</H4>
                <H5>H5</H5>
                <P>P</P>
                <FormError text="Form Error" />
                <ListEmpty />
              </BG>
            </Column>
            <Column>
              <BG>
                <H2 color={Colors.lightBlue}>Buttons:</H2>
                <Item>
                  <ButtonText theme="primary" text="Primary" />
                </Item>
                <Item>
                  <ButtonText theme="text" text="Text" />
                </Item>
                <Item>
                  <ButtonText theme="warning" text="Warning" />
                </Item>
                <Item>
                  <ButtonText theme="success" text="Success" />
                </Item>
                <Item>
                  <ButtonText theme="round" text="Round" />
                </Item>
                <Item>
                  <ButtonText theme="round-alternative" text="Round Alternative" />
                </Item>
                <Item>
                  <ButtonText theme="solid" text="Solid" />
                </Item>
                <Item>
                  <ButtonText theme="default" text="Default" />
                </Item>
                <Item>
                  <ButtonText theme="danger" text="Danger" />
                </Item>
                <Item>
                  <ButtonText theme="dashed" text="Dashed" />
                </Item>
              </BG>
            </Column>
          </Row>
          <Row>
            <Column>
              <BG>
                <DropZoneContainer>
                  <H2 color={Colors.lightBlue}>DropZone:</H2>
                  <DropZone link="http://localhost:3000/brandCSVExample.csv" />
                </DropZoneContainer>
              </BG>
            </Column>
            <Column>
              <BG>
                <H2 color={Colors.lightBlue}>Inputs:</H2>
                <Item>
                  <InputText placeholder="Input Text" />
                </Item>
                <Item>
                  <InputText placeholder="Input with error" hasError />
                </Item>
                <Item>
                  <InputSearch />
                </Item>
              </BG>
            </Column>
          </Row>
          <Row>
            <Column>
              <BG>
                <H2 color={Colors.lightBlue}>Modal:</H2>
                <ButtonText
                  theme="primary"
                  text="Modal"
                  onClick={() => this.setState({ isVisible: !isVisible })}
                />
                <Modal
                  title="Modal"
                  isVisible={isVisible}
                  onClose={() => this.setState({ isVisible: false })}
                >
                  Modal Body
                </Modal>
              </BG>
            </Column>
            <Column>
              <BG>
                <H2 color={Colors.lightBlue}>Alert Confirm:</H2>
                <ButtonText
                  theme="danger"
                  text="Alert Confirm"
                  onClick={() => this.setState({ isVisibleAlertConfirm: !isVisibleAlertConfirm })}
                />
                <AlertConfirm
                  title="Alert Confirm"
                  isVisible={isVisibleAlertConfirm}
                  onCancel={() => this.setState({ isVisibleAlertConfirm: false })}
                  onSuccess={() => this.setState({ isVisibleAlertConfirm: false })}
                >
                  Alert Confirm Body
                </AlertConfirm>
              </BG>
            </Column>
          </Row>
          <Row>
            <Column>
              <BG>
                <H2 color={Colors.lightBlue}>Dropdown:</H2>
                <Dropdown
                  icon="ellipsis-v"
                  options={[
                    { title: 'Edit', icon: 'pencil-alt', color: Colors.green, action: () => {} },
                    { title: 'Remove', icon: 'trash', color: Colors.red, action: () => {} },
                  ]}
                  isDropdownOpen={isOpenDropdown}
                  onClickDropdownButton={() => this.setState({ isOpenDropdown: !isOpenDropdown })}
                />
              </BG>
            </Column>
            <Column>
              <BG>
                <H2 color={Colors.lightBlue}>Switch:</H2>
                <Switch
                  onChange={() =>
                    this.setState({
                      isChecked: !isChecked,
                    })
                  }
                  isChecked={isChecked}
                  titleChecked="Checked"
                  titleUnchecked="Unchecked"
                />
              </BG>
            </Column>
          </Row>
          <Row>
            <BG>
              <H2 color={Colors.lightBlue}>TableResponsive:</H2>
              <Table>
                <THeader>
                  <TableTitle width="20%">A1</TableTitle>
                  <TableTitle width="20%">A2</TableTitle>
                  <TableTitle width="20%">A3</TableTitle>
                  <TableTitle width="40%">A4</TableTitle>
                </THeader>
                <TBody>
                  <TRow>
                    <TableItem color={Colors.lightBlue} width="20%">
                      B1
                    </TableItem>
                    <TableItem color={Colors.lightBlue} width="20%">
                      B2
                    </TableItem>
                    <TableItem color={Colors.lightBlue} width="20%">
                      B3
                    </TableItem>
                    <TableItem width="30%">B4</TableItem>
                    <TableItem width="10%">B5</TableItem>
                  </TRow>
                  <TRow>
                    <TableItem width="20%">B1</TableItem>
                    <TableItem width="20%">B2</TableItem>
                    <TableItem width="20%">B3</TableItem>
                    <TableItem width="30%">B4</TableItem>
                    <TableItem width="10%">B5</TableItem>
                  </TRow>
                  <TRow>
                    <TableItem width="20%">B1</TableItem>
                    <TableItem width="20%">B2</TableItem>
                    <TableItem width="20%">B3</TableItem>
                    <TableItem width="30%">B4</TableItem>
                    <TableItem width="10%">B5</TableItem>
                  </TRow>
                </TBody>
              </Table>
            </BG>
          </Row>
          <Row>
            <Column>
              <BG>
                <H2 color={Colors.lightBlue}>Pagination:</H2>
                <PaginationButtons
                  currentPage={2}
                  changePage={() => console.log('functionChangePage')}
                  totalPages={5}
                />
              </BG>
            </Column>
            <Column>
              <BG>
                <H2 color={Colors.lightBlue}>HorizontalLine:</H2>
                <Line />
              </BG>
            </Column>
          </Row>
          <Row>
            <Column>
              <BG>
                <H2 color={Colors.lightBlue}>IconButton:</H2>
                <Icon name="plus-circle" fontSize={20} color={Colors.lightBlue} />
              </BG>
            </Column>
            <Column>
              <BG>
                <H2 color={Colors.lightBlue}>Filter:</H2>
                <Filter
                  title="Filter"
                  selectedValue={selectedValue}
                  handleChange={this.selectOnChange}
                  options={selectOptions}
                />
              </BG>
            </Column>
          </Row>
          <Row>
            <Column>
              <BG>
                <H2 color={Colors.lightBlue}>Datepicker:</H2>
                <Datepicker
                  onChange={date =>
                    this.setState({
                      date,
                    })
                  }
                />
              </BG>
            </Column>
          </Row>
          <Row>
            <BG>
              <H2 color={Colors.lightBlue}>Tabs:</H2>
              <Tabs tabs={tabs} active={activeTab} />
            </BG>
          </Row>
        </Container>
      </Screen>
    );
  }
}

export default UIComponents;
