// @flow
/**
 * @module Actions/User
 * @desc Ota Actions
 */
import { createActions } from 'redux-actions';

import { ActionTypes } from 'constants/index';

export const {
  getOtas,
  getOta,
  getOtaVersionsWfAndSoftener,
  getOtaVersionsRo,
  createOtaVersion,
  deleteOtaVersion,
  getUpgradeStatus,
  createUpgradeStatus,
  getUpgradeStatusDevices,
  getPreviousInstallationInfo,
  deleteUpgradeStatus,
  getUpdateActiveDevices,
} = createActions({
  [ActionTypes.GET_OTAS]: params => params,
  [ActionTypes.GET_OTA]: id => ({ id }),
  [ActionTypes.GET_OTA_VERSIONS_WF_AND_SOFTENER]: params => params,
  [ActionTypes.GET_OTA_VERSIONS_RO]: params => params,
  [ActionTypes.CREATE_OTA_VERSION]: params => params,
  [ActionTypes.DELETE_OTA_VERSION]: params => params,
  [ActionTypes.GET_UPGRADE_STATUS]: params => params,
  [ActionTypes.CREATE_UPGRADE_STATUS]: params => params,
  [ActionTypes.CREATE_UPGRADE_STATUS_DEVICES]: params => params,
  [ActionTypes.GET_UPGRADE_STATUS_DEVICES]: params => params,
  [ActionTypes.GET_PREVIOUS_INSTALLATION_INFO]: params => params,
  [ActionTypes.DELETE_UPGRADE_STATUS]: params => params,
  [ActionTypes.GET_UPDATE_ACTIVE_DEVICES]: params => params,
});
