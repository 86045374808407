import React, { Component } from 'react';
import styled from 'styled-components';
import { Colors } from 'modules/theme';
import { push } from 'modules/history';
import InputSearch from 'components/InputSearch';
import H4 from 'components/H4';
import ButtonText from 'components/ButtonText';
import PaginationButtons from 'components/PaginationButtons';
import Table, { THeader, TableTitle, TRow, TableItem, TBody } from 'components/Table';
import Filter, { FilterGroup, FilterContainer } from 'components/Filter';
import Dropdown from 'components/Dropdown';
import AlertConfirm from 'components/AlertConfirm';
import ListEmpty from 'components/ListEmpty';
import Loader from 'components/Loader';
import PropTypes from 'prop-types';

const Container = styled.div``;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 20px;
`;

const SearchContainer = styled.div`
  padding: 10px;
  flex-direction: column;
`;

const ButtonContainer = styled.div`
  flex-direction: column;
  float: right;
`;

const ButtonGroup = styled.div`
  padding: 10px;
  flex-direction: column;
  float: right;
`;

const TableContainer = styled.div`
  padding-bottom: 50px;
  margin-bottom: 50px;
`;

const Footer = styled.div`
  position: fixed;
  bottom: 0;
  padding-left: 25px;
  padding-bottom: 25px;
  background-color: ${Colors.white};
  width: 100%;
`;

export default class LocationListView extends Component {
  state = {
    dropdownOpenId: null,
    locationSelected: null,
    isOpenModal: false,
    isOpenAlert: false,
    search: '',
    brand_id: '',
    account_id: '',
    state: '',
  };

  searchOnChange = search => {
    this.setState({ search }, () => {
      const { fetchLocations } = this.props;
      const { search, brand_id, account_id, state } = this.state;

      fetchLocations({ search, brand_id, account_id, state });
    });
  };

  filterOnChange = event => {
    const { name, value } = event.target;
    const { fetchLocations, fetchAccounts } = this.props;

    if (name === 'brand_id') {
      fetchAccounts({ brands: [value] });
    }

    this.setState({ [name]: value }, () => {
      const { search, brand_id, account_id, state } = this.state;

      fetchLocations({ search, brand_id, account_id, state });
    });
  };

  switchDropdown = key => {
    const { dropdownOpenId } = this.state;
    const id = dropdownOpenId !== key ? key : null;

    this.setState({ dropdownOpenId: id });
  };

  switchModal = () => {
    const { isOpenModal } = this.state;

    this.setState({ isOpenModal: !isOpenModal });
  };

  switchAlert = (locationSelected = null) => {
    const { isOpenAlert } = this.state;

    this.setState({ isOpenAlert: !isOpenAlert, locationSelected });
  };

  editLocation = ({ id }) => {
    push(`/locations/${id}/edit`);
  };

  viewLocation = ({ id }) => {
    push(`/locations/${id}`);
  };

  remove = () => {
    const { removeLocation } = this.props;
    const {
      locationSelected: { id },
    } = this.state;
    removeLocation(id);
    this.switchAlert();
  };

  renderItem = (item, key) => {
    const { role_code } = this.props;
    return (
      <TRow key={key}>
        <TableItem
          width="19%"
          style={{ cursor: 'pointer', color: Colors.lightBlue }}
          onClick={() => this.viewLocation(item)}
        >
          {item.identificator}
        </TableItem>
        <TableItem width="19%">{item.brand_name}</TableItem>
        <TableItem width="19%">{item.account_name}</TableItem>
        <TableItem width="19%">{item.full_address}</TableItem>
        <TableItem width="19%">{item.contact_email || item.locationAdmin?.email || '-'}</TableItem>

        <TableItem width="5%" isComponent>
          <Dropdown
            icon="ellipsis-v"
            options={
              ['admin', 'general'].includes(role_code)
                ? [
                    {
                      title: 'View',
                      icon: 'eye',
                      color: Colors.blue,
                      action: () => this.viewLocation(item),
                    },
                    {
                      title: 'Edit',
                      icon: 'pencil-alt',
                      color: Colors.green,
                      action: () => this.editLocation(item),
                    },
                    {
                      title: 'Remove',
                      icon: 'trash',
                      color: Colors.red,
                      action: () => this.switchAlert(item),
                    },
                  ]
                : [
                    {
                      title: 'View',
                      icon: 'eye',
                      color: Colors.blue,
                      action: () => this.viewLocation(item),
                    },
                  ]
            }
            isDropdownOpen={this.state.dropdownOpenId === key}
            onClickDropdownButton={() => this.switchDropdown(key)}
          />
        </TableItem>
      </TRow>
    );
  };

  renderAlertConfirm = () => {
    const { isOpenAlert, locationSelected } = this.state;

    return (
      <AlertConfirm
        title="Remove location"
        onCancel={() => this.switchAlert()}
        onSuccess={this.remove}
        successText="Remove"
        isVisible={isOpenAlert}
      >
        <H4 style={{ textAlign: 'center' }}>
          {`Are you sure that can remove the "${
            locationSelected ? locationSelected.identificator : ''
          }" location`}
        </H4>
      </AlertConfirm>
    );
  };

  render() {
    const {
      list,
      pages,
      currentPage,
      loading,
      states,
      brands,
      accounts,
      fetchLocations,
      role_code,
    } = this.props;
    const { search, brand_id, account_id, state } = this.state;

    return (
      <Container>
        <Header>
          <SearchContainer>
            <InputSearch onChange={this.searchOnChange} value={search} name="search" />
          </SearchContainer>
          {['admin', 'general'].includes(role_code) && (
            <ButtonContainer>
              <ButtonGroup>
                <ButtonText
                  theme="primary"
                  text="New"
                  icon="plus"
                  onClick={() => push('/locations/create')}
                />
              </ButtonGroup>
            </ButtonContainer>
          )}
        </Header>

        <FilterGroup>
          <FilterContainer>
            <Filter
              title="Brand:"
              selectedValue={brand_id}
              name="brand_id"
              handleChange={this.filterOnChange}
              options={brands}
            />
          </FilterContainer>
          <FilterContainer>
            <Filter
              title="Account:"
              selectedValue={account_id}
              name="account_id"
              handleChange={this.filterOnChange}
              options={accounts}
            />
          </FilterContainer>
          <FilterContainer>
            <Filter
              title="State:"
              selectedValue={state}
              name="state"
              handleChange={this.filterOnChange}
              options={states}
            />
          </FilterContainer>
        </FilterGroup>
        <TableContainer>
          <Table>
            <THeader>
              <TableTitle width="19%">Location ID</TableTitle>
              <TableTitle width="19%">Brand</TableTitle>
              <TableTitle width="19%">Account</TableTitle>
              <TableTitle width="19%">Address</TableTitle>
              <TableTitle width="19%">Contact</TableTitle>
              <TableTitle width="5%" />
            </THeader>
            <TBody>
              {loading ? (
                <Loader size={100} block />
              ) : (
                list.map((item, key, role_code) => this.renderItem(item, key, role_code))
              )}
            </TBody>
          </Table>
          {(!list || !list.length) && !loading && (
            <ListEmpty message="There are currently no locations" />
          )}
        </TableContainer>
        <Footer>
          <PaginationButtons
            currentPage={currentPage}
            changePage={page => fetchLocations({ page, search, brand_id, account_id, state })}
            totalPages={pages}
          />
        </Footer>
        {this.renderAlertConfirm()}
      </Container>
    );
  }
}

LocationListView.propTypes = {
  accounts: PropTypes.array,
  brands: PropTypes.array,
  currentPage: PropTypes.number,
  fetchAccounts: PropTypes.func,
  fetchLocations: PropTypes.func,
  list: PropTypes.array,
  loading: PropTypes.bool,
  pages: PropTypes.number,
  removeLocation: PropTypes.func,
  role_code: PropTypes.string,
  states: PropTypes.array,
};
