import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ButtonText from './ButtonText';

const Container = styled.h1`
  width: 200px;
  heigth: 200px;
`;

const Image = styled.img`
  width: 200px;
  heigth: 200px;
`;

const ImageUploaded = ({ image, onClick }) => (
  <Container>
    <Image src={image} />
    {onClick && <ButtonText text="Remove" theme="dashed" onClick={onClick} />}
  </Container>
);

export default ImageUploaded;

ImageUploaded.propTypes = {
  image: PropTypes.string,
  onClick: PropTypes.func,
};
