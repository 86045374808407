import { REHYDRATE } from 'redux-persist/lib/constants';
import { handleActions } from 'modules/helpers';

import { ActionTypes } from 'constants/index';

export const appState = {
  list: [],
  pages: 0,
  currentPage: 0,
  loading: false,
};

export default {
  supports: handleActions(
    {
      [REHYDRATE]: draft => {
        draft.list = [];
      },
      [ActionTypes.GET_SUPPORTS]: draft => {
        draft.loading = true;
      },
      [ActionTypes.GET_SUPPORTS_SUCCESS]: (draft, { payload: { rows, pages, currentPage } }) => {
        draft.list = rows;
        draft.pages = pages;
        draft.currentPage = currentPage;
        draft.loading = false;
      },
      [ActionTypes.GET_SUPPORTS_FAILURE]: draft => {
        draft.loading = false;
      },
      [ActionTypes.CHECK_AS_READ]: draft => {
        draft.loading = true;
      },
      [ActionTypes.CHECK_AS_READ_SUCCESS]: (draft, { payload }) => {
        draft.loading = false;
      },
      [ActionTypes.CHECK_AS_READ_FAILURE]: draft => {
        draft.loading = false;
      },
      [ActionTypes.DELETE_SUPPORT]: draft => {
        draft.loading = true;
      },
      [ActionTypes.DELETE_SUPPORT_SUCCESS]: (draft, { payload: { id } }) => {
        draft.list = draft.list.filter(item => item.id !== id);
        draft.loading = false;
      },
      [ActionTypes.DELETE_SUPPORT_FAILURE]: (draft, { payload: { id } }) => {
        draft.loading = false;
      },
    },
    appState,
  ),
};
