import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  getLocationsForFilter,
  createUpgradeStatus,
  getUpgradeStatusDevices,
  getPreviousInstallationInfo,
} from 'actions/index';
import PropTypes from 'prop-types';
import OtaUpgradeStatusLocationsView from './OtaUpgradeStatusLocationsView';

class OtaUpgradeStatusLocationsContainer extends Component {
  componentDidMount() {
    const {
      fetchUpgradeStatusDevices,
      fetchLocationsForFilter,
      match: {
        params: { otaUpdateId },
      },
    } = this.props;

    fetchUpgradeStatusDevices({ otaUpdateId });
    fetchLocationsForFilter();
  }

  render() {
    const {
      submitUpgradeStatus,
      upgradesListDevices,
      upgradesPagesDevices,
      upgradesCurrentPageDevices,
      fetchUpgradeStatusDevices,
      upgradesLoadingDevices,
      locationsForFilter,
      brandsForFilter,
      fetchPreviousInstallationInfo,
      loadingGetPreviousInstallationInfo,
      previousInstallationInfo,
      match: {
        params: { otaUpdateId },
      },
    } = this.props;

    return (
      <OtaUpgradeStatusLocationsView
        submitUpgradeStatus={submitUpgradeStatus}
        upgradesListDevices={upgradesListDevices}
        upgradesPagesDevices={upgradesPagesDevices}
        upgradesCurrentPageDevices={upgradesCurrentPageDevices}
        fetchUpgradeStatusDevices={fetchUpgradeStatusDevices}
        upgradesLoadingDevices={upgradesLoadingDevices}
        otaUpdateId={otaUpdateId}
        locationsForFilter={locationsForFilter}
        brandsForFilter={brandsForFilter}
        fetchPreviousInstallationInfo={fetchPreviousInstallationInfo}
        previousInstallationInfo={previousInstallationInfo}
        loadingGetPreviousInstallationInfo={loadingGetPreviousInstallationInfo}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    upgradesListDevices: state.ota.upgradesListDevices,
    upgradesPagesDevices: state.ota.upgradesPagesDevices,
    upgradesCurrentPageDevices: state.ota.upgradesCurrentPageDevices,
    upgradesLoadingDevices: state.ota.upgradesLoadingDevices,
    locationsForFilter: state.locations.listForFilter,
    brandsForFilter: state.brands.listForFilter,
    loadingGetPreviousInstallationInfo: state.ota.loadingGetPreviousInstallationInfo,
    previousInstallationInfo: state.ota.previousInstallationInfo,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    submitUpgradeStatus: data => dispatch(createUpgradeStatus(data)),
    fetchUpgradeStatusDevices: params => dispatch(getUpgradeStatusDevices(params)),
    fetchLocationsForFilter: params => dispatch(getLocationsForFilter(params)),
    fetchPreviousInstallationInfo: params => dispatch(getPreviousInstallationInfo(params)),
  };
}

OtaUpgradeStatusLocationsContainer.propTypes = {
  brandsForFilter: PropTypes.array,
  fetchLocationsForFilter: PropTypes.func,
  fetchPreviousInstallationInfo: PropTypes.func,
  fetchUpgradeStatusDevices: PropTypes.func,
  loadingGetPreviousInstallationInfo: PropTypes.bool,
  locationsForFilter: PropTypes.array,
  match: PropTypes.object,
  previousInstallationInfo: PropTypes.object,
  submitUpgradeStatus: PropTypes.func,
  upgradesCurrentPageDevices: PropTypes.number,
  upgradesListDevices: PropTypes.array,
  upgradesLoadingDevices: PropTypes.bool,
  upgradesPagesDevices: PropTypes.number,
};
export default connect(mapStateToProps, mapDispatchToProps)(OtaUpgradeStatusLocationsContainer);
