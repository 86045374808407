import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createWaterFilter, getCatridgesForFilter } from 'actions/index';
import PropTypes from 'prop-types';
import WaterFilterForm from './WaterFilterForm';

class CreateWaterFilter extends Component {
  componentDidMount() {
    const { fetchCatridges } = this.props;
    fetchCatridges();
  }

  render() {
    const { submitWaterFilter, catridges, loading, token } = this.props;

    const initialValues = {
      name: '',
      series_or_parallel: '',
      filter_head_name: '',
      taste_odor: false,
      contaminant: false,
      scale: false,
      photo: '',
      spots: [
        {
          catridge_id: '',
          position: '',
          outlet: '',
          series_or_parallel: '',
        },
      ],
    };

    return (
      <WaterFilterForm
        title="Create water filter"
        onSubmit={submitWaterFilter}
        initialValues={initialValues}
        loading={loading}
        catridges={catridges}
        token={token}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    loading: state.libraries.loading,
    token: state.auth.token,
    catridges: state.libraries.catridgesForFilter,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    submitWaterFilter: library => dispatch(createWaterFilter(library)),
    fetchCatridges: () => dispatch(getCatridgesForFilter()),
  };
}

CreateWaterFilter.propTypes = {
  catridges: PropTypes.arrayOf(PropTypes.object).isRequired,
  fetchCatridges: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  submitWaterFilter: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateWaterFilter);
