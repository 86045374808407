import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getSensor, editSensor } from 'actions/index';
import Loader from 'components/Loader';
import PropTypes from 'prop-types';
import SensorForm from './SensorForm';

class EditSensor extends Component {
  componentDidMount() {
    const {
      match: { params },
      fetchSensor,
    } = this.props;
    const { id, type } = params;

    fetchSensor(id, type);
  }

  onSubmit = data => {
    const {
      submitSensor,
      match: { params },
    } = this.props;
    const { id, type } = params;

    submitSensor(id, type, data);
  };

  setInitialValues = (library, sensor_type) => {
    if (sensor_type === 'flow-meter') {
      return {
        name: library.name,
        sensor_type,
        pulses_per_galon: library.pulses_per_galon,
      };
    }

    if (sensor_type === 'pressure-transducer') {
      return {
        name: library.name,
        sensor_type,
        conversion_formula: library.conversion_formula,
      };
    }

    if (sensor_type === 'tds-probe') {
      return {
        name: library.name,
        sensor_type,
        conversion_formula: library.conversion_formula,
      };
    }

    if (sensor_type === 'temperature') {
      return {
        name: library.name,
        sensor_type,
        conversion_formula: library.conversion_formula,
      };
    }
    if (sensor_type === 'distance') {
      return {
        name: library.name,
        sensor_type,
      };
    }
    return {};
  };

  render() {
    const {
      match: { params },
      library,
      loading,
    } = this.props;
    const { type } = params;
    const device_type = type ? type.replace('-sensor', '') : '';

    if (loading) {
      return <Loader size={200} block />;
    }

    if (!library) {
      return null;
    }

    const initialValues = this.setInitialValues(library, device_type);

    return (
      <SensorForm
        title="Edit Sensor"
        isEdit
        onSubmit={this.onSubmit}
        initialValues={initialValues}
        loading={loading}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    loading: state.libraries.loading,
    library: state.libraries.item,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    submitSensor: (id, type, library) => dispatch(editSensor(id, type, library)),
    fetchSensor: (id, type) => dispatch(getSensor(id, type)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(EditSensor);

EditSensor.propTypes = {
  fetchSensor: PropTypes.func,
  library: PropTypes.object,
  loading: PropTypes.bool,
  match: PropTypes.object,
  submitSensor: PropTypes.func,
};
