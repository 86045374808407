import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import PageWithMenu from './PageWithMenu';

const RoutePrivate = ({ component: Component, isAuthenticated, to, role_code, allow, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated && allow.includes(role_code) ? (
        <PageWithMenu path={props.location.pathname} role_code={role_code}>
          <Component {...props} />
        </PageWithMenu>
      ) : (
        <Redirect
          to={{
            pathname: to,
            state: { redirect: props.location.pathname, isAuthenticated },
          }}
        />
      )
    }
  />
);

RoutePrivate.propTypes = {
  allow: PropTypes.array,
  component: PropTypes.func,
  isAuthenticated: PropTypes.bool,
  location: PropTypes.object,
  role_code: PropTypes.string,
  to: PropTypes.string,
};

RoutePrivate.defaultProps = {
  to: '/login',
};

export default RoutePrivate;
