import React, { Component } from 'react';
import styled from 'styled-components';
import { Formik, Form, FieldArray } from 'formik';
import * as Yup from 'yup';
import { Colors } from 'modules/theme';
import { push } from 'modules/history';
import { DeviceTypes } from 'constants/index';
import config from 'config';
import Textarea from 'components/Textarea';
import DropZone from 'components/DropZone';
import Input from 'components/InputText';
import Select from 'components/Select';
import ButtonText from 'components/ButtonText';
import H2 from 'components/H2';
import H4 from 'components/H4';
import Icon from 'components/Icon';
import FormError from 'components/FormError';
import ImageUploaded from 'components/ImageUploaded';
import PropTypes from 'prop-types';
import WaterFiltersForm from './WaterFiltersForm';
import ReverseOsmosisForm from './ReverseOsmosisForm';
import SoftenerForm from './SoftenerForm';

const url = `${config.BASE_URL}/uploads`;

const Container = styled.div`
  margin: 30px;
`;

const InputContainer = styled.div`
  margin: 20px 0px;
`;

const ButtonContainer = styled.div`
  margin-top: 25px;
  margin-bottom: 25px;
  float: left;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const Column = styled.div`
  flex-direction: column;
`;

const ModelsContainer = styled.div``;

const waterFilterValidation = {
  name: Yup.string().required('Required'),
  device_type: Yup.string().required('Required'),
  flow_port_1_id: Yup.number().required('Required'),
  flow_port_2_id: Yup.number().required('Required'),
  flow_port_3_id: Yup.number().required('Required'),
  analog_port_1_id: Yup.number().required('Required'),
  analog_port_2_id: Yup.number().required('Required'),
  analog_port_3_id: Yup.number().required('Required'),
  analog_port_4_id: Yup.number().required('Required'),
  salt_level_laser_id: Yup.number().required('Required'),
  tds_probe_sensor_id: Yup.number().required('Required'),
  installationSteps: Yup.string().required('Required'),
  water_filter_id: Yup.string().required('Required'),
};

const defaultValidation = {
  name: Yup.string().required('Required'),
  device_type: Yup.string().required('Required'),
};

const roValidation = {
  name: Yup.string().required('Required'),
  device_type: Yup.string().required('Required'),
  ro_filter_id: Yup.string().required('Required'),
  low_tank_pressure: Yup.number().required('Required'),
  flow_port_1_id: Yup.number().required('Required'),
  flow_port_2_id: Yup.number().required('Required'),
  flow_port_3_id: Yup.number().required('Required'),
  analog_port_1_id: Yup.number().required('Required'),
  analog_port_2_id: Yup.number().required('Required'),
  analog_port_3_id: Yup.number().required('Required'),
  analog_port_4_id: Yup.number().required('Required'),
  salt_level_l_id: Yup.number().required('Required'),
  tds_probe_s_id: Yup.number().required('Required'),
  temp_sensor_id: Yup.number().required('Required'),
  // low_inlet_pressure: Yup.number().required('Required'),
};

const softenerValidation = {
  name: Yup.string().required('Required'),
  device_type: Yup.string().required('Required'),
  flow_port_1_id: Yup.number().required('Required'),
  flow_port_2_id: Yup.number().required('Required'),
  flow_port_3_id: Yup.number().required('Required'),
  analog_port_1_id: Yup.number().required('Required'),
  analog_port_2_id: Yup.number().required('Required'),
  analog_port_3_id: Yup.number().required('Required'),
  analog_port_4_id: Yup.number().required('Required'),
  salt_level_laser_id: Yup.number().required('Required'),
  tds_probe_sensor_id: Yup.number().required('Required'),
  low_inlet_pressure: Yup.number().required('Required'),
};

const stepInstallationDefault = {
  title: '',
  description: '',
  image: null,
};

export default class DeviceConfigurationForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      device_type: props.initialValues.device_type || '',
    };
  }

  submit = values => {
    const { onSubmit } = this.props;

    onSubmit(values);
  };

  getRules = () => {
    const { device_type } = this.state;

    if (device_type === 'water-filter') {
      return waterFilterValidation;
    }

    if (device_type === 'reverse-osmosis') {
      return roValidation;
    }

    if (device_type === 'water-softener') {
      return softenerValidation;
    }

    return defaultValidation;
  };

  renderInstallationStep = (installationStep, key, form, push, remove, errors = {}, token, url) => {
    const installationStepSize = form.values.installationSteps.length;

    const changeValue = e => {
      e.stopPropagation();
      form.setFieldValue(e.target.name, e.target.value);
    };

    const secondaryChangeValue = (name, value) => {
      form.setFieldValue(name, value);
    };

    return (
      <Row>
        <Column style={{ width: '50%', paddingRight: '30px' }}>
          <H4 inputLabel color={Colors.gray8}>
            Step Title
          </H4>
          <InputContainer>
            <Input
              placeholder="Name"
              onChange={changeValue}
              icon="bookmark"
              name={`installationSteps.${key}.title`}
              value={installationStep.title}
            />
            <FormError text={errors.title && /* touched.title && */ errors.title} />
          </InputContainer>
          <H4 inputLabel color={Colors.gray8}>
            Step Description
          </H4>
          <InputContainer>
            <Textarea
              value={installationStep.description}
              onChange={value =>
                secondaryChangeValue(`installationSteps.${key}.description`, value)
              }
              higlights={[]}
            />
            <FormError
              text={errors.description /* && touched.description */ && errors.description}
            />
          </InputContainer>
        </Column>
        <Column style={{ width: '50%', paddingRight: '30px' }}>
          <H4 inputLabel color={Colors.gray8} style={{ marginBottom: '20px' }}>
            Step Image
          </H4>

          {installationStep.photo ? (
            <ImageUploaded
              image={installationStep.photo}
              onClick={() => secondaryChangeValue(`installationSteps.${key}.photo`, null)}
            />
          ) : (
            <DropZone
              folder="device-configuration"
              title="Choose a photo"
              height="230px"
              token={token}
              handleResponse={({ url }) =>
                secondaryChangeValue(`installationSteps.${key}.photo`, url)
              }
              url={url}
            />
          )}
        </Column>

        <Column style={{ width: '15%', paddingTop: '30px' }}>
          {installationStepSize - 1 === key ? (
            <Icon
              name="plus-circle"
              color={Colors.lightBlue}
              fontSize={22}
              onClick={() => push(stepInstallationDefault)}
            />
          ) : (
            <Icon
              name="minus-circle"
              color={Colors.red}
              fontSize={22}
              onClick={() => remove(key)}
            />
          )}
        </Column>
      </Row>
    );
  };

  render() {
    const {
      initialValues,
      token,
      title,
      waterFilters,
      reverseOsmosisFilters,
      softeners,
      isEdit,
      flowSensorsForSelect,
      pressureSensorsForSelect,
      distanceSensorsForSelect,
      tdsProbeSensorsForSelect,
      tempSensorsForSelect,
    } = this.props;
    const { device_type } = this.state;

    return (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={Yup.object().shape(this.getRules())}
        onSubmit={this.submit}
      >
        {({ values, errors, touched, handleChange, handleBlur, isValid, setFieldValue }) => (
          <Container>
            <Form>
              <Icon onClick={() => push('/libraries')} name="chevron-left" color={Colors.blue} />
              <H2 color={Colors.black}>{title}</H2>
              <Row>
                <Column style={{ width: '33.33%', paddingRight: '30px' }}>
                  <InputContainer>
                    <H4 inputLabel color={Colors.lightBlue}>
                      Choose Device Type
                    </H4>
                    <Select
                      placeholder="Choose Device Type "
                      name="device_type"
                      options={DeviceTypes}
                      disabled={isEdit}
                      value={values.device_type}
                      onChange={evt => {
                        this.setState({ [evt.target.name]: evt.target.value });
                        handleChange(evt);
                      }}
                    />
                    <FormError
                      text={errors.device_type && touched.device_type && errors.device_type}
                    />
                  </InputContainer>
                </Column>
                <Column style={{ width: '33.33%', paddingRight: '30px' }}>
                  <H4 inputLabel color={Colors.gray8}>
                    Configuration Name
                  </H4>
                  <InputContainer>
                    <Input
                      placeholder="Name"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      icon="bookmark"
                    />
                    <FormError text={errors.name && touched.name && errors.name} />
                  </InputContainer>
                </Column>
              </Row>
              {device_type === 'water-filter' && (
                <WaterFiltersForm
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  waterFilters={waterFilters}
                  flowSensorsForSelect={flowSensorsForSelect}
                  pressureSensorsForSelect={pressureSensorsForSelect}
                  distanceSensorsForSelect={distanceSensorsForSelect}
                  tdsProbeSensorsForSelect={tdsProbeSensorsForSelect}
                />
              )}
              {device_type === 'reverse-osmosis' && (
                <ReverseOsmosisForm
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  reverseOsmosisFilters={reverseOsmosisFilters}
                  flowSensorsForSelect={flowSensorsForSelect}
                  pressureSensorsForSelect={pressureSensorsForSelect}
                  distanceSensorsForSelect={distanceSensorsForSelect}
                  tdsProbeSensorsForSelect={tdsProbeSensorsForSelect}
                  tempSensorsForSelect={tempSensorsForSelect}
                />
              )}
              {device_type === 'water-softener' && (
                <SoftenerForm
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  softeners={softeners}
                  flowSensorsForSelect={flowSensorsForSelect}
                  pressureSensorsForSelect={pressureSensorsForSelect}
                  distanceSensorsForSelect={distanceSensorsForSelect}
                  tdsProbeSensorsForSelect={tdsProbeSensorsForSelect}
                />
              )}
              <H4 inputLabel color={Colors.lightBlue}>
                Installation Steps
              </H4>

              <FieldArray
                name="installationSteps"
                render={({ form, push, remove }) => (
                  <ModelsContainer>
                    {(values.installationSteps || []).map((installationStep, key) =>
                      this.renderInstallationStep(
                        installationStep,
                        key,
                        form,
                        push,
                        remove,
                        errors && errors.installationSteps ? errors.installationSteps[key] : {},
                        token,
                        url,
                      ),
                    )}
                  </ModelsContainer>
                )}
              />

              <Row>
                <ButtonContainer>
                  <ButtonText text="Save" theme="primary" type="submit" disabled={!isValid} />
                </ButtonContainer>
              </Row>
            </Form>
          </Container>
        )}
      </Formik>
    );
  }
}
DeviceConfigurationForm.propTypes = {
  distanceSensorsForSelect: PropTypes.array,
  flowSensorsForSelect: PropTypes.array,
  initialValues: PropTypes.object,
  isEdit: PropTypes.bool,
  onSubmit: PropTypes.func,
  pressureSensorsForSelect: PropTypes.array,
  reverseOsmosisFilters: PropTypes.array,
  softeners: PropTypes.array,
  tdsProbeSensorsForSelect: PropTypes.array,
  tempSensorsForSelect: PropTypes.array,
  title: PropTypes.string,
  token: PropTypes.string,
  waterFilters: PropTypes.array,
};
