import React from 'react';
import styled from 'styled-components';
import { Colors } from 'modules/theme';
import Icon from './Icon';

const Pagination = styled.div`
  display: inline-block;
  visibility: ${props => (props.show ? 'initial' : 'hidden')};
`;

const Page = styled.a`
  color: ${props => (props.active ? 'white' : Colors.gray)};
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  background-color: ${props => (props.active ? Colors.lightBlueAlternative : '#FFFFFF')};
  height: 30px;
  width: 30px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
  cursor: pointer;
`;

export default props => {
  const { currentPage, changePage, totalPages } = props;
  const nextPage = currentPage + 1;
  const backPage = currentPage - 1;

  return (
    <Pagination show={totalPages > 1}>
      {currentPage > 1 && (
        <>
          <Page onClick={() => changePage(backPage)}>
            <Icon color={Colors.gray} name="caret-left" />
          </Page>
          <Page onClick={() => changePage(backPage)}>{backPage}</Page>
        </>
      )}
      <Page active>{currentPage}</Page>
      {nextPage <= totalPages && (
        <>
          <Page onClick={() => changePage(nextPage)}>{nextPage}</Page>
          <Page onClick={() => changePage(nextPage)}>
            <Icon color={Colors.gray} name="caret-right" />
          </Page>
        </>
      )}
    </Pagination>
  );
};
