import React, { Component } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import Modal from 'components/Modal';
import DatePickerComponent from 'components/Datepicker/DatePickerComponent';
import Row from 'components/Row';
import Col from 'components/Col';
import H4 from 'components/H4';
import H5 from 'components/H5';
import H2 from 'components/H2';
import { Colors } from 'modules/theme';
import Icon from 'components/Icon';
import RadioInput from 'components/RadioInput';

import ButtonText from 'components/ButtonText';
import Table, { THeader, TableTitle, TRow, TableItem, TBody } from 'components/Table';
import ListEmpty from 'components/ListEmpty';
import Loader from 'components/Loader';
import Line from 'components/Line';
import PropTypes from 'prop-types';

const TableContainer = styled.div`
  padding-bottom: 25px;
  overflow-x: auto;
`;

class ModalCsvFiles extends Component {
  renderItem = (item, key) => {
    const itemLowercase = item.status.charAt(0).toUpperCase() + item.status.slice(1);
    const setColorStatus = {
      pending: Colors.yellow,
      complete: Colors.green,
      failed: Colors.red,
    };
    return (
      <TRow minWidth={500} key={key}>
        <TableItem width="25%">{moment(item.from).format('MM/DD/YYYY hh:mm a')}</TableItem>
        <TableItem width="25%">{moment(item.to).format('MM/DD/YYYY hh:mm a')}</TableItem>
        <TableItem width="25%">
          <H5 color={setColorStatus[item.status]}> {itemLowercase} </H5>
        </TableItem>
        <TableItem width="25%">
          {item.status === 'complete' && (
            <a href={item.url} target="_blank" rel="noreferrer">
              Download
            </a>
          )}
          {item.status === 'pending' && <Loader size={20} />}
          {item.status === 'failed' && (
            <Icon
              fontSize={30}
              name="exclamation-circle"
              color={Colors.red}
              customStyle={{ marginRight: 10 }}
            />
          )}
        </TableItem>
      </TRow>
    );
  };

  render() {
    const {
      minDate,
      isVisible,
      switchModal,
      dateFrom,
      dateTo,
      handleDateFrom,
      handleDateTo,
      changeCsvType,
      csvType,
      saveChanges,
      csvFiles,
      csvFilesLoading,
    } = this.props;
    return (
      <Modal title="Generate CSV report" onClose={switchModal} isVisible={isVisible} size="large">
        <H2 color={Colors.gray}>Generate new report:</H2>

        <Row style={{ marginBottom: 15 }}>
          <Col lg={37} md={37} sm={37} xs={37} alignItems="flex-end">
            <H4 color={Colors.lightBlue} style={{ marginRight: 10 }}>
              From:
            </H4>
          </Col>
          <Col lg={25} md={25} sm={25} xs={25} alignItems="center">
            <DatePickerComponent minDate={minDate} date={dateFrom} onChange={handleDateFrom} />
          </Col>
          <Col lg={38} md={38} sm={38} xs={38} alignItems="flex-start">
            <Icon name="calendar-alt" color={Colors.lightBlue} customStyle={{ fontSize: '20px' }} />
          </Col>
        </Row>
        <Row style={{ marginBottom: 15 }}>
          <Col lg={37} md={37} sm={37} xs={37} alignItems="flex-end">
            <H4 color={Colors.lightBlue} style={{ marginRight: 10 }}>
              To:
            </H4>
          </Col>
          <Col lg={25} md={25} sm={25} xs={25} alignItems="center">
            <DatePickerComponent minDate={minDate} date={dateTo} onChange={handleDateTo} />
          </Col>
          <Col lg={38} md={38} sm={38} xs={38} alignItems="flex-start">
            <Icon name="calendar-alt" color={Colors.lightBlue} customStyle={{ fontSize: '20px' }} />
          </Col>
        </Row>
        <Row>
          <RadioInput
            options={['Raw Data', 'Calculated Data', 'All data']}
            handleChange={changeCsvType}
            currentValue={csvType}
            name="csvType"
          />
        </Row>

        <Row>
          <ButtonText
            onClick={saveChanges}
            text="Generate"
            theme="primary"
            customStyles={{ marginTop: 25 }}
          />
        </Row>
        <Line style={{ marginTop: '30px', marginBottom: '30px' }} />
        <H2 color={Colors.gray}>Reports history:</H2>
        <TableContainer>
          <Table>
            <THeader minWidth={500}>
              <TableTitle width="25%">From</TableTitle>
              <TableTitle width="25%">To</TableTitle>
              <TableTitle width="25%">Status</TableTitle>
              <TableTitle width="25%">Action</TableTitle>
            </THeader>

            {!csvFilesLoading ? (
              <TBody>
                {csvFiles.map((item, key, role_code) => this.renderItem(item, key, role_code))}
              </TBody>
            ) : (
              <Loader size={50} block />
            )}
          </Table>

          {!csvFilesLoading && !csvFiles.length > 0 && (
            <ListEmpty message="There are currently no csv files" />
          )}
        </TableContainer>
        {csvFiles &&
          csvFiles.length > 0 &&
          csvFiles.filter(csv => csv.status === 'pending').length > 0 && (
            <>
              <H5 color={Colors.gray} textAlign="left">
                *CSV reports can take up to 15 minutes to generate.
              </H5>
            </>
          )}
        <H5 color={Colors.gray} textAlign="left">
          *The files will remain here for 24 hours, then they will be automatically deleted.
        </H5>
      </Modal>
    );
  }
}

export default ModalCsvFiles;

ModalCsvFiles.propTypes = {
  changeCsvType: PropTypes.func,
  csvFiles: PropTypes.array,
  csvFilesLoading: PropTypes.bool,
  csvType: PropTypes.string,
  dateFrom: PropTypes.string,
  dateTo: PropTypes.string,
  handleDateFrom: PropTypes.func,
  handleDateTo: PropTypes.func,
  isVisible: PropTypes.bool,
  minDate: PropTypes.string,
  saveChanges: PropTypes.func,
  switchModal: PropTypes.func,
};
