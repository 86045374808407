import React, { Component } from 'react';
import styled from 'styled-components';
import { Formik, Form, FieldArray } from 'formik';
import * as Yup from 'yup';
import {
  DeviceTypes,
  AlertTypes,
  AlertTrigger,
  RepeatAlertSelectOptions,
  RepeatAlertSelectOptionsForByPass,
  AlertFields,
  AlertFieldsForWFAndRo,
  AlertRecipients,
} from 'constants/index';
import { Colors } from 'modules/theme';
import { push } from 'modules/history';
import Input from 'components/InputText';
import Textarea from 'components/Textarea';
import Select from 'components/Select';
import Dropbox from 'components/Dropbox';
import ButtonText from 'components/ButtonText';
import H2 from 'components/H2';
import H4 from 'components/H4';
import H5 from 'components/H5';
import Switch from 'components/Switch';
import Icon from 'components/Icon';
import FormError from 'components/FormError';
import PropTypes from 'prop-types';

const Container = styled.div`
  margin: 30px;
`;

const InputContainer = styled.div`
  margin: 20px 0px;
`;

const ButtonContainer = styled.div`
  margin-top: 25px;
  margin-bottom: 25px;
  float: left;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const Column = styled.div`
  flex-direction: column;
  width: 50%;
`;

const RecipientContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const RecipientInputContainer = styled.div`
  flex-direction: column;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 90%;
`;
const AddRecipientContainer = styled.div`
  flex-direction: column;
  width: 10%;
  padding-top: 22px;
  padding-left: 15px;
`;

const RecipientsContainer = styled.div``;

export default class AlertTypeForm extends Component {
  submit = values => {
    const { onSubmit } = this.props;

    onSubmit(values);
  };

  renderRecipient = (recipient, key, form, push, remove) => {
    const recipientSize = form.values.recipient_type.length;
    const changeValue = e => {
      e.stopPropagation();
      form.setFieldValue(e.target.name, e.target.value);
    };

    return (
      <RecipientContainer key={key}>
        <RecipientInputContainer>
          <Select
            placeholder="Choose recipients"
            name={`recipient_type.${key}`}
            value={recipient}
            options={AlertRecipients}
            onChange={changeValue}
          />
          {/* <FormError text={errors.role_id && errors.role_id} /> */}
        </RecipientInputContainer>
        <AddRecipientContainer>
          {recipientSize - 1 === key ? (
            <Icon
              name="plus-circle"
              color={Colors.lightBlue}
              fontSize={22}
              onClick={() => push('')}
            />
          ) : (
            <Icon
              name="minus-circle"
              color={Colors.red}
              fontSize={22}
              onClick={() => remove(key)}
            />
          )}
        </AddRecipientContainer>
      </RecipientContainer>
    );
  };

  render() {
    const { initialValues, title } = this.props;

    return (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          allow_email_notifications: Yup.string().required('Required'),
          allow_sms_notifications: Yup.string().required('Required'),
          device_type: Yup.string().required('Required'),
          enabled: Yup.string().required('Required'),
          message: Yup.string().required('Required'),
          recipient_type: Yup.array().of(Yup.string()),
          repeat: Yup.string().required('Required'),
          title: Yup.string().required('Required'),
          trigger: Yup.string().required('Required'),
          type: Yup.string().required('Required'),
        })}
        onSubmit={this.submit}
      >
        {({ values, errors, touched, handleChange, handleBlur, isValid, setFieldValue }) => (
          <Container>
            <Form>
              <Icon onClick={() => push('/alerts')} name="chevron-left" color={Colors.blue} />
              <H2 color={Colors.black}>{title}</H2>
              <Row>
                <Column style={{ marginRigth: '30px' }}>
                  <InputContainer>
                    <H4 inputLabel color={Colors.gray8}>
                      Device Type
                    </H4>
                    <Select
                      placeholder="Device Type"
                      name="device_type"
                      options={DeviceTypes}
                      value={values.device_type}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <FormError
                      text={errors.device_type && touched.device_type && errors.device_type}
                    />
                  </InputContainer>
                  <InputContainer>
                    <H4 inputLabel color={Colors.gray8}>
                      Alert trigger
                    </H4>
                    <Select
                      placeholder="Alert trigger"
                      name="trigger"
                      options={AlertTrigger[values.device_type]}
                      value={values.trigger}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <FormError text={errors.trigger && touched.trigger && errors.trigger} />
                  </InputContainer>
                  <InputContainer>
                    <H4 inputLabel color={Colors.gray8}>
                      Repeat
                    </H4>
                    <Select
                      placeholder="Repeat"
                      name="repeat"
                      value={values.repeat}
                      options={
                        ['filter-bypass-detected', 'soft-filter-bypass-detected'].includes(
                          values.trigger,
                        )
                          ? RepeatAlertSelectOptionsForByPass
                          : RepeatAlertSelectOptions
                      }
                      onChange={handleChange}
                    />
                  </InputContainer>
                  <InputContainer>
                    <H4 inputLabel color={Colors.gray8}>
                      Recipients
                    </H4>
                    <FieldArray
                      name="recipient_type"
                      render={({ form, push, remove }) => (
                        <RecipientsContainer>
                          {values.recipient_type.map((recipient, key) =>
                            this.renderRecipient(
                              recipient,
                              key,
                              form,
                              push,
                              remove,
                              errors && errors.recipient_type ? errors.recipient_type[key] : {},
                            ),
                          )}
                        </RecipientsContainer>
                      )}
                    />
                  </InputContainer>
                </Column>
                <Column style={{ marginLeft: '30px' }}>
                  <InputContainer>
                    <H4 inputLabel color={Colors.gray8}>
                      Alert Type
                    </H4>
                    <Dropbox
                      name="type"
                      options={AlertTypes}
                      currentValue={values.type}
                      onChange={value => setFieldValue('type', value)}
                      onBlur={handleBlur}
                    />
                    <FormError text={errors.type && touched.type && errors.type} />
                  </InputContainer>
                  <InputContainer>
                    <H4 inputLabel color={Colors.gray8}>
                      Title
                    </H4>
                    <Input
                      placeholder="Title"
                      name="title"
                      value={values.title}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      icon="bookmark"
                    />
                    <FormError text={errors.title && touched.title && errors.title} />
                  </InputContainer>
                </Column>
              </Row>
              <Row>
                <Column style={{ marginRigth: '30px' }}>
                  <InputContainer>
                    <Switch
                      name="enabled"
                      isChecked={values.enabled}
                      titleChecked="Enabled"
                      titleUnchecked="Enabled"
                      onChange={evt => setFieldValue(evt.target.name, evt.target.checked)}
                    />
                  </InputContainer>
                  <H4 inputLabel color={Colors.gray8}>
                    Notifications
                  </H4>
                  <InputContainer>
                    <Switch
                      name="allow_email_notifications"
                      isChecked={values.allow_email_notifications}
                      titleChecked="Email notifications"
                      titleUnchecked="Email notifications"
                      onChange={evt => setFieldValue(evt.target.name, evt.target.checked)}
                    />
                  </InputContainer>
                  <InputContainer>
                    <Switch
                      name="allow_sms_notifications"
                      isChecked={values.allow_sms_notifications}
                      titleChecked="SMS notifications"
                      titleUnchecked="SMS notifications"
                      onChange={evt => setFieldValue(evt.target.name, evt.target.checked)}
                    />
                  </InputContainer>
                </Column>
              </Row>
              <Row>
                <Column style={{ marginRigth: '30px' }}>
                  <H4 inputLabel color={Colors.gray8}>
                    Message
                  </H4>
                  <Textarea
                    value={values.message}
                    onChange={value => setFieldValue('message', value)}
                    higlights={
                      values.device_type === 'softener' ? AlertFields : AlertFieldsForWFAndRo
                    }
                    onBlur={handleBlur}
                  />
                  <FormError text={errors.message && touched.message && errors.message} />
                  <H5 inputLabel color={Colors.lightBlue}>
                    To add a field, type something starting with ":"
                  </H5>
                </Column>
              </Row>
              <Row>
                <ButtonContainer>
                  <ButtonText text="Save" theme="primary" type="submit" disabled={!isValid} />
                </ButtonContainer>
              </Row>
            </Form>
          </Container>
        )}
      </Formik>
    );
  }
}

AlertTypeForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  title: PropTypes.string,
};
