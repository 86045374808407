import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { updateProfile, changePassword, closeAccount, logOut } from 'actions/index';
import Modal from 'components/Modal';
import H4 from 'components/H4';
import PropTypes from 'prop-types';
import LogoutView from './LogoutView';
import EditProfileView from './EditProfileView';
import ChangePasswordView from './ChangePasswordView';
import AlertSetupView from './AlertSetupView';
import CloseAccountView from './CloseAccountView';

const infoEmailChanged = {
  title: 'The app will log out because you changed your email address.',
  message: 'Please, log in again.',
};

const infoAccountClosed = {
  title: 'The app will log out because you closed your account.',
  message: '',
};

const infoPasswordChanged = {
  title: 'The app will log out because you changed your password.',
  message: 'Please, log in again.',
};

const Container = styled.div`
  margin 30px;
`;

class SettingsContainer extends Component {
  state = { modalInfo: { title: '', message: '' } };

  renderAlert = () => {
    const { requireLogout } = this.props;
    const {
      modalInfo: { title, message },
    } = this.state;
    return (
      <Modal title="Alert" isVisible={requireLogout} onClose={() => {}} size="small">
        <H4 textAlign="center">{title}</H4>
        <H4 textAlign="center">{message}</H4>
      </Modal>
    );
  };

  onDeleteAccount = () => {
    const { deleteAccount } = this.props;
    this.setState({ modalInfo: infoAccountClosed }, () => {
      deleteAccount();
    });
  };

  onEditProfile = profile => {
    const { editProfile } = this.props;
    this.setState({ modalInfo: infoEmailChanged }, () => {
      editProfile(profile);
    });
  };

  onEditPassword = (currentPassword, password) => {
    const { editPassword } = this.props;
    this.setState({ modalInfo: infoPasswordChanged }, () => {
      editPassword(currentPassword, password);
    });
  };

  render() {
    const { profile, logout, editProfile } = this.props;

    return (
      <Container>
        <LogoutView logout={logout} />
        <EditProfileView editProfile={this.onEditProfile} profile={profile} />
        <ChangePasswordView editPassword={this.onEditPassword} />
        <AlertSetupView editProfile={editProfile} profile={profile} />
        <CloseAccountView deleteAccount={this.onDeleteAccount} />
        {this.renderAlert()}
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    profile: state.auth.profile,
    requireLogout: state.auth.requireLogout,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    editProfile: profile => dispatch(updateProfile(profile)),
    editPassword: (currentPassword, password) =>
      dispatch(changePassword(currentPassword, password)),
    deleteAccount: () => dispatch(closeAccount()),
    logout: () => dispatch(logOut()),
  };
}

SettingsContainer.propTypes = {
  deleteAccount: PropTypes.func,
  editPassword: PropTypes.func,
  editProfile: PropTypes.func,
  logout: PropTypes.func,
  profile: PropTypes.object,
  requireLogout: PropTypes.bool,
};
export default connect(mapStateToProps, mapDispatchToProps)(SettingsContainer);
