import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getAccounts, getBrandsForFilter, getStates, deleteAccount } from 'actions/index';
import PropTypes from 'prop-types';
import AccountListView from './AccountListView';

class AccountListContainer extends Component {
  componentDidMount() {
    const { fetchAccounts, fetchBrandsForFilter, fetchStates } = this.props;

    fetchAccounts();
    fetchBrandsForFilter();
    fetchStates();
  }

  render() {
    const {
      list,
      pages,
      currentPage,
      loading,
      fetchAccounts,
      removeAccount,
      brands,
      states,
      role_code,
    } = this.props;

    return (
      <AccountListView
        list={list}
        pages={pages}
        currentPage={currentPage}
        loading={loading}
        brands={brands}
        states={states}
        fetchAccounts={fetchAccounts}
        removeAccount={removeAccount}
        role_code={role_code}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    role_code: state.auth.profile.role_code,
    list: state.accounts.list,
    pages: state.accounts.pages,
    currentPage: state.accounts.currentPage,
    loading: state.accounts.loading,
    brands: [{ label: 'All', value: '' }, ...state.brands.listForFilter],
    states: [{ label: 'All', value: '' }, ...state.utils.states],
  };
}
function mapDispatchToProps(dispatch) {
  return {
    fetchAccounts: params => dispatch(getAccounts(params)),
    fetchBrandsForFilter: () => dispatch(getBrandsForFilter()),
    fetchStates: () => dispatch(getStates()),
    removeAccount: id => dispatch(deleteAccount(id)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(AccountListContainer);

AccountListContainer.propTypes = {
  brands: PropTypes.array,
  currentPage: PropTypes.number,
  fetchAccounts: PropTypes.func,
  fetchBrandsForFilter: PropTypes.func,
  fetchStates: PropTypes.func,
  list: PropTypes.array,
  loading: PropTypes.bool,
  pages: PropTypes.number,
  removeAccount: PropTypes.func,
  states: PropTypes.array,
};
