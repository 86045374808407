// @flow
/**
 * @module Actions/Library
 * @desc Library Actions
 */
import { createActions } from 'redux-actions';

import { ActionTypes } from 'constants/index';

export const {
  getLibraries,
  getCatridge,
  getWaterFilter,
  getReverseOsmosisMembrane,
  getReverseOsmosis,
  getSoftener,
  getBrineDrum,
  getTanks,
  getConfiguration,
  getCatridgesForFilter,
  getWaterFiltersForFilter,
  getReverseOsmosisMembraneForFilter,
  getReverseOsmosisForFilter,
  getSoftenerForFilter,
  getBrineDrumForFilter,
  getWaterFilterConfigurationsForFilter,
  getReverseOsmosisConfigurationsForFilter,
  getSoftenerConfigurationsForFilter,
  createWaterCatridge,
  createWaterFilter,
  createConfiguration,
  createSoftener,
  createReverseOsmosis,
  createBrineDrum,
  createReverseOsmosisMembrane,
  editWaterCatridge,
  editWaterFilter,
  editConfiguration,
  editSoftener,
  editReverseOsmosis,
  editBrineDrum,
  editReverseOsmosisMembrane,
  deleteLibrary,
  createSensor,
  getSensor,
  editSensor,
  getFlowSensorsForSelect,
  getPressureSensorsForSelect,
  getDistanceSensorsForSelect,
  getTdsProbeSensorsForSelect,
  getTemperatureSensorsForSelect,
} = createActions({
  [ActionTypes.GET_LIBRARIES]: params => params,
  [ActionTypes.GET_CATRIDGE]: id => ({ id }),
  [ActionTypes.GET_WATER_FILTER]: id => ({ id }),
  [ActionTypes.GET_REVERSE_OSMOSIS_MEMBRANE]: id => ({ id }),
  [ActionTypes.GET_REVERSE_OSMOSIS]: id => ({ id }),
  [ActionTypes.GET_SOFTENER]: id => ({ id }),
  [ActionTypes.GET_BRINE_DRUM]: id => ({ id }),
  [ActionTypes.GET_TANKS]: () => ({}),
  [ActionTypes.GET_CONFIGURATION]: (id, type) => ({ id, type }),
  [ActionTypes.GET_CATRIDGES_FOR_FILTER]: () => ({}),
  [ActionTypes.GET_WATER_FILTERS_FOR_FILTER]: () => ({}),
  [ActionTypes.GET_REVERSE_OSMOSIS_MEMBRANE_FOR_FILTER]: () => ({}),
  [ActionTypes.GET_REVERSE_OSMOSIS_FOR_FILTER]: () => ({}),
  [ActionTypes.GET_SOFTENER_FOR_FILTER]: () => ({}),
  [ActionTypes.GET_BRINE_DRUM_FOR_FILTER]: () => ({}),
  [ActionTypes.GET_WATER_FILTER_CONFIGURATIONS_FOR_FILTER]: () => ({}),
  [ActionTypes.GET_REVERSE_OSMOSIS_CONFIGURATIONS_FOR_FILTER]: () => ({}),
  [ActionTypes.GET_SOFTENER_CONFIGURATIONS_FOR_FILTER]: () => ({}),
  [ActionTypes.CREATE_WATER_CATRIDGE]: data => data,
  [ActionTypes.CREATE_WATER_FILTER]: data => data,
  [ActionTypes.CREATE_CONFIGURATION]: data => data,
  [ActionTypes.CREATE_SOFTENER]: data => data,
  [ActionTypes.CREATE_REVERSE_OSMOSIS]: data => data,
  [ActionTypes.CREATE_BRINE_DRUM]: data => data,
  [ActionTypes.CREATE_REVERSE_OSMOSIS_MEMBRANE]: data => data,
  [ActionTypes.EDIT_WATER_CATRIDGE]: (id, waterCatridge) => ({ id, waterCatridge }),
  [ActionTypes.EDIT_WATER_FILTER]: (id, waterFilter) => ({ id, waterFilter }),
  [ActionTypes.EDIT_CONFIGURATION]: (id, type, deviceConfiguration) => ({
    id,
    type,
    deviceConfiguration,
  }),
  [ActionTypes.EDIT_SOFTENER]: (id, softener) => ({ id, softener }),
  [ActionTypes.EDIT_REVERSE_OSMOSIS]: (id, reverseOsmosis) => ({ id, reverseOsmosis }),
  [ActionTypes.EDIT_BRINE_DRUM]: (id, brineDrum) => ({ id, brineDrum }),
  [ActionTypes.EDIT_REVERSE_OSMOSIS_MEMBRANE]: (id, reverseOsmosisMembrane) => ({
    id,
    reverseOsmosisMembrane,
  }),
  [ActionTypes.DELETE_LIBRARY]: (id, reference, type) => ({ id, reference, type }),
  [ActionTypes.CREATE_SENSOR]: data => data,
  [ActionTypes.GET_SENSOR]: (id, type) => ({ id, type }),
  [ActionTypes.EDIT_SENSOR]: (id, type, sensor) => ({ id, type, sensor }),
  [ActionTypes.GET_FLOW_SENSORS_FOR_SELECT]: () => ({}),
  [ActionTypes.GET_PRESSURE_SENSORS_FOR_SELECT]: () => ({}),
  [ActionTypes.GET_DISTANCE_SENSORS_FOR_SELECT]: () => ({}),
  [ActionTypes.GET_TDS_PROBE_SENSORS_FOR_SELECT]: () => ({}),
  [ActionTypes.GET_TEMPERATURE_SENSORS_FOR_SELECT]: () => ({}),
});
