import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { getBrand, editBrand, getStates, getCitiesByState } from 'actions/index';
import { Colors } from 'modules/theme';
import { push } from 'modules/history';
import Icon from 'components/Icon';
import H2 from 'components/H2';
import Tabs from 'components/Tabs';
import Loader from 'components/Loader';
import PropTypes from 'prop-types';
import DetailsForm from './DetailsForm';
import AccountsForm from './AccountsForm';
import LocationsForm from './LocationsForm';
import DataForm from './DataForm';

const Container = styled.div`
  margin: 30px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const Column = styled.div`
  flex-direction: column;
  align-self: center;
`;

class EditBrand extends Component {
  state = {
    activeTab: 'Details',
    brand: {
      accounts: [],
    },
  };

  componentDidMount() {
    const {
      match: { params },
      fetchStates,
      fetchBrand,
    } = this.props;
    const { id } = params;

    fetchBrand(id);
    fetchStates();
  }

  switchTab = activeTab => {
    this.setState({ activeTab });
  };

  getCities = stateId => {
    const { fetchCitiesByState } = this.props;

    fetchCitiesByState(stateId);
  };

  saveData = (data, nextTab) => {
    const { brand, activeTab } = this.state;
    const brandUpdated = {
      ...brand,
      ...data,
    };
    if (activeTab === 'Data') {
      const {
        match: { params },
        submitBrand,
      } = this.props;
      const { id } = params;

      submitBrand(id, brandUpdated);
    } else {
      this.setState({
        activeTab: nextTab,
        brand: brandUpdated,
      });
    }
  };

  back = backTab => {
    this.setState({
      activeTab: backTab,
    });
  };

  render() {
    const { brand, states, cities, token, loading } = this.props;
    const {
      activeTab,
      brand: { accounts },
    } = this.state;

    if (loading) {
      return <Loader size={200} block />;
    }

    if (!brand) {
      return null;
    }

    const detailsInitialValues = {
      name: brand.name,
      address_line: brand.address_line,
      address_line_1: brand.address_line_1,
      city_id: brand.city_id,
      state_id: brand.state_id,
      zip_code: brand.zip_code,
      contact_account: brand.contact_account,
      contact_first_name: brand.contact_first_name,
      contact_last_name: brand.contact_last_name,
      contact_phone_number: brand.contact_phone_number,
      contact_email: brand.contact_email,
      createNewUser: false,
      brand_admin_id: null,
      admins: brand.BrandsPerUsers.reduce(
        (acc, { User, id }) => [
          ...acc,
          {
            label: `${User.first_name} ${User.last_name} (${User.email})`,
            value: id,
            deleted: false,
            new: false,
          },
        ],
        [],
      ),
    };
    const dataInitialValues = {
      out_of_compliance: brand.out_of_compliance,
      near_compliance_1_end: brand.near_compliance_1_end,
      in_compliance_end: brand.in_compliance_end,
      near_compliance_2_end: brand.near_compliance_2_end,
      out_of_compliance_2_end: brand.out_of_compliance_2_end,
      good_end: brand.good_end,
      fair_end: brand.fair_end,
      poor_end: brand.poor_end,
    };

    const tabs = [
      {
        title: 'Details',
      },
      {
        title: 'Accounts',
      },
      {
        title: 'Locations',
      },
      {
        title: 'Data',
      },
    ];

    return (
      <Container>
        <Icon onClick={() => push('/brands')} name="chevron-left" color={Colors.blue} />
        <Row>
          <Column>
            <H2 color={Colors.black} style={{ paddingRight: 30 }}>
              Edit brand
            </H2>
          </Column>
          <Column>
            <Tabs tabs={tabs} active={activeTab} />
          </Column>
        </Row>
        {activeTab === 'Details' && (
          <DetailsForm
            initialValues={detailsInitialValues}
            isInitialValid
            cities={cities}
            states={states}
            getCities={this.getCities}
            onSubmit={this.saveData}
            isEditing
          />
        )}
        {activeTab === 'Accounts' && (
          <AccountsForm
            token={token}
            back={() => this.back('Details')}
            onSubmit={this.saveData}
            dropzoneTitle="Choose CSV File to add new accounts"
          />
        )}
        {activeTab === 'Locations' && (
          <LocationsForm
            token={token}
            accounts={accounts}
            back={() => this.back('Accounts')}
            onSubmit={this.saveData}
            dropzoneTitle="Choose CSV File to add new locations"
          />
        )}
        {activeTab === 'Data' && (
          <DataForm
            initialValues={dataInitialValues}
            isInitialValid
            back={() => this.back('Locations')}
            onSubmit={this.saveData}
          />
        )}
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    brand: state.brands.item,
    loading: state.brands.loading,
    states: state.utils.states,
    cities: state.utils.cities,
    token: state.auth.token,
    adminsBrands: state.brands.adminsBrands,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    submitBrand: (id, data) => dispatch(editBrand(id, data)),
    fetchBrand: id => dispatch(getBrand(id)),
    fetchStates: () => dispatch(getStates()),
    fetchCitiesByState: stateId => dispatch(getCitiesByState(stateId)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(EditBrand);

EditBrand.propTypes = {
  brand: PropTypes.object,
  cities: PropTypes.array,
  fetchBrand: PropTypes.func,
  fetchCitiesByState: PropTypes.func,
  fetchStates: PropTypes.func,
  loading: PropTypes.bool,
  match: PropTypes.object,
  states: PropTypes.array,
  submitBrand: PropTypes.func,
  token: PropTypes.string,
};
