import React, { Component } from 'react';
import styled from 'styled-components';
import config from 'config';
import { Colors } from 'modules/theme';
import DropZone from 'components/DropZone';
import Table, { THeader, TableTitle, TRow, TableItem, TBody } from 'components/Table';
import H4 from 'components/H4';
import H5 from 'components/H5';
import ButtonText from 'components/ButtonText';
import AlertConfirm from 'components/AlertConfirm';
import Icon from 'components/Icon';
import ListEmpty from 'components/ListEmpty';
import Line from 'components/Line';
import PropTypes from 'prop-types';

const url = `${config.BASE_URL}/locations/import`;

const Container = styled.div``;

const TableContainer = styled.div``;

const ErrorsContainer = styled.div`
  text-align: left;
`;

const ErrorContainer = styled.div`
  flex-direction: row;
  line-height: 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  padding-bottom: 15px;
`;

const ListContainer = styled.div``;

const ItemList = styled.div`
  border: 1px solid ${props => (props.isActive ? Colors.lightBlue : Colors.gray9)};
  box-sizing: border-box;
  border-radius: 5px;
  padding: 15px;
  margin: 15px 0px;
`;

const ItemHeader = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
`;
const ItemTable = styled.div``;

const LeftComponent = styled.div`
  flex-direction: column;
  width: 95%;
`;
const RightComponent = styled.div`
  flex-direction: column;
  float: right;
  width: 5%;
`;

const LineContainer = styled.div`
  margin-top: 30px;
`;

export default class AccountsForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      accounts: props.accounts || [],
      isOpenRedoAlert: false,
      isOpenNextAlert: false,
      listOpenId: null,
      hasErrors: false,
    };
  }

  submit = () => {
    const { onSubmit } = this.props;
    const { accounts } = this.state;
    const activeTab = 'Data';

    const accountsApproved = accounts.map(account => {
      const { locations } = account;
      return {
        ...account,
        locations: locations.filter(location => !location.errors.length),
      };
    });

    onSubmit({ accounts: accountsApproved }, activeTab);
  };

  switchList = key => {
    const { listOpenId } = this.state;
    const id = listOpenId !== key ? key : null;

    this.setState({ listOpenId: id });
  };

  renderRodoAlert = () => {
    const { isOpenRedoAlert, listOpenId, accounts } = this.state;

    if (!isOpenRedoAlert) {
      return null;
    }

    const accountsCopy = accounts;
    accountsCopy[listOpenId].locations = [];

    return (
      <AlertConfirm
        title="Redo the import"
        onCancel={() => this.setState({ isOpenRedoAlert: false })}
        onSuccess={() =>
          this.setState({ accounts: accountsCopy, isOpenRedoAlert: false, hasErrors: false })
        }
        successText="Remove"
        isVisible={isOpenRedoAlert}
      >
        <H4 style={{ textAlign: 'center' }}>Are you sure you want to redo the import?</H4>
      </AlertConfirm>
    );
  };

  renderNextAlert = () => {
    const { isOpenNextAlert } = this.state;

    return (
      <AlertConfirm
        title="Alert"
        onCancel={() => this.setState({ isOpenNextAlert: false })}
        onSuccess={() => {
          this.setState({ isOpenNextAlert: false }, () => {
            this.submit();
          });
        }}
        successText="Next"
        isVisible={isOpenNextAlert}
      >
        <H4 style={{ textAlign: 'center' }}>Are you sure you want continue with errors?</H4>
      </AlertConfirm>
    );
  };

  renderLocation = (location, key) => (
    <TRow key={key}>
      <TableItem style={{ minHeight: 130 }} width="15%">
        <H5 style={{ overflow: 'hidden' }}>{location.identificator}</H5>
      </TableItem>
      <TableItem style={{ minHeight: 130 }} width="20%">
        <H5 style={{ overflow: 'hidden' }}>
          {`${location.address_line}${
            location.address_line_1 ? ' ' : ''
          }${location.address_line_1 || ''},`}
        </H5>
        <H5 style={{ overflow: 'hidden' }}>{`${location.zip_code || ''}`}</H5>
      </TableItem>
      <TableItem style={{ minHeight: 130 }} width="15%">
        <H5 style={{ overflow: 'hidden' }}>
          {`${location.contact_first_name} ${location.contact_last_name}`}
        </H5>
      </TableItem>
      <TableItem style={{ minHeight: 130 }} width="15%">
        <H5 style={{ overflow: 'hidden' }}>{location.contact_email}</H5>
        <H5 style={{ overflow: 'hidden' }}>{location.contact_phone_number}</H5>
      </TableItem>
      <TableItem style={{ minHeight: 130 }} width="15%">
        <H5 style={{ overflow: 'hidden' }}>
          {`${location.installer_first_name} ${location.installer_last_name}`}
        </H5>
      </TableItem>
      <TableItem style={{ minHeight: 130 }} width="20%">
        {location.errors && location.errors.length ? (
          <ErrorsContainer>
            {location.errors.map((error, key) => (
              <ErrorContainer key={key}>
                <Icon
                  name="exclamation-circle"
                  color={Colors.red}
                  customStyle={{ marginRight: '10px' }}
                />
                {error}
              </ErrorContainer>
            ))}
          </ErrorsContainer>
        ) : (
          <Icon name="check-circle" fontSize={25} color={Colors.green} />
        )}
      </TableItem>
    </TRow>
  );

  renderAccount = (account, key) => {
    const { token, dropzoneTitle } = this.props;
    const { listOpenId } = this.state;
    const isActive = listOpenId === key;

    return (
      <ItemList isActive={isActive} key={key}>
        <ItemHeader>
          <LeftComponent>
            <H5 style={{ overflow: 'hidden' }}>
              {`${account.address_line} ${
                account.address_line_1 ? ' ' : ''
              }${account.address_line_1 || ''}, ${account.zip_code || ''}`}
            </H5>
            <H4>{account.name}</H4>
          </LeftComponent>
          <RightComponent>
            <Icon
              name={isActive ? 'chevron-up' : 'chevron-down'}
              onClick={() => this.switchList(key)}
            />
          </RightComponent>
        </ItemHeader>
        {isActive && (
          <ItemTable>
            {account.locations.length ? (
              <TableContainer>
                <ButtonContainer>
                  <ButtonText
                    theme="text"
                    icon="redo"
                    text="Redo Import"
                    onClick={() => this.setState({ isOpenRedoAlert: true })}
                  />
                </ButtonContainer>
                <Table>
                  <THeader>
                    <TableTitle width="15%">Name</TableTitle>
                    <TableTitle width="20%">Address</TableTitle>
                    <TableTitle width="15%">Admin</TableTitle>
                    <TableTitle width="15%">Contact</TableTitle>
                    <TableTitle width="15%">Installer</TableTitle>
                    <TableTitle width="20%">Status</TableTitle>
                  </THeader>
                  <TBody>
                    {account.locations.map((location, key) => this.renderLocation(location, key))}
                  </TBody>
                </Table>
              </TableContainer>
            ) : (
              <DropZone
                token={token}
                url={url}
                handleResponse={this.handleResponse}
                link={config.LOCATIONS_CSV_EXAMPLE_URL}
                title={dropzoneTitle}
              />
            )}
          </ItemTable>
        )}
      </ItemList>
    );
  };

  handleResponse = locations => {
    const { listOpenId, accounts } = this.state;
    const accountsCopy = accounts;
    accountsCopy[listOpenId].locations = locations;
    const hasErrors =
      accounts.findIndex(
        account =>
          account.locations.findIndex(location => location.errors && location.errors.length) !== -1,
      ) !== -1;

    this.setState({
      hasErrors,
      accounts: accountsCopy,
    });
  };

  render() {
    const { hasErrors, accounts } = this.state;
    const { back } = this.props;

    return (
      <Container>
        {accounts.length ? (
          <ListContainer>
            {accounts.map((account, key) => this.renderAccount(account, key))}
          </ListContainer>
        ) : (
          <ListEmpty message="There are currently no accounts" />
        )}
        <LineContainer>
          <Line />
        </LineContainer>
        <ButtonContainer>
          <ButtonText
            text="Back"
            type="button"
            theme="default"
            customStyles={{ marginTop: '20px', marginRight: '20px' }}
            onClick={back}
          />
          <ButtonText
            text={accounts.length ? `Next${hasErrors ? ', with errors' : ''}` : 'Skip'}
            theme="primary"
            customStyles={{ marginTop: '20px' }}
            onClick={() => (hasErrors ? this.setState({ isOpenNextAlert: true }) : this.submit())}
          />
        </ButtonContainer>
        {this.renderRodoAlert()}
        {this.renderNextAlert()}
      </Container>
    );
  }
}

AccountsForm.propTypes = {
  accounts: PropTypes.array,
  back: PropTypes.func,
  dropzoneTitle: PropTypes.string,
  onSubmit: PropTypes.func,
  token: PropTypes.string,
};
