import { all, takeLatest } from 'redux-saga/effects';
import { ActionTypes } from 'constants/index';
import client from 'modules/client';

import { startupApp } from './startupSaga';
import {
  login,
  recoverPassword,
  resetPassword,
  newPassword,
  logout,
  updateProfile,
  changePassword,
  closeAccount,
  resendInvitation,
} from './authSaga';
import {
  getLibraries,
  getCatridge,
  getWaterFilter,
  getConfiguration,
  getCatridgesForFilter,
  getReverseOsmosisMembrane,
  getReverseOsmosis,
  getSoftener,
  getBrineDrum,
  getTanks,
  getWaterFiltersForFilter,
  getReverseOsmosisMembraneForFilter,
  getReverseOsmosisForFilter,
  getSoftenerForFilter,
  getBrineDrumForFilter,
  getWaterFilterConfigurationsForFilter,
  getReverseOsmosisConfigurationsForFilter,
  getSoftenerConfigurationsForFilter,
  createWaterFilter,
  createWaterCatridge,
  createDeviceConfiguration,
  createSoftener,
  createReverseOsmosis,
  createBrineDrum,
  createReverseOsmosisMembrane,
  createSensor,
  editSensor,
  getSensor,
  editWaterFilter,
  editWaterCatridge,
  editDeviceConfiguration,
  editSoftener,
  editReverseOsmosis,
  editBrineDrum,
  editReverseOsmosisMembrane,
  deleteLibrary,
  getFlowSensorsForSelect,
  getPressureSensorsForSelect,
  getDistanceSensorsForSelect,
  getTdsProbeSensorsForSelect,
  getTemperatureSensorsForSelect,
} from './librarySaga';
import {
  getLocations,
  getLocation,
  getLocationsForFilter,
  createLocation,
  editLocation,
  deleteLocation,
  changeMembranes,
  getCsvFiles,
  setDeviceId,
  updateInstallationStatus,
  getWaterFilterCurrentState,
  getWaterFilterCurrentPressure,
  getWaterFilterConsumption,
  getWaterFilterMaxFlowRate,
  getWaterFilterPressureForFlowRate,
  getWaterFilterPressureForConsumption,
  getWaterFilterBypass,
  getTotalConsumptionWaterFilter,
  generateWaterFilterCsv,
  getWaterFilterCurrentBypass,
  getTotalConsumptionCartridgeWf,
  getAllTotalConsumptionCartridgeWf,
  replaceWaterFilterCartridges,
  getReverseOsmosisCurrentState,
  getReverseOsmosisConsumption,
  generateReverseOsmosisCsv,
  getTotalConsumptionReverseOsmosis,
  getReverseOsmosisWaste,
  getReverseOsmosisTds,
  getTotalConsumptionCartridgeRo,
  getAllTotalConsumptionCartridgeRo,
  getConsumptionFromMembraneChangeRo,
  replaceReverseOsmosisCartridges,
  getReverseOsmosisBypass,
  getReverseOsmosisCurrentBypass,
  getReverseOsmosisCurrentPressure,
  getReverseOsmosisCurrentTemperature,
  getSoftenerCurrentState,
  getSoftenerConsumption,
  getSoftenerPressure,
  getSoftenerBypass,
  getSoftenerWaste,
  generateSoftenerCsv,
  getConsumptionFromInstallSo,
  getSoftenerCurrentPressure,
  getSoftenerCurrentBypass,
} from './locationSaga';
import { getAlerts, getAllAlerts, markAlertAsViewed } from './alertSaga';
import {
  getAlertsTypes,
  getAlertType,
  createAlertType,
  editAlertType,
  deleteAlertType,
} from './alertTypeSaga';
import {
  getUsers,
  getUser,
  createUser,
  getInstallersByAccount,
  editUser,
  deleteUser,
  resendEmail,
} from './userSaga';
import {
  getAccounts,
  getAccount,
  getAccountsForFilter,
  createAccount,
  editAccount,
  deleteAccount,
} from './accountSaga';
import {
  getBrands,
  getBrand,
  getBrandsForFilter,
  createBrand,
  editBrand,
  deleteBrand,
  getAdminsAccounts,
  getAdminsLocations,
} from './brandSaga';
import { getSupports, checkAsRead, deleteSupport } from './supportSaga';
import { getRoles, getStates, getCitiesByState } from './utilsSaga';
import {
  getOtas,
  getOta,
  getOtaVersionsWfAndSoftener,
  getOtaVersionsRo,
  createOtaVersion,
  deleteOtaVersion,
  createUpgradeStatus,
  getUpgradeStatus,
  getUpgradeStatusDevices,
  getPreviousInstallationInfo,
  deleteUpgradeStatus,
  getUpdateActiveDevices,
} from './otaSaga';

/**
 * rootSaga
 */
const api = client.create();

export default function* root() {
  yield all([
    // startup
    takeLatest(ActionTypes.STARTUP, startupApp, api),
    // login
    takeLatest(ActionTypes.USER_LOGIN, login, api),
    takeLatest(ActionTypes.RECOVER_PASSWORD, recoverPassword, api),
    takeLatest(ActionTypes.RESET_PASSWORD, resetPassword, api),
    takeLatest(ActionTypes.NEW_PASSWORD, newPassword, api),
    takeLatest(ActionTypes.USER_LOGOUT, logout, api),
    // profile
    takeLatest(ActionTypes.UPDATE_PROFILE, updateProfile, api),
    takeLatest(ActionTypes.CHANGE_PASSWORD, changePassword, api),
    takeLatest(ActionTypes.CLOSE_ACCOUNT, closeAccount, api),
    takeLatest(ActionTypes.RESEND_INVITATION, resendInvitation, api),

    // library
    takeLatest(ActionTypes.GET_LIBRARIES, getLibraries, api),
    takeLatest(ActionTypes.GET_CATRIDGE, getCatridge, api),
    takeLatest(ActionTypes.GET_CATRIDGES_FOR_FILTER, getCatridgesForFilter, api),
    takeLatest(ActionTypes.GET_WATER_FILTERS_FOR_FILTER, getWaterFiltersForFilter, api),
    takeLatest(
      ActionTypes.GET_REVERSE_OSMOSIS_MEMBRANE_FOR_FILTER,
      getReverseOsmosisMembraneForFilter,
      api,
    ),
    takeLatest(ActionTypes.GET_REVERSE_OSMOSIS_FOR_FILTER, getReverseOsmosisForFilter, api),
    takeLatest(ActionTypes.GET_SOFTENER_FOR_FILTER, getSoftenerForFilter, api),
    takeLatest(ActionTypes.GET_BRINE_DRUM_FOR_FILTER, getBrineDrumForFilter, api),
    takeLatest(ActionTypes.GET_WATER_FILTER, getWaterFilter, api),

    takeLatest(ActionTypes.GET_REVERSE_OSMOSIS_MEMBRANE, getReverseOsmosisMembrane, api),
    takeLatest(ActionTypes.GET_REVERSE_OSMOSIS, getReverseOsmosis, api),
    takeLatest(ActionTypes.GET_SOFTENER, getSoftener, api),
    takeLatest(ActionTypes.GET_BRINE_DRUM, getBrineDrum, api),
    takeLatest(ActionTypes.GET_TANKS, getTanks, api),

    takeLatest(
      ActionTypes.GET_WATER_FILTER_CONFIGURATIONS_FOR_FILTER,
      getWaterFilterConfigurationsForFilter,
      api,
    ),
    takeLatest(
      ActionTypes.GET_REVERSE_OSMOSIS_CONFIGURATIONS_FOR_FILTER,
      getReverseOsmosisConfigurationsForFilter,
      api,
    ),
    takeLatest(
      ActionTypes.GET_SOFTENER_CONFIGURATIONS_FOR_FILTER,
      getSoftenerConfigurationsForFilter,
      api,
    ),

    takeLatest(ActionTypes.GET_CONFIGURATION, getConfiguration, api),
    takeLatest(ActionTypes.CREATE_WATER_FILTER, createWaterFilter, api),
    takeLatest(ActionTypes.CREATE_WATER_CATRIDGE, createWaterCatridge, api),
    takeLatest(ActionTypes.CREATE_CONFIGURATION, createDeviceConfiguration, api),
    takeLatest(ActionTypes.CREATE_SOFTENER, createSoftener, api),
    takeLatest(ActionTypes.CREATE_REVERSE_OSMOSIS, createReverseOsmosis, api),
    takeLatest(ActionTypes.CREATE_BRINE_DRUM, createBrineDrum, api),
    takeLatest(ActionTypes.CREATE_REVERSE_OSMOSIS_MEMBRANE, createReverseOsmosisMembrane, api),
    takeLatest(ActionTypes.CREATE_SENSOR, createSensor, api),
    takeLatest(ActionTypes.GET_SENSOR, getSensor, api),
    takeLatest(ActionTypes.EDIT_SENSOR, editSensor, api),
    takeLatest(ActionTypes.EDIT_WATER_FILTER, editWaterFilter, api),
    takeLatest(ActionTypes.EDIT_WATER_CATRIDGE, editWaterCatridge, api),
    takeLatest(ActionTypes.EDIT_CONFIGURATION, editDeviceConfiguration, api),
    takeLatest(ActionTypes.EDIT_SOFTENER, editSoftener, api),
    takeLatest(ActionTypes.EDIT_REVERSE_OSMOSIS, editReverseOsmosis, api),
    takeLatest(ActionTypes.EDIT_BRINE_DRUM, editBrineDrum, api),
    takeLatest(ActionTypes.EDIT_REVERSE_OSMOSIS_MEMBRANE, editReverseOsmosisMembrane, api),
    takeLatest(ActionTypes.DELETE_LIBRARY, deleteLibrary, api),
    takeLatest(ActionTypes.GET_FLOW_SENSORS_FOR_SELECT, getFlowSensorsForSelect, api),
    takeLatest(ActionTypes.GET_PRESSURE_SENSORS_FOR_SELECT, getPressureSensorsForSelect, api),
    takeLatest(ActionTypes.GET_DISTANCE_SENSORS_FOR_SELECT, getDistanceSensorsForSelect, api),
    takeLatest(ActionTypes.GET_TDS_PROBE_SENSORS_FOR_SELECT, getTdsProbeSensorsForSelect, api),
    takeLatest(ActionTypes.GET_TEMPERATURE_SENSORS_FOR_SELECT, getTemperatureSensorsForSelect, api),

    // location
    takeLatest(ActionTypes.GET_LOCATIONS, getLocations, api),
    takeLatest(ActionTypes.GET_LOCATION, getLocation, api),
    takeLatest(ActionTypes.GET_LOCATIONS_FOR_FILTER, getLocationsForFilter, api),
    takeLatest(ActionTypes.CREATE_LOCATION, createLocation, api),
    takeLatest(ActionTypes.EDIT_LOCATION, editLocation, api),
    takeLatest(ActionTypes.DELETE_LOCATION, deleteLocation, api),
    takeLatest(ActionTypes.CHANGE_MEMBRANES, changeMembranes, api),
    takeLatest(ActionTypes.GET_CSV_FILES, getCsvFiles, api),
    takeLatest(ActionTypes.SET_DEVICE_ID, setDeviceId, api),
    takeLatest(ActionTypes.UPDATE_INSTALLATION_STATUS, updateInstallationStatus, api),

    // water filter dynamo
    takeLatest(ActionTypes.GET_WATER_FILTER_CURRENT_STATE, getWaterFilterCurrentState, api),
    takeLatest(ActionTypes.GET_WATER_FILTER_CURRENT_PRESSURE, getWaterFilterCurrentPressure, api),
    takeLatest(ActionTypes.GET_WATER_FILTER_CONSUMPTION, getWaterFilterConsumption, api),
    takeLatest(ActionTypes.GET_WATER_FILTER_MAX_FLOW_RATE, getWaterFilterMaxFlowRate, api),
    takeLatest(
      ActionTypes.GET_WATER_FILTER_PRESSURE_FOR_FLOW_RATE,
      getWaterFilterPressureForFlowRate,
      api,
    ),
    takeLatest(
      ActionTypes.GET_WATER_FILTER_PRESSURE_FOR_CONSUMPTION,
      getWaterFilterPressureForConsumption,
      api,
    ),

    takeLatest(ActionTypes.GET_WATER_FILTER_BYPASS, getWaterFilterBypass, api),
    takeLatest(ActionTypes.GET_TOTAL_CONSUMPTION_WATER_FILTER, getTotalConsumptionWaterFilter, api),
    takeLatest(ActionTypes.GENERATE_WATER_FILTER_CSV, generateWaterFilterCsv, api),
    takeLatest(ActionTypes.GET_WATER_FILTER_CURRENT_BYPASS, getWaterFilterCurrentBypass, api),
    takeLatest(ActionTypes.GET_TOTAL_CONSUMPTION_CARTRIDGE_WF, getTotalConsumptionCartridgeWf, api),
    takeLatest(
      ActionTypes.GET_ALL_TOTAL_CONSUMPTION_CARTRIDGE_WF,
      getAllTotalConsumptionCartridgeWf,
      api,
    ),
    takeLatest(ActionTypes.REPLACE_WATER_FILTER_CARTRIDGES, replaceWaterFilterCartridges, api),

    // reverse osmosis dynamo
    takeLatest(ActionTypes.GET_REVERSE_OSMOSIS_CURRENT_STATE, getReverseOsmosisCurrentState, api),
    takeLatest(ActionTypes.GET_REVERSE_OSMOSIS_CONSUMPTION, getReverseOsmosisConsumption, api),
    takeLatest(ActionTypes.GENERATE_REVERSE_OSMOSIS_CSV, generateReverseOsmosisCsv, api),
    takeLatest(ActionTypes.GET_REVERSE_OSMOSIS_WASTE, getReverseOsmosisWaste, api),
    takeLatest(ActionTypes.GET_REVERSE_OSMOSIS_TDS, getReverseOsmosisTds, api),
    takeLatest(
      ActionTypes.GET_TOTAL_CONSUMPTION_REVERSE_OSMOSIS,
      getTotalConsumptionReverseOsmosis,
      api,
    ),
    takeLatest(
      ActionTypes.GET_CONSUMPTION_FROM_MEMBRANE_CHANGE_RO,
      getConsumptionFromMembraneChangeRo,
      api,
    ),
    takeLatest(ActionTypes.GET_TOTAL_CONSUMPTION_CARTRIDGE_RO, getTotalConsumptionCartridgeRo, api),
    takeLatest(
      ActionTypes.GET_ALL_TOTAL_CONSUMPTION_CARTRIDGE_RO,
      getAllTotalConsumptionCartridgeRo,
      api,
    ),
    takeLatest(
      ActionTypes.REPLACE_REVERSE_OSMOSIS_CARTRIDGES,
      replaceReverseOsmosisCartridges,
      api,
    ),
    takeLatest(ActionTypes.GET_REVERSE_OSMOSIS_BYPASS, getReverseOsmosisBypass, api),
    takeLatest(ActionTypes.GET_REVERSE_OSMOSIS_CURRENT_BYPASS, getReverseOsmosisCurrentBypass, api),
    takeLatest(
      ActionTypes.GET_REVERSE_OSMOSIS_CURRENT_PRESSURE,
      getReverseOsmosisCurrentPressure,
      api,
    ),
    takeLatest(
      ActionTypes.GET_REVERSE_OSMOSIS_CURRENT_TEMPERATURE,
      getReverseOsmosisCurrentTemperature,
      api,
    ),

    // softener dynamo
    takeLatest(ActionTypes.GET_SOFTENER_CURRENT_STATE, getSoftenerCurrentState, api),
    takeLatest(ActionTypes.GET_SOFTENER_CONSUMPTION, getSoftenerConsumption, api),
    takeLatest(ActionTypes.GET_SOFTENER_PRESSURE, getSoftenerPressure, api),
    takeLatest(ActionTypes.GET_SOFTENER_BYPASS, getSoftenerBypass, api),
    takeLatest(ActionTypes.GET_SOFTENER_WASTE, getSoftenerWaste, api),
    takeLatest(ActionTypes.GENERATE_SOFTENER_CSV, generateSoftenerCsv, api),
    takeLatest(ActionTypes.GET_CONSUMPTION_FROM_INSTALL_SO, getConsumptionFromInstallSo, api),
    takeLatest(ActionTypes.GET_SOFTENER_CURRENT_PRESSURE, getSoftenerCurrentPressure, api),
    takeLatest(ActionTypes.GET_SOFTENER_CURRENT_BYPASS, getSoftenerCurrentBypass, api),

    // alert
    takeLatest(ActionTypes.GET_ALERTS, getAlerts, api),
    takeLatest(ActionTypes.GET_ALL_ALERTS, getAllAlerts, api),
    takeLatest(ActionTypes.MARK_ALERT_AS_VIEWED, markAlertAsViewed, api),

    // alert type
    takeLatest(ActionTypes.GET_ALERTS_TYPES, getAlertsTypes, api),
    takeLatest(ActionTypes.GET_ALERT_TYPE, getAlertType, api),
    takeLatest(ActionTypes.CREATE_ALERT_TYPE, createAlertType, api),
    takeLatest(ActionTypes.EDIT_ALERT_TYPE, editAlertType, api),
    takeLatest(ActionTypes.DELETE_ALERT_TYPE, deleteAlertType, api),
    // user
    takeLatest(ActionTypes.GET_USERS, getUsers, api),
    takeLatest(ActionTypes.GET_USER, getUser, api),
    takeLatest(ActionTypes.RESEND_EMAIL, resendEmail, api),
    takeLatest(ActionTypes.CREATE_USER, createUser, api),
    takeLatest(ActionTypes.GET_INSTALLERS_BY_ACCOUNT, getInstallersByAccount, api),
    takeLatest(ActionTypes.EDIT_USER, editUser, api),
    takeLatest(ActionTypes.DELETE_USER, deleteUser, api),
    // account
    takeLatest(ActionTypes.GET_ACCOUNTS, getAccounts, api),
    takeLatest(ActionTypes.GET_ACCOUNT, getAccount, api),
    takeLatest(ActionTypes.GET_ACCOUNTS_FOR_FILTER, getAccountsForFilter, api),
    takeLatest(ActionTypes.CREATE_ACCOUNT, createAccount, api),
    takeLatest(ActionTypes.EDIT_ACCOUNT, editAccount, api),
    takeLatest(ActionTypes.DELETE_ACCOUNT, deleteAccount, api),
    // brand
    takeLatest(ActionTypes.GET_BRANDS, getBrands, api),
    takeLatest(ActionTypes.GET_BRAND, getBrand, api),
    takeLatest(ActionTypes.GET_BRANDS_FOR_FILTER, getBrandsForFilter, api),
    takeLatest(ActionTypes.CREATE_BRAND, createBrand, api),
    takeLatest(ActionTypes.EDIT_BRAND, editBrand, api),
    takeLatest(ActionTypes.DELETE_BRAND, deleteBrand, api),
    takeLatest(ActionTypes.GET_ADMINS_ACCOUNTS, getAdminsAccounts, api),
    takeLatest(ActionTypes.GET_ADMINS_LOCATIONS, getAdminsLocations, api),
    // support
    takeLatest(ActionTypes.GET_SUPPORTS, getSupports, api),
    takeLatest(ActionTypes.CHECK_AS_READ, checkAsRead, api),
    takeLatest(ActionTypes.DELETE_SUPPORT, deleteSupport, api),
    // utils
    takeLatest(ActionTypes.GET_ROLES, getRoles, api),
    takeLatest(ActionTypes.GET_STATES, getStates, api),
    takeLatest(ActionTypes.GET_CITIES_BY_STATE, getCitiesByState, api),
    // Ota
    takeLatest(ActionTypes.GET_OTAS, getOtas, api),
    takeLatest(ActionTypes.GET_OTA, getOta, api),
    takeLatest(ActionTypes.GET_OTA_VERSIONS_WF_AND_SOFTENER, getOtaVersionsWfAndSoftener, api),
    takeLatest(ActionTypes.GET_OTA_VERSIONS_RO, getOtaVersionsRo, api),
    takeLatest(ActionTypes.CREATE_OTA_VERSION, createOtaVersion, api),
    takeLatest(ActionTypes.DELETE_OTA_VERSION, deleteOtaVersion, api),
    takeLatest(ActionTypes.GET_UPGRADE_STATUS, getUpgradeStatus, api),
    takeLatest(ActionTypes.GET_UPGRADE_STATUS_DEVICES, getUpgradeStatusDevices, api),
    takeLatest(ActionTypes.CREATE_UPGRADE_STATUS, createUpgradeStatus, api),
    takeLatest(ActionTypes.GET_PREVIOUS_INSTALLATION_INFO, getPreviousInstallationInfo, api),
    takeLatest(ActionTypes.DELETE_UPGRADE_STATUS, deleteUpgradeStatus, api),
    takeLatest(ActionTypes.GET_UPDATE_ACTIVE_DEVICES, getUpdateActiveDevices, api),
  ]);
}
