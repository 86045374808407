// @flow
/**
 * @module Actions/Account
 * @desc Account Actions
 */
import { createActions } from 'redux-actions';

import { ActionTypes } from 'constants/index';

export const {
  getAccounts,
  getAccount,
  getAccountsForFilter,
  createAccount,
  editAccount,
  deleteAccount,
} = createActions({
  [ActionTypes.GET_ACCOUNTS]: params => params,
  [ActionTypes.GET_ACCOUNT]: id => ({ id }),
  [ActionTypes.GET_ACCOUNTS_FOR_FILTER]: params => params,
  [ActionTypes.CREATE_ACCOUNT]: data => data,
  [ActionTypes.EDIT_ACCOUNT]: (id, account) => ({ id, account }),
  [ActionTypes.DELETE_ACCOUNT]: id => ({ id }),
});
